import appGlobalConfig from '../appGlobalConfig';

export async function getPrivateFundsBySearchKey(searchkey) {

  let api = appGlobalConfig.apiGetPrivateFundsBySearchKey + "?searchkey="+searchkey
  let output = await (await fetch(api)).json();
  console.log(output)
  return output.message;
}

export async function getLatestPrivateFunds() {

  let api = appGlobalConfig.apiGetLatestPrivateFunds
  let output = await (await fetch(api)).json();
  return output.message;
}

export async function getPrivateFunds(account) {

  let api = appGlobalConfig.apiGetPrivateFunds + "?account="+account
  let output = await (await fetch(api)).json();
  return output.message;

}

export async function getFundActivitiesByWalletOrContractAddr(account, contractaddress, showAll = false) {

  let api = appGlobalConfig.apiGetFundActivitiesByWalletOrContractAddr + "?account="+account+"&contractaddress="+contractaddress+"&showAll="+showAll
  let output = await (await fetch(api)).json();
  return output.message;

}
