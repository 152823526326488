// import Web3 from "web3";
import xWinStableAlpha from "../abi/v2/xWinStableAlpha.json";
import { convertFromWei } from "../utils/helper";

export async function getCompositionValues(web3, contractaddress) {

  try {
    const contract = new web3.eth.Contract(xWinStableAlpha, contractaddress);

    const promiseData = await Promise.all([
      contract.methods.getStableValues().call(),
      contract.methods.getTargetValues().call(),
    ]);
    let stableValues = promiseData[0]
    let targetValues = promiseData[1]

    return {
      "stableValues": convertFromWei(stableValues || 0),
      "targetValues": convertFromWei(targetValues || 0),
    };

    // return {
    //   "stableValues": 0,
    //   "targetValues": 0,
    // };
      
  } catch (error) {
    console.log(error)
  }
}

export async function getNextInvestmentAmount(web3, contractaddress) {

	const contract = new web3.eth.Contract(xWinStableAlpha, contractaddress);
	let amount = await contract.methods.getAmountToSwap().call();
  return {
    "nextInvestAmount": convertFromWei(amount || 0)
  };
}

export async function getNextInvestmentBlock(web3, contractaddress) {

	const contract = new web3.eth.Contract(xWinStableAlpha, contractaddress);
	let blocks = await contract.methods.getNextInvestBlock().call();
  return blocks;
}

export async function getStableTargetName(web3, contractaddress, tokensMaster) {

	const contract = new web3.eth.Contract(xWinStableAlpha, contractaddress);
  const promiseData = await Promise.all([
    contract.methods.stablecoin().call(),
    contract.methods.targetToken().call(),
  ]);
  let baseCcy = promiseData[0]
  let targetCcy = promiseData[1]
  
  return {
    "baseCcy": tokensMaster.find(x=>x.address === baseCcy)?.name,
    "targetCcy" : tokensMaster.find(x=>x.address === targetCcy)?.name
  };
}

