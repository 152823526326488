import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => (
  {
    hoverHighlight: {
      "&:hover": {
        boxShadow: theme.customShadows.widgetWide,
        borderRadius:"20px"
      },
    },
    expanded: { 
      backgroundColor: theme.palette.background.default,
    },
    expansion: {
      backgroundColor: theme.palette.primary.light,
      color: 'white '
    },
    column1: {
      flexBasis: '20.00%',
      verticalAlign: 'center',
    },
    column2: {
      flexBasis: '20.00%',
      verticalAlign: 'center',
    },
    column3: {
      flexBasis: '40.00%',
      verticalAlign: 'center',
    },
    column4: {
      flexBasis: '50.00%',
      verticalAlign: 'right',
      alignItems: "right"
    },
    column5: {
      flexBasis: '25.00%',
      verticalAlign: 'bottom',
    },
    column6: {
      flexBasis: '60.00%',
      verticalAlign: 'bottom',
    },
    columnHide: {
      [theme.breakpoints.down('xs')]: {
        display: "none"
      }
    },
    columnShow: {
      display: "none",
      [theme.breakpoints.down('xs')]: {
        display: "inline"
      }
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    newsHeader: {
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: '#536DFE',
      padding: theme.spacing(0, 0),
    },
    subTitle: {
      color: theme.palette.primary.main,
    },
    subSuccess: {
      color: theme.palette.warning.dark,
    },
    avatar: {
      height: 32,
      width: 32
    },
    card: {
      minWidth: "33%",
      backgroundImage: props => `url(${props.card})`,
      backgroundSize: "cover",
      backgroundPosition: "50%",
      color: "#fff",
      padding: theme.spacing(2,2,2,5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0,0,0,0),
      }
    },
    cardhide: {
      minWidth: "33%",
      backgroundImage: props => `url(${props.card})`,
      backgroundSize: "cover",
      backgroundPosition: "50%",
      color: "#fff",
      padding: theme.spacing(2,2,2,5),
      [theme.breakpoints.down('xs')]: {
        display: "none",
        padding: theme.spacing(0,0,0,0),
      }
    },
    cardMedia: {
      minWidth: "100%",
    },
    media: {
      height: "140px",
    },
    starIcon: {
      color: "#ffc247",
    },
    cardActions: {
      padding: theme.spacing(2)
    },
    snackbarWarning: {
      backgroundColor: theme.palette.warning.main,
    },
    snackbarSuccess: {
      backgroundColor: theme.palette.success.main,
      color: "black"
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      //color: theme.palette.primary.main,
      fontWeight: 'bold',
      color: theme.palette.success.main,
      visited: {
        color: theme.palette.success.main,
      }
    },
    avatar: {
      height: 50,
      width: 50,
      [theme.breakpoints.down('xs')]: {
        height: 25,
        width: 25,
      }
    },
    icon1: {
      height: "32px",
      width: "32px",
      [theme.breakpoints.down('xs')]: {
        height: 25,
        width: 25,
      },
      zIndex: "3",
      borderRadius: "50px",
      justifyContent: "center",
      // background: "#FFFFFF",
      alignItems: "center",
      marginRight: "-25px",
    },
    icon2: {
      height: "45px",
      width: "45px",
      [theme.breakpoints.down('xs')]: {
        height: 32,
        width: 32,
      },
      zIndex: "1",
      borderRadius: "50px",
      justifyContent: "center",
      // background: "#FFFFFF",
      alignItems: "center",
      marginRight: "-15px",
    },
    avatarSmall: {
      height: 20,
      width: 20,
      [theme.breakpoints.down('xs')]: {
        height: 15,
        width: 15,
      },
      zIndex: "3",
      borderRadius: "50px",
      justifyContent: "center",
      // background: "#FFFFFF",
      alignItems: "center",
      // marginRight: "-25px",
    },
  }
))