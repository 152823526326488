import React, { useLayoutEffect, useEffect  } from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Box } from '@material-ui/core';
import siteLogo from '../../../assets/images/xWin-logo.png';
import './Header.css';
import AOS from 'aos';
import '../../home.css';
import "font-awesome/css/font-awesome.min.css";

function Header() {
	useEffect(() => {
		AOS.init({
			disable: 'mobile'
		  });
	});
	return (
		<header id="header">
			<Container maxWidth="lg">
				<Grid container className="container" spacing={0} display="flex" alignItems="center">
					<Grid className="logo-container" item xs={12} sm={6} justify={{ xs: 'center', sm: 'flex-start' }} dispaly="flex">
						<Box component="strong" className="logo" display="inline-block">
							<Link data-aos="zoom-out" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" to="/" className="d-inline-block align-top">
								<img className="img-fluid" src={siteLogo} alt="Site Logo" />
							</Link>
						</Box>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Box data-aos="fade-up" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" display="flex" justifyContent="flex-end" component="ul" className="social-links list-unstyled">
							<Box component="li" className="plane"><a href="https://www.t.me/xwinfinance" rel="noreferrer" target="_blank" >&nbsp;</a></Box>
							<Box component="li" className="facebook"><a href="https://www.facebook.com/toppy.xwin" rel="noreferrer" target="_blank" >&nbsp;</a></Box>
							<Box component="li" className="twitter"><a href="https://twitter.com/xwinfinance" rel="noreferrer" target="_blank" >&nbsp;</a></Box>
							<Box component="li" className="mail"><a href="https://xwin.medium.com/" rel="noreferrer" target="_blank" >&nbsp;</a></Box>
							<Box component="li" className="fa fa-git"><a href="https://github.com/xwinfinance/xWinBSC" rel="noreferrer" target="_blank" >&nbsp;</a></Box>
							{/* <i className="fa fa-git" /> */}
						</Box>
					</Grid>
				</Grid>
			</Container>
		</header>
	);
}
export default Header;