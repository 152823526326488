import React, { useEffect, useState, useRef, useContext  } from "react";
import xWinLib from '../../../xWinLib';
import GetAppTwoToneIcon from '@material-ui/icons/GetAppTwoTone';
import format from 'format-number';
import { HarvestAll } from "../../../utils/masterChefInteractor";
import SnackbarMessage from '../../snackbarmessage/SnackbarMessage'
import confetti from 'canvas-confetti'

import {
  Grid,
  Button,
} from "@material-ui/core";
import { Typography} from "../../../components/Wrappers/Wrappers";
import {AppContext}  from '../../../context/AppProvider';

// styles
import useStyles from "../styles";
import xWinUtils from "../../../xWinUtils";

export default function MyRewardValue(props) {
  var classes = useStyles();
  const { prices, loading, farmCollections, farmTotalPending, parentCallback, ...rest } = props;
  const { account, theWeb3 } = useContext(AppContext);
  const [totalFarmEarn, setTotalFarmEarn] = useState("0")
  
  const [loadingTxn, setLoadingTxn] = React.useState(false);
  const refsnackbar = useRef(null)
  const showSnackBar = (status, msg) => {
      refsnackbar.current.handleOpen(status, msg)
  }

  useEffect(() => {
    let activeFarm = farmCollections.filter(x=>x.pending > 0);
    setTotalFarmEarn(activeFarm.length)
  }, [farmCollections]);

  const handleClickHarvest = async event => {
    event.preventDefault();
    
    setLoadingTxn(true)

    HarvestAll(theWeb3, account)
    .then(res => {
        console.log(res)
        setLoadingTxn(false)
        showSnackBar('success', res)
        confetti()
        parentCallback
    })
    .catch(err => {
        setLoadingTxn(false)
        console.log(err)
        showSnackBar('error', err)
        parentCallback
    })

  };

  return (
    
      <>
        {xWinUtils.getProgress(loadingTxn)}
        <SnackbarMessage ref={refsnackbar} />
        <Grid item lg={6} sm={12} xs={12}>
        <Typography className={classes.positiveNum} >
          Total Harvest: {xWinUtils.displayBalance(false, format({prefix: "", suffix: ""})(xWinLib.roundTo(farmTotalPending,5)))}
        </Typography>  
        </Grid>
        <Grid item lg={6} sm={12} xs={12}>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            onClick={handleClickHarvest}
          >
              <GetAppTwoToneIcon />
              Harvest all ({totalFarmEarn})
          </Button>
        </Grid>
        <br/>
      </>
    
  );
}
