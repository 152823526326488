import React, { useState, useEffect, useContext } from 'react'
import xWinLib from '../../../xWinLib'
import format from 'format-number'
import xWinUtils from '../../../xWinUtils'
import moment from 'moment'
import {
    Box,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableContainer,
} from '@material-ui/core'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// styles
import useStyles from '../styles'
import Widget from '../../../components/Widget/Widget'
import { lstat } from 'fs-extra'
import { maxHeight } from '@mui/system'
import { makeStyles } from '@material-ui/core/styles';
import { GetMarketTrends, GetSharpeRatio } from '../../../utils/firestoreDataInteractor'


export default function AssetSharpeRatio(props) {
    var classes = useStyles()
    const { tokensMaster } = props
    const [expectedReturns3M,setExpectedReturns3M] = React.useState([])
    const [expectedReturns, setExpectedReturns] = React.useState([])
    const [expectedReturns12M,setExpectedReturns12M] = React.useState([])
    const [dropdown, setDropdown]= React.useState("6-months")
    const isDark = localStorage.getItem("theme")

    const [
        lastMonthExpectedReturns,
        setLastMonthExpectedReturns,
    ] = React.useState([])

    const yyyymm = moment(new Date())
        .endOf('month')
        .format('YYYYMM')

    const lastMonth = moment(yyyymm)
        .subtract(1, 'month')
        .format('YYYYMM')

    useEffect(() => {
        fetchData()
    }, [dropdown])


    useEffect(() => {
        if (expectedReturns.length < 1) {
            setExpectedReturns(lastMonthExpectedReturns)
        }
    }, [expectedReturns])

    const fetchData = async () => {
    
        const promiseData = await Promise.all([
            GetSharpeRatio("optimization-6m", yyyymm),
            GetSharpeRatio("optimization-3m", yyyymm),
            GetSharpeRatio("optimization-12m", yyyymm),
            GetSharpeRatio("optimization-6m", lastMonth),
        ]);
        let o6m = promiseData[0]
        let o3m = promiseData[1]
        let o12m = promiseData[2]
        let o6mLast = promiseData[3]

        o6m.sort(xWinLib.compareValues('sharpeRatio', 'desc'))
        setExpectedReturns(o6m.slice(0, 10))

        o3m.sort(xWinLib.compareValues('sharpeRatio', 'desc'))
        setExpectedReturns3M(o3m.slice(0, 10))

        o12m.sort(xWinLib.compareValues('sharpeRatio', 'desc'))
        setExpectedReturns12M(o12m.slice(0, 10))

        o6mLast.sort(xWinLib.compareValues('sharpeRatio', 'desc'))
        setLastMonthExpectedReturns(o6mLast.slice(0, 10))
    };

    const handleChangeDropdown = (event) => {
        setDropdown(event.target.value);    
    }

    const displaySharpeRatio = (dropdown) => {
        let data
        if(dropdown === "3-months"){
            data = expectedReturns3M
        }else if(dropdown === "6-months"){
            data = expectedReturns

        }else{
            data= expectedReturns12M

        }
        if (data?.length > 0) {
            return data.map(r => (
                <TableRow hover>
                    <TableCell width={'30%'}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'left'}
                        >
                            {xWinUtils.getIcons(
                                xWinLib.getTokenName(tokensMaster, r.asset)
                            )}
                        </Box>
                    </TableCell>
                    <TableCell className={classes.contentText}>
                            {xWinLib.getTokenName(tokensMaster, r.asset)}
                    </TableCell>
                    <TableCell width={'30%'} className={classes.positiveNum}>
                            {xWinLib.roundTo(r.sharpeRatio, 2)}
                    </TableCell>
                </TableRow>
            ))
        }else{
            <TableRow hover>
                <TableCell width={"100%"} colSpan="3">
                {xWinUtils.getProgressLinear(true)}
                </TableCell>
            </TableRow>
        }
    }



    return (
        <Widget
            bodyClass={classes.mainChartBody}
            title="Asset Sharpe Ratio"
            disableWidgetMenu
        >
            <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between",backgroundColor:"transparent"}}>
                <FormControl  sx={{  minWidth: 50,  }} size="small" variant="standard">
                    <Select
                    labelStyle={{ color: '#ff0000' }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={dropdown}
                    label="months"
                    onChange={handleChangeDropdown}
                    sx={{ color:(isDark === "dark") && "white", backgroundColor:(isDark === "dark") && "#2f2f36",paddingLeft:1 }}
                    >
                    <MenuItem  value="3-months">3-months</MenuItem>
                    <MenuItem value="6-months">6-months</MenuItem>
                    <MenuItem value="12-months">12-months</MenuItem>
                    </Select>
                </FormControl>
                </div>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.contentText} width={'25%'}></TableCell>
                            <TableCell className={classes.contentText} width={'30%'}>Name</TableCell>
                            <TableCell className={classes.positiveNum} width={'35%'}>Sharpe Ratio</TableCell>
                        </TableRow>
                    </TableHead>
                    {displaySharpeRatio(dropdown)}
                </Table>
            </TableContainer>
        </Widget>
    )
}
