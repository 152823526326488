import React, { useLayoutEffect, useEffect, useState  } from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Box, Button } from '@material-ui/core';
import './Banner.css';
import '../../home.css';
import AOS from 'aos';
import {withRouter} from 'react-router-dom'
import appGlobalConfig from "../../../../appGlobalConfig";
import CountUp from 'react-countup';
import xWinUtils from '../../../../xWinUtils';
// import Snowflakes from 'magic-snowflakes';
import confetti from 'canvas-confetti'
import Announcement from 'react-announcement'
import Logo from '../../../../images/logo/xwinlogo.jpg'

const Banner = props => {

	const [totalValueLock, setTotalValueLock] = React.useState(0);
	var count = 200;
	var defaults = {
	origin: { y: 0.7 }
	};

	function fire(particleRatio, opts) {
	confetti(Object.assign({}, defaults, opts, {
		particleCount: Math.floor(count * particleRatio)
	}));
	}

	fire(0.25, {
	spread: 26,
	startVelocity: 55,
	});
	fire(0.2, {
	spread: 60,
	});
	fire(0.35, {
	spread: 100,
	decay: 0.91,
	scalar: 0.8
	});
	fire(0.1, {
	spread: 120,
	startVelocity: 25,
	decay: 0.92,
	scalar: 1.2
	});
	fire(0.1, {
	spread: 120,
	startVelocity: 45,
	});
	
	useEffect(() => {
		fetchMarketData()
	}, []);
	
	//fetch market data from compound
	const fetchMarketData = async () => {
    
		let cancel = false;
		fetch(appGlobalConfig.apiGetTVL)
			.then((response) => {
			  if (response.ok) {
				return response.json().then(async data => {
				  if (cancel) {
					return;
				  }
				  setTotalValueLock(data)
				});
			  }
			  return Promise.reject(response);
			})
			.catch(/* similar stuff here */);
	  };

	useEffect(() => {
		AOS.init({
			disable: 'mobile'
		  });
	});

	const goToDashboard = event => {
		
		props.history.push('/app/dashboard')
	}
	
	const goToDoc = event => {
		window.open("https://xwinfinance.gitbook.io/xwin-docs/master","_blank")
	}
	
	return (
		<section id="banner">
			<Announcement
				title="Toppy Marketplace Launched!"
				subtitle="You can now create and sell your NFT in Toppy Market. You can buy Toppy Mystery Box and Stake for high APR in XWIN token"
				link="https://toppy.market"
				imageSource={Logo}
			/>
			<div className="banner-content">
				<Container maxWidth="lg" className="text-white container">
					<Grid data-aos="zoom-in-down" data-aos-offset="80" data-aos-duration="2000" data-aos-delay="200" container className="banner-holder" spacing={0} display="flex" direction="column">
						<h1>xWin Makes Investment Easier</h1>
						<h2>DEFI Investment Management in Binance Smart Chain</h2>
						<div className="btns-holder">
							
							<Button size="small" variant="contained" color="primary" onClick={goToDashboard}>Launch App</Button>
							<Button size="small" variant="contained" color="secondary" onClick={goToDoc}>xWin Docs</Button>
							<Button size="small" variant="contained" color="secondary" onClick= {xWinUtils.buyInPCS} >
								Swap $XWIN
							</Button>
						</div>
					</Grid>
				</Container>
			</div>
			<div className="value-locked-area">
				<Container maxWidth="lg">
					<Grid container spacing={0} display="flex" justify="center">
						<Box data-aos="zoom-in" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" textAlign="center">
							<h2>Total Value Locked (TVL) 
								<CountUp 
									separator=","
									decimals={0}
									decimal="."
									prefix=" $"   
									duration={1.75} 
									start={totalValueLock/2}         
									end={totalValueLock} />
							</h2> 
							<p>Across all Vaults and XWIN Farming Pools</p>
						</Box>
					</Grid>
				</Container>
			</div>
		</section>
	);
}

export default withRouter(Banner)
