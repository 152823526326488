import React, { useState, useEffect, useRef, useContext } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import format from 'format-number';
import "react-toastify/dist/ReactToastify.css";
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'

import { Typography, Button } from "../../components/Wrappers/Wrappers";
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import HomeIcon from '@material-ui/icons/Home';
import EditIcon from '@material-ui/icons/Edit';
import { RebalanceAsync } from "../../utils/fundV2Interactor";

import {
  TableRow,
  Table,
  TableCell,
  TableBody,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  ButtonGroup,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Box
} from "@material-ui/core";
// components
import useStyles from "./styles";
import {AppContext}  from '../../context/AppProvider';


const RebalanceV2 = ({ 
  openModal,
  fundData,
  tokensMaster,
  selectedport,
  networkName,
  parentCallback,
  reset
}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [positions, setPositions] = useState([])
  const { account, theWeb3, web3static } = useContext(AppContext);
  
  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }
  
  useEffect(() => {
    init()
  }, [fundData])

  const init = () => {
    if(fundData.tokenNames === undefined) return;
    let p = []
    fundData.tokenNames.forEach(token => {
      if(token.address !== fundData.baseToken){
        let tkName = xWinLib.getTokenName(tokensMaster, token.address)
        p.push({
          symbol: tkName,
          weight: token.targetweight / 100, 
          taddress: token.address
        })
      }
    });
    setPositions(p)
  }

  const [state, setState] = useState({
    symbol: '',
    fundname : '',
    baseccy : '',
    manageraddress : ''
  });
  const [errMsg, setErrmsg] = React.useState("");
  
  const [ticker, setTicker] = useState({
    symbol: '',
    weight: 0
  })

  const getTotalWeight = () => {
    let total = 0
    positions.forEach(p => {
      total = total + parseFloat(p.weight)
    })
    return xWinLib.roundTo(total, 3);
  }

  const getTotalWeightExceptThis = (pos) => {
    let total = 0
    const filteredItems = positions.filter(t => t.symbol !== pos.symbol)
    filteredItems.forEach(p => {
      total = total + parseFloat(p.weight)
    })
    return total;
  }

  const handleClickSameBM = event => {
    event.preventDefault();
    init();
  }


  const handleClickAdd = event => {
    event.preventDefault();

    let result = positions.filter(t => t.symbol === state.symbol)
    if(result.length > 0){
      return
    }
    setErrmsg("")
    let existingWgt = getTotalWeight()
    let totalweight = parseFloat(existingWgt) + parseFloat(state.weight)
    if(totalweight > 100){
      setErrmsg("Not more than 100% weight")
      return
    }  

    let p = []
    positions.forEach(element => {
      p.push({
        symbol: element.symbol,
        weight: element.weight,
        taddress: element.taddress,
      })
    });

    let tokenaddress = xWinLib.getTokenAddress(tokensMaster, ticker.symbol) 
    
    p.push({
      symbol: ticker.symbol,
      weight: state.weight,
      taddress: tokenaddress,
    })
    setPositions(p)
  };

  const handleChange = name => event => {
    
    setState({
      ...state,
      [name]: event.target.value,
    });
  };


  const handleClickRebalanceAllInOne = async event => {
    event.preventDefault();
    
    let totalweight = getTotalWeight()
    if(parseFloat(totalweight) !== 100){
      showSnackBar("error", "Total Weight Must be 100%")
      return
    }

    setLoading(true)
    RebalanceAsync(
        theWeb3,
        fundData.contractaddress,
        account,
        positions
      )
      .then(res =>
        { 
          setLoading(false)
          showSnackBar("success", res)
          // props.parentCallback(true);
        }
      )
      .catch(err => {
        setLoading(false)
        showSnackBar("error", err)
        // props.parentCallback(false);
        }
      )
    
  };

  const handleClickUpdate = (symbol) => event => {
    event.preventDefault();
    const selected = positions.find(t => t.symbol === symbol)
    var weight = prompt("Input Weight", selected.weight);
    if (weight == null) return
    
    let existingWgt = getTotalWeightExceptThis(selected)
    let totalweight = parseFloat(existingWgt) + parseFloat(weight)
    if(totalweight > 100){
      setErrmsg("Not more than 100% weight")
      return
    }  

    selected.weight = weight
    let p = []
    positions.forEach(element => {
      p.push({
        symbol: element.symbol,
        weight: element.weight,
        taddress: element.taddress,
      })
    });
    setPositions(p)

  }
  
  const handleClickDelete = (symbol) => event => {
    event.preventDefault();
    const filteredItems = positions.filter(t => t.symbol !== symbol)
    setPositions(filteredItems)
  }
  
  
  const getSetTarget = (selectedport) => {

    return (
      <DialogContent> 
          <Box display="flex" alignItems="left" justifyContent={"left"}>
            <Table 
              size="small" 
            >
            <TableBody>
            {positions.map((p, i) => ( 
              <TableRow
                  hover
                >
                  <TableCell width="15%" className={classes.columnHide}>
                    <Typography variant="h3" className={classes.secondaryHeading}>
                      {xWinUtils.getIcons(p.symbol)}
                    </Typography>
                  </TableCell>
                  <TableCell width="20%">
                      {p.symbol}
                  </TableCell>
                  <TableCell width="20%">
                      {format({prefix: '', suffix: '%'})(p.weight)}
                  </TableCell>
                  
                  <TableCell align="left" className={classes.columnHide}>
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      {p.taddress} 
                    </Typography> 
                  </TableCell>
                  <TableCell>
                  <ButtonGroup size="small" color="secondary" aria-label="small outlined primary button group">
                  <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      startIcon={<EditIcon />}
                      onClick={handleClickUpdate(p.symbol)}
                    >
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      startIcon={<DeleteIcon />}
                      onClick={handleClickDelete(p.symbol)}
                    >
                    </Button>
                  </ButtonGroup>
                </TableCell>
                </TableRow>
              ))}
              <TableRow className={classes.tableRow}>
                  <TableCell width="15%" className={classes.columnHide}>
                      {/* {getIcons(ticker)} */}
                  </TableCell>
                <TableCell width="20%">
                  <Autocomplete
                    id="symbol"
                    style={{ width: "100%" }}
                    name="symbol"
                    options={xWinUtils.getSupportedSymbols(tokensMaster, networkName, selectedport)}
                    getOptionLabel={option => option.symbol}
                    onChange={(event, newValue) => {
                      setTicker({
                        symbol: newValue == null? "" : newValue.symbol
                      });
                    }}
                    renderInput={params => <TextField {...params} label="Token" margin="dense" variant="outlined" />}
                  />
                </TableCell>
                <TableCell width="20%">
                  <TextField
                    type="number" 
                    className={classes.inputText}
                    label={'Weight %'}
                    margin="dense"
                    name="weight"
                    onChange={handleChange("weight")}
                    required
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                  />
                </TableCell>
                
                <TableCell className={classes.columnHide}>
                  <TextField
                    className={classes.inputText}
                    fullWidth
                    label={'Token Address'}
                    margin="dense"
                    name="taddress"
                    value={xWinLib.getTokenAddress(tokensMaster, ticker.symbol)}
                    required
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HomeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                
                <TableCell>
                  <ButtonGroup size="small" color="primary" aria-label="small outlined primary button group">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      startIcon={<AddCircleIcon />}
                      onClick={handleClickAdd}
                    >
                    {""}
                      
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
              </TableBody>
            </Table>
          </Box>    
      </DialogContent>
    )
  }

  return (
    <Dialog 
          open={openModal} 
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          fullScreen={false}
          maxWidth = {'md'}
          >
          <SnackbarMessage ref={refsnackbar} />
          <CardHeader
              action={
                <IconButton 
                  className={classes.title}
                  onClick={reset}
                  aria-label="settings">
                  <CancelIcon />
                </IconButton>
              }
              title={"Update Target Weight"} 
              />
          {xWinUtils.getProgress(loading)}
          {getSetTarget(selectedport)}              
          <DialogActions>
          <Typography variant="h5" className={classes.negativeNum}>
            {errMsg}
          </Typography> 
              
            <ButtonGroup size="small" color="primary" aria-label="small outlined primary button group">
              <Button 
                onClick={handleClickSameBM} 
                color="secondary"
                variant="outlined"
                startIcon={<AddCircleIcon />}>
                Original %
              </Button>
              <Button 
                onClick={handleClickRebalanceAllInOne} 
                color="primary"
                variant="outlined"
                startIcon={<AddCircleIcon />}>
                  Rebalance
              </Button>
            </ButtonGroup>
          </DialogActions>
    </Dialog>
  );
};


export default RebalanceV2;