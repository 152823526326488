import React, { Suspense } from 'react';
import ReactDOM from "react-dom";
import axios from "axios";
import { createBrowserHistory } from 'history'
import { ThemeProvider } from "@material-ui/styles";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import {
  ThemeProvider as ThemeChangeProvider,
  ThemeStateContext
} from "./context/ThemeContext";
import { CssBaseline } from "@material-ui/core";
import AppProvider from "./context/AppProvider";
import config from "../src/config";

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common["Content-Type"] = "application/json";

ReactDOM.render(
  <Suspense fallback={null}>
    <AppProvider>
    <LayoutProvider>
      {/* <UserProvider> */}
          <ThemeChangeProvider>
            <ThemeStateContext.Consumer>
              {theme => (
                <ThemeProvider theme={theme}>
                  {/* <ManagementProvider> */}
                  <CssBaseline />
                  <App />
                  {/* </ManagementProvider> */}
                </ThemeProvider>
              )}
            </ThemeStateContext.Consumer>
          </ThemeChangeProvider>
      {/* </UserProvider> */}
    </LayoutProvider>
    </AppProvider>
  </Suspense>,
  document.getElementById("root")
);

serviceWorker.unregister();
