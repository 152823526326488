import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  Fab,
  IconButton,
} from '@material-ui/core';
import {
  mdiFacebookBox as FacebookIcon,
  mdiGithubBox as GithubIcon,
  mdiMedium as MediumIcon,
  mdiSettings as SettingsIcon,
  mdiTelegram as TelegramIcon,
  mdiTwitterBox as TwitterIcon,
} from '@mdi/js';
import { Route, Switch, withRouter } from 'react-router-dom';

import BonusClaim from '../../pages/bonusclaim';
import BuyLP from '../../pages/buyLP';
import BuySell from '../../pages/buysell';
import ColorChangeThemePopper from './components/ColorChangeThemePopper';
import Dashboard from '../../pages/dashboard';
import MyMenu from '../../pages/mymenu';
import FarmingPageV2 from '../../pages/farmingV2';
import FarmingBuddyPageV2 from '../../pages/farmingBuddyV2';
import Footer from '../Footer';
import FundDetailPageV2 from '../../pages/funddetailV2/FundDetailV2';
import Header from '../Header';
import LuckyDraw from '../../pages/luckydraw/LuckyDraw';
import Sidebar from '../Sidebar';
import FundV2Page from '../../pages/tradingsV2';
import AssetAllocation from '../../pages/assetAllocation';
import AutoLockStake from '../../pages/autoLockStake';
import structure from '../Sidebar/SidebarStructure';
import useStyles from './styles';
import { Link, Typography } from '../Wrappers';
import { useLayoutState } from '../../context/LayoutContext';
import appGlobalConfig from '../../appGlobalConfig';
import xwinfundv2 from '../../v2Config/xwinfundv2';
import xwinstakingv2 from '../../v2Config/xwinstakingv2';
import xwinbuddystakingv2 from '../../v2Config/xwinbuddystakingv2';
import tokensv2 from '../../v2Config/tokensv2';


function Layout(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'add-section-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(open ? null : event.currentTarget);
  };

  // global
  const layoutState = useLayoutState();
  const [portfoliosV2, setPortfoliosV2] = useState([]);
  const [stakingPools, setStakingPools] = useState([]);
  const [stakingBuddyPools, setStakingBuddyPools] = useState([]);
  const [tokensMasterV2, setTokensMasterV2] = useState([]);

  useEffect(() => {
    const newValues = xwinfundv2.funds.filter(x=>x.env === appGlobalConfig.env)
    setPortfoliosV2(newValues);
  }, []);

  useEffect(() => {
    const newValues = xwinstakingv2.funds.filter(x=>x.env === appGlobalConfig.env)
    setStakingPools(newValues);
  }, []);

  useEffect(() => {
    const newValues = xwinbuddystakingv2.funds.filter(x=>x.env === appGlobalConfig.env)
    setStakingBuddyPools(newValues);
  }, []);

  useEffect(() => {
    const newValues = tokensv2.tokens.filter(x=>x.env === appGlobalConfig.env)
    setTokensMasterV2(newValues);
  }, []);

  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Sidebar structure={structure} />
      <div
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        <div className={classes.fakeToolbar} />
        <Switch>
          <Route path="/app/dashboard" render={() => <Dashboard tokensMaster={tokensMasterV2} portfolios={portfoliosV2} />} />
          <Route path="/app/mymenu" render={() => <MyMenu 
            tokensMaster={tokensMasterV2} 
            portfolios={portfoliosV2} 
            stakingPools={stakingPools}
            stakingBuddyPools={stakingBuddyPools}
            />} />
          <Route path="/app/allocation" render={() => <AssetAllocation portfolios={portfoliosV2} tokensMaster={tokensMasterV2}/>} />
          <Route path="/app/autolockstake" render={() => <AutoLockStake showNewPosition={true}/>} />
          <Route path="/app/xwin/buysell" render={() => <BuySell />} />
          <Route path="/app/xwin/buyLP" render={() => <BuyLP />} />
          <Route path="/app/xwin/bonusclaim" render={() => <BonusClaim />} />
          <Route path="/app/luckydraw" render={() => <LuckyDraw />} />
          <Route path="/app/vaults/index" component={FundV2Page}>
            <FundV2Page
              tokensMaster={tokensMasterV2}
              portfolios={portfoliosV2}
              fundtype="v2index"
            />
          </Route>
          <Route path="/app/vaults/stablealpha" component={FundV2Page}>
            <FundV2Page
              tokensMaster={tokensMasterV2}
              portfolios={portfoliosV2}
              fundtype="v2stablealpha"
            />
          </Route>
          <Route path="/app/vaults/trading" component={FundV2Page}>
            <FundV2Page
              tokensMaster={tokensMasterV2}
              portfolios={portfoliosV2}
              fundtype="v2trading"
            />
          </Route>
          <Route path="/app/vaults/tradingmanual" component={FundV2Page}>
            <FundV2Page
              tokensMaster={tokensMasterV2}
              portfolios={portfoliosV2}
              fundtype="v2tradingmanual"
            />
          </Route>
          <Route path="/app/vaults/single" component={FundV2Page}>
            <FundV2Page
              tokensMaster={tokensMasterV2}
              portfolios={portfoliosV2}
              fundtype="v2single"
            />
          </Route>
          <Route path="/app/vaults/singlebase" component={FundV2Page}>
            <FundV2Page
              tokensMaster={tokensMasterV2}
              portfolios={portfoliosV2}
              fundtype="v2singlebase"
            />
          </Route>
          <Route path="/app/vaults/privatevault" component={FundV2Page}>
            <FundV2Page
              tokensMaster={tokensMasterV2}
              portfolios={portfoliosV2}
              fundtype="v2private"
              fundtypeparam="v2private"
            />
          </Route>
          <Route path="/app/vaults/allprivatevault" component={FundV2Page}>
            <FundV2Page
              tokensMaster={tokensMasterV2}
              portfolios={portfoliosV2}
              fundtype="v2allprivate"
              fundtypeparam="v2allprivate"
            />
          </Route>
          <Route path="/app/farmingv2/:id" component={FarmingPageV2}>
            <FarmingPageV2
              tokensMaster={tokensMasterV2}
              portfolios={stakingPools}
              fundtype="v2staking"
              category="all"
            />
          </Route>
          <Route path="/app/farmingbuddy" component={FarmingBuddyPageV2}>
            <FarmingBuddyPageV2
              tokensMaster={tokensMasterV2}
              portfolios={stakingBuddyPools}
              fundtype="v2buddystaking"
              category="all"
            />
          </Route>
          <Route path="/app/funddetailv2/:id" component={FundDetailPageV2}>
            <FundDetailPageV2 portfolios={portfoliosV2} tokensMaster={tokensMasterV2}/>
          </Route>
        </Switch>
        <Fab
          color="primary"
          aria-label="settings"
          onClick={(e) => handleClick(e)}
          className={classes.changeThemeFab}
          style={{ zIndex: 100 }}
        >
          <Icon path={SettingsIcon} size={1} color="#fff" />
        </Fab>
        <ColorChangeThemePopper
          id={id}
          open={open}
          anchorEl={anchorEl}
        />
        <Footer>
          <div>
            <Link
              href="https://www.t.me/xwinfinance"
              target="_blank"
            >
              <IconButton aria-label="telegram">
                <Icon
                  path={TelegramIcon}
                  size={1}
                  color="#6E6E6E99"
                />
              </IconButton>
            </Link>
            <Link
              href="https://www.facebook.com/toppy.xwin"
              target="_blank"
            >
              <IconButton aria-label="facebook">
                <Icon
                  path={FacebookIcon}
                  size={1}
                  color="#6E6E6E99"
                />
              </IconButton>
            </Link>
            <Link
              href="https://twitter.com/xwinfinance"
              target="_blank"
            >
              <IconButton aria-label="twitter">
                <Icon
                  path={TwitterIcon}
                  size={1}
                  color="#6E6E6E99"
                />
              </IconButton>
            </Link>
            <Link
              href="https://github.com/xwinfinance/xWinBSC"
              target="_blank"
            >
              <IconButton aria-label="github">
                <Icon
                  path={GithubIcon}
                  size={1}
                  color="#6E6E6E99"
                />
              </IconButton>
            </Link>
            <Link
              href="https://xwin.medium.com/"
              target="_blank"
            >
              <IconButton aria-label="github">
                <Icon
                  path={MediumIcon}
                  size={1}
                  color="#6E6E6E99"
                />
              </IconButton>
            </Link>
          </div>
          <div>
            <Typography
              color="text"
              colorBrightness="hint"
              variant="caption"
            >
              Powered by &copy; xWIN Technology Sdn. Bhd
              {' '}
              {new Date().getFullYear()}
            </Typography>
          </div>
        </Footer>
      </div>
    </div>
  );
}

export default withRouter(Layout);
