const tokensv2 = {
  tokens: [
    {
      "address": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "ADA",
      "type": "native"
    },
    {
      "address": "0xAC51066d7bEC65Dc4589368da368b212745d63E8",
      "decimal": 6,
      "env": "v2bscmain",
      "name": "ALICE",
      "type": "native"
    },
    {
      "address": "0x0Eb3a705fc54725037CC9e008bDede697f62F335",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "ATOM",
      "type": "native"
    },
    {
      "address": "0x1CE0c2827e2eF14D5C4f29a091d735A204794041",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "AVAX",
      "type": "native"
    },
    {
      "address": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "BTC",
      "type": "native"
    },
    {
      "address": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "CAKE",
      "type": "native"
    },
    {
      "address": "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
      "decimal": 8,
      "env": "v2bscmain",
      "name": "DOGE",
      "type": "native"
    },
    {
      "address": "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "DOT",
      "type": "native"
    },
    {
      "address": "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "ETH",
      "type": "native"
    },
    {
      "address": "0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "FIL",
      "type": "native"
    },
    {
      "address": "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "LINK",
      "type": "native"
    },
    {
      "address": "0x4338665CBB7B2485A8855A139b75D5e34AB0DB94",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "LTC",
      "type": "native"
    },
    {
      "address": "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "MATIC",
      "type": "native"
    },
    {
      "address": "0x4B0F1812e5Df2A09796481Ff14017e6005508003",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "TWT",
      "type": "native"
    },
    {
      "address": "0xBf5140A22578168FD562DCcF235E5D43A02ce9B1",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "UNI",
      "type": "native"
    },
    {
      "address": "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "USDC",
      "type": "native"
    },
    {
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "USDT",
      "type": "native"
    },
    {
      "address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "WBNB",
      "type": "native"
    },
    {
      "address": "0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "XRP",
      "type": "native"
    },
    {
      "address": "0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "XVS",
      "type": "native"
    },
    {
      "address": "0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "XWIN",
      "type": "native"
    },
    {
      "address": "0x965F527D9159dCe6288a2219DB51fc6Eef120dD1",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "BSW",
      "type": "native"
    },
    {
      "address": "0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "BANANA",
      "type": "native"
    },
    {
      "address": "0x2088A8AAf5C5BFC6D0b801683fa78fE6c0522678",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "xDCA",
      "template": "dca",
      "type": "strategy"
    },
    {
      "address": "0xAC01D131C04546C8dB2eacE57e323046Caf3d390",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "xSCA",
      "template": "alpha",
      "type": "strategy"
    },
    {
      "address": "0xb805048Ee58A7d8bC46E5C3B1fAab7f5492bD505",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "xIRT",
      "template": "autotrading",
      "type": "strategy"
    },
    {
      "address": "0x4AAfF5E3584e6aa68422D2a491a7C751961ced31",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "xBBMA",
      "template": "autotrading",
      "type": "strategy"
    },
    {
      "address": "0x27F30D83554e2aE856Ffb50187f565E54A7A8611",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "xUSDT-V",
      "template": "single",
      "type": "strategy"
    },
    {
      "address": "0xB26012DB23683b823C6CA317e228E225f3845944",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "xUSDT-O",
      "template": "single",
      "type": "strategy"
    },
    {
      "address": "0xA9c087E8B46C508682334979915e18414c208463",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "xBTC-V",
      "template": "single",
      "type": "strategy"
    },
    {
      "address": "0x9a54717818E89d6d99AF2353Fa53a865734cC78f",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "xBTC-O",
      "template": "single",
      "type": "strategy"
    },
    {
      "address": "0xab731D8657CFf08be761B55FcB39C99FdAEad19D",
      "decimal": 18,
      "env": "v2bscmain",
      "name": "xADA-V",
      "template": "single",
      "type": "strategy"
    }
  ]
};

export default tokensv2;

