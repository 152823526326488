import React, { useContext, useEffect } from 'react';
import classnames from 'classnames';
import {
  Button,
  Typography,
  useTheme
} from '@material-ui/core';

import ReactInterval from 'react-interval'
import BigStar from '../../assets/images/toppy8.png';
import SmallStar from '../../assets/images/toppy6.png';
import ThreeStar from '../../assets/images/three-stars.png';
import useStyles from '../styles';
import xWinLib from '../../../xWinLib';
import { AppContext } from '../../../context/AppProvider';
import xWinUtils from '../../../xWinUtils';


const RegisterLuckyDraw = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  
  const [amount, setAmount] = React.useState(0);
  const [loadingTxn, setLoadingTxn] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState([]);
  
  const { account, theWeb3 } = useContext(AppContext);
  const events = {
    getAmount: async () => {
      if (theWeb3 == undefined) return;
      if(props.protocol== undefined) return;
      setAmount(xWinUtils.convertFromWei(theWeb3, await props.protocol.methods.getNextPrizesBalance().call()));
    },
    onRegister: async () => {

      if(account === "---"){
        props.snackbar.current.handleOpen('error', 'Please connect to your wallet');
        return
      }

      if (props.protocol) {
        try {
          if (userInfo.registered) {
            props.snackbar.current.handleOpen('info', 'You have already registered for the lucky draw!');
            return;
          }
          if (! props.userStakeInfo.isEligible) {
            props.snackbar.current.handleOpen('error', 'You do not meet the minimum balance staking in XWIN-BNB LP or XWIN token in XWIN Farm.');
            return;
          }
          setLoadingTxn(true)

          xWinLib.registerLuckyDrawAsync(props.protocol, account)
          .then(res =>
            { 
              console.log(res)
              setLoadingTxn(false)
              props.snackbar.current.handleOpen('success', res);
              refreshUser()
            }
          )
          .catch(err => {
            setLoadingTxn(false)
            console.log(err)
            props.snackbar.current.handleOpen('error', err);
              
          })
        } catch (error) {
          props.snackbar.current.handleOpen('error', error);
          setLoadingTxn(false)
        }
      } else {
        props.snackbar.current.handleOpen('info', 'Please connect to BSC Mainnet');
        setLoadingTxn(false)
      }
    },
  };

  const refreshUser = async () => {
    if(account === "---") return
    const info = await props.protocol.methods.userInfo(account).call();
    setUserInfo(info);
  }

  useEffect(() => {
    if (props.protocol) {

      events.getAmount();
      refreshUser()

    }
  }, [props.round, account]);

  return (
    <div className={classnames('py-5 px-3', theme.palette.type === "dark" ? classes.registerContainer : classes.registerContainerLight)}>
      <ReactInterval timeout={10000} enabled callback={() => events.getAmount()} />
      {xWinUtils.getProgress(loadingTxn)}
      <div className="position-absolute w-100 h-100">
        <img src={BigStar} className={classnames(classes.leftImageAnimation, classes.bigStarImage)} />
        <img src={SmallStar} className={classnames(classes.leftImageAnimation, classes.smallStarImage)} />
        <img src={ThreeStar} className={classnames(classes.rightImageAnimation, classes.threeStarsImage)} />
        <img src={SmallStar} className={classnames(classes.rightImageAnimation, classes.rightSmallStarImage)} />
      </div>
      <div className="d-flex justify-content-center py-5">
        <h2 className={classes.tokenAmount}>FREE!</h2>
          &nbsp;&nbsp;
        <h2 className={classes.headerText}>Lucky Draw {props.round.displayNextID}</h2>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center py-5">
        <h2 className={classes.tokenAmount}>
          {xWinLib.roundTo(amount, 4)}
          {' '}
          XWIN
        </h2>
        <h4 className={classnames('mt-2', classes.headerText)}>has been accumulated for next Lucky Draw</h4>
      </div>
      <div className={classnames('d-flex justify-content-center py-5', classes.buttonAnimation)}>
        <Button
          //disabled={userInfo.registered}
          onClick={events.onRegister}
          color="primary"
          //className={classes.registerButton}
          variant="contained"
        >
          <Typography variant={'body2'} className={classes.buttonFont}>
            {userInfo.registered ? "Your Lucky ID: "+ userInfo.userid : "Register For Lucky Draw Now"}
          </Typography> 
        </Button>
      </div>
    </div>
  );
};

export default RegisterLuckyDraw;
