import React, { useState, useEffect, useContext, useRef  } from "react";
import xWinUtils from '../../xWinUtils';
import FarmlistBuddyV2 from "./components/FarmlistBuddyV2";
import { getNetworkById } from "../../utils/helper";
import {AppContext}  from '../../context/AppProvider';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import { MoreVert as MoreIcon, Search as SearchIcon, CastConnected as CastConnectedIcon } from "@material-ui/icons";
import {
  Grid,
  Container,
  TextField as Input,
  InputAdornment
} from "@material-ui/core";
// components
import Widget from "../../components/Widget/Widget";
import useStyles from "./styles";
import { systemConfig } from "../../configs/systemConfig";
import { getAllPrices } from "../../utils/xWinPriceMasterInteractor";
import VaultFaq from '../vaultfaq/VaultFaq'
import vaultfaqconfig from '../vaultfaq/faq/buddychefFAQ'
import appGlobalConfig from "../../appGlobalConfig";

export default function FarmingBuddyV2(props) {
  const classes = useStyles();
  const { portfolios } = props;
  const [filterPortfolios, setFilterPortfolios] = useState([])
  const [filterPortfoliosByCategory, setFilterPortfoliosByCategory] = useState([])
  const [loading, setLoading] = React.useState(false);
  const [prices, setPrices] = useState([])
  const refsnackbar = useRef(null);
  const [faqs, setFaqs] = useState([])

  const { account, theWeb3, web3static, globalProvider, refreshAccount } = useContext(
      AppContext
  )

  useEffect(() => {
    loadWeb3()
  }, [portfolios, account]);
  
  const loadWeb3 = async () => {
      // let allprices = await xWinUtils.getAllPrices(web3static)
      let allprices = await getAllPrices(web3static, globalProvider)

      if (web3static === undefined) return

      if (account !== '---') {
          refreshAccount()
      }

      let filterEnv = getNetworkById(systemConfig.chainId).env // xWinLib.getNetwork()

      let filterport = []
      let searchExcludeDiscontinuePort
      if (filterEnv === undefined) {
          filterport = portfolios //portfolios.filter(x=>x.env.toLowerCase() === "bscmain")
          searchExcludeDiscontinuePort = filterport.filter(
              x => x.category !== 'discontinued'
          )
          setFilterPortfoliosByCategory(searchExcludeDiscontinuePort)
      } else {
          filterport = portfolios //.filter(x=>x.env.toLowerCase() === filterEnv.toLowerCase())
          searchExcludeDiscontinuePort = filterport.filter(
              x => x.category !== 'discontinued'
          )
          setFilterPortfoliosByCategory(searchExcludeDiscontinuePort)
      }

      let fqs = vaultfaqconfig.faqs.filter(x => x.env === appGlobalConfig.env)
      setFaqs(fqs)

      setPrices(allprices)
      setFilterPortfolios(filterport)
  }


  const displayPortfolios = () => {
    
    let funds = filterPortfolios
    funds = filterPortfoliosByCategory
    return (
      funds.map(port => (
        <Grid item lg={6} sm={12} xs={12} className={classes.hoverHighlight}>
          <FarmlistBuddyV2 
            port={port}
            // portfolios={funds}
            globalWeb3={theWeb3}
            web3static={web3static}
            // account={account}
            selectedWallet={account}
            refreshAccount={refreshAccount}
            prices={prices}
            /> 
        </Grid>
           
           
        )) 
    )    
  }

  const handleOnchange = async event => {
    
    event.preventDefault();

    const value = event.target.value;
    let filterport = filterPortfolios.filter(x => x.symbol.toLowerCase().includes(value.toLowerCase()))
    
    if(filterport.length === 0){
      filterport = filterPortfolios.filter(x => x.name.toLowerCase().includes(value.toLowerCase()))
    }
    setFilterPortfoliosByCategory(filterport)      
  }

  return (
    <Container>
      <SnackbarMessage ref={refsnackbar} />
      
      <Grid container spacing={2}>
      <Grid item lg={12} sm={12} xs={12} className={classes.hoverHighlight2}>
        {xWinUtils.getProgress(loading)}
          <Widget
            spacing={0}
            className={classes.newsHeader}
            title={"Stake XWIN Earn ALT"}
            disableWidgetMenu
            inheritHeight
            enableWalletDisplay
            selectedWallet={account}
            globalWeb3={web3static}
            //searchField
          >
          <Input
            placeholder="symbol or vault name"
            id="search-field"
            fullWidth
            onChange={handleOnchange}
            className={classes.textField}
            margin="dense"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              )
            }}
          />
          </Widget>
        </Grid>
        <Grid item lg={12} sm={12} xs={12} className={classes.hoverHighlight2}>
          
              <Grid container spacing={2}>
                {displayPortfolios()}
              </Grid>
        </Grid>
      </Grid>
      <VaultFaq faqs={faqs}/>
    </Container>
  );
}
