const createfundFAQ = {
	faqs: [
	  {
		symbol: "createfund", 
		env: "v2bscmain", 
		question: 'Platform Fee',
		defaultExpanded : true,
		desc: 'There is a default platform fee of 1.00% per annum based on the total value in your private vault',
	  },
	  {
		symbol: "createfund", 
		env: "v2bscmain", 
		question: 'Strategy Fee',
		desc: 'A default of 1.00% per annum based on the total value in your private vault',
	  },
	  {
		symbol: "createfund", 
		env: "v2bscmain", 
		question: 'Performance Fee',
		desc: 'A default of 20.00% based on profit you made in the private vault. Fundv2 recorded your book cost or average cost whenever you make a deposit in your vault. When the unitprice you sold is greater than your book cost, it means you are making profit. <br/> For example, your book cost is USDT 100 per share, when you sold at USDT 150 for 1 share, your profit is USDT 50. Therefore, a 20% of USDT 50, which is USDT 10 will be considered as performance fee that paid to the platform. <br/> On the other hand, if your sold price is less than USDT 100, then there will be no performance charged in your withdrawal. <br/> You can consider to lock your xWIN tokens and enjoy for up to 50% of performance discount',
	  },
	  {
		symbol: "createfund", 
		env: "v2bscmain", 
		question: 'What is creation fee?',
		desc: 'To create a new vault, you are paying 50 XWIN tokens. This is one-off payment and you are then free to rebalance your private vault at your own controls. <br/> This is one of the utility introduced in our tokenomics eco-system',
	  }
	],
  };
  
  export default createfundFAQ;
  
  