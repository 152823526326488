import React, { useEffect } from "react";
import { Container, Grid, Box, Button  } from '@material-ui/core';
import freature01 from '../../../assets/images/Sector-Index-Icon.png';
import freature02 from '../../../assets/images/Trading-Vaults-Icon.png';
import freature03 from '../../../assets/images/Yield-Farming-Icon.png';
import freature04 from '../../../assets/images/Earn-xWin-Token-Icon.png';
import './Features.css';
import AOS from 'aos';
import '../../home.css';
import {withRouter} from 'react-router-dom'



const Feaures = props => {

	useEffect(() => {
		AOS.init({
			disable: 'mobile'
		});
	});

	const goToDashboard = event => {
		props.history.push('/app/dashboard')
	}
	
	const goToPage = (path) => event => {
		event.preventDefault()
		props.history.push(path)
	  }
	  
	const goToDoc = event => {
		window.open("https://xwinfinance.gitbook.io/xwin-docs/master","_blank")
	}

	return (
		<section id="features">
			<Container maxWidth="lg" className="main-container text-white">
				<Grid container spacing={7}>
					<Grid item xs={12}>
						<Box textAlign="center" className="section-heading text-uppercase">
							<h2 data-aos="fade-up" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200">Features Highlight</h2>
						</Box>
					</Grid>
					<Grid data-aos="fade-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" item md={6} xs={12}>
						<Box textAlign="center" className="feature-box">
							<Box textAlign="center" className="feture-icon-holder">
								<img src={freature01} alt="Feature Icon" />
							</Box>
							<Box className="feture-text-holder">
								<h2>Token INDEX</h2>
								<p>Expose to DEFI or specific basket of tokens. Add them to your portfolio for diversification.</p>
							</Box>
							<Button onClick={goToPage('/app/tradings/index')} data-aos="fade-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" variant="contained" color="primary">More..</Button>
						</Box>
					</Grid>
					<Grid data-aos="fade-left" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" item md={6} xs={12}>
						<Box textAlign="center" className="feature-box">
							<Box textAlign="center" className="feture-icon-holder">
								<img src={freature02} alt="Feature Icon" />
							</Box>
							<Box className="feture-text-holder">
								<h2>TRADING VAULTS</h2>
								<p>Follow the experts on various trading strategies. Be it technical analysis or fundamental. Enjoy the free rides!</p>
							</Box>
							<Button onClick={goToPage('/app/tradings/trading')} data-aos="fade-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" variant="contained" color="primary">More..</Button>
						</Box>
					</Grid>
					<Grid data-aos="fade-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" item md={6} xs={12}>
						<Box textAlign="center" className="feature-box">
							<Box textAlign="center" className="feture-icon-holder">
								<img src={freature03} alt="Feature Icon" />
							</Box>
							<Box className="feture-text-holder">
								<h2>YIELD FARMING VAULT</h2>
								<p>xWin make farming investment easier than ever. It adds BNB into PCS liquidity and farming pool with auto compound utility.</p>
							</Box>
							<Button onClick={goToPage('/app/yields/pcs')} data-aos="fade-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" variant="contained" color="primary">More..</Button>
						</Box>
					</Grid>
					<Grid data-aos="fade-left" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" item md={6} xs={12}>
						<Box textAlign="center" className="feature-box">
							<Box textAlign="center" className="feture-icon-holder">
								<img src={freature04} alt="Feature Icon" />
							</Box>
							<Box className="feture-text-holder">
								<h2>EARN xWIN TOKEN</h2>
								<p>Fully utilize XWIN vault token to farm more XWIN. You can trade it in DEX and provide liquidity to Pancakeswap LP.</p>
							</Box>
							<Button onClick={goToPage('/app/farming/all')} data-aos="fade-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" variant="contained" color="primary">More..</Button>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Box textAlign="center" className="footer-btns-holder">
							<Button onClick={goToDashboard} data-aos="fade-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" variant="contained" color="primary">Launch App</Button>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</section>
	);
}

export default withRouter(Feaures)
;