import xWinFundFactory from "../abi/v2/fundV2Factory.json";
import appGlobalConfig from "../appGlobalConfig";
import { getGasPrice, convertToWei, convertFromWei } from "../utils/helper";
import xWinLib from '../xWinLib';

export async function IsWhiteListed(web3, account) {
	const contract = new web3.eth.Contract(xWinFundFactory, appGlobalConfig.mainnetFundFactory);
  let isWhiteList = await contract.methods.whitelisted(account).call();
  return isWhiteList
}

export async function getLatestFundID(web3) {
	const contract = new web3.eth.Contract(xWinFundFactory, appGlobalConfig.mainnetFundFactory);
  let latestFundId = await contract.methods.getLatestFundID().call();
  return latestFundId
}

export async function CreateFundAsync(web3, name, symbol, account, positions) {
  
  return new Promise((resolve, reject) => {
    
    const destAddress = [];
    const targetWgts = [];
    positions.forEach((p) => {
      destAddress.push(p.taddress);
      targetWgts.push(Number(xWinLib.roundTo(p.weight * 100, 0)));
    });
    const contract = new web3.eth.Contract(xWinFundFactory, appGlobalConfig.mainnetFundFactory);
    const gasParam = getGasPrice();
    contract.methods.createFundPrivate(
      convertToWei(appGlobalConfig.mainnetCreateFundCost),
      name,
      symbol,
      destAddress, 
      targetWgts
      ).send({
      from: account,
      value: 0,
      ...gasParam
    })
      .on('confirmation', (confirmationNumber, receipt) => {
        resolve(receipt.transactionHash);
      })
      .on('error', (error, receipt) => {
        if (receipt !== undefined) reject(receipt.transactionHash);
      })
      .catch((err) => {
        console.log('error: %j', err);
        reject(err.message);
      });
  });
}

export async function getFundV2TVL(web3) {

  try {

    const contract = new web3.eth.Contract(xWinFundFactory, appGlobalConfig.mainnetFundFactory);
    let tvl = await contract.methods.countTVL().call();
    return convertFromWei(tvl);
      
  } catch (error) {
    console.log(error)
  }
  return 0;

}

export async function getFundV2TotalInfoByUser(web3, account) {

  try {

    const contract = new web3.eth.Contract(xWinFundFactory, appGlobalConfig.mainnetFundFactory);
    
    const promiseData = await Promise.all([
      contract.methods.countFundNumberByAddress(account).call(),
      contract.methods.countTotalTVLByAddress(account).call(),
      contract.methods.countTotalProfitByAddress(account).call()
    ]);
    let myfundcount = promiseData[0]
    let myTvl = promiseData[1]
    let myPnL = promiseData[2]

    return{
      myfundcount: myfundcount,
      myTvl: convertFromWei(myTvl),
      myPnL: convertFromWei(myPnL),
    }
      
  } catch (error) {
    console.log(error)
  }
  return{
    myfundcount: "0",
    myTvl: "0",
    myPnL: "0",
  }

}

export async function getUserFundAllInfo(web3, account) {

  try {

    const contract = new web3.eth.Contract(xWinFundFactory, appGlobalConfig.mainnetFundFactory);
    let myFund  = await contract.methods.getMyFundInfo(account).call();
    return myFund;
      
  } catch (error) {
    console.log(error)
  }
}
