import React, { useState, useEffect, useRef, useContext } from "react";
import { useTheme } from "@material-ui/styles";
import xWinLib from '../../../xWinLib';
import ReactApexChart from "react-apexcharts";
import TimerIcon from '@material-ui/icons/Timer';
import { Typography, Button } from "../../../components/Wrappers/Wrappers";
import {
  Box,
  Grid,
  TextField,
  InputAdornment
} from "@material-ui/core";
// styles
import useStyles from "../styles";
import Widget from "../../../components/Widget/Widget";
import xWinUtils from "../../../xWinUtils";
import { init } from "aos";
import { getCompositionValues, getNextInvestmentBlock, getStableTargetName, getNextInvestmentAmount } from "../../../utils/xWinStableAlphaInteractor";
import {AppContext}  from '../../../context/AppProvider';
import Dot from "../../../components/Sidebar/components/Dot";


export default function StrategyDetails(props) {
  var classes = useStyles();
  var theme = useTheme();
  const { strategy, tokensMaster } = props;
  const { isConnected, account, theWeb3, web3static, ETHBalance, refreshAccount } = useContext(AppContext);
  const [compositionValues, setCompositionValues] = useState([])
  const [stableTargetName, setStableTargetName] = useState([])
  const [nextInvBlock, setNextInvBlock] = useState(0)
  const [nextInvAmount, setNextInvAmount] = useState(0)
  const [values, setValues] = useState({
    series2: [], 
  })
  
  // console.log(port);

  useEffect(() => {
    init()
  }, [strategy]);

  let myPayLabel = []
  let seriesValue = []
  
  const init = async () => {

    const nextInvAmt = await getNextInvestmentAmount(web3static, strategy.address);
    const nextInv = await getNextInvestmentBlock(web3static, strategy.address);
    const values = await getCompositionValues(web3static, strategy.address);
    const names = await getStableTargetName(web3static, strategy.address, tokensMaster);
    setCompositionValues(values);
    setStableTargetName(names);
    setNextInvAmount(nextInvAmt.nextInvestAmount);
    setNextInvBlock(nextInv)
    
    let total = Number(values.stableValues) + Number(values.targetValues);
    let targetPerc = Number(values.targetValues) /  Number(total);
    let stablePerc = Number(values.stableValues) /  Number(total);
    myPayLabel.push(names.baseCcy);
    myPayLabel.push(names.targetCcy);
    seriesValue.push(stablePerc)
    seriesValue.push(targetPerc)
    setValues({
      series2: seriesValue, 
    })
  }

  
  
  // const values = {
  //   series2: seriesValue, 
  // };

  const themeOptions = theme => {
    return {
      labels: myPayLabel, 
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'top'
      },
      chart: {
        foreColor: '#ccc',
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          // breakpoint: 480,
          // options: {
          //   chart: {
          //     width: 200
          //   },
          //   legend: {
          //     position: "bottom"
          //   }
          // }
        }
      ],
      colors: [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.warning.main,
        theme.palette.success.light,
        theme.palette.info.main
      ],
    };
  };

  const goToExplorerScan = event => {
    window.open(xWinLib.getEtherLink(nextInvBlock, "block/countdown"), "_blank")
	}

  const displayDesc = () => {

    if(strategy.template.toLowerCase() === "dca"){
      return `The strategy accept the deposit of
      ${stableTargetName.baseCcy?.toUpperCase()} token staked into Venus Finance to earn interest.
      It takes a small portion of USDT staked and buy ${stableTargetName.targetCcy} to achieve dollar cost average effect`
    }
    else if(strategy.template.toLowerCase() === "alpha"){
      return `The strategy accept the deposit of
      ${stableTargetName.baseCcy?.toUpperCase()} tokenz staked into Venus Finance to earn interest.
      It takes interest earned every day and buy ${stableTargetName.targetCcy}. It preserves principle while targeting the ${stableTargetName.targetCcy} rising oppoturnity`
    }
    else {
      return ""
    }

  }

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} sm={12} xs={12} className={classes.hoverHighlight2}>
        <ReactApexChart
          options={themeOptions(theme)}
          series={values.series2}
          type="donut"
          height="250"
          stroke={""}
        />  
        <Typography
            color="text"
            colorBrightness={"hint"}
            className={classes.contentText}
            // style={{ marginRight: 5 }}
          >
            {displayDesc()}
          </Typography>
      </Grid>
      
      <Grid item lg={6} sm={12} xs={12} className={classes.cardpie}>
        <Grid item>
                <Typography
                    className="p-2"
                    color="text"
                    colorBrightness={"hint"}
                    variant={"caption"}
                    style={{ marginRight: 5 }}
                  >
                    Next Invest Block
                  </Typography>
                <Box display="flex" alignItems="center" justifyContent={"right"}>
                  <TextField
                    value={nextInvBlock}
                    size='small'
                    variant="outlined"
                    onClick={goToExplorerScan}
                    fullWidth
                    InputProps={{
                      classes: {
                        input: classes.positiveNum,
                      },
                      readOnly: true,
                      style: { textAlign: "right" },
                      startAdornment : 
                        <InputAdornment position="end">
                          <TimerIcon />
                        </InputAdornment>,
                      endAdornment : 
                      <InputAdornment position="end">
                        Blocks
                      </InputAdornment>,
                      }
                    }
                  />
                </Box>
        </Grid>
        
        <Grid item>
          <Typography
              className="p-2"
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
            >
              $ Value in USDT
            </Typography>
            <Box display="flex" alignItems="center" justifyContent={"right"}>
                  <TextField
                    value={xWinLib.displayBigNumber(compositionValues.stableValues)}
                    size='small'
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        input: classes.positiveNum,
                      },
                      readOnly: true,
                      style: { textAlign: "right" },
                      startAdornment : 
                        <InputAdornment position="end">
                          {xWinUtils.getIcons(stableTargetName.baseCcy)}
                        </InputAdornment>,
                      endAdornment : 
                      <InputAdornment position="end">
                        {stableTargetName.baseCcy}
                      </InputAdornment>,
                      }
                    }
                  />
            </Box>
        </Grid>
        <Grid item>
          <Typography
              className="p-2"
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
            >
              $ Value in BTCB
            </Typography>
            <Box display="flex" alignItems="center" justifyContent={"right"}>
                  <TextField
                    value={xWinLib.displayBigNumber(compositionValues.targetValues)}
                    size='small'
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        input: classes.positiveNum,
                      },
                      readOnly: true,
                      style: { textAlign: "right" },
                      startAdornment : 
                        <InputAdornment position="end">
                          {xWinUtils.getIcons(stableTargetName.targetCcy)}
                        </InputAdornment>,
                      endAdornment : 
                      <InputAdornment position="end">
                        {stableTargetName.baseCcy}
                      </InputAdornment>,
                      }
                    }
                  />
            </Box>
        </Grid>
        <Grid item>
          <Typography
              className="p-2"
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
            >
              $ Next Invest Amount
            </Typography>
            <Box display="flex" alignItems="center" justifyContent={"right"}>
                  <TextField
                    value={xWinLib.displayBigNumber(nextInvAmount)}
                    size='small'
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        input: classes.positiveNum,
                      },
                      readOnly: true,
                      style: { textAlign: "right" },
                      startAdornment : 
                        <InputAdornment position="end">
                          {xWinUtils.getIcons(stableTargetName.baseCcy)}
                        </InputAdornment>,
                      endAdornment : 
                      <InputAdornment position="end">
                        {stableTargetName.baseCcy}
                      </InputAdornment>,
                      }
                    }
                  />
            </Box>
        </Grid>
              
      </Grid>
    </Grid>
  );
}
