import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
// import { AppContext } from '../../../context/AppProvider';
import useStyles from './styles';
// import xWinUtils from '../../../xWinUtils';
import { useTheme } from '@material-ui/styles';

const VaultFaq = ({faqs}) => {
  const classes = useStyles();
  // const theme = useTheme();
  
  // console.log(faq)
  const displayFAQ = () => {
    
    return (
      faqs.map((faq, index) => 
        <Accordion
        defaultExpanded={faq.defaultExpanded !== undefined}
        >
            <AccordionSummary 
            // classes={{ expanded: classes.expanded }}
            expandIcon={<ExpandMoreIcon />}>
            <Typography variant={'body2'} className={classes.subTitle}>
              {++index}{". "}{faq.question}
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
              >  
                <div dangerouslySetInnerHTML={{__html:faq.desc}} />
              </Typography>
            </AccordionDetails>
          </Accordion>
      ) 
    )    
  }

  const display = () => {
    if(faqs.length === 0) return <></>

    return (
      <>
          <br/>
          <br/>
            <Typography
              color="text"
              colorBrightness={"hint"}
              variant={"h3"}
              style={{ marginRight: 5 }}
            >  
              FAQ
            </Typography>
            {displayFAQ()}
        </>
    )
  }

  return (
        <>
          {display()}
        </>
  );
};

export default VaultFaq;
