const autoLockFAQ = {
	faqs: [
	  {
		symbol: "autolock", 
		env: "v2bscmain", 
		question: 'What is xWIN Auto?',
		defaultExpanded : true,
		desc: 'You deposit xWIN into xWIN Master Chef and lock up to 52 weeks. Your staked xWIN will get auto compound as well as you are entitled for extra bonus from bonus pool <br/><div style=\"margin-left: auto; margin-right: auto;\" data-ephox-embed-iri=\"https://xwinfinance.s3.amazonaws.com/xWIN-AutoCompoundLock.mp4#t=2\"><video style=\"width: 100%;\" controls=\"controls\">   <source src=\"https://xwinfinance.s3.amazonaws.com/xWIN-AutoCompoundLock.mp4#t=2\" type=\"video/mp4\">   Your browser does not support the video element. </video></div><div style=\"width: 100%; display: flex; flex-wrap: wrap;\"><div style=\"flex: 1 1 0%; text-align: justify;\">&nbsp;</div><div>&nbsp;</div></div>',
	  },
	  {
		symbol: "autolock", 
		env: "v2bscmain", 
		question: 'What is the benefit of xWIN Auto Vault?',
		desc: 'In addition to the auto compound your earned xWIN tokens, you are entitled to enjoy up to 50% discount in all of the private and public vaults in xWIN Finance platform. The longer you locked and staked, the more you will get the discount',
	  },
	  {
		symbol: "autolock", 
		env: "v2bscmain", 
		question: 'What do I need to do when it expires?',
		desc: 'You can either extend the lock to continue to enjoy the auto compound effect and performance fee discount. Or you can withdraw it from the vault',
	  },
	  {
		symbol: "autolock", 
		env: "v2bscmain", 
		question: 'Can I stake addition xWIN tokens before expiry date?',
		desc: 'Yes, you can extend the stake. However, you have to choose the expiry date which is longer than current locked expiry date. When you extend, your xWIN token from your bonus pool will be tranferred back to you, while the capital will continue to be locked in the vault.',
	  },
	  {
		symbol: "autolock", 
		env: "v2bscmain", 
		question: 'Can I withdraw bonus token during the lock period',
		desc: 'No, unless you extend and stake additional token. Your xWIN token from your bonus pool will be tranferred back to you, while the capital will continue to be locked in the vault.',
	  },
	],
  };
  
  export default autoLockFAQ;
  
  