import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => (
  {
    root: {
      //padding: theme.spacing(5),
    }, 
    expansion: {
      backgroundColor: theme.palette.primary.light,
      color: 'white '
    },
    newsHeader: {
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: '#536DFE',
    },
    subTitle: {
      color: "#ffc247",
    },
    avatar: {
      height: 50,
      width: 50
    },
    columnHide: {
      [theme.breakpoints.down('xs')]: {
        display: "none"
      }
    },
    notificationItem: {
      marginTop: theme.spacing(2),
    },
  positiveNum: {
    textAlign: "left",
    fontSize: 20,
    color: theme.palette.white,
  },
  negativeNum: {
    textAlign: "right",
    fontSize: 20,
    color: theme.palette.secondary.dark,
  },
  snackbarWarning: {
    backgroundColor: theme.palette.warning.light,
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.success.light,
    color: "black"
  },
  snackbarCopied: {
    backgroundColor: theme.palette.success.light,
    color: "black"
  },
  subTitle: {
    fontWeight: 100,
    color: '#536DFE',
  },
  link: {
    //display: 'flex',
    //alignItems: 'center',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
}
))