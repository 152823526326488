import React, { forwardRef, useImperativeHandle  } from "react";
import xWinLib from '../../xWinLib';
// components
import { Typography } from "../../components/Wrappers/Wrappers";
import { Alert } from '@material-ui/lab';
import xWinUtils from '../../xWinUtils';

import {
  Slide,
  IconButton,
  Collapse
} from "@material-ui/core";
import useStyles from "./styles";
// import { IconButton, Button, Collapse } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";


function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

const MessageBar = forwardRef((props, ref) => {
  const { className } = props;
  const classes = useStyles();
  const [msgStatus, setMsgStatus] = React.useState("info");
  const [responseMsg, setResponseMsg] = React.useState("");
  const [open, setOpen] = React.useState(true);
	
  
  const handleOpen = (msgStatus, responseMsg) => {
    
    setMsgStatus(msgStatus)
    setResponseMsg(responseMsg)
    setOpen(true);
    // setPosition(_position)
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen,
      hideMessageTxn: hideMessageTxn
    };
  });

  const hideMessageTxn = () => {
    setOpen(false);
  };

  const getMessage = (msgStatus, responseMsg) => {

    return(
      <Typography variant={'body2'} className={classes.positiveNum}>
        {/* {xWinUtils.getIcons("bnb")} */}
        &nbsp;&nbsp;
        {responseMsg}
      </Typography>
    )
  }
  
  return (

    <Collapse in={open}>
				<Alert
          icon={xWinUtils.getIcons("xwin")} 
          severity="info"
					action={
					<IconButton
					aria-label="close"
					color="inherit"
					size="large"
					onClick={() => {
						setOpen(false);
					}}
					variant="filled" 
          // severity={msgStatus}
					>
					<CloseIcon fontSize="inherit" />
					</IconButton>
				}
				sx={{ mb: 2 }}
				>
				{getMessage(msgStatus, responseMsg)}
				</Alert>
			</Collapse>
      
    );
});


export default MessageBar;
