import { makeStyles } from '@material-ui/styles';
import FooterGrass from '../assets/images/Footer-Grass.png';
import LightBackground from '../assets/images/light-background.png'



export default makeStyles((theme) => ({
  buttonAnimation: {
    animationName: '$rotate',
    animationDuration: '3s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    animationDelay: '0s',
    animationPlayState: 'running',
    animationFillMode: 'none',
  },
  container: {
    margin: '-38px',
  },
  checkButton: {
    background: theme.palette.primary.main, // '#015FAE',
    border: '2px solid #015FAE',
    borderRadius: '8px',
    color: 'white',
    '&:hover': {
      background: 'transparent',
      color: '#015FAE',
      border: '2px solid #015FAE',
    },
  },
  headerText: {
    color: '#FFFFFF',
    textAlign: 'center',
  },
  headerTextDark: {
    color: '#010204',
    textAlign: 'center',
  },
  subTitle: {
    fontWeight: 100,
    color: theme.palette.primary.main,
  },
  navigateButton: {
    color: theme.palette.primary.main, // '#015FAE',
    '&:disabled': {
      color: 'lightgray',
    },
  },
  roundInputField: {
    border: '1px solid rgb(231, 227, 235)',
    borderRadius: '8px',
    marginLeft: '0.5rem',
    padding: '4px 16px',
    width: '60px',
    '&:focus-visible': {
      border: '1px solid rgb(231, 227, 235)',
      borderRadius: '8px',
    },
  },
  dateText: {
    fontSize: '0.75rem',
  },
  registerButton: {
    background: '#db9137',
    border: '2px solid #db9137',
    borderRadius: '8px',
    color: '#212529',
    '&:hover': {
      background: 'transparent',
      color: '#db9137',
      border: '2px solid #db9137',
    },
  },
  registerContainer: {
    background: 'linear-gradient(0deg,#010204 0,#3b3363 0,#3b3363 69%,#23232D 73%,#050609 100%,#040508 0,#000 0,#000)',
    position: 'relative',
  },
  registerContainerLight: {
    background: 'linear-gradient(180deg,#b4b5b8 0,#b4b5b8 0,#dcdde0 69%,#8a8aa1 73%,#dcdde0 100%,#dcdde0 0,#000 0,#000)',
    position: 'relative',
  },
  checkWinnerContainer: {
    background: '#13131A',
    backgroundImage: `url(${ FooterGrass })`,
    backgroundSize: 'auto',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    minHeight: '280px',
  },
  checkWinnerContainerLight: {
    background: '#13131A',
    backgroundImage: `url(${ LightBackground })`,
    backgroundSize: 'auto',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    minHeight: '280px',
  },
  logoTypeIcon: {
    width: '50%',
  },
  previousLuckyDrawContainer: {
    background: 'linear-gradient(180deg,#010204 0,#3b3363 0,#3b3363 69%,#23232D 73%,#050609 100%,#040508 0,#000 0,#000)',
  },
  previousLuckyDrawContainerLight: {
    background: 'linear-gradient(180deg,#b4b5b8 0,#b4b5b8 0,#dcdde0 69%,#23232D 73%,#dcdde0 100%,#dcdde0 0,#000 0,#000)',
  },
  previousLuckyDrawCard: {
    color: 'black',
  },
  previousWinnerContainer: {
    minHeight: '210px',
  },
  borderBottom: {
    borderBottom: '1px solid rgb(231, 227, 235)',
  },
  tokenAmount: {
    color: theme.palette.secondary.main,
  },
  buttonFont: {
    color: theme.palette.white,
  },
  bigStarImage: {
    top: '22%',
    left: '19%',
    height: 'auto',
    maxWidth: '154px',
    position: 'absolute',
    animationDuration: '3.5s',
    animationDelay: '0.5s',
    [theme.breakpoints.down('md')]: {
      top: '20%',
      left: '17%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  smallStarImage: {
    top: '52%',
    left: '25%',
    height: 'auto',
    maxWidth: '104px',
    position: 'absolute',
    animationDuration: '3s',
    animationDelay: '0.25s',
    [theme.breakpoints.down('md')]: {
      top: '50%',
      left: '20%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  threeStarsImage: {
    top: '22%',
    right: '19%',
    height: 'auto',
    maxWidth: '124px',
    position: 'absolute',
    animationDuration: '4s',
    animationDelay: '0.75s',
    [theme.breakpoints.down('md')]: {
      top: '20%',
      right: '17%',
    },
    [theme.breakpoints.down('sm')]: {
      // display: 'none',
    },
  },
  rightSmallStarImage: {
    top: '52%',
    right: '25%',
    height: 'auto',
    maxWidth: '84px',
    position: 'absolute',
    animationDuration: '2.5s',
    animationDelay: '0.25s',
    [theme.breakpoints.down('md')]: {
      top: '50%',
      right: '20%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  leftImageAnimation: {
    animationName: '$translateLeft',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    animationPlayState: 'running',
    animationFillMode: 'none',
  },
  rightImageAnimation: {
    animationName: '$translateRight',
    animationDuration: '3.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    animationPlayState: 'running',
    animationFillMode: 'none',
  },
  luckyDrawFaqContainer: {
    background: '#13131A',
    backgroundImage: `url(${ FooterGrass })`,
    backgroundSize: 'auto',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    minHeight: '280px',
    color: '#ffffff',
  },
  luckyDrawFaqContainerLight: {
    background: '#13131A',
    backgroundImage: `url(${ LightBackground })`,
    backgroundSize: 'auto',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    minHeight: '280px',
    color: '#ffffff',
  },
  descriptionText: {
    color: '#b2bdc7',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(-3deg)',
    },
    '50%': {
      opacity: 1,
      transform: 'rotate(3deg)',
    },
    '100%': {
      opacity: 1,
      transform: 'rotate(-3deg)',
    },
  },
  '@keyframes translateLeft': {
    '0%': {
      transform: 'translate(0px, 0px)',
    },
    '50%': {
      transform: 'translate(10px, 10px)',
    },
    '100%': {
      transform: 'translate(0px, 0px)',
    },
  },
  '@keyframes translateRight': {
    '0%': {
      transform: 'translate(0px, 0px)',
    },
    '50%': {
      transform: 'translate(-10px, -15px)',
    },
    '100%': {
      transform: 'translate(0px, 0px)',
    },
  },
  textfield: {
    backgroundColor: theme.palette.primary.main,
  },
}));
