import React, { forwardRef, useImperativeHandle } from "react";
// components
import { Typography, Button } from "../../components/Wrappers/Wrappers";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import useStyles from "./styles";

const termcondition = forwardRef((props, ref) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [openTnc, setOpenTnc] = React.useState(false);
  const [symbol, setSymbol] = React.useState("");

  const handleOpen = (_symbol) => {
    setOpenTnc(true);
    setSymbol(_symbol)
  }

  const handleCloseDisagree = () => {
    setOpenTnc(false);
    let itemKey = 'xwin-tnc' + symbol
    localStorage.setItem(itemKey, "no");
    props.parentCallback(false)
  };

  const handleCloseAgree = () => {    
    setOpenTnc(false);
    let itemKey = 'xwin-tnc' + symbol
    localStorage.setItem(itemKey, "yes");
    props.parentCallback(true)
  };

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen
    };
  });
  
  return (
    
    <Dialog
        open={openTnc}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        <Typography className={classes.CardTitle}>
          {"xWIN Term Condition"}
          </Typography>
          </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Typography
            className={classes.subTitle}
            variant={"caption"}
          >
          xWIN is a decentralized protocol that social trader can launch his or her vaults or funds. 
          xWIN platform connect users to social trader to the vaults or funds, which indirectly buying various of underlying BEP-20 tokens. 
          xWIN protocol is made up of free, public, open-source or source-available software including a set of smart contracts 
          that are deployed on the Binance Smart Chain Blockchain. 
          Your use of the xWIN protocol involves various risks, including, but not limited to, losses while digital assets 
          are being supplied to the xWIN protocol and losses due to the fluctuation of prices of tokens in a underlying tokens. 
          Before using the xWIN platform, you should review the relevant documentation to make sure you understand how the xWIN protocol works. 
          You are responsible for doing your own diligence on those interfaces to understand the platform fee, manager fees and risks they present.
          <br/>
          <br/>
          THE XWIN PROTOCOL IS PROVIDED ”AS IS”, AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. No developer or entity involved in creating the xWIN protocol will be liable for any claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of, the xWIN protocol, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value
          <br/>
          <br/>
          Click Agree to continue to use the platform for all the vaults available.
          </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisagree} color="primary">
            Disagree
          </Button>
          <Button onClick={handleCloseAgree} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
   
  );
});


export default termcondition;
