const host = process.env.NODE_ENV === "development" ? "http://localhost" : "https://xwin.finance";
const port = process.env.NODE_ENV === "development" ? 8080 : ""
export default {
  analytics: {
    google: {
      id: 'UA-206578988-1'
    },
  },
  auth: {
    email: "admin@xwin.finance",
    password: "password"
  },
 
  hostApi: host,
  portApi: port,
  baseURLApi: `${host}${port ? `:${port}` : ``}/api`,
  redirectUrl: process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://xwin.finance",
  remote: "https://xwin.finance",
  isBackend: process.env.REACT_APP_BACKEND,
};