import React, { useContext,  useState } from 'react';
import classnames from 'classnames';

import useStyles from '../styles';
import xWinLib from '../../../xWinLib';
import xWinUtils from '../../../xWinUtils';
import { AppContext } from '../../../context/AppProvider';
import { Avatar, Typography } from "../../../components/Wrappers/Wrappers";
import {
  Button,
  Chip
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';


const CheckWinner = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  
  const { account, theWeb3 } = useContext(AppContext);
  const [user, setUser] = React.useState(null);
  const [loadingTxn, setLoadingTxn] = React.useState(false);
  const [winners, setWinners] = useState([]);
  const events = {
    onCheck: async (event) => {
      if(account === "---"){
        props.snackbar.current.handleOpen('error', 'Please connect to your wallet');
        return
      }

      if (props.protocol) {
        event.preventDefault();
        const response = await props.protocol.methods.winnerUser(props.round.lastDrawnID, account).call({ from: account });

        setUser({
          poolID: Number(response.poolID),
          totalWonBalance: Number(xWinUtils.convertFromWei(theWeb3, response.totalWonBalance)),
          receivedBalance: Number(xWinUtils.convertFromWei(theWeb3, response.receivedBalance)),
        });

        await getWinners(props.round.lastDrawnID);

      } else {
        props.snackbar.current.handleOpen('info', 'Please connect to BSC Mainnet');
      }
    },
    onRedeem: async () => {

      if (account === '---') return;
      if (props.protocol) {

        setLoadingTxn(true);
        xWinLib.redeemLuckyDrawAsync(props.protocol, account, props.round.lastDrawnID)
        .then(res =>
          { 
            console.log(res)
            setLoadingTxn(false)
            props.snackbar.current.handleOpen('success', res);
            setUser({
              ...user,
              receivedBalance: user.totalWonBalance,
            });
          }
        )
        .catch(err => {
          setLoadingTxn(false)
          console.log(err)
          props.snackbar.current.handleOpen('error', err);            
        })
        
      } else {
        props.snackbar.current.handleOpen('info', 'Please connect to BSC Mainnet');
        setLoadingTxn(false);
      }
    },
  };

  // if (theWeb3 !== undefined) {
  //   theWeb3.currentProvider.on('accountsChanged', () => {
  //     setUser(null);
  //   });

  //   theWeb3.currentProvider.on('chainChanged', () => {
  //     setUser(null);
  //   });
  // }

  const getWinners = async (poolId) => {
    setWinners([]);
    let numberOfPrizes = await props.protocolDisplay.methods.numberOfPrizes().call();
    const list = [];

    if(poolId < 3) numberOfPrizes = 3
    for (let index = 0; index < numberOfPrizes; index ++) {
      
      const luckyValue = await props.protocolDisplay.methods.luckyValues(poolId, index).call();
      const luckyUseraddress = await props.protocolDisplay.methods.userAddressArray(luckyValue).call();

      list.push({
        userID: luckyValue,
        address: luckyUseraddress,
      });
    }

    setWinners(list);
  };

  const displayLuckyValues = () => {
    if (winners.length === 0) return '';
    let numbers = '';

    winners.map((winner) => (
      numbers = `${ numbers + winner.userID }, `
    ));

    numbers += ';';
    numbers = numbers.replace(', ;', '');
    return numbers;
  };

  return (
    <div className={classnames('py-5 px-3', theme.palette.type === "dark" ? classes.checkWinnerContainer : classes.checkWinnerContainerLight)}>
      {xWinUtils.getProgress(loadingTxn)}

      {props.round.displayLastDrawnID === 0 && (
        <>
          <div className="d-flex flex-column align-items-center justify-content-center pt-5">
            <h2 className={theme.palette.type === "dark" ? classes.headerText : classes.headerTextDark}>No Draw Yet</h2>
            <h4 className={theme.palette.type === "dark" ? classes.headerText : classes.headerTextDark}>Wait for the next draw!</h4>
          </div>
        </>
      )}
      {! user && props.round.displayLastDrawnID > 0 && (
        <>
          <div className="d-flex align-items-center justify-content-center pt-5">
            <h2 className={theme.palette.type === "dark" ? classes.headerText : classes.headerTextDark}>
              Latest round
              {props.round.displayLastDrawnID && ` ${ props.round.displayLastDrawnID }`}
            </h2>
          </div>
          <div className="d-flex align-items-center justify-content-center pt-5">
            <h3 className={theme.palette.type === "dark" ? classes.headerText : classes.headerTextDark}>
              Total Prize
              {' '}
              {' '}
              <Chip
              // size="large"
                label={xWinLib.roundTo(props.latestPoolWon, 3)}
                color="primary"
                avatar={<Avatar src="/images/icons/color/xwin.png" />}
              />
            </h3>
          </div>
          <div className="d-flex align-items-center justify-content-center pt-5">
            <h3 className={theme.palette.type === "dark" ? classes.headerText : classes.headerTextDark}>
              Are you the winner
              {props.round.displayLastDrawnID && ` for round ${ props.round.displayLastDrawnID }`}
              ?
            </h3>
          </div>
          <div className='d-flex justify-content-center pt-3 pb-5'>
            <Button
              onClick={events.onCheck}
              className={classes.checkButton}
              color="primary"
              variant="contained"
            >
              Check Now
            </Button>
          </div>
        </>
      )}
      {user && user.receivedBalance === 0 && user.totalWonBalance > 0 && (
        <>
          <div className="d-flex flex-column align-items-center justify-content-center pt-5">
            <h2 className={classes.tokenAmount}>Congratulations!</h2>
            <h3 className={theme.palette.type === "dark" ? classes.headerText : classes.headerTextDark}>
              You have won
              {' '}
              <h3 className={classes.tokenAmount}>
                {xWinLib.roundTo(user.totalWonBalance, 3)}
              </h3>
              {' '}
              XWIN
            </h3>
          </div>
          <div className='d-flex justify-content-center pt-3 pb-5'>
            <Button
              onClick={events.onRedeem}
              className={classes.checkButton}
              color="primary"
              variant="contained"
            >
              Redeem Now
            </Button>
          </div>
        </>
      )}
      {user && user.totalWonBalance > 0 && user.receivedBalance === user.totalWonBalance && (
        <>
          <div className="d-flex flex-column align-items-center justify-content-center pt-5">
            <h2 className={classes.tokenAmount}>Congratulations!</h2>
            <h5 className={theme.palette.type === "dark" ? classes.headerText : classes.headerTextDark}>
              You have redeemed
              {' '}
              <Chip
                label={xWinLib.roundTo(user.receivedBalance, 3)}
                color="primary"
                avatar={<Avatar src="/images/icons/color/xwin.png" />}
              />
            </h5>
          </div>
        </>
      )}
      {user && user.receivedBalance === 0 && user.totalWonBalance === 0 && (
        <>
          <div className="d-flex align-items-center justify-content-center pt-5">
            <h3 className={theme.palette.type === "dark" ? classes.headerText : classes.headerTextDark}>
              Total Prize
              {' '}
              {' '}
              <Chip
              // size="large"
                label={xWinLib.roundTo(props.latestPoolWon, 3)}
                color="primary"
                avatar={<Avatar src="/images/icons/color/xwin.png" />}
              />
            </h3>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center pt-5">
            <h3 className="text-center">
                  {`Lucky Numbers`}
            </h3>
            <h3 className={classes.tokenAmount}>
            { displayLuckyValues() }
            </h3>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center pt-5">
            <h2 className={theme.palette.type === "dark" ? classes.headerText : classes.headerTextDark}>Ops! You didn&apos;t win the lucky draw.</h2>
            <h4 className={theme.palette.type === "dark" ? classes.headerText : classes.headerTextDark}>Wait for the next draw!</h4>
          </div>
        </>
      )}
    </div>
  );
};

export default CheckWinner;
