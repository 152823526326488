import React, { useState, useEffect, useContext } from "react";
import { useTheme } from "@material-ui/styles";
import { MoneySharp } from '@material-ui/icons/';
import { Typography } from "../../../components/Wrappers/Wrappers";
import {
  Box,
  Grid,
  TextField,
  InputAdornment
} from "@material-ui/core";
import useStyles from "../styles";
import {AppContext}  from '../../../context/AppProvider';
import { getAccruedComp, getFundAndUnderlyingValue, getStrategyInfo, getSupplyLendingRates } from "../../../utils/xWinSingleAssetInteractor";
import appGlobalConfig from "../../../appGlobalConfig";
import xWinUtils from "../../../xWinUtils";
import xwinstrategies from "../../../v2Config/xwinstrategies";
import xWinLib from "../../../xWinLib";


export default function SingleAsset(props) {
  var classes = useStyles();
  var theme = useTheme();
  const { singleaddress, tokensMaster } = props;
  const { isConnected, account, theWeb3, web3static, ETHBalance, refreshAccount } = useContext(AppContext);
  const [fundData, setFundData] = useState({
    "accruedComp": "0",
    "supplyRate" : "0",
    "borrowRate" : "0",
    "fundValue" : "0",
    "underlyingValue" : "0",
    "targetaddress" : appGlobalConfig.mainnetAddressZero,
    "rewardaddress" : appGlobalConfig.mainnetAddressZero,
    "targetname" : "usdt",
    "rewardname" : "banana"
  })

  const [values, setValues] = useState({
    series: [
      {
        data: []
      }
    ]
  })

  useEffect(() => {
    init()
  }, [singleaddress]);

  let myPayLabel = []
  let seriesValue = []
  let myBalDataValueOnly = []

  const init = async () => {

    const strategyInfo = await getStrategyInfo(web3static, singleaddress);
    const accruedComp = await getAccruedComp(web3static, singleaddress);
    const supplyLendingRates = await getSupplyLendingRates(web3static, singleaddress);
    const fundValues = await getFundAndUnderlyingValue(web3static, singleaddress, strategyInfo.targetaddress, "0x55d398326f99059ff775485246999027b3197955");
    let rewardToken = tokensMaster.find(x=>x.address === strategyInfo.rewardaddress);
    let targetToken = tokensMaster.find(x=>x.address === strategyInfo.targetaddress);
    let blockPerYear = xwinstrategies.strategies.find(x=>x.contractaddress === singleaddress)?.blockPerYear;

    setFundData({
      "accruedComp": accruedComp.accrual,
      "supplyRate" : supplyLendingRates.supplyRatePerBlock,
      "borrowRate" : supplyLendingRates.borrowRatePerBlock,
      "fundValue" : fundValues.vaultValue,
      "underlyingValue" : fundValues.underlyingValue,
      "targetaddress" : strategyInfo?.targetaddress || appGlobalConfig.mainnetAddressZero,
      "rewardaddress" : strategyInfo?.rewardaddress || appGlobalConfig.mainnetAddressZero,
      "targetname" : targetToken?.name  || "",
      "rewardname" : rewardToken?.name || "",
      "blockPerYear": blockPerYear || 0
    })

    seriesValue.push(fundValues.vaultValue)
    seriesValue.push(fundValues.underlyingValue)
    myPayLabel.push("Fund Values");
    myPayLabel.push("Underlying Values");
        
    myBalDataValueOnly.push(seriesValue)
    setValues({
      series: [
        {
          data: myBalDataValueOnly
        }
      ],
    })
  }

  const getAPY = () => {
    const proceeds = fundData.supplyRate * fundData.blockPerYear;
    return xWinLib.APRToAPY(Number(proceeds))
  }

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} sm={12} xs={12} className={classes.gridlite}>
        <Typography
          color="text"
          colorBrightness={"hint"}
          className={classes.contentText}
        >
          The strategy accept the deposit of 
          <a className={classes.link}>{fundData.targetname.toUpperCase()} </a> token and supply into third party protocol such as Ola Finance or Venus Finance for lending purpose. 
          <br/>
          <br/>
          In returns the strategy receive the interest APY as much as 
          <a className={classes.link}>{getAPY()}%</a>
        </Typography>
      </Grid>
      <Grid item lg={6} sm={12} xs={12} className={classes.gridlite}>
        <Grid item>
                <Typography
                    className="p-2"
                    color="text"
                    colorBrightness={"hint"}
                    variant={"caption"}
                    style={{ marginRight: 5 }}
                  >
                    Accrual Bonus Tokens
                  </Typography>
                <Box display="flex" alignItems="center" justifyContent={"right"}>
                  <TextField
                    value={fundData.accruedComp}
                    size='small'
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        input: classes.positiveNum,
                      },
                      readOnly: true,
                      style: { textAlign: "right" },
                      startAdornment : 
                        <InputAdornment position="end">
                          {xWinUtils.getIcons(fundData?.rewardname.toLowerCase())}
                          {/* <img height="32px" width="32px" src={"/images/icons/color/"+ fundData?.rewardname.toLowerCase() +".png"} /> */}
                        </InputAdornment>,
                      endAdornment : 
                      <InputAdornment position="end">
                        {fundData?.rewardname.toUpperCase()}
                      </InputAdornment>,
                      }
                    }
                  />
                </Box>
          </Grid>
        <Grid item>
          <Typography
              className="p-2"
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
            >
              Vault Balance
            </Typography>
            <Box display="flex" alignItems="center" justifyContent={"right"}>
                  <TextField
                    value={Number(fundData.fundValue).toFixed(2)}
                    size='small'
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        input: classes.positiveNum,
                      },
                      readOnly: true,
                      style: { textAlign: "right" },
                      startAdornment : 
                        <InputAdornment position="end">
                          {xWinUtils.getIcons(fundData?.targetname.toLowerCase())}

                          {/* <img height="32px" width="32px" src={"/images/icons/color/"+ fundData.targetname.toLowerCase() +".png"} /> */}
                        </InputAdornment>,
                      endAdornment : 
                      <InputAdornment position="end">
                        {fundData.targetname.toUpperCase()}
                      </InputAdornment>,
                      }
                    }
                  />
            </Box>
        </Grid>
        
        <Grid item>
          <Typography
              className="p-2"
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
            >
              Interest Earned Rate
            </Typography>
            <Box display="flex" alignItems="center" justifyContent={"right"}>
                  <TextField
                    value={getAPY()}
                    size='small'
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        input: classes.positiveNum,
                      },
                      readOnly: true,
                      style: { textAlign: "right" },
                      startAdornment : 
                        <InputAdornment position="end">
                          APY
                        </InputAdornment>,
                      endAdornment : 
                      <InputAdornment position="end">
                        %
                      </InputAdornment>,
                      }
                    }
                  />
                </Box>
        </Grid>
              
      </Grid>
    </Grid>
  );
}
