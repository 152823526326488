import React, { useState, useEffect, useRef  } from "react";
import xWinLib from '../../../xWinLib';
import format from 'format-number';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
// styles
import useStyles from "../styles";
import { Typography, Button, Link } from "../../../components/Wrappers/Wrappers";
import xWinUtils from '../../../xWinUtils';
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  Accordion,
  AccordionSummary,
  ExpansionPanel,
  ExpansionPanelSummary,
  Badge,
} from "@material-ui/core";
import { GetFundDataAll } from "../../../utils/fundV2Interactor";
import { getTokenInfo } from "../../../utils/erc20Interactor";
// import { AddTokenToMetamask } from "../../../utils/helper";

const TradinglistV2 = props => {
  var classes = useStyles();
  const { fundtype, port, selectedWallet, web3static, tokensMaster, networkName, refreshAccount } = props;
  let tmp = []
  const [fundData, setFundData] = useState({
    "fundvalue": 0,
    "tokenNames": tmp,
    "uniprice": 0,
    "unipriceInUSD": 0,
    "totalSupply":  0,
    "fundETHbalance": 0,
    "networkName": "bscmain",
    "baseCcy": "USDT"
  });

  const [loading, setLoading] = React.useState(false);
  const [userData, setUserData] = useState({
    "userfundbal" : 0,
  });
  const refsnackbar = useRef(null);

  useEffect(() => {
    loadFundData()
  }, [port.contractaddress, selectedWallet]);
  
  const loadFundData = async () => {

    if(port.contractaddress === undefined) return;
    setLoading(true)
    
    let myFundData = await GetFundDataAll(web3static, port.contractaddress)
    // console.log(myFundData)
    if(selectedWallet !== "---"){

      const promiseData = await Promise.all([
        getTokenInfo(web3static, port.contractaddress, selectedWallet),
        getTokenInfo(web3static, myFundData.baseToken, selectedWallet),
      ]);
      let fundHolderData = promiseData[0]
      let myBaseCcyData = promiseData[1]

      setUserData({
        "userfundbal" : fundHolderData.targetBal,
        "userbasebal" : myBaseCcyData.targetBal
      })  
    }else{
      setUserData({
        "userfundbal" : "0",
        "userbasebal" : "0"
      })
    }
    
    let tokenNames = []
    const selected = tokensMaster.find(x=>x.address.toLowerCase() === myFundData.baseToken?.toLowerCase());
    const baseCcy = selected?.name
    const decimal = selected?.decimal
    setFundData({
      ...fundData,
      "contractaddress" : port.contractaddress,
      "fundvalue": myFundData?.fundvalue?.toString(), //fundvalue / PRECISION,
      "tokenNames": tokenNames,
      "uniprice": myFundData?.uniprice.toString(), //uniprice / PRECISION,
      "unipriceInUSD": myFundData?.unipriceInUSD.toString(), //uniprice / PRECISION,
      "totalSupply":  myFundData?.totalSupply.toString(), // totalSupply / PRECISION,
      "fundETHbalance": myFundData?.fundETHbalance.toString(), //fundETHbalance / PRECISION
      "baseToken": myFundData?.baseToken,
      "baseCcy": baseCcy,
      "decimal" : decimal
    })

    setLoading(false)
    
  }

  return (
    <div>
          <ExpansionPanel className={classes.hoverHighlight}>
              <Link 
                  underline="none"
                  component={RouterLink}
                  to={{
                  pathname: "/app/funddetailV2/"+ port.contractaddress,
                  state: {
                    port: port,
                    portparam: port,
                    tokensMaster: tokensMaster,
                    fundtype: fundtype,
                    fundtypeparam: fundtype
                  }
                }}>
                   
              <ExpansionPanelSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{ expanded: classes.expanded }}
              >
                
                <div className={clsx(classes.column2)}>
                  <img alt={port.symbol.toUpperCase()} src={xWinLib.getIcon(port.logo)} className={classes.avatar}/>
                  <Typography
                      className={classes.columnShow}
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      <br/>
                      {port.symbol}
                      
                  </Typography>
                </div>
                
                <div className={clsx(classes.column3, classes.columnHide, classes.helper)}>
                    <Typography
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                      className={classes.subTitle}
                    >
                      {port.name}
                      
                    </Typography>
                    <Typography 
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                       >{port.symbol} 
                       
                    </Typography>
                    
                </div>
                
                <div className={clsx(classes.column6, classes.helper)}>
                  <Grid item>
                  <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      Unit Price
                    </Typography>
                  <Typography variant={'body2'} className={classes.subTitle}>
                    {xWinUtils.displayBalance(loading, format({prefix: " $", suffix: ''})(xWinLib.roundTo(fundData.unipriceInUSD,2)))}
                  </Typography>
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      TVL
                    </Typography>
                     <Typography variant={'body2'} className={classes.subTitle}>
                     {xWinUtils.displayBalance(loading, xWinLib.getMarketCap(fundData))}

                      </Typography>
                  </Grid>
                </div>
                <div className={clsx(classes.column6, classes.helper)}>
                  <Grid item>
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      Balance
                    </Typography>
                    <Typography variant={'body2'} className={userData.userfundbal > 0 ? classes.subSuccess : classes.subTitle}>
                    <img src={xWinLib.getIcon(port.logo)} alt={port.logo} className={classes.avatarSmall} />
                    {xWinUtils.displayBalance(loading, format({prefix: ' ', suffix: ` `})(xWinLib.roundTo(userData.userfundbal,4)))}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      Available
                    </Typography>
                    <Typography variant={'body2'} className={userData.userbasebal > 0 ? classes.subSuccess : classes.subTitle}>
                        <img src={xWinLib.getIcon(fundData.baseCcy?.toLowerCase()+".png")} alt={fundData.baseCcy} className={classes.avatarSmall} />
                                
                        {xWinUtils.displayBalance(loading, format({prefix: ' ', suffix: ` `})(xWinLib.roundTo(userData.userbasebal,4)))}
                      </Typography>
                  </Grid>
                </div>
                {/* <Badge
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    badgeContent={"High"}
                    color="primary"
                    overlap="rectangle"
                  ></Badge> */}
              </ExpansionPanelSummary>
              </Link>
          </ExpansionPanel>
    </div>
  );
}
export default withRouter(TradinglistV2);

