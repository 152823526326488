import React, { useState, useRef, useContext } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import format from 'format-number';
import "react-toastify/dist/ReactToastify.css";
import { Typography, Button, Avatar } from "../../components/Wrappers/Wrappers";
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import { getPerformanceData, RedeemAsync } from "../../utils/fundV2Interactor";
import Dot from '../../components/Sidebar/components/Dot'

import {
  Box,
  CardHeader,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  ButtonGroup,
  TextField,
  InputAdornment,
} from "@material-ui/core";
// components
import useStyles from "./styles";
import {AppContext}  from '../../context/AppProvider';
import confetti from "canvas-confetti";
import { GetUserPerformanceFee } from "../../utils/autoLockInteractor";

const redeemV2 = ({ 
  openModal,
  fundData,
  port,
  userData,
  selectedWallet,
  parentCallback,
  reset
}) => {

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [subsAmt, setsubsAmt] = useState("");
  const [eligibleDiscount, setEligibleDiscount] = useState(false);
  const [estimateOut, setEstimateOut] = useState({
    estOut: 0,
    perf : 0,
    bookcost : 0,
    estOutAfterDiscount : 0,
    perfAfterDiscount : 0
  })
  const { account, theWeb3, web3static, refreshAccount } = useContext(AppContext);
  
  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }
  const handleClickAmt = (ratio) => async event => {
    event.preventDefault();
    
    if(ratio == 1){
      setsubsAmt(userData.userfundbal)
      calcEstimateOut(userData.userfundbal)
    }else{
      let redeemAmt = xWinLib.roundDown(ratio * userData.userfundbal, 18)
      if(redeemAmt > userData.userfundbal) redeemAmt = userData.userfundbal 
      setsubsAmt(redeemAmt)  
      calcEstimateOut(redeemAmt)
    }
  }

  const handleClickClearAmt = () => event => {
    event.preventDefault();
    setsubsAmt(0)
    setEligibleDiscount(false)
    setEstimateOut({
      estOut: 0,
      perf : 0,
      bookcost : 0,
      estOutAfterDiscount : 0,
      perfAfterDiscount : 0
    })
  };

  const handleAmtChange = () => async event => {
    
    if(event.target.value === "") return

    const val = parseFloat(event.target.value)
    if(val >= 0 && val !== undefined){
      setsubsAmt(val)
      calcEstimateOut(val)
    }else{
      setsubsAmt("")
      setEstimateOut({
        estOut: 0,
        perf : 0,
        bookcost : 0,
        estOutAfterDiscount : 0,
        perfAfterDiscount : 0
      })
    }
  }

  const handleClickRedeem = async event => {
    event.preventDefault();
    
    if(subsAmt <= 0){
      showSnackBar("error", "Please input withdraw amount")
      return
    } 
    if(subsAmt > userData?.userfundbal){
      showSnackBar("error", "Withdraw amount is larger than available balance")
      return
    } 

    setLoading(true)
    
    RedeemAsync(
        theWeb3, 
        port.contractaddress, 
        selectedWallet, 
        subsAmt
      )
    .then(res => {
        setLoading(false)
        showSnackBar("success", res)
        confetti()
        parentCallback(true);
      }
    )
    .catch(err => {
      setLoading(false)
      console.log(err)
      showSnackBar("error", err)
      parentCallback(false);
    })
  };

  const calcEstimateOut = async (amount) => {
    if (account !== '---' && fundData?.baseToken !== undefined) {

        let newFee = await GetUserPerformanceFee(web3static, account, fundData.performanceFee || 0)
        setEligibleDiscount(newFee > 0)
        let performanceData = await getPerformanceData(web3static, fundData?.contractaddress, account)
        let outperformAmount = fundData.uniprice >= performanceData.avgPrice ? (fundData.uniprice - performanceData.avgPrice) * amount : 0
        let performanceAmount = (fundData?.performanceFee / 100) * outperformAmount
        let performanceAmountAfterDiscount = (newFee / 100) * outperformAmount
        let out = (fundData.uniprice * amount) - performanceAmount
        let outAfterDiscount = (fundData.uniprice * amount) - performanceAmountAfterDiscount
        let bookcost = performanceData.avgPrice * amount
        setEstimateOut({
          estOut: out,
          perf : performanceAmount,
          bookcost : bookcost,
          estOutAfterDiscount : outAfterDiscount,
          perfAfterDiscount : performanceAmountAfterDiscount
        })
    }
  }

  const getSubHeader = () => {

    return (
      <Typography color="secondary">
        {format({prefix: "", suffix: ''})(xWinLib.roundTo(userData?.userfundbal, 14))}
      </Typography>
    )
  }

  const displayEst = () => {

    if(eligibleDiscount)
    {
      return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent={'right'}
        >
             <img src={xWinLib.getIcon(fundData.baseCcy?.toLowerCase()+".png")} alt={fundData.baseCcy} className={classes.avatarSmall} />
            <Typography
                variant={'body2'}
                className={classes.subTitle}
                style={{ marginLeft: 5 }}
            >
                <s>{format({ prefix: ' ', suffix: " " })(xWinLib.roundDown(estimateOut.estOut, 4))}</s>
            </Typography>
            <Typography
                variant={'body2'}
                // className={classes.subTitle}
                style={{ marginLeft: 5 }}
            >
                &rarr;
              {format({ prefix: ' ', suffix: " " })(xWinLib.roundDown(estimateOut.estOutAfterDiscount, 4))}
            </Typography>
        </Box>
      
      )
    }else{

      return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent={'right'}
        >
             <img src={xWinLib.getIcon(fundData.baseCcy?.toLowerCase()+".png")} alt={fundData.baseCcy} className={classes.avatarSmall} />
            <Typography
                variant={'body2'}
                className={classes.subTitle}
                style={{ marginLeft: 5 }}
            >
                {format({ prefix: ' ', suffix: " " })(xWinLib.roundDown(estimateOut.estOut, 4))}
            </Typography>
        </Box>
      )
    }      
  }

  const displayPerformanceFee = () => {

    if(eligibleDiscount)
    {
      return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent={'right'}
        >
             <img src={xWinLib.getIcon(fundData.baseCcy?.toLowerCase()+".png")} alt={fundData.baseCcy} className={classes.avatarSmall} />
            <Typography
                variant={'body2'}
                className={classes.subTitle}
                style={{ marginLeft: 5 }}
            >
                <s>{format({ prefix: ' ', suffix: " " })(xWinLib.roundDown(estimateOut.perf, 4))}</s>
          </Typography>  
                <Typography
                variant={'body2'}
                // className={classes.subTitle}
                style={{ marginLeft: 5 }}
            >
                &rarr;
              {format({ prefix: ' ', suffix: " " })(xWinLib.roundDown(estimateOut.perfAfterDiscount, 4))}
            </Typography>
        </Box>

      )
    }else{

      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent={'right'}
      >
          <img src={xWinLib.getIcon(fundData.baseCcy?.toLowerCase()+".png")} alt={fundData.baseCcy} className={classes.avatarSmall} />
          <Typography
              variant={'body2'}
              className={classes.subTitle}
              style={{ marginLeft: 5 }}
          >
              {format({ prefix: ' ', suffix: " " })(xWinLib.roundDown(estimateOut.perf, 4))}
          </Typography>
      </Box>
        
      )
    }      
  }

  return (
    <div>
    <Dialog 
          open={openModal} 
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          fullScreen={false}
          //maxWidth = {'md'}
          >
            <SnackbarMessage ref={refsnackbar} />
            <CardHeader
              action={
                <IconButton 
                  className={classes.positiveNum}
                  onClick={reset}
                  aria-label="settings">
                  <CancelIcon />
                </IconButton>
              }
              avatar={
                <Avatar variant="round"> 
                  {<img alt={port?.symbol} src={xWinLib.getIcon(port?.logo)} className={classes.avatar}/>}
                 </Avatar>
              }
              subheader={getSubHeader()}
            />
          <DialogContent>
              {xWinUtils.getProgress(loading)}
                  
              <Box display="flex" alignItems="center" justifyContent={"right"}>
                <ButtonGroup size="small" color="secondary" aria-label="">
                      <Button
                        // className={classes.columnHide}
                        fullWidth
                        color="secondary"
                        size="small"
                        variant="outlined"
                        onClick={handleClickAmt(0.25)}
                      >
                        {'25%'}
                      </Button>
                      <Button
                        fullWidth
                        color="secondary"
                        size="small"
                        variant="outlined"
                        onClick={handleClickAmt(0.50)}
                      >
                        {'50%'}
                      </Button>
                      <Button
                        className={classes.columnHide}
                        fullWidth
                        color="secondary"
                        size="small"
                        variant="outlined"
                        onClick={handleClickAmt(0.75)}
                      >
                        {'75%'}
                      </Button>
                      <Button
                        fullWidth
                        color="secondary"
                        size="small"
                        variant="outlined"
                        onClick={handleClickAmt(1)}
                      >
                        {'100%'}
                      </Button>
                      <Button
                      fullWidth
                      color="secondary"
                      size="small"
                      variant="outlined"
                      onClick={handleClickClearAmt()}
                    >
                      {'Clear'}
                    </Button>
                    </ButtonGroup>
              </Box>
          </DialogContent>
          <DialogContent>
              <Box display="flex" alignItems="center" justifyContent={"center"}>
                <TextField
                    type="number" 
                    // className={classes.text}
                    // label={'Amount'}
                    margin="dense"
                    name="subsAmt"
                    onChange={handleAmtChange()}
                    required
                    variant="outlined"
                    value={subsAmt}
                    fullWidth
                    InputProps={{
                      classes: {
                        input: classes.negativeNum,
                      },
                      style: { textAlign: "right" },
                      step:"0.1", 
                      startAdornment: <InputAdornment position="start">{port?.symbol}</InputAdornment>,
                    }}                              
                  />
              </Box>
          </DialogContent>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                <Typography
                  color="text"
                  colorBrightness={'hint'}
                  variant={'caption'}
                  style={{ marginRight: 5 }}
                  // noWrap
              >
                  {'Est Received'}
              </Typography>
              </Grid>
              <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
              {displayEst()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                <Typography
                    color="text"
                    colorBrightness={'hint'}
                    variant={'caption'}
                    style={{ marginRight: 5 }}
                    // noWrap
                >
                    {'Book Cost'}
                </Typography>
              </Grid>
              <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={'right'}
                >
                  <img src={xWinLib.getIcon(fundData.baseCcy?.toLowerCase()+".png")} alt={fundData.baseCcy} className={classes.avatarSmall} />
                  <Typography
                      variant={'body2'}
                      className={classes.subTitle}
                      style={{ marginLeft: 5 }}
                  >
                    {format({ prefix: ' ', suffix: " " })(xWinLib.roundDown(estimateOut.bookcost, 4))}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogContent>
            <Grid container spacing={1}>
                <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                  <Typography
                      color="text"
                      colorBrightness={'hint'}
                      variant={'caption'}
                      style={{ marginRight: 5 }}
                      // noWrap
                  >
                      {'Est. Performance Fee'}
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">  
                  {displayPerformanceFee()}
                </Grid>
            </Grid>
          </DialogContent>
            
          <DialogActions>
              
          <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
              <Button 
                onClick={handleClickRedeem} 
                color="secondary"
                variant="contained"
                startIcon={<AddCircleIcon />}>
              {'Withdraw'}
              </Button>
            </ButtonGroup>
            
          </DialogActions>
        </Dialog>
        </div>
  );
};


export default redeemV2;
