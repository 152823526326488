import React, { useState, useEffect, useContext  } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import xWinProtocolV5 from '../../abi/xWinProtocolV5.json'
import appGlobalConfig from '../../appGlobalConfig';
import {AppContext}  from '../../context/AppProvider';
import ReactInterval from 'react-interval'
import { getNetworkById, convertFromWei } from "../../utils/helper";
import { systemConfig } from "../../configs/systemConfig";

import {
  Grid,
  Button,
  Container,
  Box,
} from "@material-ui/core";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
  Favorite as FavoriteIcon
} from "@material-ui/icons";

import ApexLineChart from "./components/ApexLineChart";
import TokenStats from "./components/TokenStats";
// styles
import useStyles from "./styles";
// components
import Widget from "../../components/Widget";
import { Typography } from "../../components/Wrappers";
import { getTokenInfo } from "../../utils/erc20Interactor";
import TradingSignal from "./components/TradingSignal";
import AssetSharpeRatio from "./components/AssetSharpeRatio";
import { useTheme } from "@material-ui/styles";
import { getAllPrices } from "../../utils/xWinPriceMasterInteractor";
import { getFundV2TVL } from "../../utils/fundV2FactoryInteractor";
import MarketTrends from "./components/MarketTrends";
import StrategyTVL from "./components/StrategyTVL";
import { GetTATradingSignal, GetTokenPrices, GetTVLv1, GetWinStats } from "../../utils/firestoreDataInteractor";

const Dashboard = props => {
  const {portfolios, tokensMaster } = props;
  var theme = useTheme();
  
  var classes = useStyles();
  const [mainData, setMainData] = React.useState([]);
  const [overallStat, setOverallStat] = useState([])
  const [xwinStats, setXwinStats] = useState([])
  const [totalValueLock, setTotalValueLock] = React.useState(0);
  const [totalValueLockV2, setTotalValueLockV2] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [pickCategory, setPickCategory] = React.useState("tradingv2");
  const { isConnected, ETHBalance, account, theWeb3, web3static } = useContext(AppContext);
  const [returnChoice, setReturnChoice] = React.useState(3);
  const [period, setPeriod] = React.useState(31);
  const [signals, setSignals] = React.useState([]);

  useEffect(() => {
    fetchTokenPrice()
  }, []);

  useEffect(() => {
    fetchSignal()
  }, []);

  useEffect(() => {
    fetchMarketData()
  }, []);

  useEffect(() => {
    fetchTVLv1()
  }, []);

  useEffect(() => {
    loadWeb3()
  }, [portfolios, account, isConnected]);

  const loadWeb3 = async () => {
    
    try {
      setLoading(true)
    let filterEnv = getNetworkById(systemConfig.chainId).env; //xWinLib.getNetwork()
    
    if(filterEnv === undefined){
      setOverallStat({
        "xwinPrice": "0",
        "xwinPriceInBNB": "0",
        "BNBPrice": "0",
        "WBNBXWINPrice": "0",
        "burnBal" : "0"
      })
    }else{

      let totalTVL = await getFundV2TVL(web3static)
      setTotalValueLockV2(totalTVL)
      let prices = await getAllPrices(web3static)
      let burnInfo = await getTokenInfo(web3static, appGlobalConfig.mainnetXWIN, appGlobalConfig.mainnetBurn)
      setOverallStat({
        "xwinPrice": prices.xwinPriceInUSD,
        "xwinPriceInBNB": convertFromWei(prices.unipriceInBNB.toString()),
        "BNBPrice": convertFromWei(prices.BNBUSDT.toString()),
        "WBNBXWINPrice": prices.pcsLPPrice,
        "JPYUSD" : prices.JPYUSD,
        "BabyLPPrice" : prices.babyLPPrice,
        "burnBal" : burnInfo.targetBal
      })  
    }
    setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
    
  }

  const fetchMarketData = async () => {
    
    const promiseData = await Promise.all([
      GetWinStats()
    ]);
    let winStatsData = promiseData[0]
    setXwinStats(winStatsData)
  };

  const fetchTVLv1 = async () => {
    
    const promiseData = await Promise.all([
      GetTVLv1(),
    ]);
    let tvlv1 = promiseData[0]
    setTotalValueLock(tvlv1)
  };

  const fetchSignal = async () => {
    
    const promiseData = await Promise.all([
      GetTATradingSignal(),
    ]);
    let signalData = promiseData[0]
    setSignals(signalData)
  };

  const fetchTokenPrice = async () => {
    
    const promiseData = await Promise.all([
      GetTokenPrices(appGlobalConfig.mainnetXWIN),
    ]);
    let priceData = promiseData[0]
    setMainData(priceData)
  };

  const AddToMetamask = async event => {
    
    setLoading(true)
    xWinLib.AddToMetamask(appGlobalConfig.mainnetXWIN, "XWIN", 18)
      .then(res =>
        { 
          setLoading(false)
        }
      )
      .catch(err => {
        setLoading(false)
        }
      )
    
  };

  const refreshDisplayToken = () => {
    setPickCategory(pickCategory === "tradingv2" ? "indexv2": "tradingv2");
  }
  
  const handleClickPeriod = (event, choice) => {
    
    let duration = 30;
    if(choice === 1){
      duration = 2
    }else if(choice === 2){
      duration = 8
    }else if(choice === 3){
      duration = 31
    }else if(choice === 4){
      duration = 366
    }
    setReturnChoice(choice)
    setPeriod(duration)
  };

  return (
    <Container>
    <ReactInterval timeout={20000} enabled={true}
          callback={() => refreshDisplayToken()} />

    <Grid container spacing={2}>
			<Grid item lg={12} sm={12} xs={12} className={classes.hoverHighlight1}>
      <coingecko-coin-price-marquee-widget  coin-ids="bitcoin,ethereum,binancecoin,xwin-finance,pancakeswap-token" currency="usd" 
      background-color={theme.palette.background.light} locale="en"></coingecko-coin-price-marquee-widget>
      </Grid>
      <Grid item lg={4} sm={12} xs={12} className={classes.hoverHighlight1}>
      <TokenStats
          totalValueLockV2={totalValueLockV2}
          totalValueLock={totalValueLock}
          xwinStats={xwinStats}
          overallStat={overallStat}
          />
      </Grid>
      <Grid item lg={8} sm={12} xs={12} className={classes.hoverHighlight1}>
      <Widget
          bodyClass={classes.mainChartBody}
          header={
            <div className={classes.mainChartHeader}>
              <Typography
                variant="h6"
                color="text"
                weight={"medium"}
                colorBrightness="secondary"
              >
                xWIN Daily Price
              </Typography>
              {/* <Typography
                size="lg"
                weight={"bold"}
                style={{ marginRight: 8 }}
                className={priceReturns > 0? classes.positiveNum: classes.negativeNum}
              > */}
                <ToggleButtonGroup 
                  color={"primary"}
                  size="small" value={returnChoice} exclusive onChange={handleClickPeriod}>
                  <ToggleButton value={1} style={{outlineColor: theme.palette.primary.main, outlineWidth: '1px', outlineStyle: 'solid', margin: '2px'}} >
                    24H
                  </ToggleButton>
                  <ToggleButton value={2} style={{outlineColor: theme.palette.primary.main, outlineWidth: '1px', outlineStyle: 'solid', margin: '2px'}} >
                    1W
                  </ToggleButton>
                  <ToggleButton value={3} style={{outlineColor: theme.palette.primary.main, outlineWidth: '1px', outlineStyle: 'solid', margin: '2px'}} >
                    1M
                  </ToggleButton>
                  <ToggleButton value={4} style={{outlineColor: theme.palette.primary.main, outlineWidth: '1px', outlineStyle: 'solid', margin: '2px'}} >
                    1Y
                  </ToggleButton>
                </ToggleButtonGroup>


              {/* </Typography> */}
              <div className={classes.mainChartHeaderLabels}>
                <div className={classes.mainChartHeaderLabel}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => AddToMetamask()}
                    color="secondary"
                  >
                    <FavoriteIcon />
                    Add $XWIN
                  </Button>
                </div>
              </div>
            </div>
          }
        >
          <ApexLineChart mainData={mainData} period={period} />
          
        </Widget>

      </Grid>

      
    </Grid>
    <Grid container spacing={2}>
      <Grid item lg={8} sm={12} xs={12} >
          <TradingSignal signals={signals}/>
      </Grid>
      <Grid item lg={4} sm={12} xs={12}>          
          <Box>
            <MarketTrends />
          </Box>
          <Box className="pt-3">
            <AssetSharpeRatio tokensMaster={tokensMaster}/>
          </Box>
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item lg={12} sm={12} xs={12} >
          <StrategyTVL/>
      </Grid>
    </Grid>
    </Container>
  );
}


export default Dashboard;
