const appGlobalConfig = {
  MAXALLOWANCE: '10000000000000000000000000000000000000000000000',
  mainnetCreateFundCost: "50",
  testnetXWIN: '0xa83575490D7df4E2F47b7D38ef351a2722cA45b9',
  mainnetRiskFreeAsset: '0x55d398326f99059fF775485246999027B3197955',
  mainnetRiskFreeProxy: '0x27F30D83554e2aE856Ffb50187f565E54A7A8611',
  mainnetLockStake: '0xaF91DB52BEE0b35eFEd1024fEf4b8f7d6c8535f9',
  mainnetMasterChef: '0xe4cC29694e891733D59d1Cc48D967fc6B4bBaE86',
  mainnetBuddyChef: '0x593a506e5877cA165bC4013943361D71CA21FaC8',
  mainnetFundFactory: '0xf209Df022f888891EA1A64f32973402A1c3cb575',
  mainnetxWInPriceMaster: '0x21ad7Ab671fa02b3b9906130E268710528e151c8',
  mainnetXWIN: '0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28',
  mainnetBurn: '0x000000000000000000000000000000000000dead',
  mainnetAddressZero: '0x0000000000000000000000000000000000000000',
  mainnetWBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  mainnetBTC: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  mainnetETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  mainnetUSDT: '0x55d398326f99059fF775485246999027B3197955',
  mainnetUSDC: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  mainnetUSDTStakingFund: '0x6439f73dA37Bc8EB83Ac05c615Eb8BAb9fA2a701',
  mainnetXWINBNBLPPool: '0x2D74b7DbF2835aCadd8d4eF75B841c01E1a68383',
  mainnetXWINUSDTLPPool: '0x2E5946E39687FdE415aFEa24fF7272231F69adAF',
  mainnetXWINUSDTAPELPPool: '0x4F263c2F03d8dcd7Dea928de0224e34cEbD9F03c',
  mainnetXWINBNBLPPoolV2: '0xD4A3Dcf47887636B19eD1b54AAb722Bd620e5fb4',
  mainnetXWINBNBLPPoolApeSwap: '0x9f1762b15e740704f40a3606b31dccfbe5318d8b',
  mainnetxWinLuckyDraw: '0x6F995c3e2001D45e2ACa7d6f537ce6a106767Beb', //'0x61Bc448a71b01e0693EB3ABA630Ab046D7C13108', // Testnet
  mainnetxWinProtocol: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
  mainnetxWinProtocolDummy: '0x21B323a2Ac030095A7f9509B3b53f52367B76D94',
  mainnetxWinProtocolTestnet: '0x788E09a42b64d5C4D5887eB426da2CA810a93D8e',
  mainnetxWinSalev1: '0x78bd8dad87D668180BD77c34131eDe04963CbD97',
  mainnetxWinSale: '0x2AD5a4213cCd4569Ec82E7Dc5C0dEc91DB95bbD2',
  mainnetxWinSaleBonus: '0x83c346A57ed1CEAa03f954bE0142044773980e61',
  mainnetxWinSaleBonusv1: '0x4ee8909Bd14c572738514B5f92AB44353AC42779',
  mainnetxWinTeamRewards: '0x8672B1e168053927d8d39C55902d1Ed60498ED53',
  mainnetxWinCompensation: '0xB295306381456Cf4f86db2d4E3AcEF03D1aAb793',  // use this line when mainnet
  mainnetWinRobustCompensation: '0xb8c695A62a0D663dE4D7Fe81A460B3a6642321E0',      // use this line when mainnet 
  mainnetxWinUSDTStaking: '0x95088e705BC6bE78642f2F20A3286190af000685',
  mainnetxWinPrivateUSDT: '0xd2DC56F4BcAb9377FBfC689ED57777F2aC3DFd36',
  mainnetJPYC: '0x158e63f5afa64c84b07e462c3e45fe62c5419803',
  mainnetxWINMaster: '0x3e3EA5dA8eD28be5cdb9D5E95ad74B9A6cbc2196', 
  apiGetWinStats: 'https://us-central1-xwininvestment.cloudfunctions.net/getXWINStats',
  apiGetTVL: 'https://us-central1-xwininvestment.cloudfunctions.net/getTotalLockValueV2',
  apiGetJPYUSD: 'https://us-central1-xwininvestment.cloudfunctions.net/getJpyUsdExchangeRate',
  apiOptimization: 'https://us-central1-xwinstage.cloudfunctions.net/allocation',
  apiBasicAllocation: 'https://us-central1-xwinstage.cloudfunctions.net/basicAllocation',
  apiGetPrivateFundsByContractAddress: 'https://us-central1-xwinstage.cloudfunctions.net/getPrivateFundsByContractAddress',
  apiGetPrivateFundsBySearchKey: 'https://us-central1-xwinstage.cloudfunctions.net/getPrivateFundsBySearchKey',
  apiGetLatestPrivateFunds: 'https://us-central1-xwinstage.cloudfunctions.net/getLatestPrivateFunds',
  apiGetFundActivitiesByWalletOrContractAddr: 'https://us-central1-xwinstage.cloudfunctions.net/getFundActivitiesByWalletOrContractAddr',
  apiGetPrivateFunds: 'https://us-central1-xwinstage.cloudfunctions.net/getPrivateFunds',
  apiGetTATradingSignal: 'https://us-central1-xwinstage.cloudfunctions.net/getTATradingSignal',
  apiGetTokenPrices: 'https://us-central1-xwinstage.cloudfunctions.net/getTokenPrices',
  apiGetMarketTrends: 'https://us-central1-xwinstage.cloudfunctions.net/getMarketTrends',
  apiGetSharpeRatio: 'https://us-central1-xwinstage.cloudfunctions.net/getSharpeRatio',
  apiGetPerformanceCompare: 'https://us-central1-xwinstage.cloudfunctions.net/getPerformanceReturns',
  gasPrice: '5000000000',  // for Main Net
  blockPerDay: 28800,  // for Main Net
  env: 'v2bscmain',  // for Main Net
  nativeToken: 'BNB', 
  privateVaults: [],
  fixedRateVaults: [],
  fixedRateRetiredVaults: [],
  charity: [],
  privateVaultsAlpaca: [],
  tokenDisplay : [
    {name : "BNB", logo: "bnb.png", address : "0x0000000000000000000000000000000000000000"},
    {name : "USDT", logo: "usdt.png", address : "0x55d398326f99059fF775485246999027B3197955"},
    {name : "USDC", logo: "usdc.png", address : "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"},
    {name : "XWIN", logo: "xwin.png", address : "0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28"},
  ]
};

export default appGlobalConfig;

