const xwinstrategies = {
  strategies: [
    {
      "contractaddress": "0x27F30D83554e2aE856Ffb50187f565E54A7A8611",
      "env" : "v2bscmain",
      "type": "singleasset",
      "underlying": "0x55d398326f99059fF775485246999027B3197955",
      "proxyfund": "0x6439f73dA37Bc8EB83Ac05c615Eb8BAb9fA2a701",
      "name": "xUSDT-V",
      "decimal": 18,
      "strategyname":"xUSDT Venus Staking",
      "blockPerYear" : 10512000


    },
    {
      "contractaddress": "0xB26012DB23683b823C6CA317e228E225f3845944",
      "env" : "v2bscmain",
      "type": "singleasset",
      "underlying": "0x55d398326f99059fF775485246999027B3197955",
      "proxyfund": "0x6439f73dA37Bc8EB83Ac05c615Eb8BAb9fA2a701",
      "name": "xUSDT-O",
      "decimal": 18,
      "strategyname":"xUSDT Ola Staking",
      "blockPerYear" : 31536000


    },
    {
      "contractaddress": "0xA9c087E8B46C508682334979915e18414c208463",
      "env" : "v2bscmain",
      "type": "singleasset",
      "underlying": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "proxyfund": "0x61B170B66DD1d463e9f9Eb4c6646a5fE4fbDEb7C",
      "name": "xBTC-V",
      "decimal": 18,
      "strategyname":"xBTC Venus Staking",
      "blockPerYear" : 10512000


    },
    {
      "contractaddress": "0x9a54717818E89d6d99AF2353Fa53a865734cC78f",
      "env" : "v2bscmain",
      "type": "singleasset",
      "underlying": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "proxyfund": "0x61B170B66DD1d463e9f9Eb4c6646a5fE4fbDEb7C",
      "name": "xBTC-O",
      "decimal": 18,
      "strategyname":"xBTC Ola Staking",
      "blockPerYear" : 31536000


    },
    {
      "contractaddress": "0xab731D8657CFf08be761B55FcB39C99FdAEad19D",
      "env" : "v2bscmain",
      "type": "singleasset",
      "underlying": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
      "proxyfund": "0x6747342E441979C08DC03500243E864F491e607f",
      "name": "xADA-V",
      "decimal": 18,
      "strategyname":"xADA Venus Staking",
      "blockPerYear" : 10512000
      


    },
    {
      "contractaddress": "0x2088A8AAf5C5BFC6D0b801683fa78fE6c0522678",
      "env" : "v2bscmain",
      "type": "dca",
      "underlying": "0x2088A8AAf5C5BFC6D0b801683fa78fE6c0522678",
      "proxyfund": "0xDFB607317629114d32DBD4Bf0Ab70785f953e8b4",
      "name": "xDCA",
      "decimal": 18,
      "strategyname":"Dollar Cost Average"


    },
    {
      "contractaddress": "0xAC01D131C04546C8dB2eacE57e323046Caf3d390",
      "env" : "v2bscmain",
      "type": "sca",
      "underlying": "0xAC01D131C04546C8dB2eacE57e323046Caf3d390",
      "proxyfund": "0xc11D9796Ff40Ac7089299C37cB336cd62d6c564a",
      "name": "xSCA",
      "decimal": 18,
      "strategyname":"Stable Coin Alpha"


    },
    {
      "contractaddress": "0xb805048Ee58A7d8bC46E5C3B1fAab7f5492bD505",
      "env" : "v2bscmain",
      "type": "irt",
      "underlying": "0xb805048Ee58A7d8bC46E5C3B1fAab7f5492bD505",
      "proxyfund": "0x2E89724E1d8b6b9F0f173496298dFf24c82facDD",
      "name": "xIRT",
      "decimal": 18,
      "strategyname":"Interest Rate Trading"


    },
    {
      "contractaddress": "0x4AAfF5E3584e6aa68422D2a491a7C751961ced31",
      "env" : "v2bscmain",
      "type": "bbma",
      "underlying": "0x4AAfF5E3584e6aa68422D2a491a7C751961ced31",
      "proxyfund": "0x9A0d13f09ef7426361e54f1cB44E7F46DCDBf523",
      "name": "xBBMA",
      "decimal": 18,
      "strategyname":"BBMA Trading"


    }
  ]
};

export default xwinstrategies;

