import WalletConnectProvider from "@walletconnect/web3-provider";
import { useEffect, useState } from 'react';
import Web3 from 'web3';
import Web3Modal from "web3modal";
import Torus from "@toruslabs/torus-embed";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Authereum from "authereum";
import { changeToBSCNetwork, convertFromWei } from "./utils/helper";
import { systemConfig } from "./configs/systemConfig";
import { getTokenInfo } from "./utils/erc20Interactor";
import appGlobalConfig from "./appGlobalConfig";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { EthereumAddressFromSignedMessageResponse } from "@coinbase/wallet-sdk/dist/relay/Web3Response";
import Fortmatic from "fortmatic";
import Portis from "@portis/web3";
import EthProvider from "eth-provider";
import { Bitski } from "bitski";

const isDark = (localStorage.getItem("theme") === "dark") ? true : false



const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        [systemConfig.chainId] : systemConfig.rpc //'https://bsc-dataseed.binance.org/'
      },
      network: 'binance',
      // infuraId: process.env.REACT_APP_INFURA_ID,
    }
    
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "My Awesome App", // Required
      infuraId: "8043bb2cf99347b1bfadfb233c5325c0", // Required
      // rpc: systemConfig.rpc, // Optional if `infuraId` is provided; otherwise it's required
      chainId: 56, // Optional. It defaults to 1 if not provided
      darkMode: false // Optional. Use dark theme, defaults to false
    }
  },
  torus: {
    package: Torus,
    options: {
      networkParams: {
        host: systemConfig.rpc, //"https://bsc-dataseed.binance.org/", // optional
        chainId: 56, // optional
        networkId: 56, // optional
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
        supportedChainIds: [1, 3, 4, 5, 42]
      },
    }
  },
  authereum: {
    package: Authereum // required
  },
  fortmatic: {
    package: Fortmatic, // required
    options: {
      key: "pk_live_6DE170239580F37E", // required
        network:  {
          rpcUrl: systemConfig.rpc,
          chainId: 56 // if we don't pass it, it will default to localhost:8454
      }
    }
  },


  bitski: {
    package: Bitski, // required
    options: {
      clientId: systemConfig.rpc, // required
      callbackUrl: systemConfig.rpc // required
    }
  }



}



const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
  theme: isDark ? {
    background: "rgb(39, 49, 56)",
    main: "rgb(199, 199, 199)",
    secondary: "rgb(136, 136, 136)",
    border: "rgba(195, 195, 195, 0.14)",
    hover: "rgb(16, 26, 32)"
  } : "light"
})

function useNetwork() {
  const [wallet, setWallet] = useState("---")
  const [network, setNetwork] = useState("---")
  const [account, setAccount] = useState("---")
  const [theWeb3, setWeb3] = useState(new Web3(systemConfig.rpc))
  const [web3static, setWeb3static] = useState(new Web3(systemConfig.rpc))
  const [isConnected, setIsConnected] = useState(false)
  const [ETHBalance, setETHBalance] = useState(0)
  const [USDTBalance, setUSDTBalance] = useState(0)
  const [USDCBalance, setUSDCBalance] = useState(0)
  const [XWINBalance, setXWINBalance] = useState(0)
  const [tokenBalances, setTokenBalances] = useState([])
  const [globalProvider, setGlobalProvider] = useState(undefined)

  useEffect(() => {
    // if ethereum-compatible browsers
    if (typeof window.ethereum !== 'undefined') {
      setWallet(window.ethereum?.isMetaMask ? "MetaMask" : "OtherWallet")
    } else {
      setWallet("None")
    }
  }, [])

  const connectNetwork = async () => {

      // await changeToBSCNetwork(network, theWeb3.currentProvider);
      const provider = await web3Modal.connect()
      
      // console.log(parseInt(provider.chainId).toString())
      // if(parseInt(provider.chainId) === systemConfig.chainId){
        // console.log("here")
        setIsConnected(true)
        setGlobalProvider(provider)
        const web3static_ = new Web3(systemConfig.rpc)
        const web3_ = new Web3(provider)    
        setWeb3(web3_)
        setWeb3static(web3static_)
        setupNetwork(web3_)
        setupAccount(web3_)
      // }else{
      //   throw TypeError("Wrong network");
      //   // throw ("Wrong network");
      // }
  }

  const refreshAccount = async () => {
    const web3_ = new Web3(globalProvider)
    setupAccount(web3_)
  }

  const setupNetwork = async (web3_) => {
    const nodeInfo = await web3_.eth.getNodeInfo()
    setWallet(nodeInfo.split(" ", 1)[0])

    const chainId = await web3_.eth.getChainId()
    const networkId = await web3_.eth.net.getId()
    const networkType = await web3_.eth.net.getNetworkType()
      .catch(err => {
        console.log(`Error in getNetworkType: ${err.message}`)
        return "unknown"
      })

      // console.log(`${chainId} ${networkId} (${networkType})`)
      setNetwork(chainId)
  }

  const setupAccount  = async (web3_) => {
    
    let tokens = []
    let accounts = await web3_.eth.getAccounts()
    setAccount(accounts[0])



    const promises = appGlobalConfig.tokenDisplay.map(async t => {
      
      if(t.address === appGlobalConfig.mainnetAddressZero){
        let balance = await web3_.eth.getBalance(accounts[0])
        balance = web3_.utils.fromWei(balance.toString())   
        return {
          name : t.name,
          address : t.address,
          logo : t.logo,
          balance : balance,
        }     
      }else{
        const tokenInfo= await getTokenInfo(web3_, t.address, accounts[0]);
        return {
          name : t.name,
          address : t.address,
          logo : t.logo,
          balance : tokenInfo.targetBal,
        }
      }
    })

    const tmpTokens = await Promise.all(promises);
    let t = tmpTokens.find(x=>x.address === appGlobalConfig.mainnetAddressZero)
    setETHBalance(t.balance)
    t = tmpTokens.find(x=>x.address === appGlobalConfig.mainnetXWIN)
    setXWINBalance(t?.balance || 0);
    t = tmpTokens.find(x=>x.address === appGlobalConfig.mainnetUSDT)
    setUSDTBalance(t.balance);
    t = tmpTokens.find(x=>x.address === appGlobalConfig.mainnetUSDC)
    setUSDCBalance(t.balance);
    setTokenBalances(tmpTokens);



    
  }


  
  const disconnectNetwork = async () => {
    if (theWeb3 && theWeb3.currentProvider && theWeb3.currentProvider.close) {
      await theWeb3.currentProvider.close()
    }
    setGlobalProvider(undefined)
    web3Modal.clearCachedProvider()

    setNetwork("---")
    setAccount("---")
    // setWeb3(undefined)
    // setWeb3static(undefined)
    setIsConnected(false)
  }

  return [{ wallet, network, account, theWeb3, web3static, isConnected, ETHBalance, XWINBalance, USDTBalance, tokenBalances, globalProvider }, refreshAccount, connectNetwork, disconnectNetwork]
}

export default useNetwork
