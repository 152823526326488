import React from "react";
import ReactGA from 'react-ga';
import {
  useLocation,
} from "react-router-dom";

import config from "../../../src/config";

const Analytics = () => {
  const location = useLocation();  
  if (config?.analytics?.google?.id) {
    ReactGA.initialize(config.analytics.google.id);
  }
  React.useEffect(() => {
    ReactGA.pageview(location.pathname);
  },[location.pathname]);

  return null;
}

export default Analytics;