import React, { useLayoutEffect, useEffect  } from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Box, Button } from '@material-ui/core';
import protocolBanner01 from '../../../assets/images/Band-Protocol-Graphic.png';
import protocolBanner02 from '../../../assets/images/Pancake-Swap-Graphic.png';
import './Protocol.css';
import AOS from 'aos';
import '../../home.css';
import {withRouter} from 'react-router-dom'
import xWinUtils from '../../../../xWinUtils';
import { Tooltip } from "reactstrap";

// function Protocol() {
const Protocol = props => {

	useEffect(() => {
		AOS.init({
			disable: 'mobile'
		  });
	});
	
	const goToDashboard = event => {
		props.history.push('/app/dashboard')
	}
	
	const goToDoc = event => {
		window.open("https://xwinfinance.gitbook.io/xwin-docs/master","_blank")
	}

	return (
		<section className="section-spacing" id="protocol">
			<Container maxWidth="lg" className="main-container text-white">
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Box data-aos="fade-up" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" textAlign="center" className="section-heading text-uppercase">
							<h2>Collaboration Project</h2>
						</Box>
					</Grid>
					<Grid item md={2} xs={6}>
						<Box data-aos="flip-left" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" >
							<Box onClick={xWinUtils.buyInPCS} className="protocol-banner">
								<img width={150} height={150} title="Pancakeswap" className="img-fluid" src="../../images/background/icon-square-512.png" alt="Pancakeswap" />
							</Box>
						</Box>
					</Grid>
					<Grid item md={2} xs={6}>
						<Box data-aos="flip-left" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" >
							<Box onClick={xWinUtils.buyInBaby} className="protocol-banner">
								<img width={150} height={150} title="Babyswap" className="img-fluid" src="../../images/background/BABY-icon.png" alt="Babyswap" />
							</Box>
						</Box>
					</Grid>
					<Grid item md={2} xs={6}>
						<Box data-aos="flip-left" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" >
							<Box onClick={xWinUtils.goAlpaca} className="protocol-banner">
								<img width={150} height={150} title="Alpaca Finance" className="img-fluid" src="../../images/background/alpaca-logo.png" alt="Alpaca Finance" />
							</Box>
						</Box>
					</Grid>
					<Grid item md={2} xs={6}>
						<Box data-aos="flip-left" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" >
							<Box onClick={xWinUtils.goChainlink} className="protocol-banner">
								<img width={150} height={150} title="Chainlink" className="img-fluid" src="../../images/background/chainlink-logo.png" alt="Chainlink" />
							</Box>
						</Box>
					</Grid>
					<Grid item md={2} xs={6}>
						<Box data-aos="flip-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" >
							<Box onClick={xWinUtils.goBandProtocol} className="protocol-banner">
								<img width={150} height={150} title="BandProtocol" className="img-fluid" src="../../images/background/bandprotocol.png" alt="BandProtocol" />
							</Box>
						</Box>
					</Grid>
					<Grid item md={2} xs={6}>
						<Box data-aos="flip-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" >
							<Box onClick={xWinUtils.goCroxSwap} className="protocol-banner">
								<img width={150} height={150} title="CroxSwap" className="img-fluid" src="../../images/background/CROX-Color-logo-HD.png" alt="CroxSwap" />
							</Box>
						</Box>
					</Grid>
					<Grid item md={2} xs={6}>
						<Box data-aos="flip-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" >
							<Box onClick={xWinUtils.goCoingecko} className="protocol-banner">
								<img width={150} height={150} title="Coingecko" className="img-fluid" src="../../images/background/coingecko-logo.png" alt="Coingecko" />
							</Box>
						</Box>
					</Grid>
					<Grid item md={2} xs={6}>
						<Box data-aos="flip-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" >
							<Box onClick={xWinUtils.goCoinmarketcap} className="protocol-banner">
								<img width={150} height={150} title="Coinmarketcap" className="img-fluid" src="../../images/background/coinmarketcap-logo.png" alt="Coinmarketcap" />
							</Box>
						</Box>
					</Grid>
					
					<Grid item md={2} xs={6}>
						<Box data-aos="flip-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" >
							<Box onClick={xWinUtils.goVulkania} className="protocol-banner">
								<img width={150} height={150} title="Vulkania" className="img-fluid" src="../../images/background/vulkania.png" alt="Vulkania" />
							</Box>
						</Box>
					</Grid>
					<Grid item md={2} xs={6}>
						<Box data-aos="flip-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" >
							<Box onClick={xWinUtils.goP2P} className="protocol-banner">
								<img width={150} height={150} title="P2PB2B" className="img-fluid" src="../../images/background/p2pb2b.png" alt="P2PB2B" />
							</Box>
						</Box>
					</Grid>
					<Grid item md={2} xs={6}>
						<Box data-aos="flip-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" >
							<Box onClick={xWinUtils.goBscScan} className="protocol-banner">
								<img width={150} height={150} title="BSCSCAN" className="img-fluid" src="../../images/background/bscscan.png" alt="BSCSCAN" />
							</Box>
						</Box>
					</Grid>
					<Grid item md={2} xs={6}>
						<Box data-aos="flip-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" >
							<Box onClick={xWinUtils.goBscScan} className="protocol-banner">
								<img width={150} height={150} title="Metamask" className="img-fluid" src="../../images/background/metamask.png" alt="Metamask" />
							</Box>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Box textAlign="center" className="footer-btns-holder">
							<Button onClick={goToDashboard} data-aos="fade-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" variant="contained" color="primary">Launch App</Button>
							<Button onClick={goToDoc} data-aos="fade-left" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" variant="contained" color="secondary">xWin Docs</Button>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</section>
	);
}
export default withRouter(Protocol)
