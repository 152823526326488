// import Web3 from "web3";
import xWinPriceMaster from "../abi/v2/xWinPriceMaster.json";
import appGlobalConfig from "../appGlobalConfig";
import { systemConfig } from "../configs/systemConfig";
import { convertFromWei } from "../utils/helper";
import xWinLib from '../xWinLib';
import xWinUtils from "../xWinUtils";

export async function getPrice(web3, from, to) {

  try {
    // console.log(from, to)
    const contract = new web3.eth.Contract(xWinPriceMaster, appGlobalConfig.mainnetxWInPriceMaster);
    let rate = await contract.methods.getPrice(from, to).call();
    return rate;

  } catch (error) {
    console.log(error)
  }
}

export async function getAllPrices(web3, globalProvider) {

  // console.log(globalProvider)
  // if(globalProvider.networkVersion === "56"){
    try {
      const contract = new web3.eth.Contract(xWinPriceMaster, appGlobalConfig.mainnetxWInPriceMaster);
      const promiseData = await Promise.all([
        contract.methods.getPrice(appGlobalConfig.mainnetXWIN, appGlobalConfig.mainnetUSDT).call(),
        // contract.methods.getPrice(appGlobalConfig.mainnetXWIN, appGlobalConfig.mainnetWBNB).call(),
        contract.methods.getPrice(appGlobalConfig.mainnetWBNB, appGlobalConfig.mainnetUSDT).call(),
        contract.methods.getPrice(appGlobalConfig.mainnetXWINBNBLPPoolV2, appGlobalConfig.mainnetUSDT).call(),
        contract.methods.getPrice(appGlobalConfig.mainnetXWINUSDTAPELPPool, appGlobalConfig.mainnetUSDT).call(),
        xWinUtils.fetchJPYUSDRate(),
      ]);
  
    let XWINUSDTRates = promiseData[0]
    let BNBUSDTRates = promiseData[1]
    let XWINBNBLPRates = promiseData[2]
    let XWINUSDTLPRates = promiseData[3]
    let JPYUSDRates = promiseData[4]
      
    let unipriceInBNB = Number(convertFromWei(XWINUSDTRates)) / Number(convertFromWei(BNBUSDTRates))
    return {
        xwinPriceInUSD : convertFromWei(XWINUSDTRates),
        BNBUSDT : convertFromWei(BNBUSDTRates),
        unipriceInBNB : unipriceInBNB,
        pcsLPPrice : convertFromWei(XWINBNBLPRates),
        JPYUSD : JPYUSDRates,
        babyLPPrice : convertFromWei(XWINUSDTLPRates)
      }
        
    } catch (error) {
      console.log(error)
    }
  // }else{
  //   return {
  //     xwinPriceInUSD : "0",
  //     BNBUSDT : "0",
  //     unipriceInBNB : "0",
  //     pcsLPPrice : "0",
  //     JPYUSD : "0",
  //     babyLPPrice : "0"
  //   }
  // }
}
