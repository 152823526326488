import HomeIcon from '@material-ui/icons/Home';
import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';

import {
  Box,
  Button,
  Chip,
  IconButton,
} from '@material-ui/core';
import { Avatar, Typography } from "../../../components/Wrappers/Wrappers";
import { LastPage as LastPageIcon, NavigateBefore as NavigateBeforeIcon, NavigateNext as NavigateNextIcon } from '@material-ui/icons';

import useStyles from '../styles';
import xWinLib from '../../../xWinLib';
import xWinUtils from '../../../xWinUtils';
import { AppContext } from '../../../context/AppProvider';

import { useTheme } from '@material-ui/styles';


const PreviousLuckyDraw = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { account, isConnected, theWeb3 } = useContext(AppContext);
  const [current, setCurrent] = React.useState(1);
  const [max, setMax] = React.useState(1);
  const [pool, setPool] = React.useState({ totalTobeWon: 0 });
  const [user, setUser] = React.useState(null);
  const [winners, setWinners] = useState([]);
  const [loadingTxn, setLoadingTxn] = React.useState(false);


  const events = {
    onUpdateInput: (event) => {
      if(props.round.displayLastDrawnID === 0) return
      setCurrent(event.target.value);

      if (event.target.value === '') return;
      const n = event.target.value > max ? max : event.target.value;

      events.onChangeRound(n);
    },
    onChangeRound: async (value) => {
      
      if(props.round.displayLastDrawnID === 0) return

      setCurrent(value);
      const info = await props.protocolDisplay.methods.poolInfo(value - 1).call();
      info.totalTobeWon = Number(xWinUtils.convertFromWei(theWeb3, info.totalTobeWon.toString()));
      info.drawDateTime = xWinLib.FormatUnixDate(info.drawDateTime);
      
      if(account !== "---"){
        const winner = await props.protocolDisplay.methods.winnerUser(value - 1, account).call({ from: account });
        setUser({
          poolID: Number(winner.poolID),
          totalWonBalance: Number(xWinUtils.convertFromWei(theWeb3, winner.totalWonBalance.toString())),
          receivedBalance: Number(xWinUtils.convertFromWei(theWeb3, winner.receivedBalance.toString())),
        });
      }else{
        setUser({
          poolID: 0,
          totalWonBalance: 0,
          receivedBalance: 0,
        });
      }
      

      setPool(info);

      await getWinners(value - 1);
    },
    onRedeem: async () => {
      if (props.protocol) {
          setLoadingTxn(true);
          xWinLib.redeemLuckyDrawAsync(props.protocol, account, current - 1)
          .then(res =>
            { 
              console.log(res)
              setLoadingTxn(false)
              props.snackbar.current.handleOpen('success', res);
              events.onChangeRound(current);
            }
          )
          .catch(err => {
            setLoadingTxn(false)
            console.log(err)
            props.snackbar.current.handleOpen('error', err);
              
          })
      } else {
        props.snackbar.current.handleOpen('info', 'Please connect to BSC Mainnet');
        setLoadingTxn(false);
      }
    },
  };


  // if (theWeb3 !== undefined && props.protocol !== undefined) {
  //   theWeb3.currentProvider.on('accountsChanged', () => {
  //     load();
  //   });

  //   theWeb3.currentProvider.on('chainChanged', () => {
  //     load();
  //   });
  // }

  useEffect(() => {
    if (props.protocol && props.round.lastDrawnID) {

      load();
    }
  }, [props.round.lastDrawnID]);

  const load = async () => {
    // if (! isConnected) return;

    if (theWeb3 === undefined) return;

    // if (account === '---') return;

    if (props.round.lastDrawnID === 0) return;

    if (! props.protocolDisplay) return;

    const info = await props.protocolDisplay.methods.poolInfo(props.round.lastDrawnID - 1).call();
    info.totalTobeWon = xWinUtils.convertFromWei(theWeb3, info.totalTobeWon);
    info.drawDateTime = xWinLib.FormatUnixDate(info.drawDateTime);

    if(account !== "---"){
      const winner = await props.protocolDisplay.methods.winnerUser(props.round.lastDrawnID - 1, account).call({ from: account });
      setUser({
        poolID: Number(winner.poolID),
        totalWonBalance: Number(xWinUtils.convertFromWei(theWeb3, winner.totalWonBalance)),
        receivedBalance: Number(xWinUtils.convertFromWei(theWeb3, winner.receivedBalance)),
      });
    }else{
      setUser({
        poolID: 0, // Number(winner.poolID),
        totalWonBalance: 0,
        receivedBalance: 0,
      });
    }
    

    setPool(info);
    setCurrent(props.round.lastDrawnID);
    setMax(props.round.lastDrawnID);
    await getWinners(props.round.lastDrawnID - 1);
  };

  const getWinners = async (poolId) => {
    setWinners([]);
    let numberOfPrizes = await props.protocolDisplay.methods.numberOfPrizes().call();
    const list = [];
    if(poolId < 3) numberOfPrizes = 3
    for (let index = 0; index < numberOfPrizes; index ++) {
      const luckyValue = await props.protocolDisplay.methods.luckyValues(poolId, index).call();
      const luckyUseraddress = await props.protocolDisplay.methods.userAddressArray(luckyValue).call();

      list.push({
        userID: luckyValue,
        address: luckyUseraddress,
      });
    }

    setWinners(list);
  };

  const displayLuckyValues = () => {
    if (winners.length === 0) return '';
    let numbers = '';

    winners.map((winner) => (
      numbers = `${ numbers + winner.userID }, `
    ));

    numbers += ';';
    numbers = numbers.replace(', ;', '');
    return numbers;
  };

  return (
    <div className={classnames('py-5 px-3', theme.palette.type === "dark" ? classes.previousLuckyDrawContainer : classes.previousLuckyDrawContainerLight )}>
      {xWinUtils.getProgress(loadingTxn)}
      <div className="d-flex justify-content-center py-5">
        <h3 className={classes.headerText}>Previous Lucky Draw</h3>
      </div>
      <div className={classnames('d-flex align-items-center justify-content-center pb-5', classes.previousLuckyDrawCard)}>
        <div className="card col-lg-6 col-md-8 col-sm-12 mx-5">
          <div className={classnames('p-3', classes.borderBottom)}>
            <div className="d-flex justify-content-between mb-2">
              <div className="d-flex align-items-center">
                <h4 className="m-0">Round</h4>
                <input className={classes.roundInputField} type="text" value={current} onChange={events.onUpdateInput} />
              </div>
              <div>
                <IconButton
                  classes={{
                    root: classes.navigateButton,
                  }}
                  variant="outlined"
                  color="primary"
                  disabled={current <= 1}
                  onClick={() => events.onChangeRound(parseFloat(current) - 1)}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton
                  classes={{
                    root: classes.navigateButton,
                  }}
                  variant="outlined"
                  color="primary"
                  disabled={current === max}
                  onClick={() => events.onChangeRound(parseFloat(current) + 1)}
                >
                  <NavigateNextIcon />
                </IconButton>
                <IconButton
                  classes={{
                    root: classes.navigateButton,
                  }}
                  variant="outlined"
                  color="primary"
                  disabled={current === max}
                  onClick={() => events.onChangeRound(max)}
                >
                  <LastPageIcon />
                </IconButton>
              </div>
            </div>
            {pool?.drawDateTime && (
              <span className={classes.dateText}>
                Drawn on
                {` ${ pool?.drawDateTime }`}
              </span>
            )}
          </div>
          {props.round.displayLastDrawnID === 0 && (
            <>
              <div className="d-flex flex-column align-items-center justify-content-center pt-5">
                <h2 className={classes.tokenAmount}>No Draw Yet</h2>
                <h4 className={classes.tokenAmount}>Wait for the next draw!</h4>
              </div>
              <br/>
            </>
          )}
          {props.round.displayLastDrawnID > 0 && (
            <>
            <div className={classnames('d-flex flex-column justify-content-center align-items-center p-5', user && classes.borderBottom)}>
              <h2 className={classes.tokenAmount}>
                {'Total '}
                {xWinLib.roundTo(pool.totalTobeWon, 4)}
                {' '}
                XWIN
              </h2>
              <h3>has been won</h3>
            </div>
            </>
          )}
          {user && user.receivedBalance === 0 && user.totalWonBalance > 0 && (
            <>
              <div className={classnames('d-flex flex-column align-items-center justify-content-center p-5', classes.borderBottom)} >
                <h2>Congratulations</h2>
                <h4 className={classes.tokenAmount}>
                  You have won
                  {' '}
                  <Chip
                    label={xWinLib.roundTo(user.totalWonBalance, 3)}
                    color="primary"
                    avatar={<Avatar src="/images/icons/color/xwin.png" />}
                  />
                </h4>
              </div>
              <div className='d-flex justify-content-center pt-3 pb-5'>
                <Button
                  onClick={events.onRedeem}
                  className={classes.checkButton}
                  variant="contained"
                >
                  Redeem Now
                </Button>
              </div>
            </>
          )}
          {user && user.totalWonBalance > 0 && user.receivedBalance === user.totalWonBalance && (
          <div className={classnames('d-flex flex-column align-items-center justify-content-center p-5', classes.borderBottom)} >
          <h2 className="text-center">Congratulations</h2>
            <h4 className="text-center">You have redeemed the prize!</h4>
            <h4 className={classes.tokenAmount}>
              Total of
              {' '}
              <Chip
                label={xWinLib.roundTo(user.totalWonBalance, 3)}
                color="primary"
                avatar={<Avatar src="/images/icons/color/xwin.png" />}
              />
              {' '}

            </h4>
          </div>
          )}
          {account === "---" && (
              <>
              <div className={classnames('d-flex flex-column align-items-center justify-content-center p-5', classes.borderBottom)} >
                <h3 className="text-center">
                  Please connect to your wallet to check the result
                </h3>
              </div>
              </>
            )
          }
          {account !== "---" && user && user.receivedBalance === 0 && user.totalWonBalance === 0 && (
            <>
              <div className={classnames('d-flex flex-column align-items-center justify-content-center p-5', classes.borderBottom)} >
                <h3 className="text-center">
                  Ops! You didn&apos;t win round
                  {' '}
                  {current}
                  {' '}
                  lucky draw.
                </h3>
                <h4 className="text-center">Try again next time.</h4>
              </div>
            </>
          )}
           {props.round.displayLastDrawnID > 0 && (
            <>
          <div className={classnames('d-flex flex-column align-items-center justify-content-center p-5')} >
            <h3 className="text-center">
                  {`Previous Lucky Numbers`}
            </h3>
            <h3 className={classes.tokenAmount}>
            { displayLuckyValues() }
            </h3>
            <Typography variant="body2">
              {winners.map((winner) => (
                <Box key={winner.address} display="flex" alignItems="center" justifyContent="left">
                  <HomeIcon />&nbsp;&nbsp;
                  <p className="ml-3 mb-0">{xWinUtils.getAddressShort(winner.address)}</p>
                </Box>
              ))}
            </Typography>
          </div>
          </>
          )}
        </div>

      </div>
    </div>
  );
};

export default PreviousLuckyDraw;
