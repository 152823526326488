const vaultFAQ = {
	faqs: [
	  {
		symbol: "v2index", 
		env: "v2bscmain", 
		question: 'What is Token Index Vault?',
		defaultExpanded : true,
		desc: 'Token Index Vault is a basket of tokens that has been categorized based on the nature of the tokens. For example, we have DEFI index vault with tokens such as CAKE, XWIN, Banana and etc in the basket. <br/> Owning DEFI vault tokens allow you to get the exposure to the DEFI tokens without holding each of the tokens separately. It makes your portfolio cleaner and easy to manage.',
	  },
	  {
		symbol: "v2index", 
		env: "v2bscmain", 
		question: 'What is the fJPI Vault?',
		desc: 'fJPI Index Vault consist of major top 5 coins / tokens in BTC, ETH, LTC and etc in the basket. <br/> Owning fJPI vault tokens allow you to get the exposure to the 80% of the crypto currencies exposure without holding each of the tokens separately. It makes your portfolio cleaner and easy to manage.',
	  },
	  {
		symbol: "v2index", 
		env: "v2bscmain", 
		question: 'What is the fDEFI Vault?',
		desc: 'fDEFI Index Vault consist of tokens such as CAKE, XWIN, Banana and etc in the basket. <br/> Owning DEFI vault tokens allow you to get the exposure to the fDEFI tokens without holding each of the tokens separately. It makes your portfolio cleaner and easy to manage.',
	  },
	  {
		symbol: "v2trading", 
		env: "v2bscmain", 
		question: 'What is Auto Trading Vault?',
		desc: 'Auto Trading Vault involve several different trading strategies provided by xWIN team. It ranges from the simple algorithm such as dollar cost average that help you to buy the BTC everyday to the complicated trading strategy based on the technical analysis on the market. <br/> The engine and algo will do all the hardwork everyday, where investor will have a freeride on the vault returns.',
	  },
	  {
		symbol: "v2trading", 
		env: "v2bscmain", 
		question: 'What is (BTC) Dollar Cost Average (fbtcDCA) Vault?',
		defaultExpanded : true,
		desc: 'fbtcDCA Trading Vault stake USDT into Venus lending protocol to earn interest. In the same time, engine behind will use part of the USDT to buy BTC everyday to achieve lower book cost in your investment especially in the market downturn <br/>Dollar cost averaging is investing a fixed amount of money into a particular investment at regular intervals, typically daily, monthly or quarterly. This strategy, with its potential to mitigate timing risk, is most often employed for riskier investments especially in crypto funds<div style=\"margin-left: auto; margin-right: auto;\" data-ephox-embed-iri=\"https://xwinfinance.s3.amazonaws.com/xWIN-DCA.m4v#t=4\"><video style=\"width: 100%;\" controls=\"controls\">   <source src=\"https://xwinfinance.s3.amazonaws.com/xWIN-DCA.m4v#t=4\" type=\"video/mp4\">   Your browser does not support the video element. </video></div><div style=\"width: 100%; display: flex; flex-wrap: wrap;\"><div style=\"flex: 1 1 0%; text-align: justify;\">&nbsp;</div><div>&nbsp;</div></div>',
	  },
	  {
		symbol: "v2stablealpha", 
		env: "v2bscmain", 
		question: 'What is USDT Stablecoin Alpha (fusdtSCA) Vault?',
		defaultExpanded : true,
		desc: 'fusdtSCA Trading Vault stake USDT into Venus lending protocol to earn interest. While the interest earned each day will be used to buying BTC. The vault is aiming on the upside opportunity while protecting the capital of your investment. <br/> When come to bull market, your portfolio would worth more than just USDT staking returns.<br/><div style=\"margin-left: auto; margin-right: auto;\" data-ephox-embed-iri=\"https://xwinfinance.s3.amazonaws.com/xWIN-SCA.mp4#t=1\"><video style=\"width: 100%;\" controls=\"controls\">   <source src=\"https://xwinfinance.s3.amazonaws.com/xWIN-SCA.mp4#t=1\" type=\"video/mp4\">   Your browser does not support the video element. </video></div><div style=\"width: 100%; display: flex; flex-wrap: wrap;\"><div style=\"flex: 1 1 0%; text-align: justify;\">&nbsp;</div><div>&nbsp;</div></div>',
	  },
	  {
		symbol: "v2trading", 
		env: "v2bscmain", 
		question: 'What is (BTC) Interest Rate Trading (fbtcIRT) Vault?',
		desc: 'fbtcIRT, Interest Rate Trading Vault depends on the indicator of USDTs deposit rate which is a strong indicator of where the market is at. We believe that a high deposit rate indicates the market is over-leveraged while a low deposit rate may signal a under-leverage market. <br/><div style=\"margin-left: auto; margin-right: auto;\" data-ephox-embed-iri=\"https://xwinfinance.s3.amazonaws.com/xWIN-IRT.mp4#t=4\"><video style=\"width: 100%;\" controls=\"controls\">   <source src=\"https://xwinfinance.s3.amazonaws.com/xWIN-IRT.mp4#t=4\" type=\"video/mp4\">   Your browser does not support the video element. </video></div><div style=\"width: 100%; display: flex; flex-wrap: wrap;\"><div style=\"flex: 1 1 0%; text-align: justify;\">&nbsp;</div><div>&nbsp;</div></div>',
	  },
	  {
		symbol: "v2singlebase", 
		env: "v2bscmain", 
		question: 'What is Single Asset?',
		defaultExpanded : true,
		desc: 'Single Asset Vault allow you to stake your single asset token to earn the interest from lending protocol in Venus or Ola Finance. The unit price is based on the token price. It will be allocating 50% / 50% to Venus and Ola if both protocols provide the pool. It is rebalanced accordingly based on the interest rates provided gradually',
	  },
	  {
		symbol: "v2singlebase", 
		env: "v2bscmain", 
		question: 'What is fxBTC-vo?',
		desc: 'fxBTC-vo stake your BTC into Venus and Ola Finance in a optimal proportion. It is initially set as 50% / 50% and it will gradually rebalance according to the interest rates available in both protocols',
	  },
	  {
		symbol: "v2singlebase", 
		env: "v2bscmain", 
		question: 'What is fxADA-v?',
		desc: 'fxADA-v stake your ADA into Venus and earn the interest. It is currently set as 100% in Venus protocol only',
	  }, 
	  {
		symbol: "v2singlebase", 
		env: "v2bscmain", 
		question: 'What is fxUSDT-vo?',
		desc: 'fxUSDT-vo stake your USDT into Venus and Ola Finance in a optimal proportion. It is initially set as 50% / 50% and it will gradually rebalance according to the interest rates available in both protocols',
	  },
	  {
		symbol: "v2single", 
		env: "v2bscmain", 
		question: 'What is Single Asset Vault in Buy/Sell?',
		desc: 'Buy/Sell is price in USDT. If you intend to buy BTC, then buying xBTC will more more value than native BTC because xBTC stake in lending protocol and earn you the interest. The unit price is based on the USDT.',
	  },
	  {
		symbol: "v2private", 
		env: "v2bscmain", 
		question: 'What is Private Vault?',
		defaultExpanded : true,
		desc: 'You can create your own private own on the fly in xWIN Finance. You can run optimizer based on your criteria and xWIN optimizer will recommend the optimal weight allocation. <br/> Only your wallet is allowed to deposit and withdraw. This is completely your vault!',
	  },
	  {
		symbol: "v2private", 
		env: "v2bscmain", 
		question: 'Are other wallets allowed to deposit into my private vault?',
		desc: 'In short, No. Unless you set it to public (To be supported)',
	  },
	  {
		symbol: "v2private", 
		env: "v2bscmain", 
		question: 'What are the fee charged in the private vault?',
		desc: 'By default, there are 1% xWIN platform fee, 1% strategy fee and 20% of performance fee attached in the private vault.',
	  },
	  {
		symbol: "v2private", 
		env: "v2bscmain", 
		question: 'Can I earn some fee through my private vault opened to public?',
		desc: 'Yes. Please contact xWIN Admin at admin@xwin.finance',
	  },
	],
  };
  
  export default vaultFAQ;
  
  