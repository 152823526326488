import appGlobalConfig from '../appGlobalConfig';

export async function GetTokenPrices(contractaddress) {

  let api = appGlobalConfig.apiGetTokenPrices + "?contractaddress=" +contractaddress
  let output = await (await fetch(api)).json();
  return output.message;
}

export async function GetTATradingSignal() {

  let api = appGlobalConfig.apiGetTATradingSignal
  let output = await (await fetch(api)).json();
  return output.message;

}

export async function GetPerformanceCompare(targetAddr, bmAddr) {

  let api = appGlobalConfig.apiGetPerformanceCompare+ "?contractaddress=" +targetAddr + "&bmaddress=" + bmAddr
  let output = await (await fetch(api)).json();
  return output.message;

}


export async function GetWinStats() {

  let api = appGlobalConfig.apiGetWinStats
  let output = await (await fetch(api)).json();
  return output;

}

export async function GetTVLv1() {

  let api = appGlobalConfig.apiGetTVL
  let output = await (await fetch(api)).json();
  return output;

}

export async function GetMarketTrends(month) {

  let api = appGlobalConfig.apiGetMarketTrends + "?month="+month
  let output = await (await fetch(api)).json();
  return output.message;

}

export async function GetSharpeRatio(model, month) {

  let api = appGlobalConfig.apiGetSharpeRatio + "?month="+month+"&model="+model
  let output = await (await fetch(api)).json();
  return output.message;

}

