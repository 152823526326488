import React, { useState, useEffect, useRef, useContext } from 'react'
import xWinLib from '../../xWinLib'
import xWinUtils from '../../xWinUtils'
import { useParams } from 'react-router-dom'
import UnitPrice from './components/UnitPrice'
import format from 'format-number'
import { withRouter } from 'react-router-dom'
import ApexLineChart from './components/ApexLineChart'
import ReturnsCompareChart from './components/returnsCompareChart'
import TargetAllocation from './components/TargetAllocation'
import Strategies from '../strategies/Strategies'
import TermCondition from '../termcondition/termcondition'
import DepositV2 from '../depositV2/depositV2'
import RedeemV2 from '../redeemV2/redeemV2'
import { AppContext } from '../../context/AppProvider'
import confetti from 'canvas-confetti'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import BarChartIcon from '@material-ui/icons/BarChart'

import HistoryEvents from '../historyDetails/HistoryDetailV2'
import HistoryIcon from '@material-ui/icons/History';
import PersonIcon from '@mui/icons-material/Person';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ShareIcon from '@mui/icons-material/Share';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { TwitterShareButton } from 'react-share';
import TwitterIcon from '@mui/icons-material/Twitter';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';



import {
    Grid,
    Box,
    CardContent,
    Card,
    CardHeader,
    TableRow,
    Table,
    TableCell,
    TableBody,
    Avatar,
    TableHead,
    Tooltip,
    ButtonGroup,
    Tabs,
    Tab,
    IconButton
} from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { Typography, Button, Link } from '../../components/Wrappers/Wrappers'
import useStyles from './styles'
import PieChartIcon from '@material-ui/icons/PieChart'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import PropTypes from 'prop-types'
import { GetFundDataAll, getFundExtraInfo } from '../../utils/fundV2Interactor'
import { getTokenInfo } from '../../utils/erc20Interactor'
import { getNetworkById } from '../../utils/helper'
import { systemConfig } from '../../configs/systemConfig'
import { useTheme } from '@material-ui/styles'
import RebalanceV2 from '../rebalanceV2/RebalanceV2'
import MessageTemplate from './components/MessageTemplate'
import { GetTokenPrices } from '../../utils/firestoreDataInteractor'
import xwinfundv2 from '../../v2Config/xwinfundv2'
import appGlobalConfig from '../../appGlobalConfig'
import tokensv2 from '../../v2Config/tokensv2'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const FundDetailV2 = props => {
    
    // const { portfolios, tokensMaster } = props;
    var theme = useTheme()
    const classes = useStyles()
    const [networkName, setNetworkName] = useState('')
    const [mainData, setMainData] = React.useState([])
    const [priceData, setPriceData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [openWithdrawModal, setOpenWithdrawModal] = useState(false)
    const [openDepositModal, setOpenDepositModal] = useState(false)
    const [openRebModal, setOpenRebModal] = useState(false)
    const [returnChoice, setReturnChoice] = React.useState(2)
    const [period, setPeriod] = React.useState(8)
    const [priceReturns, setPriceReturns] = useState(0)
    const [isCopied, setIsCopied] = useState(false)
    const [durationTweet, setDurationTweet]= useState("Weekly")


    const [userData, setUserData] = useState({
        walletETHBalance: 0,
        selectedWallet: '',
        userfundbal: 0,
        referral: '',
    })
    const [fundData, setFundData] = useState([])
    const [relatedStrategyData, setRelatedStrategyData] = useState([])
    const { id } = useParams()
    const [port, setPort] = useState([])
    const [fundtype, setFundtype] = useState([])
    const [tokensMaster, setTokensMaster] = useState([])
    
    const { portparam, fundtypeparam } = props.location?.state || {}
    const refTermCondition = useRef(null)
    
    const {
        account,
        theWeb3,
        web3static,
        refreshAccount
    } = useContext(AppContext)

    // const { port, fundtype } = props.location?.state || {}
    const [value, setValue] = React.useState(0)
    const [graphValue, setGraphValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const handleChangeGraph = (event, newValue) => {
        setGraphValue(newValue)
    }

    useEffect(() => {
        loadFundData()
    }, [id, account])

    useEffect(() => {
        calcReturns(priceData, period)
    }, [priceData])

    useEffect(() => {
        loadGraph()
    }, [id])

    const loadGraph = async () => {
        const promiseData = await Promise.all([
            GetTokenPrices(id)
          ]);
        let priceData = promiseData[0]
        setMainData(priceData)
        setPriceData(priceData)
    }

    const loadFundData = async () => {
        
        if (id === undefined) {
            props.history.push('/app/dashboard')
        }

        const ports = xwinfundv2.funds.filter(x=>x.env === appGlobalConfig.env)
        const tokens = tokensv2.tokens.filter(x=>x.env === appGlobalConfig.env)
        setTokensMaster(tokens)
        let p = ports.find(x=>x.contractaddress === id)
        // console.log(p)
        if (p === undefined) {
            setFundtype(fundtypeparam)
            setPort(portparam)
            p = portparam
        }else{
            setFundtype(p.fundtype)
            setPort(p)    
        }
        setLoading(true)

        try {
            setNetworkName(appGlobalConfig.env)
            

            let myFundData = await GetFundDataAll(web3static, id)
            if (account !== '---') {

                const promiseData = await Promise.all([
                    getTokenInfo(
                        web3static,
                        p.contractaddress,
                        account
                    ),
                    getTokenInfo(
                        web3static,
                        myFundData.baseToken,
                        account
                    ),
                  ]);
                let fundHolderData = promiseData[0]
                let myBaseCcyData = promiseData[1]
                
                setUserData({
                    userfundbal: fundHolderData.targetBal,
                    userbasebal: myBaseCcyData.targetBal,
                })
            }
            let tokenNames = []
            let targetaddressNew = []
            for (let i = 0; i < myFundData.targetaddress.length; i++) {
                targetaddressNew.push(myFundData.targetaddress[i])
            }
            const baseCcy = tokens.find(
                x =>
                    x.address.toLowerCase() ===
                    myFundData.baseToken.toLowerCase()
            ).name
            let strategies = []

            for (let i = 0; i < targetaddressNew.length; i++) {
                
                const promiseData = await Promise.all([
                    getFundExtraInfo(
                        web3static,
                        id,
                        targetaddressNew[i]
                    ),
                    getTokenInfo(
                        web3static,
                        targetaddressNew[i],
                        id
                    ),
                  ]);
                let fundExtraInfo = promiseData[0]
                let tokenInfo = promiseData[1]
                let diffBal = fundExtraInfo.AllBal - tokenInfo.targetBal
                let tname = ''
                let token = tokens.find(
                    t =>
                        t.address.toLowerCase() ===
                        targetaddressNew[i].toLowerCase()
                )
                if (token == undefined) {
                    tname = ''
                } else {
                    tname = token.name
                }

                let price =
                    fundExtraInfo.fundTokenValue /
                    (fundExtraInfo.AllBal === 0 ? 1 : fundExtraInfo.AllBal)
                tokenNames.push({
                    key: i,
                    address: targetaddressNew[i],
                    targetweight: fundExtraInfo.targetweight,
                    balance: tokenInfo.targetBal,
                    fundTokenValue: fundExtraInfo.fundTokenValue,
                    aTokenBal: diffBal.toString(),
                    name: tname,
                    price: price,
                    decimals: tokenInfo.decimals,
                })

                //get all relavant strategy address for strategy display later
                if (token.type === 'strategy') {
                    strategies.push(token)
                }
            }

            tokenNames.push({
                key: 999,
                address: myFundData.baseToken, //xWinLib.GetBNB_ADDRESS(),
                targetweight: 0,
                balance: myFundData.fundETHbalance.toString(),
                fundTokenValue: myFundData.fundETHbalance.toString(),
                aTokenBal: 0,
                name: baseCcy, //env === "bsctest"? "BNB" :  "ETH",
                price: 1,
                decimals: 18,
            })
            setFundData({
                ...fundData,
                baseCcy: baseCcy,
                baseToken: myFundData.baseToken,
                contractaddress: id,
                targetaddress: myFundData.targetaddress,
                fundvalue: myFundData.fundvalue.toString(),
                fundvalueInUSD: myFundData.fundvalueUSD.toString(),
                tokenNames: tokenNames,
                uniprice: myFundData.uniprice.toString(),
                totalSupply: myFundData.totalSupply.toString(),
                fundName: myFundData.fundName,
                fundmanager: myFundData.fundmanager,
                fundETHbalance: myFundData.fundETHbalance.toString(),
                symbol: myFundData.symbol,
                managerFee: myFundData.managerFee / 100,
                performanceFee: myFundData.performanceFee / 100,
                platformFee: myFundData.platformFee / 100,
                unipriceInUSD: myFundData.unipriceInUSD.toString(),
                // networkName: env,
                marketCap: Number(
                    myFundData.unipriceInUSD.toString() *
                        myFundData.totalSupply.toString()
                ).toFixed(0),
                openForPublic : myFundData.openForPublic
            })

            setRelatedStrategyData(strategies)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
            // props.history.push('/app/dashboard')
        }
    }

    const handleClickPeriod = (event, choice) => {
        let duration = 30
        if (choice === 1) {
            duration = 2
            setDurationTweet("Daily")
        } else if (choice === 2) {
            duration = 8
            setDurationTweet("Weekly")

        } else if (choice === 3) {
            duration = 31
            setDurationTweet("Monthly")

        } else if (choice === 4) {
            duration = 366
            setDurationTweet("Annualized")

        }
        setReturnChoice(choice)
        setPeriod(duration)
        calcReturns(priceData, duration)
    }

    const getDateFormat = _date => {
        let year = _date.substring(0, 4)
        let month = _date.substring(4, 6)
        let day = _date.substring(6, 8)
        return new Date(year + '-' + month + '-' + day)
    }

    const calcReturns = (data, duration) => {
        if (data === undefined) return
        if (data.length === 0) return
        if (data !== undefined) {
            let dataArr = []
            const date = new Date()
            date.setDate(date.getDate() - duration)
            Object.keys(mainData).forEach(function(key) {
                if (key !== 'env') {
                    const historydate = getDateFormat(key)
                    if (historydate > date) {
                        dataArr.push(xWinLib.roundTo(mainData[key], 7))
                    }
                }
            })
            let returns =
                Number(dataArr[dataArr.length - 1]) / Number(dataArr[0]) - 1
            setPriceReturns(returns * 100)

        }
    }

    const getFundDesc = () => {
        if (port === undefined) return

        if (fundtype === 'v2private' || fundtype === 'v2allprivate') {
            return (
                <Card>
                    <CardContent className={classes.expanded} >
                            <div dangerouslySetInnerHTML={{__html:xWinLib.replaceString(MessageTemplate.privateDesc, {
                                account: xWinUtils.getAddressShort(fundData.fundmanager),
                                openForPublic: fundData.openForPublic ? "YES" : "NO"})}} />
                    </CardContent>
                </Card>
            )
        }
        return (
            <Card>
                <CardContent className={classes.expanded} >
                        <div dangerouslySetInnerHTML={{__html:port.desc}} />
                </CardContent>
            </Card>
        )
    }

    const handleClickBack = async event => {
        event.preventDefault();
        history.back()
    }
   
    const getSubHeader = () => {
        return (
            <div>
                <Typography 
                    color={'success'}>
                    {' Balance: ' +
                        format({ prefix: ' ', suffix: '' })(
                            xWinLib.roundTo(userData.userfundbal, 4)
                        )}
                </Typography>
                <Typography
                    color="text"
                    colorBrightness={'hint'}
                    variant={'caption'}
                    //style={{ marginRight: 5 }}
                >
                    <a
                        className={classes.link}
                        href={xWinLib.getEtherLink(id, 'address')}
                        target="blank"
                    >
                        View on BSCScan
                    </a>
                </Typography>
                
            </div>
        )
    }

    const getActWgt = target => {
        const val = xWinLib.getTokenActiveWeight(target, fundData.fundvalue)
        if (val < 0) {
            return (
                <Typography className={classes.negativeNum}>
                    {format({ prefix: '', suffix: '%' })(
                        xWinLib.roundTo(val, 2)
                    )}
                </Typography>
            )
        } else {
            return (
                <Typography className={classes.secondaryHeading}>
                    {format({ prefix: '', suffix: '%' })(
                        xWinLib.roundTo(val, 2)
                    )}
                </Typography>
            )
        }
    }

    const displayToggleButton = () => {
        return (
            <ToggleButtonGroup
                color={'primary'}
                size="small"
                value={returnChoice}
                exclusive
                onChange={handleClickPeriod}
            >
                <Box
                    className={classes.columnHide}
                    display="flex"
                    alignItems="center"
                    justifyContent={'right'}
                >
                    <Typography
                        variant={'body1'}
                        className={
                            priceReturns >= 0
                                ? classes.positiveNum
                                : classes.negativeNum
                        }
                    >
                        {priceReturns >= 0 ? (
                            <ArrowUpwardIcon />
                        ) : (
                            <ArrowDownwardIcon />
                        )}
                        {format({ prefix: '', suffix: '%' })(
                            xWinLib.roundTo(priceReturns, 2)
                        )}
                    </Typography>
                </Box> 
                &nbsp;
                
                <ToggleButton
                    value={1}
                    style={{
                        outlineColor: theme.palette.primary.main,
                        outlineWidth: '1px',
                        outlineStyle: 'solid',
                        margin: '2px',
                    }}
                >
                    24H
                </ToggleButton>
                <ToggleButton
                    className={classes.columnHide}
                    value={2}
                    style={{
                        outlineColor: theme.palette.primary.main,
                        outlineWidth: '1px',
                        outlineStyle: 'solid',
                        margin: '2px',
                    }}
                >
                    1W
                </ToggleButton>
                <ToggleButton
                    value={3}
                    style={{
                        outlineColor: theme.palette.primary.main,
                        outlineWidth: '1px',
                        outlineStyle: 'solid',
                        margin: '2px',
                    }}
                >
                    1M
                </ToggleButton>
                <ToggleButton
                    value={4}
                    style={{
                        outlineColor: theme.palette.primary.main,
                        outlineWidth: '1px',
                        outlineStyle: 'solid',
                        margin: '2px',
                    }}
                >
                    1Y
                </ToggleButton>
                <IconButton 
                    className={classes.mainHeading}
                    onClick={handleClickBack}
                    aria-label="settings">
                    <ArrowBackIcon />
                    
                </IconButton>
            </ToggleButtonGroup>
        )
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const copyAdressToClipboard = account => {
        navigator.clipboard.writeText(window.location.href)
        setIsCopied(true)
        // refsnackbar.current.handleOpen("success", "Copied to clipboard !", "bottom")
    }


    const getPriceChart = () => {
        if (port === undefined) return

        return (
            <Card>
                <CardHeader
                    action={displayToggleButton()}
                    avatar={
                        <Avatar
                            variant={'rounded'}
                            className={classes.avatar}
                            src={xWinLib.getIcon(port.logo)}
                        />
                    }
                    
                    title={<Typography className={classes.primaryHeading} >{port.name}
                      <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <ShareIcon/>
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleClose}>
                            <TwitterShareButton
                            // url={`${port.name} is ${priceReturns > 0 ? `Up ${xWinLib.roundTo(priceReturns, 2)}%`  : `Down ${xWinLib.roundTo(priceReturns, 2)}%` }! \nIt is an on-chain Defi vault deployed to @BNBCHAIN, powered by @xwinfinance.\n\nhttps://alpha.xwin.finance/app/funddetailv2/${port.contractaddress} ` }
                            url={`The ${durationTweet} return of ${port.name} is ${xWinLib.roundTo(priceReturns, 2)}%  ! \nIt is an on-chain Defi vault deployed to @BNBCHAIN, powered by @xwinfinance.\n\nhttps://alpha.xwin.finance/app/funddetailv2/${port.contractaddress} ` }

                            target="_blank"
                            windowWidth={window.innerWidth}
                            windowHeight={window.innerHeight}   
                            openShareDialogOnClick={true}     
                                              >
                            <TwitterIcon size={32} round />Share on twitter
                            </TwitterShareButton>
                        </MenuItem>
                        <MenuItem onClick={()=>{copyAdressToClipboard()}}>
                            {isCopied ?
                             <LibraryAddCheckIcon size={32} round/> :
                            <LibraryAddIcon size={32} round/> 
                        
                            }
                            
                            Copy link
                        </MenuItem>
                    </Menu>
                     
                     </Typography> }
                    subheader={getSubHeader()}
                    // titleTypographyProps={{ variant: 'h5' }}
                />
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'center'}
                >
                <ButtonGroup>
                    <Button
                        disabled={true}
                        // size="medium"
                        color="primary"
                        variant="contained"
                        onClick={handleClickSubsDirect}
                        className={classes.customButton}

                    >
                        Deprecated
                    </Button>
                    <Button
                        disabled={account === '---'}
                        // size="medium"
                        color="secondary"
                        variant="contained"
                        onClick={handleClickRedeem}
                        className={classes.customButton}

                    >
                        Redeem
                    </Button>
                    {account === fundData.fundmanager && (
                        <Button
                            disabled={account === '---'}
                            size="medium"
                            color="primary"
                            variant="outlined"
                            onClick={handleClickRebalance}
                        >
                            Rebalance
                        </Button>
                    )}
                    
                </ButtonGroup>
                </Box>
                <ApexLineChart
                    mainData={mainData}
                    fundData={fundData}
                    period={period}
                />                
            </Card>
        )
    }

    const getTargets = targets => {
        if (targets === undefined) return ''
        return (
            <Card>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell
                            ></TableCell>
                            <TableCell className={classes.columnHide}>Token</TableCell>
                            <TableCell>Target %</TableCell>
                            <TableCell className={classes.columnHide}>
                                Fund %
                            </TableCell>
                            <TableCell className={classes.tablecell}>
                                Active %
                            </TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell className={classes.tablecell}>
                                Token Address
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(targets).map((key, i) => (
                            <TableRow hover key={key}>
                                <TableCell >
                                    <Tooltip
                                        title={xWinLib.getTokenName(
                                            tokensMaster,
                                            targets[key].address
                                        )}
                                        arrow={true}
                                    >
                                        {xWinUtils.getIcons(
                                            xWinLib.getTokenName(
                                                tokensMaster,
                                                targets[key].address
                                            )
                                        )}
                                    </Tooltip>
                                </TableCell>
                                <TableCell className={classes.columnHide}>
                                    <Typography
                                        variant="body1"
                                        className={classes.secondaryHeading}
                                    >
                                        {xWinLib.getTokenName(
                                            tokensMaster,
                                            targets[key].address
                                        )}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="body1"
                                        className={classes.secondaryHeading}
                                    >
                                        {format({ prefix: '', suffix: '%' })(
                                            xWinLib.roundTo(
                                                targets[key].targetweight / 100,
                                                2
                                            )
                                        )}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align={'right'}
                                    className={classes.columnHide}
                                >
                                    {format({ prefix: '', suffix: '%' })(
                                        xWinLib.roundTo(
                                            xWinLib.getTokenWeight(
                                                targets[key],
                                                fundData.fundvalue
                                            ),
                                            2
                                        )
                                    )}
                                </TableCell>
                                <TableCell
                                    align={'right'}
                                    className={classes.tablecell}
                                >
                                    {getActWgt(targets[key])}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className={classes.secondaryHeading}
                                >
                                    {format({ prefix: '', suffix: '' })(
                                        xWinLib.roundTo(targets[key].balance, 5)
                                    )}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.tablecell}
                                >
                                    <Typography
                                        variant="body2"
                                        className={classes.secondaryHeading}
                                    >
                                        <a
                                            className={classes.link}
                                            href={xWinLib.getEtherLink(
                                                targets[key].address,
                                                'address'
                                            )}
                                            target="blank"
                                        >
                                            View
                                        </a>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Card>
        )
    }

    const getFundTarget = () => {
        if (
            fundtype === 'liquidity' ||
            fundtype === 'liquidity2' ||
            fundtype === 'liquidityv2'
        )
            return ''
        return (
            <Grid item lg={12} md={12} xl={12} xs={12}>
                {getTargets(fundData.tokenNames)}
            </Grid>
        )
    }

    const getPieChart = () => {
        return (
            <TargetAllocation
                fundData={fundData}
                tokensMaster={tokensMaster}
                // networkName={networkName}
            />
        )
    }

    const handleClickSubsDirect = event => {
        if (
            port.showTnC &&
            !xWinUtils.getAgreeTermConditionByPortfolio(port.symbol)
        ) {
            if (refTermCondition.current != null)
                refTermCondition.current.handleOpen(port.symbol)
            return
        }
        setOpenDepositModal(true)
    }

    const handleClickRebalance = event => {
        setOpenRebModal(true)
    }

    const handleClickRedeem = event => {
        setOpenWithdrawModal(true)
    }

    const handleCallback = (refresh) =>{
        if(refresh) {
          refreshAccount()
          loadFundData()
        }
      }

    const handleCallbackTermCondition = refresh => {
        if (refresh) setOpenDepositModal(true)
    }

    return (
        <Grid container spacing={2}>
            <TermCondition
                ref={refTermCondition}
                parentCallback={handleCallbackTermCondition}
            />

            <RedeemV2
                fundData={fundData}
                selectedWallet={account}
                openModal={openWithdrawModal}
                port={port}
                userData={userData}
                parentCallback={handleCallback}
                reset={() => {
                    setOpenWithdrawModal(false)
                }}
            />
            <DepositV2
                fundData={fundData}
                selectedWallet={account}
                openModal={openDepositModal}
                port={port}
                userData={userData}
                parentCallback={handleCallback}
                reset={() => {
                    setOpenDepositModal(false)
                }}
            />
            <RebalanceV2
                fundData={fundData}
                tokensMaster={tokensMaster}
                openModal={openRebModal}
                selectedport={port}
                networkName={networkName}
                parentCallback={handleCallback}
                reset={() => {
                    setOpenRebModal(false)
                }}
            />
            <Grid container spacing={3}>
                <Grid item lg={8} sm={12} xs={12}>
                    {getPriceChart()}
                </Grid>
                <Grid item lg={4} sm={12} xs={12}>
                    <UnitPrice
                        loading={loading}
                        fundData={fundData}
                        port={port}
                        account={account}
                        web3static={web3static}
                    />
                </Grid>
                <Grid
                    item
                    lg={12}
                    md={12}
                    xl={12}
                    xs={12}
                    className={classes.hoverHighlight}
                >
                    <Tabs
                        value={value}
                        textColor="primary"
                        indicatorColor="primary"
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="basic tabs example"
                    >
                        <Tab
                            className={classes.contentText}
                            icon={<FileCopyIcon />}
                            iconPosition="start"
                            label="Desc"
                            {...a11yProps(1)}
                        />
                        <Tab
                            className={classes.contentText}
                            icon={<PieChartIcon />}
                            iconPosition="start"
                            label="Allocation"
                            {...a11yProps(0)}
                        />
                       <Tab
                            className={classes.contentText}
                            icon={<BarChartIcon />}
                            iconPosition="start"
                            label="Returns Compare"
                            {...a11yProps(2)}
                        />
                       <Tab
                            className={classes.contentText}
                            icon={<HistoryIcon />}
                            iconPosition="start"
                            label="History Events"
                            {...a11yProps(3)}
                        />
                        {relatedStrategyData.length > 0 && (
                            <Tab
                                className={classes.contentText}
                                icon={<PieChartIcon />}
                                iconPosition="start"
                                label="Strategy"
                                {...a11yProps(4)}
                            />
                        )}
                    </Tabs>
                    <TabPanel value={value} index={1}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                lg={3}
                                sm={12}
                                xs={12}
                                className={classes.hoverHighlight2}
                            >
                                {getPieChart()}
                            </Grid>
                            <Grid
                                item
                                lg={9}
                                sm={12}
                                xs={12}
                                className={classes.cardpie}
                            >
                                {getFundTarget()}
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={0}>
                        {getFundDesc()}
                    </TabPanel>   
                    <TabPanel value={value} index={2}>
                        <ReturnsCompareChart
                            mainData={mainData}
                            fundData={fundData}
                            period={period}
                            tokensMaster={tokensMaster}
                        />
                    </TabPanel>                
                    
                    {relatedStrategyData.length > 0 && (
                        <TabPanel value={value} index={4}>
                            <Grid
                                item
                                lg={12}
                                sm={12}
                                xs={12}
                                className={classes.hoverHighlight2}
                            >
                                <Strategies
                                    tokensMaster={tokensMaster}
                                    relatedStrategyData={relatedStrategyData}
                                />
                            </Grid>
                        </TabPanel>
                    )}
                    <TabPanel value={value} index={3}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                lg={12}
                                sm={12}
                                xs={12}
                            >
                                <HistoryEvents id={id} showAll={true} />
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withRouter(FundDetailV2)
