import React, { useState, useEffect  } from "react";
import xWinLib from '../../../xWinLib';
import format from 'format-number';
import xWinUtils from "../../../xWinUtils";
import PropTypes from 'prop-types';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import {InfoRounded as InfoIcon, MoneySharp as MoneyIcon } from '@material-ui/icons';
import moment from 'moment'

import {
  Grid,
  Box,
  Tabs,
  Tab,
} from "@material-ui/core";
import { useTheme } from '@material-ui/styles';

// styles
import useStyles from "../styles";
import Widget from "../../../components/Widget/Widget";
import { Typography } from "../../../components/Wrappers/Wrappers";
import Dot from "../../../components/Sidebar/components/Dot";
import { GetSharpeRatio } from "../../../utils/firestoreDataInteractor";
import xwinstrategies from "../../../v2Config/xwinstrategies";
import appGlobalConfig from "../../../appGlobalConfig";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { GetUserPerformanceFee } from "../../../utils/autoLockInteractor";
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function UnitPrice(props) {
  var classes = useStyles();
  const theme = useTheme();
  const { fundData, loading, port, account, web3static} = props;
  const [value, setValue] = React.useState(0);
  const [returns, setReturns] = useState([])
  const [statistic, setStatistic] = useState({
    portReturns : 0,
    portVol : 0,
    portSR : 0
  })
  const [dropdown, setDropdown]= React.useState("optimization-12m")
  const [userPerformanceFee, setUserPerformanceFee] = useState(fundData.performanceFee)
    
  useEffect(() => {
    init("optimization-12m")
  }, [fundData])

  useEffect(() => {
    initUserPerformanceFee()
  }, [account])

  useEffect(() => {
    calcStatistic()
  }, [returns, dropdown])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const init = async (model) =>{
    
    const yyyymm = moment(new Date())
        .endOf('month')
        .format('YYYYMM')

    const promiseData = await Promise.all([
      GetSharpeRatio(model, yyyymm),
    ]);
    let o12m = promiseData[0]
    setReturns(o12m)
    await initUserPerformanceFee()
  }

  const initUserPerformanceFee = async () =>{
    
    if(account !== "---"){
      let newFee = await GetUserPerformanceFee(web3static, account, fundData.performanceFee || 0)
      setUserPerformanceFee(newFee)  
    }else{
      setUserPerformanceFee(fundData.performanceFee)  
    }

  }

  const calcStatistic = () => {

    if(fundData.tokenNames === undefined) return;

    let totalReturns = 0
    let totalVol = 0
    let totalSR = 0
    const proxyAssets = xwinstrategies.strategies.filter(x=>x.env === appGlobalConfig.env)
 
    fundData.tokenNames.forEach(p => {
      let r = returns.find(x=>x.asset.toLowerCase() === p.address.toLowerCase())
      if(r === undefined && p.key !== 999){
        let proxy = proxyAssets.find(x=>x.contractaddress.toLowerCase() === p.address.toLowerCase())
        
        if(proxy !== undefined){

          if(proxy.underlying.toLowerCase() === appGlobalConfig.mainnetRiskFreeAsset.toLowerCase()){
            r = {
              "return" : 0.00,
              "volatility" : 0,
              "sharpeRatio" : 1
            }
          }else{
            r = returns.find(x=>x.asset.toLowerCase() === proxy.underlying.toLowerCase())
          }
        } 
      }
      if(r !== undefined && p.key !== 999){
        totalReturns += p.targetweight / 100 * r.return
        totalVol += p.targetweight / 100 * r.volatility
        totalSR += p.targetweight / 100 * r.sharpeRatio / 100
      }  
    });
    
    let portReturns = Math.pow(1 + totalReturns/100, 12) - 1
    let portVol = totalVol / 100 * Math.sqrt(12);
    setStatistic({
      portReturns : portReturns * 100,
      portVol : portVol * 100,
      portSR : portReturns / portVol  * 100
    })
    
  }
  
  const handleChangeDropdown = async (event) => {
    event.preventDefault()
    await init(event.target.value)
    setDropdown(event.target.value);  
  }

  const displayDiscount = () => {

    if(userPerformanceFee !== undefined && userPerformanceFee !== fundData.performanceFee){
      return (
        <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
          <Box display="flex" alignItems="center" justifyContent={"right"}>
            {xWinUtils.displayToolTip("You got performance fee discount because you staked and lock xWIN Tokens. Thank you!")}
            <Typography
              size="md"
              weight={"medium"}
              style={{ marginLeft: 5 }}
              className={"p-2"}
              color="secondary"
            >
              <s>{format({prefix: '', suffix: '%'})(xWinLib.roundTo(fundData.performanceFee, 2))}</s>
            </Typography>
            &rarr;
            <Typography
              size="md"
              weight={"medium"}
              style={{ marginLeft: 5 }}
              className={"p-2"}
              color="primary"
            >
              {format({prefix: '', suffix: '%'})(xWinLib.roundTo(userPerformanceFee, 2))}
            </Typography>
          </Box>
        </Grid>
      )
    }else{
      return(
        <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
          <Box display="flex" alignItems="center" justifyContent={"right"}>
          <Dot size='medium' color="primary" />
            <Typography
              size="md"
              weight={"medium"}
              style={{ marginLeft: 5 }}
              className={"p-2"}
            >
              {format({prefix: '', suffix: '%'})(xWinLib.roundTo(fundData.performanceFee, 2))}
            </Typography>
          </Box>
        </Grid>
      )
    }
    
    

  }


  
  return (
    <Widget
      // title="Vault Info"
      disableWidgetMenu
      bodyClass={classes.fullHeightBody}
      className={classes.card}
      >
      {xWinUtils.getProgress(loading)}
      <Tabs 
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons={true}
        aria-label="scrollable force tabs example"
        value={value} 
        textColor="primary"
        indicatorColor="primary"
        onChange={handleChange}>
          <Tab 
            className={classes.contentText}
            fullWidth={false}
            icon={<InfoIcon />}  
            iconPosition="start"             
            label="Vault Info" {...a11yProps(0)} />
          <Tab 
            className={classes.contentText}
            fullWidth={false}
            icon={<AnalyticsIcon />}  
            iconPosition="start"             
            label="Ex-Ante Risk" {...a11yProps(1)} />
          <Tab 
            className={classes.contentText}
            icon={<MoneyIcon />}  
            iconPosition="start" 
            label="Fees" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <br/>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                <Typography
                    color="text"
                    colorBrightness={"hint"}
                    variant={"caption"}
                    style={{ marginLeft: 5 }}
                    className={"p-2"}
                  >
                    Symbol
                  </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">  
                <Box sx={{ p: 2, border: '1px dashed grey' }} display="flex" alignItems="center" justifyContent={"right"}>
                <Dot size='medium' color="primary" />
                <Typography
                  size="md"
                  weight={"medium"}
                  style={{ marginLeft: 5 }}
                  className={"p-2"}
                >
                  {port?.symbol}
                </Typography>
              </Box>
              </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                <Typography
                    color="text"
                    colorBrightness={"hint"}
                    variant={"caption"}
                    style={{ marginLeft: 5 }}
                    className={"p-2"}
                  >
                    Unit Price (USD)
                  </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                <Box display="flex" alignItems="center" justifyContent={"right"}>
                <Dot size='medium' color="primary" />
                <Typography
                  size="md"
                  weight={"medium"}
                  style={{ marginLeft: 5 }}
                  className={"p-2"}
                >
                  {format({prefix: " $ ", suffix: ''})(xWinLib.roundTo(fundData.unipriceInUSD,2))}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginLeft: 5 }}
                      className={"p-2"}
                    >
                      Unit Price ({fundData.baseCcy})
                    </Typography>
                </Grid>
                <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                  <Box display="flex" alignItems="center" justifyContent={"right"}>
                  
                  <Dot size='medium' color="primary" />
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginLeft: 5 }}
                    className={"p-2"}
                  >
                    {format({prefix: fundData.baseCcy, suffix: ''})(xWinLib.roundTo(fundData.uniprice,4))}
                  </Typography>
                </Box>
                </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginLeft: 5 }}
                      className={"p-2"}
                    >
                      Market Cap
                    </Typography>
              </Grid>
              <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                  <Box display="flex" alignItems="center" justifyContent={"right"}>
                  
                  <Dot size='medium' color="primary" />
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginLeft: 5 }}
                    className={"p-2"}
                  >
                    {xWinLib.getMarketCap(fundData)}
                    {/* {format({prefix: fundData.baseCcy, suffix: ''})(xWinLib.roundTo(fundData.fundvalue,2))} */}
                  </Typography>
                </Box>
                </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginLeft: 5 }}
                      className={"p-2"}
                    >
                      Total Supply
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
              <Box display="flex" alignItems="center" justifyContent={"right"}>
              
              <Dot size='medium' color="primary" />
              <Typography
                size="md"
                weight={"medium"}
                style={{ marginLeft: 5 }}
                className={"p-2"}
              >
                  {format({prefix: "", suffix: ''})(xWinLib.roundTo(fundData.totalSupply,4))}
              </Typography>
            </Box>
            </Grid>
          </Grid>
            
        </TabPanel>
        <TabPanel value={value} index={1}>
          <br/>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                <Typography
                    color="text"
                    colorBrightness={"hint"}
                    variant={"caption"}
                    style={{ marginLeft: 5 }}
                    className={"p-2"}
                  >
                    Risk Model
                  </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
              <Box sx={{ p: 2, border: '1px dashed grey' }} display="flex" alignItems="center" justifyContent={"right"}>
                <FormControl  sx={{  minWidth: 50,  }} size="small" variant="standard">
                      <Select
                      value={dropdown}
                      label="months"
                      onChange={handleChangeDropdown}
                      sx={{ color:(theme.palette.type === "dark") && "white", backgroundColor:(theme.palette.type === "dark") && "#2f2f36",paddingLeft:1 }}
                      >
                      <MenuItem  value="optimization-3m">3-months</MenuItem>
                      <MenuItem value="optimization-6m">6-months</MenuItem>
                      <MenuItem value="optimization-12m">12-months</MenuItem>
                      </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
              
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                <Typography
                    color="text"
                    colorBrightness={"hint"}
                    variant={"caption"}
                    style={{ marginLeft: 5 }}
                    className={"p-2"}
                  >
                    Ex-Ante Returns
                  </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">  
                <Box sx={{ p: 2, border: '1px dashed grey' }} display="flex" alignItems="center" justifyContent={"right"}>
                <Dot size='medium' color="primary" />
                <Typography
                  size="md"
                  weight={"medium"}
                  style={{ marginLeft: 5 }}
                  className={"p-2"}
                >
                  {format({prefix: '', suffix: '%'})(xWinLib.roundTo(statistic.portReturns,2))}
                </Typography>
              </Box>
              </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                <Typography
                    color="text"
                    colorBrightness={"hint"}
                    variant={"caption"}
                    style={{ marginLeft: 5 }}
                    className={"p-2"}
                  >
                    Volatility
                  </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                <Box display="flex" alignItems="center" justifyContent={"right"}>
                <Dot size='medium' color="primary" />
                <Typography
                  size="md"
                  weight={"medium"}
                  style={{ marginLeft: 5 }}
                  className={"p-2"}
                >
                  {format({prefix: '', suffix: '%'})(xWinLib.roundTo(statistic.portVol,2))}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginLeft: 5 }}
                      className={"p-2"}
                    >
                      Sharpe Ratio
                    </Typography>
                </Grid>
                <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                  <Box display="flex" alignItems="center" justifyContent={"right"}>
                  
                  <Dot size='medium' color="primary" />
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginLeft: 5 }}
                    className={"p-2"}
                  >
                    {format({prefix: '', suffix: ''})(xWinLib.roundTo(statistic.portSR / 100,2))}
                  </Typography>
                </Box>
                </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <br/>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
              <Typography
                  color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginLeft: 5 }}
                  className={"p-2"}
                >
                  Strategy Fee
                </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
              <Box display="flex" alignItems="center" justifyContent={"right"}>
                <Dot size='medium' color="primary" />
                <Typography
                  size="md"
                  weight={"medium"}
                  style={{ marginLeft: 5 }}
                  className={"p-2"}
                >
                  {format({prefix: '', suffix: '%'})(xWinLib.roundTo(fundData.managerFee,2))}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
              <Typography
                  color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginLeft: 5 }}
                  className={"p-2"}
                >
                  Platform Fee
                </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
              <Box display="flex" alignItems="center" justifyContent={"right"}>
              <Dot size='medium' color="primary" />
              <Typography
                size="md"
                weight={"medium"}
                style={{ marginLeft: 5 }}
                className={"p-2"}
              >
                {format({prefix: '', suffix: '%'})(xWinLib.roundTo(fundData.platformFee, 2))}
              </Typography>
            </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
              <Typography
                  color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginLeft: 5 }}
                  className={"p-2"}
                >
                  Performance Fee
                </Typography>
            </Grid>
            {displayDiscount()}
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                <Typography
                  color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginLeft: 5 }}
                  className={"p-2"}
                >
                  Manager Wallet
                </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
              <Box display="flex" alignItems="center" justifyContent={"right"}>
              
              {fundData.fundmanager &&  <Jazzicon diameter={20} seed={jsNumberForAddress(fundData.fundmanager)} />}
              <Typography 
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginLeft: 5 }}
                className={"p-2"}
                >
                <a className={classes.link} href={xWinLib.getEtherLink(fundData.fundmanager, "address")} target="blank">
                  {xWinUtils.getAddressShort(fundData.fundmanager)}
                  </a>

              </Typography>
            </Box>
            </Grid>
          </Grid>
        </TabPanel>
       </Widget>
    
  );
}
