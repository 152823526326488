import React, { useState, useEffect, useContext  } from "react";
import xWinLib from '../../../xWinLib';
import format from 'format-number';
import xWinUtils from "../../../xWinUtils";
import CountUp from 'react-countup';

import {
  Grid,
  Box,
  CardContent,
  Chip
} from "@material-ui/core";

// styles
import useStyles from "../styles";
import Widget from "../../../components/Widget/Widget";
import { Typography, Avatar} from "../../../components/Wrappers/Wrappers";
import Dot from "../../../components/Sidebar/components/Dot";
import { getTotalFarmValue } from "../../../utils/masterChefInteractor";
import {AppContext}  from '../../../context/AppProvider';
import { ContactSupportOutlined } from "@material-ui/icons";




export default function TokenStats(props) {
  var classes = useStyles();
  const { web3static } = useContext(AppContext);
  const { xwinStats, overallStat, totalValueLock, totalValueLockV2 } = props;
  const [totalFarmValue, setTotalFarmValue] = React.useState(0);
  
  useEffect(() => {
    init()
  }, []);

  const init = async () => {
    
      let totalFarmTVL = await getTotalFarmValue(web3static)
      setTotalFarmValue(totalFarmTVL)
  }

  return (
    <Widget
      title="Token Info"
      disableWidgetMenu
      bodyClass={classes.fullHeightBody}
      className={classes.card}
      >
      <CardContent>
      <Grid container spacing={4}>
        <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
          <Typography
            color="text"
            colorBrightness={"hint"}
            variant={"caption"}
            style={{ marginRight: 5 }}
          >
            Price
          </Typography>
        </Grid>
        <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">  
          <Box display="flex" alignItems="center" justifyContent={"right"}>
            <Typography
              size="md"
              weight={"medium"}
              style={{ marginLeft: 8 }}
            >
              <Chip 
                size="large" 
                label={xWinUtils.displayBalance(false, format({prefix: "$", suffix: ""})(xWinLib.roundTo(overallStat.xwinPrice,2)))}
                avatar={<Avatar src="/images/icons/color/xwin.png" />} 
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
          <Typography
            color="text"
            colorBrightness={"hint"}
            variant={"caption"}
            style={{ marginRight: 5 }}
          >
            Rewards Supply
          </Typography>
        </Grid>
        <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">  
          <Box display="flex" alignItems="center" justifyContent={"right"}>
          <Dot size='medium' color="primary" />
          <Typography
            size="md"
            weight={"medium"}
            style={{ marginLeft: 8 }}
          >
            <CountUp 
                separator=","
                decimals={0}
                decimal="."
                prefix=" "   
                duration={1.75}         
                end={xWinLib.roundTo(xwinStats.rewardSupply,0)} />
          </Typography>
        </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
          <Typography
            color="text"
            colorBrightness={"hint"}
            variant={"caption"}
            style={{ marginRight: 5 }}
          >
            Circulation Supply
          </Typography>
        </Grid>
        <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">  
          <Box display="flex" alignItems="center" justifyContent={"right"}>
          <Dot size='medium' color="primary" />
          <Typography
            size="md"
            weight={"medium"}
            style={{ marginLeft: 8 }}
          >
            <CountUp 
                separator=","
                decimals={0}
                decimal="."
                prefix=" "   
                duration={1.75}         
                end={xWinLib.roundTo(xwinStats.publicCirculation,0)} />
          </Typography>
        </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">  
          <Typography
            color="text"
            colorBrightness={"hint"}
            variant={"caption"}
            style={{ marginRight: 5 }}
          >
            Total Burned
          </Typography>
          </Grid>
        <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">  
          <Box display="flex" alignItems="center" justifyContent={"right"}>
          
          <Dot size='medium' color="warning" />
          <Typography
            size="md"
            weight={"medium"}
            style={{ marginLeft: 8 }}
          >
            <CountUp 
                separator=","
                decimals={0}
                decimal="."
                prefix=" "   
                duration={1.75}         
                end={xWinLib.roundTo(overallStat.burnBal,0)} />
          </Typography>
        </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">  
          <Typography
            color="text"
            colorBrightness={"hint"}
            variant={"caption"}
            style={{ marginRight: 5 }}
          >
            Total Market Cap
          </Typography>
          </Grid>
        <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">  
          <Box display="flex" alignItems="center" justifyContent={"right"}>
          <Dot size='medium' color="warning" />
          <Typography
            size="md"
            weight={"medium"}
            style={{ marginLeft: 8 }}
          >
            {xWinLib.displayBigNumber(xwinStats.publicCirculation * overallStat.xwinPrice)}
          </Typography>
        </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">  
          <Typography
            color="text"
            colorBrightness={"hint"}
            variant={"caption"}
            style={{ marginRight: 5 }}
          >
            Total Value Locked (v1)
          </Typography>
        </Grid>
        <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">  
          <Box display="flex" alignItems="center" justifyContent={"right"}>
          
          <Dot size='medium' color="warning" />
          <Typography
            size="md"
            weight={"medium"}
            style={{ marginLeft: 8 }}
          >
            {xWinLib.displayBigNumber(totalValueLock)}
          </Typography>
        </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={8} sm={8} xs={8} alignItems="flex-end"> 
          <Typography
            color="text"
            colorBrightness={"hint"}
            variant={"caption"}
            style={{ marginRight: 5 }}
          >
            Total Vault Value (v2)
          </Typography>
        </Grid>
        <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">  
          <Box display="flex" alignItems="center" justifyContent={"right"}>
          
          <Dot size='medium' color="warning" />
          <Typography
            size="md"
            weight={"medium"}
            style={{ marginLeft: 8 }}
          >
            {xWinLib.displayBigNumber(totalValueLockV2)}
          </Typography>
        </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
          <Typography
            color="text"
            colorBrightness={"hint"}
            variant={"caption"}
            // style={{ marginRight: 5 }}
          >
            Total Farmed Value (v2)
          </Typography>
          </Grid>
        <Grid item lg={4} sm={4} xs={4} alignItems="flex-end"> 
          <Box display="flex" alignItems="center" justifyContent={"right"}>
          
          <Dot size='medium' color="warning" />
          <Typography
            size="md"
            weight={"medium"}
            style={{ marginLeft: 8 }}
          >
            {xWinLib.displayBigNumber(totalFarmValue)}
          </Typography>
        </Box>
        </Grid>
      </Grid>
      </CardContent>
  </Widget>
    
  );
}
