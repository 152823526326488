import React, { useState, useEffect, useContext  } from "react";
import { 
  Grid, 
  Container,
  Box,
  TextField,
  Slider,
  Typography,
  Button,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  FormControlLabel
 } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import format from 'format-number';
import {AppContext}  from '../../context/AppProvider';
import Widget from "../../components/Widget";
import useStyles from "./styles";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@material-ui/styles";
import xWinLib from '../../xWinLib';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { Link, Avatar } from "../../components/Wrappers/Wrappers";
import appGlobalConfig from "../../appGlobalConfig";
import TextRotationNoneTwoToneIcon from '@material-ui/icons/TextRotationNoneTwoTone';
import CreatePortV2 from "../createPortV2/CreatePortV2";
import xWinUtils from '../../xWinUtils';
import VaultFaq from '../vaultfaq/VaultFaq'
import vaultfaqconfig from '../vaultfaq/faq/assetAllocationFAQ'
 import OutlinedInput from '@mui/material/OutlinedInput';
import xwinstrategies from "../../v2Config/xwinstrategies";
import { GetSharpeRatio } from "../../utils/firestoreDataInteractor";
import moment from 'moment'


export default function assetAllocation(props) {
  const { portfolios, tokensMaster } = props;
  const { account } = useContext(AppContext);
  var classes = useStyles();
  var theme = useTheme();
  const [openRebModal, setOpenRebModal] = useState(false)
    
  const [clearDesc, setClearDesc] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [assetNo, setAssetNo] = useState(3)
  const [allocatedports, setAllocatedports] = useState([])
  const [proxyAssets, setProxyAssets] = useState([])
  const [proxyChecked, setProxyChecked] = React.useState(false);
  const [riskValue, setRiskValue] = React.useState(50);
  const [ageValue, setAgeValue] = React.useState(25);
  const [values, setValues] = useState({
    series2: [], 
    labels: []
  })
  const [allocationApproach, setAllocationApproach] = useState({
    approach: 'Optimize'
  })
  const [statsValues, setStatsValues] = useState({
    wgtMean : 0, 
    wgtStd: 0,
    wgtSR : 0
  })
  const [faqs, setFaqs] = useState([])
  const [returns, setReturns] = useState([])
  
  let myPayLabel = []
  let seriesValue = []
  
  useEffect(() => {
    init()
  }, []);

  useEffect(() => {

    const newValues = xwinstrategies.strategies.filter(x=>x.env === appGlobalConfig.env)
    setProxyAssets(newValues);
  }, []);

  
  const init = async () =>{
    
    const yyyymm = moment(new Date())
        .endOf('month')
        .format('YYYYMM')

    const promiseData = await Promise.all([
      GetSharpeRatio("optimization-12m", yyyymm),
    ]);
    let o6m = promiseData[0]
    // console.log(o6m)
    setReturns(o6m)
    setFaqs(vaultfaqconfig.faqs);
  }


  const callAPI = async (value, age) =>{
    
    let api
    if(allocationApproach.approach === "Basic"){
      api = appGlobalConfig.apiBasicAllocation + "?age="+age+"&tolerance="+value/100+"&assetNumber="+assetNo+"&cycle=6"
    } else{
      let ageRemain = 100 - Number(age);
      let adjustedRiskTolerance = ageRemain / 100 * Number(value) / 100;
      api = appGlobalConfig.apiOptimization + "?TargetVol="+adjustedRiskTolerance+"&assetNumber="+assetNo+"&cycle=6"
    }
    let output = await (await fetch(api)).json();
    return output
  }

  const setAllocation = async (value, age) => {

    if(tokensMaster.length == 0) return;

    setLoading(true)
    
    let allocated = []
    setProxyChecked(false)
    let output = await callAPI(value, age)
    let weights = output.message.weights[0];
    let assets = output.message.assets[0];
    for(let i=0; i< assets.length; i++){
      let selected = tokensMaster.find(x=>x.address.toLowerCase() === assets[i].toLowerCase())
      allocated.push({
        ...selected,
        "contractaddress" : assets[i],
        "ratio" : Math.abs(Number(weights[i])*100)
      })  
      myPayLabel.push(selected.name);
      seriesValue.push(Math.abs(Number(weights[i]))*100)
    }

    setStatsValues({
      "wgtMean" : output.message.portReturn * 100, 
      "wgtStd": output.message.portVolatility * 100,
      "wgtSR" : output.message.portReturn / output.message.portVolatility * 100
    })

    let newAllocates = []
    allocated.forEach(asset => {
      if(asset.type !== "native"){
        let selected = proxyAssets.find(x=>x.underlying.toLowerCase() === asset.contractaddress.toLowerCase())
        if(selected){
          let port = portfolios.find(x=>x.contractaddress.toLowerCase() === selected.proxyfund.toLowerCase())
          newAllocates.push({
            ...asset,
            "proxyfund": selected.proxyfund,
            "fundtype": port.fundtype
          })
        }
      }else{
        newAllocates.push(asset)
      }
      
    });

    setAllocatedports(newAllocates);
    setValues({
      series2: seriesValue, 
      labels: myPayLabel
    })

    // triggerProxySwitch(proxyChecked)
    setLoading(false)
    setClearDesc(false)
    setProxyChecked(true)
    triggerProxySwitch(true, newAllocates)
  }

  const displayDesc = () => {

    if(allocatedports.length === 0) return "";
    if(clearDesc) return "";

    let text = ""
    let riskFree = allocatedports.find(x=>x.contractaddress?.toLowerCase() === appGlobalConfig.mainnetRiskFreeAsset.toLowerCase())
    if(riskFree === undefined){
      riskFree = allocatedports.find(x=>x.contractaddress?.toLowerCase() === appGlobalConfig.mainnetRiskFreeProxy.toLowerCase())
    }
    
    if(riskFree !== undefined){
      text = text + "You are trying to allocate ";
      text = text + format({prefix: '', suffix: '%'})(xWinLib.roundTo(riskFree?.ratio,2));
      text = text + " in risk free vault " + riskFree?.name + " mainly for staking and earn the interest. ";  
    }
    
    if(allocationApproach.approach === "Basic"){
      text = text + "The rest of the weight are proportionally allocated to the medium and higher risk profile vaults"
    }else{
      text = text + " These are optimized risk adjusted returns optimized by xWIN Optimizer based on your age and volatility setting"
    }
    return text;
    
  }

  const themeOptions = theme => {
    return {
      labels: values.labels, 
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'bottom'
      },
      chart: {
        foreColor: '#ccc',
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          // breakpoint: 480,
          // options: {
          //   chart: {
          //     width: 200
          //   },
          //   legend: {
          //     position: "bottom"
          //   }
          // }
        }
      ],
      colors: [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.warning.main,
        theme.palette.success.light,
        theme.palette.info.main
      ],
    };
  };


  const handleAssetNoChange = async event => {
    
    event.preventDefault()
    const noofasset = parseFloat(event.target.value)
    if(noofasset >= 0 && noofasset !== undefined){
      setAssetNo(noofasset)
    }else{
      setAssetNo("")      
    }
  }

  const handleClickRun = async event => {
    event.preventDefault()
    await setAllocation(riskValue, ageValue)
    // triggerProxySwitch(true)
  }

  const handleClickOpenCreateFund = async event => {
    event.preventDefault()

    setOpenRebModal(true)

    
  }

  const valuetext = (value) => {
    return `${value}`;
  }

  const valueAgetext = (value) => {
    return `${value}`;
  }

  const handleChange = (event, newValue) => {
    setRiskValue(newValue);
  };

  const handleAgeChange = (event, newValue) => {
    setAgeValue(newValue);
  };

  const handleProxyChange = (event) => {
    
    setProxyChecked(event.target.checked)
    triggerProxySwitch(event.target.checked, allocatedports)
  };

  const triggerProxySwitch = (toProxy, allocatedports) => {

    // console.log("triggerProxySwitch")
    let newPort = [];
    if(toProxy){
      allocatedports.forEach(asset => {
        let selected = proxyAssets.find(x=>x.underlying.toLowerCase() === asset.contractaddress.toLowerCase())
        if(selected){
          let port = portfolios.find(x=>x.contractaddress.toLowerCase() === selected.proxyfund.toLowerCase())
          newPort.push({
            "address": selected.contractaddress,
            "contractaddress": selected.contractaddress,
            "id": selected.contractaddress,
            "name": selected.name,
            "type": selected.type,
            "ratio": asset.ratio,
            "decimal": asset.decimal,
            "proxyfund": selected.proxyfund,
            "fundtype": port.fundtype
          })
        }else{
          newPort.push(asset)
        }
      });
    }else{
      allocatedports.forEach(asset => {
        let selected = proxyAssets.find(x=>x.contractaddress.toLowerCase() === asset.contractaddress.toLowerCase())
        if(selected){
          let underlying = tokensMaster.find(x=>x.address.toLowerCase() === selected.underlying.toLowerCase())
          newPort.push({
            "address": underlying.address,
            "contractaddress": underlying.address,
            "id": underlying.address,
            "name": underlying.name,
            "type": underlying.type,
            "ratio": asset.ratio,
            "decimal": underlying.decimal,
            "proxyfund": "",
            "fundtype": ""
          })
        }else{
          newPort.push(asset)
        }
      });
    }
    // console.log(newPort)
    setAllocatedports(newPort)
  }

  const getTargets = () => {

    if(allocatedports.length ===  0){
      return (
        <>
      <Table size="small">
      <TableHead>
      <TableRow>
            <TableCell>
              Expected Returns
            </TableCell>
            <TableCell>
              Volatility
            </TableCell>
            <TableCell className={classes.tablecell}>
              Sharpe Ratio
            </TableCell>
          </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.cellHideSmall}>
            {xWinUtils.displayToolTip(`The returns is based on the history returns for the past 1.5 years`)}
            --
          </TableCell>
          <TableCell>
            --
          </TableCell>
          <TableCell>
            {xWinUtils.displayToolTip(`A higher Sharpe ratio is better when comparing similar portfolios`)}
            --
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3}>
          {xWinUtils.displayToolTip(`xWIN Single Asset involves auto staking to earn interest rate from other lending protocol. Therefore, it consumes more gas during deposit, withdrawal and rebalance.`)}
          <FormControlLabel control={<Switch onChange={handleProxyChange} checked={proxyChecked} color="secondary"/>} disabled label="Use xWIN Single Asset" />
          </TableCell>
        </TableRow>
      </TableBody>
      </Table>
      <br/>
      <Card>
        <Table size="small">
        <TableHead>
          <TableRow>
              <TableCell>
              </TableCell>
              <TableCell className={classes.columnHide}>
                Name
              </TableCell>
              <TableCell>
                Symbol
              </TableCell>
              <TableCell className={classes.tablecell}>
                Fund %
              </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          Object.keys(allocatedports).map((key, i) => (
            <TableRow hover key={key}>
              <TableCell>
                {xWinUtils.getIcons(allocatedports[key].name)}
              </TableCell>
              <TableCell className={classes.columnHide}>
                {allocatedports[key].name}
              </TableCell>
              <TableCell>
                {(
                  allocatedports[key].type !== "native" && (
                    <Link 
                      component={RouterLink}
                      to={{
                      pathname: "/app/funddetailV2/"+ allocatedports[key].proxyfund,
                      state: {
                        portparam: portfolios.find(x=>x.contractaddress.toLowerCase() === allocatedports[key].proxyfund.toLowerCase()),
                        port: portfolios.find(x=>x.contractaddress.toLowerCase() === allocatedports[key].proxyfund.toLowerCase()),
                        tokensMaster: tokensMaster,
                        fundtype: allocatedports[key].fundtype,
                        fundtypeparam: allocatedports[key].fundtype
                      }
                    }}>
                      <Button variant="outlined" size="small" fullWidth color="primary">
                        {allocatedports[key].name}
                      </Button>
                    </Link>
                  )
                )}
                {(
                  allocatedports[key].type === "native" && (
                    <Button variant="outlined" size="small" fullWidth color="success">
                      {allocatedports[key].name}
                    </Button>
                  )
                )}
                
              
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" className={classes.secondaryHeading} >
                {/* {format({prefix: '', suffix: '%'})(xWinLib.roundTo(allocatedports[key].ratio,2))} */}
                --
                </Typography>
              </TableCell>
            </TableRow>
          ))
        }
        </TableBody>
        </Table>
            
            <div style={{display:"flex", justifyContent:"center"}}>
              {displayResult()}

            </div>


      </Card>
      <br/>
      <Box display="flex" alignItems="center" justifyContent={"left"}>
        <Typography
          size="md"
          weight={"small"}
          style={{ marginLeft: 8 }}
        >
            {displayDesc()} 
        </Typography>
      </Box>
      <br/>
      <br/>
      <Box display="flex" alignItems="center" justifyContent={"left"}>
        <Typography
          variant="caption"
          color="text"
          colorBrightness="hint"
          style={{ marginLeft: 8 }}
        >
            {"*** All investing involves risk, including the possible loss of money you invest, and past performance does not guarantee future performance. Historical returns, expected returns, and probability projections are provided for informational and illustrative purposes, and may not reflect actual future performance"} 
        </Typography>    
      </Box>

      </>
      )
    } else{
    return (
      <>
      <Table size="small">
      <TableHead>
      <TableRow>
            <TableCell>
              Expected Returns
            </TableCell>
            <TableCell>
              Volatility
            </TableCell>
            <TableCell className={classes.tablecell}>
              Sharpe Ratio
            </TableCell>
          </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.cellHideSmall}>
            {xWinUtils.displayToolTip(`The returns is based on the history returns for the past 1.5 years`)}
            {format({prefix: '', suffix: '%'})(xWinLib.roundTo(statsValues.wgtMean,2))}
          </TableCell>
          <TableCell>
            {format({prefix: '', suffix: '%'})(xWinLib.roundTo(statsValues.wgtStd,2))}
          </TableCell>
          <TableCell>
            {xWinUtils.displayToolTip(`A higher Sharpe ratio is better when comparing similar portfolios`)}
            {format({prefix: '', suffix: ''})(xWinLib.roundTo(statsValues.wgtSR / 100,2))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3}>
          {xWinUtils.displayToolTip(`xWIN Single Asset involves auto staking to earn interest rate from other lending protocol. Therefore, it consumes more gas during deposit, withdrawal and rebalance.`)}
          <FormControlLabel control={<Switch onChange={handleProxyChange} checked={proxyChecked} color="secondary"/>} label="Use xWIN Single Asset" />
          </TableCell>
        </TableRow>
      </TableBody>
      </Table>
      <br/>
      <Card>
        <Table size="small">
        <TableHead>
          <TableRow>
              <TableCell>
              </TableCell>
              <TableCell className={classes.columnHide}>
                Name
              </TableCell>
              <TableCell>
                Symbol
              </TableCell>
              <TableCell className={classes.tablecell}>
                Fund %
              </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          Object.keys(allocatedports).map((key, i) => (
            <TableRow hover key={key}>
              <TableCell>
                {xWinUtils.getIcons(allocatedports[key].name)}
              </TableCell>
              <TableCell className={classes.columnHide}>
                {allocatedports[key].name}
              </TableCell>
              <TableCell>
                {(
                  allocatedports[key].type !== "native" && (
                    <Link 
                      component={RouterLink}
                      to={{
                      pathname: "/app/funddetailV2/"+ allocatedports[key].proxyfund,
                      state: {
                        portparam: portfolios.find(x=>x.contractaddress.toLowerCase() === allocatedports[key].proxyfund.toLowerCase()),
                        port: portfolios.find(x=>x.contractaddress.toLowerCase() === allocatedports[key].proxyfund.toLowerCase()),
                        tokensMaster: tokensMaster,
                        fundtype: allocatedports[key].fundtype,
                        fundtypeparam: allocatedports[key].fundtype
                      }
                    }}>
                      <Button variant="outlined" size="small" fullWidth color="primary">
                        {allocatedports[key].name}
                      </Button>
                    </Link>
                  )
                )}
                {(
                  allocatedports[key].type === "native" && (
                    <Button variant="outlined" size="small" fullWidth color="success">
                      {allocatedports[key].name}
                    </Button>
                  )
                )}
                
              
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" className={classes.secondaryHeading} >
                {format({prefix: '', suffix: '%'})(xWinLib.roundTo(allocatedports[key].ratio,2))}
                </Typography>
              </TableCell>
            </TableRow>
          ))
        }
        </TableBody>
        </Table>
      </Card>
      <br/>
      <Box display="flex" alignItems="center" justifyContent={"left"}>
        <Typography
          size="md"
          weight={"small"}
          style={{ marginLeft: 8 }}
        >
            {displayDesc()} 
        </Typography>
      </Box>
      <br/>
      <br/>
      <Box display="flex" alignItems="center" justifyContent={"left"}>
        <Typography
          variant="caption"
          color="text"
          colorBrightness="hint"
          style={{ marginLeft: 8 }}
        >
            {"*** All investing involves risk, including the possible loss of money you invest, and past performance does not guarantee future performance. Historical returns, expected returns, and probability projections are provided for informational and illustrative purposes, and may not reflect actual future performance"} 
        </Typography>    
      </Box>

      </>
    );
  }
}

  const getSupportedApproach = () => {
    
    let approaches = [];
    approaches.push({
      approach: "Basic",
      desc: "Weighted Average SR"
    })
    approaches.push({
      approach: "Optimize",
      desc: "Mean-Variance Optimization"
    })
    return approaches
  }

  const displayResult = () =>{

    if(allocatedports.length === 0 || clearDesc){
      return (
        <img style={{maxWidth:"250px"}} alt={"no data"} src={"/images/background/nodata.png"} />
      )
    }else{
      return (
        <ReactApexChart
          options={themeOptions(theme)}
          series={values.series2}
          type="donut"
          height="350"
          stroke={""}
        />
      )
    }
  }

  const displayResultLeft = () =>{

    
      return (
        <ReactApexChart
          options={themeOptions(theme)}
          series={values.series2}
          type="donut"
          height="350"
          stroke={""}
        />
      )
    
  }


  return (
    <Container>
      {xWinUtils.getProgress(loading)}
        <CreatePortV2
          allocatedports={allocatedports}
          tokensMaster={tokensMaster}
          openModal={openRebModal}
          returns={returns}
          proxyAssets={proxyAssets}
          reset={() => {
              setOpenRebModal(false)
          }}
      />
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12} xs={12}>
          <Widget 
            className={classes.widgetLayout}
            title="Get Recommended Portfolio Allocation"
            disableWidgetMenu
            enableWalletDisplay
          >
            <div className="mb-1 p-1">
              <Typography
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
                noWrap
              >
                {"Allocation Approach"}
              </Typography>
            </div>
            <Box display="flex" alignItems="center" justifyContent={"left"}>
              
                <Autocomplete
                      id="approach"
                      style={{ width: "100%" }}
                      name="approach"
                      defaultValue={getSupportedApproach()[1]}
                      getOptionSelected={(option, value) => option.approach === value.approach}
                      options={getSupportedApproach()}
                      getOptionLabel={option => option.desc}
                      onChange={(event, newValue) => {
                        setAllocationApproach({
                          approach: newValue == null? "" : newValue.approach
                        });
                        setClearDesc(true);
                      }}
                      renderInput={params => <TextField {...params} label="" margin="dense" variant="outlined"  />}
                    />
            </Box>
            <div className="mb-1 p-1">
              <Typography
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
                noWrap
              >
                {"Max Assets"}
              </Typography>
            </div>
            <Box display="flex" alignItems="center" justifyContent={"left"}>
              <TextField
                  size="small"
                  type="number" 
                  margin="dense"
                  name="assetNo"
                  onChange={handleAssetNoChange}
                  required
                  variant="outlined"
                  value={assetNo}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.positiveNum,
                    },
                    style: { textAlign: "right" },
                  }}
                />
            </Box>
            

            <div className="mb-1 p-1">
              <Typography
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
                noWrap
              >
                {"Risk Tolerance"}
              </Typography>
            </div>

            <Box display="flex" alignItems="center" justifyContent={"left"}>
              <Slider
                aria-label="RiskTolerance"
                valueLabelDisplay="on"
                defaultValue={50}
                getAriaValueText={valuetext}
                value={riskValue} 
                step={10}
                marks
                min={0}
                max={100}
                onChange={handleChange}
              />
            </Box>
            
            <div className="mb-1 p-1">
              <Typography
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
                noWrap
              >
                {"Age"}
              </Typography>
            </div>

            <Box display="flex" alignItems="center" justifyContent={"left"}>
              <Slider
                aria-label="age"
                valueLabelDisplay="on"
                defaultValue={25}
                getAriaValueText={valueAgetext}
                value={ageValue} 
                step={1}
                marks
                min={20}
                max={75}
                onChange={handleAgeChange}
              />
            </Box>
            <br/>
            <Box display="flex" alignItems="center" justifyContent={"center"}>
              <Button 
                disabled={true}
                fullWidth
                startIcon={<TextRotationNoneTwoToneIcon />}
                onClick={handleClickRun}
                variant="contained" size="small" color="primary">
                {"Run"}
              </Button>
              {
                account !== "---" && (
                  <Button 
                    disabled={true}
                    fullWidth
                    onClick={handleClickOpenCreateFund} 
                    color="secondary"
                    variant="contained"
                    size="small"
                    startIcon={<TextRotationNoneTwoToneIcon />}
                    >
                      {"Build"}
                  </Button>
                )
              }
              
            </Box>
            <br/>
            <Box display="flex" alignItems="center" justifyContent={"center"}>
              {!(allocatedports.length === 0 || clearDesc) && displayResultLeft()}
            </Box>
          </Widget>   
        </Grid>
        <Grid item lg={6} sm={12} xs={12}>
          {getTargets()}
        </Grid>
      </Grid>
      <VaultFaq faqs={faqs}/>
      </Container>

  );
}
