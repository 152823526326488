import React, { useState, useEffect } from 'react'
import ApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/styles";
import xWinLib from '../../../xWinLib';
import appGlobalConfig from '../../../appGlobalConfig';
import { GetPerformanceCompare } from '../../../utils/firestoreDataInteractor';
import xWinUtils from '../../../xWinUtils';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Typography } from '@material-ui/core';

export default function returnsCompareChart(props) {
  var theme = useTheme();
  const { fundData, period, tokensMaster } = props;
  const [data, setData] = useState([])
  const [series, setSeries] = useState([])
  const [dateLabel, setDateLabel] = useState([])
  const [loading, setLoading] = React.useState(false);
  const [dropdown, setDropdown]= React.useState(appGlobalConfig.mainnetBTC)
  
  useEffect(() => {
    init(appGlobalConfig.mainnetBTC)
  }, [])
  
  
  const init = async (bmAddr) => {
    setLoading(true)
    const promiseData = await Promise.all([
        GetPerformanceCompare(fundData.contractaddress, bmAddr)
      ]);
    let seriesdata = promiseData[0]
    setData(seriesdata)
    constructData(seriesdata)
    setLoading(false)

  }


  const constructData = (dt) => {

    let arr = []
    let datesarr = []
    dt.forEach(d => {
  
      let name = xWinLib.getTokenName(tokensMaster, d.name)
      if(name === undefined){
        name = fundData.symbol
      }
      arr.push({
        name : name,
        data : d.data
      })
    });

    dt[0]?.dates.forEach(d => {
      datesarr.push(d)  
    })

    setSeries(arr)
    setDateLabel(datesarr)
  }

  
  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      dashArray: [0, 8]
    },
    xaxis: {
      type: "datetime",
      categories: dateLabel
    },    
    tooltip: {
      theme: false,
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    fill: {
      colors: [theme.palette.primary.light, theme.palette.success.light],
    },
    colors: [theme.palette.primary.main, theme.palette.success.main],
    chart: {
      foreColor: '#ccc',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      },
    },
    legend: {
      show: true,
    },
  };

  const handleChangeDropdown = async (event) => {
    event.preventDefault()
    await init(event.target.value)
    setDropdown(event.target.value);  
  }

 
  return (
    <>
    <p>
      {xWinUtils.getProgressLinear(loading)}
      <Box sx={{ p: 2, border: '1px dashed grey' }} display="flex" alignItems="center" justifyContent={"right"}>
          <Typography
            color="text"
            colorBrightness={"hint"}
            variant={"h6"}
            style={{ marginLeft: 5 }}
            className={"p-2"}
          >
            Benchmark
          </Typography>
          <FormControl  sx={{  minWidth: 50,  }} size="small" variant="standard">
                <Select
                value={dropdown}
                label="Benchmark"
                onChange={handleChangeDropdown}
                sx={{ color:(theme.palette.type === "dark") && "white", backgroundColor:(theme.palette.type === "dark") && "#2f2f36",paddingLeft:1 }}
                >
                <MenuItem  value={appGlobalConfig.mainnetBTC}>BTC</MenuItem>
                <MenuItem  value={appGlobalConfig.mainnetETH}>ETH</MenuItem>
                <MenuItem value={appGlobalConfig.mainnetXWIN}>XWIN</MenuItem>
                </Select>
          </FormControl>
        </Box>
    </p>
      <ApexCharts
        options={options}
        series={series}
        type="line"
        height={400}
      />
    </>
  );
}
