import Web3 from "web3";
import xWinBuddyChef from "../abi/v2/xWinBuddyChef.json";
import appGlobalConfig from "../appGlobalConfig";
import { getGasPrice, convertToWei, convertFromWei } from "../utils/helper";
import xWinLib from '../xWinLib';
import { getTokenInfo } from "./erc20Interactor";
import { GetFundDataAll } from "./fundV2Interactor";
import { getPrice } from "./xWinPriceMasterInteractor";

export async function GetBuddyPoolInfo(web3, port, prices) {

  try {

    const contract = new web3.eth.Contract(xWinBuddyChef, appGlobalConfig.mainnetBuddyChef);
    const promiseData = await Promise.all([
      contract.methods.poolInfo(port.pid).call(),
    ]);
    let pool = promiseData[0]
    // console.log(pool)
    let rates = await getPrice(web3, pool.rewardToken, appGlobalConfig.mainnetUSDT)
    let TVLInUSD = Number(convertFromWei(pool.totalStaked)) * prices.xwinPriceInUSD;
    return{
      totalBuddyTokens: convertFromWei(pool.totalBuddyTokens),
      totalStaked: convertFromWei(pool.totalStaked),
      tokenPerBlock: convertFromWei(pool.tokenPerBlock),
      endPeriod: pool.endPeriod,
      rewardToken: pool.rewardToken,
      lpToken: pool.lpToken,
      TVLInUSD : TVLInUSD,
      altTokenPrice: convertFromWei(rates || 0)
    }      
  } catch (error) {
    console.log(error)
  }
  return{
      totalBuddyTokens: 0,
      totalStaked: 0,
      tokenPerBlock: 0,
      endPeriod: 0,
      rewardToken: appGlobalConfig.mainnetAddressZero,
      lpToken: appGlobalConfig.mainnetAddressZero,
      TVLInUSD : 0
  }

}

export async function GetUserBuddyStakingInfo(web3, account, pid, contractaddress) {

  const contract = new web3.eth.Contract(xWinBuddyChef, appGlobalConfig.mainnetBuddyChef);
  const promiseData = await Promise.all([
    getTokenInfo(web3, contractaddress, account),
    contract.methods.userInfo(pid, account).call(),
    contract.methods.pendingRewards(pid, account).call()
  ]);
  let tokenInfo = promiseData[0]
  let userInfo = promiseData[1]
  let pendingXwin = promiseData[2]
  // console.log(tokenInfo, userInfo, pendingXwin)
  return {
    "amount": convertFromWei(userInfo.amount),
    "pendingXwin": convertFromWei(pendingXwin),
    "targetBal": tokenInfo.targetBal,
  };
  
}

export function getStakingAPR(poolInfo, tokenPrice, xwinPrice) {
  
  if (Number(tokenPrice) === 0 || Number(poolInfo.totalStaked) <= 0 || Number(poolInfo.tokenPerBlock) === 0) {
    return '0';
  }
  const tokenReward = Number(poolInfo.tokenPerBlock); 
  if (Number(poolInfo.totalStaked) <= 0 || tokenReward === undefined) {
    return '0';
  }
  const accCakePerShare = tokenReward / Number(poolInfo.totalStaked);
  const proceeds = accCakePerShare * appGlobalConfig.blockPerDay * 365 * Number(tokenPrice);
  return Number((proceeds / Number(xwinPrice)) * 100).toFixed(2);
}

export async function BuddyHarvestAll(web3, account) {

  return new Promise((resolve, reject) => {    
    
    const contract = new web3.eth.Contract(xWinBuddyChef, appGlobalConfig.mainnetBuddyChef);
    const gasParam = getGasPrice();
    contract.methods.harvestAll().send({
      from: account,
      value: 0,
      ...gasParam
    })
    .on('confirmation', (confirmationNumber, receipt) => {
      resolve(receipt.transactionHash);
    })
    .on('error', (error, receipt) => {
      if (receipt !== undefined) reject(receipt.transactionHash);
    })
    .catch((err) => {
      console.log('error: %j', err);
      reject(err.message);
    });
  });
}

export async function DepositBuddyFarm(web3, pid, account, subsAmt) {

  return new Promise((resolve, reject) => {    
    
    const contract = new web3.eth.Contract(xWinBuddyChef, appGlobalConfig.mainnetBuddyChef);
    const gasParam = getGasPrice();
    const subsAmtInWei = convertToWei(subsAmt);
    contract.methods.deposit(pid, subsAmtInWei).send({
      from: account,
      value: 0,
      ...gasParam
    })
    .on('confirmation', (confirmationNumber, receipt) => {
      resolve(receipt.transactionHash);
    })
    .on('error', (error, receipt) => {
      if (receipt !== undefined) reject(receipt.transactionHash);
    })
    .catch((err) => {
      console.log('error: %j', err);
      reject(err.message);
    });
  });
}

export async function WithdrawBuddyFarm(web3, pid, account, amtRedeem) {
  
  return new Promise((resolve, reject) => {
    
    const contract = new web3.eth.Contract(xWinBuddyChef, appGlobalConfig.mainnetBuddyChef);
    const gasParam = getGasPrice();
    const amtRedeemInWei = convertToWei(amtRedeem.toString());
    contract.methods.withdraw(pid, amtRedeemInWei).send({
      from: account,
      value: 0,
      ...gasParam
    })
      .on('confirmation', (confirmationNumber, receipt) => {
        resolve(receipt.transactionHash);
      })
      .on('error', (error, receipt) => {
        if (receipt !== undefined) reject(receipt.transactionHash);
      })
      .catch((err) => {
        console.log('error: %j', err);
        reject(err.message);
      });
  });
}

export async function getUserBuddyFarmAllInfo(web3, account) {

  try {

    const contract = new web3.eth.Contract(xWinBuddyChef, appGlobalConfig.mainnetBuddyChef);
    let myFund  = await contract.methods.getMyBuddyFarmInfo(account).call();
    return myFund;
      
  } catch (error) {
    console.log(error)
  }
}
