import React, { useLayoutEffect, useEffect  } from "react";
import { Container, Grid, Box, Button } from '@material-ui/core';
import linkedIcon from '../../../assets/images/linkedin-icon.png';
import team01 from '../../../assets/images/xwin-Team-PhotosCalvin.png';
import team02 from '../../../assets/images/xwin-Team-Photos_fumi.png';
import team03 from '../../../assets/images/xwin-Team-PhotosYuki.png';
import NextArrow from '../../../assets/images/Next-arrow.png';
import Carousel from 'react-material-ui-carousel';
import './TeamAndAdvisors.css';
import AOS from 'aos';
import '../../home.css';
import {withRouter} from 'react-router-dom'

const Team = props => {

	useEffect(() => {
		AOS.init({
			disable: 'mobile'
		  });
	});

	const goToMember = event => {
		props.history.push('app/teammember')
	}

	return (
		<section id="team-and-advisors" className="section-spacing team-and-advisors text-white">
			<Box className="team-block">
				<Container maxWidth="lg" className="main-container">
					<Grid container spacing={7}>
						<Grid item xs={12}>
							<Box data-aos="fade-up" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" textAlign="center" className="section-heading text-uppercase">
								<h2>Meet Our Team</h2>
							</Box>
							<Box textAlign="center" className="section-heading">
								<p>We are committed to xWin DeFI eco-system.</p>
							</Box>
						</Grid>
					</Grid>
				</Container>
				<Container maxWidth="lg" className="">
					<Container maxWidth="lg" className="team-row main-container">
						<Grid container spacing={4}>
							<Box data-aos="fade-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" flexWrap="wrap" display="flex">
								<Box className="team-image">
									<img src={team01} alt="team member" />
								</Box>
								<Box className="team-detail">
									<Box className="team-header">
										<Box className="team-header-row" display="flex" alignItems="center">
											<strong className="team-name text-uppercase">Calvin Thong</strong>
											<a href="https://www.linkedin.com/in/calvin-thong-b5b85116/" className="d-inline-lock"><img src={linkedIcon} alt="linked icon" /></a>
										</Box>
										<strong className="designation">Founder</strong>
									</Box>
									<p>
									Calvin Thong, founder of xWIN, is experienced IT-skilled and ex-portfolio manager in ETF and Index funds in BlackRock. 
            Calvin graduated with Masters in Applied Finance on Macquarie University and Bachelor degree in computer science.</p>
								</Box>
							</Box>
						</Grid>
					</Container>
				</Container>
				<Container maxWidth="lg" className="">
					<Container maxWidth="lg" className="team-row  main-container">
						<Grid container spacing={4}>
							<Box data-aos="fade-left" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" flexWrap="wrap" display="flex">
								<Box className="team-image">
									<img src={team02} alt="team member" />
								</Box>
								<Box className="team-detail">
									<Box className="team-header">
										<Box className="team-header-row" display="flex" alignItems="center">
											<strong className="team-name text-uppercase">Fumihiro Arasawa</strong>
											<a href="https://www.linkedin.com/in/fumi-arasawa-7b5832118/" className="d-inline-lock"><img src={linkedIcon} alt="linked icon" /></a>
										</Box>
										<strong className="designation">Co-Founder</strong>
									</Box>
									<p>
									Fumihiro Arasawa, experienced in business consulting and acted as the president of Vietnam in Japanese consulting firm. 
            Fumi contributed to globalization, strategy creation and organization making for his clients.</p>
								</Box>
							</Box>
						</Grid>
					</Container>
				</Container>
				<Container maxWidth="lg" className="">
					<Container maxWidth="lg" className="team-row main-container">
						<Grid container spacing={4}>
							<Box data-aos="fade-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" flexWrap="wrap" display="flex">
								<Box className="team-image">
									<img src={team03} alt="team member" />
								</Box>
								<Box className="team-detail">
									<Box className="team-header">
										<Box className="team-header-row" display="flex" alignItems="center">
											<strong className="team-name text-uppercase">YUKI</strong>
											<a href="#" className="d-inline-lock"><img src={linkedIcon} alt="linked icon" /></a>
										</Box>
										<strong className="designation">UX Designer</strong>
									</Box>
									<p>Yuki graduated from Art University and had been working for an advertising agency.</p>
								</Box>
							</Box>
						</Grid>
					</Container>
				</Container>

			</Box>
			
			<Box className="advisor-block team-block">
				<Container maxWidth="lg" className="main-container">
					<Grid container spacing={7}>
						<Grid item xs={12}>
							<Box data-aos="fade-up" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" textAlign="center" className="section-heading text-uppercase">
								<Button onClick={goToMember} data-aos="fade-right" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" variant="contained" color="primary">See More Members</Button>
							</Box>
						</Grid>
					</Grid>
				</Container>
				
			</Box>
		
		</section>
	);
}

export default withRouter(Team)
