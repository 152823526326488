const masterchefFAQ = {
	faqs: [
	  {
		symbol: "masterchef", 
		env: "v2bscmain", 
		question: 'What is xWIN Staking Farm?',
		defaultExpanded : true,
		desc: 'All the public vault that you deposited you are entitled to get the tokens. Those token can be staked into the xWIN Staking Farm to earn more XWIN tokens as part of the returns in our portfolio',
	  },
	  {
		symbol: "masterchef", 
		env: "v2bscmain", 
		question: 'Why are the APR different from what I see a day before?',
		desc: 'Each of the pool are allocated with the fixed emission rate and allocation points. If the staked tokens are less and emission is fixed, then you will get more XWIN tokens as returns. <br/> However, whenever the more tokens are staked, it means more tokens are sharing the tokens released from the pool, hence APR will drop accordingly',
	  },
	  {
		symbol: "masterchef", 
		env: "v2bscmain", 
		question: 'Any Fee Charge in the Staking Farm?',
		desc: 'xWIN MasterChef protocol will deduct 5% of the tokens you earned and burn it accordingly. This is part of the effort to maintain the balance of xWIN eco-system.',
	  },
	  {
		symbol: "masterchef", 
		env: "v2bscmain", 
		question: 'What is the difference from the previous farming in v1?',
		desc: 'XWIN Farming in v1 is a fixed APR staking capability. v1 will release the XWIN tokens whenever there are tokens staked into it. <br/> While in v2, the emission is fixed at each block and allocation to the pool is fixed. So, regardless if 1 tokens staked or 1m tokens staked, the emission of the tokens does not change <br/> We will soon discontinue the v1 Farming feature and please move your staking into v2 as soon as possible',
	  },
	  {
		symbol: "masterchef", 
		env: "v2bscmain", 
		question: 'Are there any fee incurred?',
		desc: '5% burned fee will be applied to all the proceeds from the xWIN Staking Pool',
	  },
	],
  };
  
  export default masterchefFAQ;
  
  