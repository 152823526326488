import React, { useState, useEffect, useContext, useRef  } from "react";
import xWinUtils from '../../xWinUtils';
import FarmlistV2 from "./components/FarmlistV2";
import { useParams } from 'react-router-dom'
import { getNetworkById } from "../../utils/helper";
import {AppContext}  from '../../context/AppProvider';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import { MoreVert as MoreIcon, Search as SearchIcon, CastConnected as CastConnectedIcon } from "@material-ui/icons";
import {
  Grid,
  Container,
  TextField as Input,
  InputAdornment,
  Box,
  Tabs,
  Tab,
} from "@material-ui/core";
import { Typography} from '../../components/Wrappers/Wrappers'
import Widget from "../../components/Widget/Widget";
import useStyles from "./styles";
import { systemConfig } from "../../configs/systemConfig";
import { getAllPrices } from "../../utils/xWinPriceMasterInteractor";
import VaultFaq from '../vaultfaq/VaultFaq'
import vaultfaqconfig from '../vaultfaq/faq/masterchefFAQ'
import appGlobalConfig from "../../appGlobalConfig";
import PropTypes from 'prop-types'
import FileCopyIcon from '@material-ui/icons/FileCopy'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
          {value === index && (
              <Box sx={{ p: 3 }}>
                  <Typography>{children}</Typography>
              </Box>
          )}
      </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function FarmingV2(props) {
  const classes = useStyles();
  const { portfolios, fundtype, category } = props;
  const [networkName, setNetworkName] = useState("")
  const [filterPortfolios, setFilterPortfolios] = useState([])
  const [filterPortfoliosByCategory, setFilterPortfoliosByCategory] = useState([])
  const [loading, setLoading] = React.useState(false);
  const [prices, setPrices] = useState([])
  const refsnackbar = useRef(null);
  const [faqs, setFaqs] = useState([])
  const [value, setValue] = React.useState(0)

  const { account, theWeb3, web3static, globalProvider, refreshAccount } = useContext(AppContext);
  
  const { id } = useParams()
  
  useEffect(() => {
    loadWeb3()
  }, [portfolios, id]);

  
  const handleChange = (event, newValue) => {
    setValue(newValue)
  } 

  const loadWeb3 = async () => {

    
    if(web3static === undefined) return;
    
    setLoading(true)
    let filterEnv = getNetworkById(systemConfig.chainId).env; // xWinLib.getNetwork()
    setNetworkName(filterEnv)

    let allprices = await getAllPrices(web3static, globalProvider)
    let filterport = []
    let searchExcludeDiscontinuePort
    if(filterEnv === undefined){
      
      filterport = portfolios; //.filter(x=>x.env.toLowerCase() === "bscmain")
      searchExcludeDiscontinuePort = filterport.filter(x=>x.category !== "discontinued")
      setFilterPortfoliosByCategory(searchExcludeDiscontinuePort)
    }else{
      
      filterport = portfolios; // .filter(x=>x.env.toLowerCase() === filterEnv.toLowerCase())
      if(id === "all"){
        searchExcludeDiscontinuePort = filterport.filter(x=>x.category !== "discontinued")
        setFilterPortfoliosByCategory(searchExcludeDiscontinuePort)
      }else{
        let searchPort = filterport.filter(x=>x.contractaddress === id)
        setFilterPortfoliosByCategory(searchPort)      
      }
    }

    // let fqs = vaultfaqconfig.faqs.filter(x=>x.env === appGlobalConfig.env);
    setFaqs(vaultfaqconfig.faqs);

    setPrices(allprices)
    setFilterPortfolios(filterport)
    setLoading(false)
    
  }

  const displayNonV2Portfolios = () => {
    
    let funds = filterPortfoliosByCategory.filter(x=>x.isxwinfund === false)
    return (
      funds.map(port => (
        <Grid item lg={6} sm={12} xs={12} className={classes.hoverHighlight}>
           <FarmlistV2 
            port={port}
            globalWeb3={theWeb3}
            web3static={web3static}
            selectedWallet={account}
            networkName={networkName}
            refreshAccount={refreshAccount}
            prices={prices}
            /> 
        </Grid>
        )) 
    )    
  }

  const displayPortfolios = () => {
    
    let funds = filterPortfoliosByCategory.filter(x=>x.isxwinfund === true)
    return (
      funds.map(port => (
        <Grid item lg={6} sm={12} xs={12} className={classes.hoverHighlight}>
           <FarmlistV2 
            port={port}
            globalWeb3={theWeb3}
            web3static={web3static}
            selectedWallet={account}
            networkName={networkName}
            refreshAccount={refreshAccount}
            prices={prices}
            /> 
        </Grid>
        )) 
    )    
  }

  const handleOnchange = async event => {
    
    event.preventDefault();

    const value = event.target.value;
    let filterport = filterPortfolios.filter(x => x.symbol.toLowerCase().includes(value.toLowerCase()))
    
    if(filterport.length === 0){
      filterport = filterPortfolios.filter(x => x.name.toLowerCase().includes(value.toLowerCase()))
    }
    setFilterPortfoliosByCategory(filterport)      
  }

  return (
    <Container>
      {xWinUtils.getProgress(loading)}
      <SnackbarMessage ref={refsnackbar} />
      <Grid container spacing={2}>
        {/* <Grid item lg={12} sm={12} xs={12} className={classes.hoverHighlight2}>
          <Widget
            spacing={0}
            className={classes.newsHeader}
            title={"Stake Alt Earn XWIN"}
            disableWidgetMenu
            inheritHeight
            enableWalletDisplay
            selectedWallet={account}
            globalWeb3={web3static}
            //searchField
          >
          <Input
            placeholder="symbol or vault name"
            id="search-field"
            fullWidth
            onChange={handleOnchange}
            className={classes.textField}
            margin="dense"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              )
            }}
          />
          </Widget>
        </Grid> */}
        <Grid item lg={12} sm={12} xs={12} className={classes.hoverHighlight2}>
              <Tabs
                  value={value}
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="basic tabs example"
              >
                  <Tab
                      className={classes.contentText}
                      icon={<FileCopyIcon />}
                      iconPosition="start"
                      label="Fund v2"
                      {...a11yProps(0)}
                  />
                  <Tab
                      className={classes.contentText}
                      icon={<FileCopyIcon />}
                      iconPosition="start"
                      label="Other Staking"
                      {...a11yProps(1)}
                  />
              </Tabs>
              <TabPanel value={value} index={0}>
                <br/>
                <Grid container spacing={2}>
                  {displayPortfolios()}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <br/>
                <Grid container spacing={2}>
                  {displayNonV2Portfolios()}
                </Grid>
              </TabPanel>
        </Grid>
      </Grid>
      <VaultFaq faqs={faqs}/>
    </Container>
  );
}
