const xwinstakingv2 = {
  funds: [
    {
      "contractaddress": "0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28",
      "env" : "v2bscmain",
      "isxwinfund": false,
      "logo" : "xwin.png",
      "name" : "XWIN Staking",
      "pid" : 0,
      "symbol" : "XWIN",
      "earnSymbol":"XWIN"
    },
    {
      "contractaddress": "0x4F263c2F03d8dcd7Dea928de0224e34cEbD9F03c",
      "env" : "v2bscmain",
      "isxwinfund": false,
      "logo" : "usdt-xwin-apelp.png",
      "name" : "APESWAP Staking",
      "pid" : 2,
      "symbol" : "XWIN_USDT",
      "earnSymbol":"XWIN"
    },
    {
      "contractaddress": "0xD4A3Dcf47887636B19eD1b54AAb722Bd620e5fb4",
      "env" : "v2bscmain",
      "isxwinfund": false,
      "logo" : "bnb-xwin-lp.png",
      "name" : "PCS Staking",
      "pid" : 3,
      "symbol" : "XWIN_BNB",
      "earnSymbol":"XWIN"
    },
    {
      "contractaddress": "0x9F1762b15e740704F40a3606B31DccFbe5318D8B",
      "env" : "v2bscmain",
      "isxwinfund": false,
      "logo" : "bnb-xwin-apelp.png",
      "name" : "APESwap Staking",
      "pid" : 4,
      "symbol" : "XWIN_BNB",
      "earnSymbol":"XWIN"
    },
    {
      "contractaddress": "0x2194808b8914c7cb145b1005a9d5d3A8B6213BFA",
      "env" : "v2bscmain",
      "isxwinfund": true,
      "logo" : "defi.png",
      "name" : "fDEFI Staking",
      "pid" : 5,
      "symbol" : "fDEFI",
      "earnSymbol":"XWIN"
    },
    {
      "contractaddress": "0xbdfB72A877E79FcFc87EC9e157bB22C829ED5479",
      "env" : "v2bscmain",
      "isxwinfund": true,
      "logo" : "jpi.png",
      "name" : "fJPI Staking",
      "pid" : 6,
      "symbol" : "fJPI",
      "earnSymbol":"XWIN"
    },
    {
      "contractaddress": "0xc11D9796Ff40Ac7089299C37cB336cd62d6c564a",
      "env" : "v2bscmain",
      "isxwinfund": true,
      "logo" : "ausdt.png",
      "name" : "fusdtSCA Staking",
      "pid" : 7,
      "symbol" : "fusdtSCA",
      "earnSymbol":"XWIN"
    },
    {
      "contractaddress": "0xDFB607317629114d32DBD4Bf0Ab70785f953e8b4",
      "env" : "v2bscmain",
      "isxwinfund": true,
      "logo" : "fbtcdca.png",
      "name" : "fbtcDCA Staking",
      "pid" : 8,
      "symbol" : "fbtcDCA",
      "earnSymbol":"XWIN"
    },
    {
      "contractaddress": "0x6747342E441979C08DC03500243E864F491e607f",
      "env" : "v2bscmain",
      "isxwinfund": true,
      "logo" : "ada.png",
      "name" : "fvADA Staking",
      "pid" : 9,
      "symbol" : "fvADA",
      "earnSymbol":"XWIN"
    },
    {
      "contractaddress": "0x61B170B66DD1d463e9f9Eb4c6646a5fE4fbDEb7C",
      "env" : "v2bscmain",
      "isxwinfund": true,
      "logo" : "btcb.png",
      "name" : "fvBTC Staking",
      "pid" : 10,
      "symbol" : "fvBTC",
      "earnSymbol":"XWIN"
    },
    {
      "contractaddress": "0x6439f73dA37Bc8EB83Ac05c615Eb8BAb9fA2a701",
      "env" : "v2bscmain",
      "isxwinfund": true,
      "logo" : "usdt.png",
      "name" : "fvUSDT Staking",
      "pid" : 11,
      "symbol" : "fvUSDT",
      "earnSymbol":"XWIN"
    },
    {
      "contractaddress": "0x2E89724E1d8b6b9F0f173496298dFf24c82facDD",
      "env" : "v2bscmain",
      "isxwinfund": true,
      "logo" : "fbtcirt.png",
      "name" : "fbtcIRT Staking",
      "pid" : 12,
      "symbol" : "fbtcIRT",
      "earnSymbol":"XWIN"
    },
    {
      "contractaddress": "0x9A0d13f09ef7426361e54f1cB44E7F46DCDBf523",
      "env" : "v2bscmain",
      "isxwinfund": true,
      "logo" : "fbtcbbma.png",
      "name" : "fbtcBBMA Staking",
      "pid" : 13,
      "symbol" : "fbtcBBMA",
      "earnSymbol":"XWIN"
    }
  ]
};

export default xwinstakingv2;

