// import Web3 from "web3";
import xWinSingleAsset from "../abi/v2/xWinSingleAsset.json";
import { convertFromWei } from "../utils/helper";
import { getPrice } from "./xWinPriceMasterInteractor";

export async function getAccruedComp(web3, contractaddress) {

  try {
    const contract = new web3.eth.Contract(xWinSingleAsset, contractaddress);
    let accrual = await contract.methods.getAccruedComp().call();
    return {
      "accrual": convertFromWei(accrual || 0),
    };
      
  } catch (error) {
    console.log(error)
  }
}

export async function getSupplyLendingRates(web3, contractaddress) {

	const contract = new web3.eth.Contract(xWinSingleAsset, contractaddress);
  const promiseData = await Promise.all([
    contract.methods.getSupplyRatePerBlock().call(),
    contract.methods.getBorrowRatePerBlock().call(),
  ]);
  let supplyRatePerBlock = promiseData[0]
  let borrowRatePerBlock = promiseData[1]
  return {
    "supplyRatePerBlock": convertFromWei(supplyRatePerBlock || 0),
    "borrowRatePerBlock": convertFromWei(borrowRatePerBlock || 0)
  };
}

export async function getFundAndUnderlyingValue(web3, contractaddress, underlyingAddr, baseaddr) {

  const contract = new web3.eth.Contract(xWinSingleAsset, contractaddress);
	const promiseData = await Promise.all([
    getPrice(web3, underlyingAddr, baseaddr),
    contract.methods.getVaultValues().call(),
  ]);
  let rates = promiseData[0]
  let vaultValue = promiseData[1]
  
  return {
    "vaultValue": convertFromWei(vaultValue || 0),
    "underlyingValue": convertFromWei(rates || 0)
  };
}

export async function getStrategyInfo(web3, contractaddress) {

  const contract = new web3.eth.Contract(xWinSingleAsset, contractaddress);
	let data = await contract.methods.getStrategyInfo().call();
  return {
    "targetaddress": data.targetaddress,
    "rewardaddress": data.rewardaddress,
    // "targetname" : data.targetname
  };
}
