import { systemConfig } from "../configs/systemConfig";
// import format from "format-number";
import moment from "moment";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import Web3 from 'web3';

export const availableNetwork = {
  
  Mainnet: {
    chainId: `0x${Number(1).toString(16)}`,
    chainName: "ETH",
    env: "ethmain",
    nativeCurrency: {
      name: "Ethereum Token",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [
      "https://rpc.ankr.com/eth",
      "https://rpc.ankr.com/eth",
    ],
    blockExplorerUrls: ["https://etherscan.com"],
  },
  bscMainnet: {
    chainId: `0x${Number(56).toString(16)}`,
    chainName: "BSC",
    env: "bscmain",
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: [
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed2.binance.org",
      "https://bsc-dataseed3.binance.org",
      "https://bsc-dataseed4.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed3.defibit.io",
      "https://bsc-dataseed4.defibit.io",
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io",
      "wss://bsc-ws-node.nariox.org",
    ],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  bscTestnet: {
    chainId: `0x${Number(97).toString(16)}`,
    chainName: "BSC Testnet",
    env: "bsctest",
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: [
      "https://data-seed-prebsc-1-s1.binance.org:8545",
      "https://data-seed-prebsc-2-s1.binance.org:8545",
      "https://data-seed-prebsc-1-s2.binance.org:8545",
      "https://data-seed-prebsc-2-s2.binance.org:8545",
      "https://data-seed-prebsc-1-s3.binance.org:8545",
      "https://data-seed-prebsc-2-s3.binance.org:8545",
    ],
    blockExplorerUrls: [
      "https://explorer.binance.org/smart-testnet",
      "https://testnet.bscscan.com",
    ],
  },
  polygonMainnet: {
    chainId: `0x${Number(137).toString(16)}`,
    chainName: "Polygon",
    env: "polygon",
    nativeCurrency: {
      name: "MATIC Token",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [
      "https://matic-mainnet.chainstacklabs.com",
      "https://rpc-mumbai.maticvigil.com",
      "https://rpc-mainnet.matic.network",
      "https://rpc-mainnet.maticvigil.com",
      "https://rpc-mainnet.matic.quiknode.pro",
      "https://matic-mainnet-full-rpc.bwarelabs.com",
    ],
    blockExplorerUrls: ["https://polygonscan.com"],
  },
  polygonTestnet: {
    chainId: `0x${Number(80001).toString(16)}`,
    chainName: "Mumbai",
    env: "mumbai",
    nativeCurrency: {
      name: "MATIC Token",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [
      "https://matic-mumbai.chainstacklabs.com",
      "https://rpc-mumbai.matic.today",
      "https://rpc-mumbai.maticvigil.com",
      "https://matic-testnet-archive-rpc.bwarelabs.com",
    ],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
  },
  localhost: {
    chainId: `0x${Number(31337).toString(16)}`,
    chainName: "localhost",
    env: "ethmain",
    nativeCurrency: {
      name: "Ethereum Native Token",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [
      "http://localhost/8545/",
    ],
    blockExplorerUrls: ["https://etherscan.com"],
  },
  
};

export const getNetworkById = (chainId) => {
  if (chainId === 1) return availableNetwork.Mainnet;
  if (chainId === 56) return availableNetwork.bscMainnet;
  if (chainId === 97) return availableNetwork.bscTestnet;
  if (chainId === 80001) return availableNetwork.polygonTestnet;
  if (chainId === 137) return availableNetwork.polygonMainnet;
  if (chainId === 1337) return availableNetwork.localhost;
  if (chainId === 31337) return availableNetwork.localhost;

  return availableNetwork.bscMainnet;
};

export const changeToBSCNetwork = async (network, currentProvider) => {
  if (systemConfig.chainId === network) {
    return;
  }

  let params;
  switch (systemConfig.chainId) {
    case 56:
      params = [availableNetwork.bscMainnet];
      break;
    case 137:
      params = [availableNetwork.polygonMainnet];
      break;
    case 80001:
      params = [availableNetwork.polygonTestnet];
      break;
    case 1337:
      params = [availableNetwork.localhost];
      break;
    case 97:
    default: // default to bsc testnet
      params = [availableNetwork.bscTestnet];
      break;
  }

  try {
    if (!window.ethereum) {
      console.log("No crypto wallet found");
      return;
      // throw new Error("No crypto wallet found");
    }
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getAddressShort = (selectedWallet) => {
  if (selectedWallet === undefined) return "";
  let right5 = selectedWallet.substring(0, 4);
  let left5 = selectedWallet.substring(selectedWallet.length - 3, selectedWallet.length);
  let combine = right5 + "...." + left5;
  return combine;
};



export const FormatDate = (_date) => {
  if (_date === undefined) return moment(new Date()).format("L");
  return moment(new Date(_date.seconds * 1000)).format("L");
};

export function convertFromWei(_number, decimals = 18) {
  
  try {
    if (_number == null) {
      return "0";
    }
    if (_number == undefined) {
      return "0";
    }
    if (isNaN(parseFloat(_number))) {
      return "0";
    }
    let value = ethers.utils.formatUnits(_number, decimals).toString();
    return value;
  } catch (error) {
    return "0";    
  }
  
}

export function convertToWei(_number, decimals = 18) {
  // console.log(ethers.utils.parseUnits(_number, decimals).toString());
  return ethers.utils.parseUnits(_number.toString(), decimals).toString();
}

export const resolvePromise = (promise) => {
  return new Promise((resolve) => {
    promise
      .then((response) => {
        resolve([response, null]);
      })
      .catch((error) => {
        resolve([null, error]);
      });
  });
};

export const getGasPrice = async () => {

  const web3 = new Web3(systemConfig.rpc)
  
  if (systemConfig.chainId === 56) {
    return {
      gasPrice: web3.utils.toWei("5", "Gwei").toString(),
    };
  } else if (systemConfig.chainId === 137) {
    const gasData = await (await fetch("https://gasstation-mainnet.matic.network/v2")).json();
    return {
      maxFeePerGas: web3.utils.toWei(gasData.fast.maxFee.toFixed(6), "Gwei").toString(),
      maxPriorityFeePerGas: web3.utils
        .toWei(gasData.fast.maxPriorityFee.toFixed(6), "Gwei")
        .toString(),
    };
  } else if (systemConfig.chainId === 80001) {
    const gasData = await (await fetch("https://gasstation-mumbai.matic.today/v2")).json();
    return {
      maxFeePerGas: web3.utils.toWei(gasData.fast.maxFee.toFixed(6), "Gwei").toString(),
      maxPriorityFeePerGas: web3.utils
        .toWei(gasData.fast.maxPriorityFee.toFixed(6), "Gwei")
        .toString(),
    };
  } else if (systemConfig.chainId === 97) {
    return {
      gasPrice: web3.utils.toWei("10", "Gwei").toString(),
    };
  } else if (systemConfig.chainId === 1337) {
    
    const gasData = await (await fetch("https://gasstation-mainnet.matic.network/v2")).json();
    // console.log(gasData)
    return {
      maxFeePerGas: web3.utils.toWei(gasData.fast.maxFee.toFixed(6), "Gwei").toString(),
      maxPriorityFeePerGas: web3.utils
        .toWei(gasData.fast.maxPriorityFee.toFixed(6), "Gwei")
        .toString(),
    };
    // return {
    //   gasPrice: web3.utils.toWei("2", "Gwei").toString(),
    // };
  }else {
    return {
      gasPrice: web3.utils.toWei("5", "Gwei").toString(),
    };
  }
};

export const displayMessage = (error, t) => {
  if (error.message !== undefined) {
    toast.error(error.message, { autoClose: 10000 });
    if (error.code === 4001) {
      toast.info(t("component:toastmessage.4001"), { delay: 1000, autoClose: 10000 });
    } else if (error.code === 32603) {
      toast.info(t("component:toastmessage.32603"), { delay: 1000, autoClose: 10000 });
    } else if (error.code === -32000) {
      toast.info(t("component:toastmessage.32000"), { delay: 1000, autoClose: 10000 });
    }
  } else {
    toast.error(error, { autoClose: 10000 });
    const idx = error.indexOf("50 blocks");
    if (idx > -1) {
      toast.info(t("component:toastmessage.50blocks"), { delay: 1000, autoClose: 10000 });
    }
  }
};

export const AddTokenToMetamask = async (tokenAddress, tokenSymbol, decimals) => {
  
  return new Promise((resolve, reject) => {
    window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals,
          // image: 'https://foo.io/token-image.svg',
        },
      },
    })
      .then((success) => {
        if (success) {
          resolve(`${ tokenSymbol } successfully added to wallet!`);
        } else {
          reject('Ops..! Please try again!');
        }
      })
      .catch((err) => {
        console.log('error: %j', err);
        reject('Ops..! Please try again!');
      });
  });

};
