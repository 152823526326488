import React, { useState, useEffect, useContext, useRef  } from "react";
import { 
  Grid, 
  Container,
  Box,
  TextField,
  Slider,
  Typography,
  Button,
  InputAdornment,
  Chip,
  IconButton,
  ExpansionPanelActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
 } from "@material-ui/core";
import Dot from '../../components/Sidebar/components/Dot';
import format from 'format-number';
import {AppContext}  from '../../context/AppProvider';
import Widget from "../../components/Widget";
import useStyles from "./styles";
import { Link, Avatar } from "../../components/Wrappers/Wrappers";
import appGlobalConfig from "../../appGlobalConfig";
import xWinUtils from '../../xWinUtils';
import { DepositLockStake, GetAPRInfo, GetBonusWeight, GetEstimatedDepositAPY, GetEstimatePerformanceDiscount, GetPoolLockStakeInfo, GetUserLockStakeInfo, WithdrawLockStake } from "../../utils/autoLockInteractor";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { approveContract, IsTokenApproved } from "../../utils/erc20Interactor";
import SnackbarMessage from '../snackbarmessage/SnackbarMessage';
import xWinLib from "../../xWinLib";
import moment from "moment";
import {
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import clsx from 'clsx';
import { getMasterChefStakingAPR } from "../../utils/masterChefInteractor";
import millify from "millify";
import VaultFaq from '../vaultfaq/VaultFaq'
import vaultfaqconfig from '../vaultfaq/faq/autoLockFAQ'
import ReactInterval from 'react-interval'
import { getAllPrices } from "../../utils/xWinPriceMasterInteractor";
import TextRotationNoneTwoToneIcon from '@material-ui/icons/TextRotationNoneTwoTone';


export default function autoLockStake(props) {
  const { showNewPosition } = props;
  const { account, XWINBalance, theWeb3, web3static, globalProvider, refreshAccount} = useContext(AppContext);
  var classes = useStyles();
  const [isApproval, setIsApproval] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [duration, setDuration] = React.useState(26);
  const [bonusWeight, setBonusWeight] = React.useState(1);
  const [performanceFeeDiscount, setPerformanceFeeDiscount] = React.useState(0);
  const [newEndDate, setNewEndDate] = React.useState(Date.now());
  const [depositAmount, setDepositAmount] = React.useState(XWINBalance);
  const refsnackbar = useRef(null);
  const [userInfo, setUserInfo] = React.useState({
    discountPerformance : "0",
    amount : 0,
    startTimestamp : "",
    endPeriodTimestamp : "",
    rewards : 0
  });
  const [poolInfo, setPoolInfo] = React.useState({
    bonusAPR : "0",
    mainAPR : "0",
    totalShares: 0,
    totalLockedShares : 0
  });
  const [faqs, setFaqs] = useState([])
  const [prices, setPrices] = useState([])
  // const [poolInfo, setPoolInfo] = useState([])
  
  useEffect(() => {
    loadData()
  }, [account]);

  const loadData = async () => {

    let newEndDate = xWinLib.getEndDate(duration)
    setNewEndDate(newEndDate)
    // let fqs = vaultfaqconfig.faqs.filter(x=>x.env === appGlobalConfig.env);
    setFaqs(vaultfaqconfig.faqs);
    
    if (theWeb3 === undefined) return;
    setLoading(true);
    let allprices = await getAllPrices(web3static, globalProvider)
    setPrices(allprices)
    
    let bonus = GetBonusWeight(duration)
    let pool = await GetPoolLockStakeInfo(web3static)
    const mainapr = await getMasterChefStakingAPR(web3static, 0);
    let annRate = xWinLib.APRToAPY(mainapr/100)
    
    setBonusWeight(bonus)
    
    let aprs = await GetEstimatedDepositAPY(web3static, duration, depositAmount)
    setPoolInfo({
      bonusAPR : aprs.estimatedBonusApr,
      mainAPR : annRate,
      totalShares: pool.totalShares,
      totalLockedShares : pool.totalLockedShares
    })
    if(account !== "---"){
      refreshAccount()
      const isApp = await IsTokenApproved(web3static, XWINBalance, account, appGlobalConfig.mainnetXWIN, appGlobalConfig.mainnetLockStake)
      setIsApproval(isApp); 
      
      await loadUserData();

      let feeDisc = GetEstimatePerformanceDiscount(duration, depositAmount)
      setPerformanceFeeDiscount(feeDisc)
    }else{
      setUserInfo({
        discountPerformance : 0,
        amount : 0,
        startTimestamp : "",
        endPeriodTimestamp : "",
        rewards : 0
      })
    }
    setLoading(false);
  };

  const loadUserData = async () => {
    
    if(account !== "---"){
      let user = await GetUserLockStakeInfo(web3static, account)
      setUserInfo(user)
    }
  }
  

  const valuetext = (value) => {
    return `${value}`;
  }

  const handleChange = async (event, newValue) => {
    setDuration(newValue);
    let bonus = GetBonusWeight(newValue)
    setBonusWeight(bonus)
    let feeDisc = GetEstimatePerformanceDiscount(newValue, depositAmount)
    setPerformanceFeeDiscount(feeDisc)
    let newEndDate = xWinLib.getEndDate(newValue * 7)
    setNewEndDate(newEndDate)
    let aprs = await GetEstimatedDepositAPY(web3static, newValue, depositAmount)
    setPoolInfo({
      ...poolInfo,
      bonusAPR : aprs.estimatedBonusApr
    })

  };

  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  };

  const handleClickWithdraw = async () =>{
    
    setLoading(true);
    WithdrawLockStake(theWeb3, account)
      .then(res =>
        { 
          console.log(res)
          setLoading(false)
          showSnackBar("success", res)
          loadData()
          // refreshAccount
        }
      )
      .catch(err => {
        setLoading(false)
        console.log(err)
        showSnackBar("error", err)
        // parentCallback(false);
      }
      )
  
  }

  const validDuration = () =>{

    if(userInfo.amount == 0) return true;

    let newEndDate = xWinLib.getEndDate(duration * 7)
    let currentEndDate = new Date(xWinLib.FormatUnixDate(userInfo?.endPeriodTimestamp))
    return currentEndDate <= newEndDate
    
  }

  const handleClickStake = async () =>{
    
    
    if(!validDuration()){
      showSnackBar('error', 'Please choose longer duration')
      return
    }
    if(depositAmount <= 0){
      showSnackBar('error', 'Please input stake amount')
      return
    }
    setLoading(true);
    
    DepositLockStake(theWeb3, account, depositAmount, duration)
      .then(res =>
        { 
          console.log(res)
          setLoading(false)
          showSnackBar("success", res)
          loadData()
          // refreshAccount
        }
      )
      .catch(err => {
        setLoading(false)
        console.log(err)
        showSnackBar("error", err)
        // parentCallback(false);
      }
      )
  
  }

  const handleClickApprove = async event => {
    event.preventDefault();
    
    setLoading(true)
    approveContract(theWeb3, appGlobalConfig.mainnetXWIN, appGlobalConfig.mainnetLockStake, account)
      .then(res => {
        setLoading(false)
        setIsApproval(true)
      }
    )
    .catch(err => {
      setLoading(false)
      console.log(err)
      setIsApproval(false)
    })
  };

  const handleAmtDepositChange = async event => {
    event.preventDefault()
    const amount = parseFloat(event.target.value)
    if (amount >= 0 && amount !== undefined && amount !== "") {
      setDepositAmount(parseFloat(amount))
      let feeDisc = GetEstimatePerformanceDiscount(duration, amount)
      setPerformanceFeeDiscount(feeDisc)
      let aprs = await GetEstimatedDepositAPY(web3static, duration, amount)
      setPoolInfo({
        ...poolInfo,
        bonusAPR : aprs.estimatedBonusApr
      })
    }else{
      setDepositAmount("")
    }
  }

  const handleMaxDepositAmt = async event => {
    setDepositAmount(XWINBalance)
    let feeDisc = GetEstimatePerformanceDiscount(duration, XWINBalance)
    setPerformanceFeeDiscount(feeDisc)
    let aprs = await GetEstimatedDepositAPY(web3static, duration, XWINBalance)
      setPoolInfo({
        ...poolInfo,
        bonusAPR : aprs.estimatedBonusApr
      })
  }

  const IsAlreadyLocked = () =>{
    return userInfo?.amount > 0
  }

  const canWithdrawExtend = () =>{
    let today = Date.now()
    let end = xWinLib.FormatUnixDate(userInfo?.endPeriodTimestamp)
    // let start = xWinLib.FormatUnixDate(today)
    // console.log(userInfo, today / 1000)
    return today / 1000 >= userInfo?.endPeriodTimestamp
  }

  const displayMyPosition = () => {
    
    if(IsAlreadyLocked()){
      return (
      <Accordion defaultExpanded={true} rounded={true}>
        <Typography
              size="md"
              weight={"medium"}
              style={{ marginLeft: 8 }}
              className={classes.newsHeader}
            >
              My Locked Position
        </Typography>
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ expanded: classes.expanded }}
        >
          <div className={clsx(classes.column1)}>
            {xWinUtils.getIcons("xwin")}
          </div>
          <div className={clsx(classes.column5, classes.helper)}>
            <Grid item>
            <Typography
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
              >
                Perf. Fee Discount
              </Typography>
            <Typography variant={'body2'} className={classes.subTitle}>
              { format({ prefix: '', suffix: '%' })(xWinLib.roundTo(userInfo?.discountPerformance, 2)) }
            </Typography>
            <Typography
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
              >
                Auto APY
              </Typography>
              <Typography variant={'body2'} className={classes.subTitle}>
                {format({ prefix: ' ', suffix: '%' })(millify(poolInfo.mainAPR || 0, {precision: 2, lowercase: true}))}
              </Typography>
              <Typography
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
              >
                Bonus APR
              </Typography>
              <Typography variant={'body2'} className={classes.subTitle}>
                {format({ prefix: ' ', suffix: '%' })(millify(userInfo?.userBonusAPR || 0, {precision: 2, lowercase: true}))}
              </Typography>
            </Grid>
          </div>
          <div className={clsx(classes.column3, classes.helper)}>
            <Grid item>
              <Typography
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
              >
                Unlocked Date
              </Typography>
              <Typography variant={'body2'} className={classes.subTitle}>
              {xWinLib.FormatUnixDate(userInfo?.endPeriodTimestamp)}

              </Typography>
              <Typography
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
              >
                Duration
              </Typography>
              <Typography variant={'body2'} className={classes.subSuccess}>
                {format({ prefix: '', suffix: ' weeks' })(userInfo?.duration)}
              </Typography>
            </Grid>
          </div>
          <div className={clsx(classes.column5, classes.helper)}>
            <Grid item>
              <Typography
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
              >
                Current Position 
              </Typography>
              <Typography variant={'body2'} className={classes.subTitle}>
                {format({ prefix: '', suffix: '' })(xWinLib.roundTo(userInfo?.currentxwinAmount, 2))}
              </Typography>
              <Box display="flex" alignItems="right" justifyContent={"right"}>
                <Typography
                  // color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginRight: 5 }}
                >
                  {format({prefix: " ~$", suffix: " "})(xWinLib.roundTo(userInfo?.currentxwinAmount * prices.xwinPriceInUSD,1))}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
              >
                Original Staked 
              </Typography>
              <Typography variant={'body2'} className={classes.subTitle}>
                {format({ prefix: '', suffix: '' })(xWinLib.roundTo(userInfo?.amount, 2))}
              </Typography>
              <Box display="flex" alignItems="right" justifyContent={"right"}>
                <Typography
                  // color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginRight: 5 }}
                >
                  {format({prefix: " ~$", suffix: " "})(xWinLib.roundTo(userInfo?.amount * prices.xwinPriceInUSD,1))}
                </Typography>
              </Box>

            </Grid>
            <Grid item>
              <Typography
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
              >
                Bonus
              </Typography>
              <Typography variant={'body2'} className={classes.subTitle}>
                  {format({ prefix: '', suffix: '' })(xWinLib.roundTo(userInfo?.rewards, 2))}
                </Typography>
              <Box display="flex" alignItems="right" justifyContent={"right"}>
                <Typography
                  // color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginRight: 5 }}
                >
                  {format({prefix: " ~$", suffix: " "})(xWinLib.roundTo(userInfo?.rewards * prices.xwinPriceInUSD,1))}
                </Typography>
              </Box>
            </Grid>
            
          </div>
        </AccordionSummary>
        <ExpansionPanelActions>
        
        <Button 
            onClick={handleClickWithdraw} 
            color="secondary"
            variant="contained"
            fullWidth
            disabled={!canWithdrawExtend()}
            startIcon={<AddCircleIcon />}>
              {"Withdraw"}
          </Button>
        </ExpansionPanelActions>
      </Accordion>
      )
    }
  }

  const displayDepositButton = () => {

        if(!isApproval){
          return (
            <Button 
              disabled={true}
              onClick={handleClickApprove} 
              color="secondary"
              variant="contained"
              fullWidth
              startIcon={<TextRotationNoneTwoToneIcon />}>
                {"Deprecated"}
            </Button>
        )
        }else{
          return (
            <Button 
              disabled={true}
              onClick={handleClickStake} 
              color="primary"
              variant="contained"
              fullWidth
              startIcon={<AddCircleIcon />}>
                {"Deprecated"}
            </Button>
          )
        }
  }

  return (
    <Container>
      {xWinUtils.getProgress(loading)}
      <SnackbarMessage ref={refsnackbar} />
      <ReactInterval timeout={30000} enabled={true}
          callback={() => loadUserData()} />
      <Grid container spacing={3}>
        <Grid item lg={9} sm={12} xs={12}>
            {displayMyPosition()}
          {
            showNewPosition && (
              <Widget 
                spacing={0}
                className={classes.newsHeader}
                // title={getTitle()}
                disableWidgetMenu
                inheritHeight
                enableWalletDisplay
                selectedWallet={account}
                globalWeb3={web3static}
                // className={classes.widgetLayout}
                title="Auto Compound Lock"
                // disableWidgetMenu
                // enableWalletDisplay
              >
                <Grid container spacing={3}>
                  <Grid item lg={12} sm={12} xs={12} alignItems="left"> 
                    <TextField
                        type="number"
                        variant="outlined"
                        value={depositAmount}
                        fullWidth
                        onChange={handleAmtDepositChange}
                        InputProps={{
                          classes: {
                            input: classes.positiveNum,
                          },
                          style: { textAlign: 'right' },
                          startAdornment:
                          <InputAdornment position="start">
                            {xWinUtils.getIcons("xwin")}
                          </InputAdornment>,
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle max'
                                onClick={handleMaxDepositAmt}
                              > 
                              <Typography color={"primary"} variant={"caption"}>MAX</Typography>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12} alignItems="left"> 
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      className={classes.contentText}
                      style={{ marginRight: 5 }}
                      noWrap
                    >
                      {"Duration (Week)"}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12} alignItems="left"> 
                    {xWinUtils.displayToolTip(`Do you know you can enjoy 50% discount on performance fee if you stake 50k of XWIN for 1 year?`)}
                    <Slider
                      aria-label="Duration"
                      defaultValue={26}
                      valueLabelDisplay="on"
                      getAriaValueText={valuetext}
                      value={duration} 
                      step={1}
                      marks
                      min={1}
                      max={52}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={6} sm={6} xs={6} alignItems="left"> 
                    <Typography
                      color="text"
                      className={classes.contentText}
                      // size={'sm'} variant={'body1'}
                      style={{ marginRight: 5, marginLeft: 5 }}
                      noWrap
                    >
                    {xWinUtils.displayToolTip(`All the capital, staked rewards and bonus can be unlocked after the expiry date <br/>All figures are estimates provided for your convenience only, and by no means represent guaranteed returns.`)}
                      Auto APY 
                      </Typography>
                  </Grid>
                  <Grid item lg={6} sm={6} xs={6} alignItems="left"> 
                    <Typography
                      color="primary"
                      className={classes.contentText}
                      style={{ marginRight: 5, marginLeft: 5 }}
                      noWrap
                    >
                      {format({ prefix: ' ', suffix: '%' })(millify(poolInfo.mainAPR || 0, {precision: 2, lowercase: true}))}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} sm={6} xs={6} alignItems="left"> 
                  <Typography
                      color="text"
                      className={classes.contentText}
                      style={{ marginRight: 5, marginLeft: 5 }}
                      noWrap
                    >
                      {xWinUtils.displayToolTip(`The longer you lock XWIN token, the more bonus you will get in XWIN tokens. <br/>All figures are estimates provided for your convenience only, and by no means represent guaranteed returns.`)}
                      Bonus APR
                    </Typography>
                  </Grid>
                  <Grid item lg={6} sm={6} xs={6} alignItems="left"> 
                    <Typography
                      color="primary"
                      className={classes.contentText}
                      style={{ marginRight: 5, marginLeft: 5 }}
                      noWrap
                    >
                      {format({ prefix: ' ', suffix: '%' })(millify(poolInfo.bonusAPR || 0, {precision: 2, lowercase: true}))}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} sm={6} xs={6} alignItems="left"> 
                  <Typography
                      color="text"
                      className={classes.contentText}
                      style={{ marginRight: 5, marginLeft: 5 }}
                      noWrap
                    >
                      {xWinUtils.displayToolTip(`Locking $XWIN allow you to enjoy performance fee discount on all the xWIN v2 public and private vault`)}
                      Est Perf. Fee Discount
                    </Typography>
                  </Grid>
                  <Grid item lg={6} sm={6} xs={6} alignItems="left"> 
                    <Typography
                      color="primary"
                      className={classes.contentText}
                      style={{ marginRight: 5, marginLeft: 5 }}
                      noWrap
                    >
                      {format({ prefix: ' ', suffix: '%' })(xWinLib.roundTo(performanceFeeDiscount, 2))}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} sm={6} xs={6} alignItems="left"> 
                  <Typography
                      color="text"
                      className={classes.contentText}
                      style={{ marginRight: 5, marginLeft: 5 }}
                      noWrap
                    >
                      {xWinUtils.displayToolTip(`You can unlock your token after this date`)}
                      Unlocked Date
                    </Typography>
                  </Grid>
                  <Grid item lg={6} sm={6} xs={6} alignItems="left"> 
                    <Typography
                      color="primary"
                      className={classes.contentText}
                      style={{ marginRight: 5, marginLeft: 5 }}
                      noWrap
                    >
                      {moment(newEndDate).format('YYYY-MM-DD HH:mm:ss')}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} sm={6} xs={6} alignItems="left"> 
                  <Typography
                      color="text"
                      className={classes.contentText}
                      style={{ marginRight: 5, marginLeft: 5 }}
                      noWrap
                    >
                      {xWinUtils.getIcons("xwin")}
                      Total Locked
                    </Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} xs={3} alignItems="left"> 
                    <Typography
                      color="primary"
                      className={classes.contentText}
                      style={{ marginRight: 5, marginLeft: 5 }}
                      noWrap
                    >
                      {format({ prefix: ' ', suffix: '' })(xWinLib.roundTo(poolInfo.totalShares, 2))}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} xs={3} alignItems="left"> 
                    <Typography
                        colorBrightness={"hint"}
                        variant={"caption"}
                        style={{ marginRight: 5 }}
                      >
                        {format({prefix: " ~$", suffix: " "})(xWinLib.roundTo(poolInfo.totalShares * prices.xwinPriceInUSD,1))}
                      </Typography>
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12} alignItems="left"> 
                    {displayDepositButton()}
                  </Grid>
                </Grid>
              </Widget> 
  
            )
          }
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={9} sm={12} xs={12}>
          <VaultFaq faqs={faqs}/>
        </Grid>
      </Grid>
      </Container>

  );
}
