const xwinbuddystakingv2 = {
  funds: [
    {
      "address": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
      "rewardtoken": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
      "contractaddress": "0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28",
      "env" : "v2bscmain",
      "logo2" : "xwin.png",
      "logo" : "ada.png",
      "name" : "Earn ADA",
      "pid" : 0,
      "decimal" : 18,
      "symbol" : "XWIN->ADA",
      "stakeSymbol":"XWIN",
      "earnSymbol":"ADA"
      
    },
    {
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "rewardtoken": "0x55d398326f99059fF775485246999027B3197955",
      "contractaddress": "0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28",
      "env" : "v2bscmain",
      "logo2" : "xwin.png",
      "logo" : "usdt.png",
      "name" : "Earn USDT",
      "pid" : 1,
      "decimal" : 18,
      "symbol" : "XWIN->USDT",
      "stakeSymbol":"XWIN",
      "earnSymbol":"USDT"
    }
  ]
};

export default xwinbuddystakingv2;

