import dotenv from "dotenv";

// bsc mainnet : "https://bsc-dataseed1.binance.org:443", chainId: 56
// localhost : "http://localhost:8545", chainId: 1337
// bsc testnet : 'https://data-seed-prebsc-1-s1.binance.org:8545', chainId: 97
// matic mainnet : 'https://matic-mainnet.chainstacklabs.com', chainId: 137
// mumbai testnet : 'https://matic-mumbai.chainstacklabs.com', chainId: 80001

const rpc = "https://bsc-dataseed.binance.org/"
// const rpc = "http://localhost:8545"
// const rpc = "https://data-seed-prebsc-1-s1.binance.org:8545"
const chainId = 56
// const chainId = 31337
// const chainId = 97

export const systemConfig = {
  // apiUrl: "https://api.xwin.com.my", // Staging server
  chainId: chainId, // use testnet network
  rpc : rpc,
  // polygonUrl: "https://toppy-polygon.web.app",
  // binanceUrl: "https://toppynft.web.app",
  // secondPerBlock: 3,
};
