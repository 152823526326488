import React from "react";
import { useTheme } from "@material-ui/styles";
import xWinLib from '../../../xWinLib';
import appGlobalConfig from '../../../appGlobalConfig';
import ReactApexChart from "react-apexcharts";
import format from 'format-number';
import xWinUtils from '../../../xWinUtils';
import {
  Box,
} from "@material-ui/core";

// styles
import useStyles from "../styles";
import Widget from "../../../components/Widget";


export default function MyBuddyFarmValue(props) {
  var classes = useStyles();
  var theme = useTheme();
  const { farmBuddyTotalValues, farmBuddyCollections, loading, ...rest } = props;

  let seriesValue = []
  let keyValue = []
  
  farmBuddyCollections.forEach(farm => {
    if(farm.fundvalue > 0){
      keyValue.push(farm.symbol)
      let ratio = farm.fundvalue / farmBuddyTotalValues
      seriesValue.push(parseFloat(xWinLib.roundTo(ratio,2)))  
    }
  });

  const values = {
    series2: seriesValue, //[144, 55, 41, 17, 15],
  };

  const themeOptions = theme => {
    return {
      chart: {
        foreColor: '#ccc',
        toolbar: {
          show: false,
        },
      },
      labels: keyValue, 
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'right'
      },
      responsive: [
        {
          // breakpoint: 480,
          // options: {
          //   chart: {
          //     width: 200
          //   },
          //   legend: {
          //     position: "bottom"
          //   }
          // }
        }
      ],
      colors: [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.warning.main,
        theme.palette.success.light,
        theme.palette.info.main
      ],
    };
  };

  const displayChart = () => {
  
    if(seriesValue.length > 0){
      return (
        <ReactApexChart
            options={themeOptions(theme)}
            series={values.series2}
            type="donut"
            height="200"
            stroke={""}
          />
      )
    }else{
      return (
        <Box display="flex" alignItems="center" justifyContent={"center"}>
          <img style={{maxWidth:"250px"}} alt={"no data"} src={"/images/background/nodata.png"} />
        </Box>
      )
    }
  }

  return (
    <Widget
          disableWidgetMenu
          title={xWinUtils.displayBalance(loading, format({prefix: "$ ", suffix: ''})(xWinLib.roundTo(farmBuddyTotalValues,0)))}
          className={classes.card}
          bodyClass={classes.alignStandaloneElement}
          subtitle= {" worth of xWIN Staked in BuddyChef"}
        >
          {xWinUtils.getProgress(loading)}
          {displayChart()}
      
    </Widget>
  );
}
