import React from "react";
import { 
  Grid, 
  Container,
  Box
 } from "@material-ui/core";
import UnrealizedPnL from "./components/UnrealizedPnL";

export default function vaultPerformance(props) {
  const { vaultCollections, tokensMaster } = props;
  
  const displayPortfolios = () => {
    
    if(vaultCollections?.length === 0) {
      return (
        <Box display="flex" alignItems="center" justifyContent={"center"}>
          <img style={{maxWidth:"250px"}} alt={"no data"} src={"/images/background/nodata.png"} />
        </Box>
      )
    }

    return (
      vaultCollections.map(port => (
        <UnrealizedPnL port={port} tokensMaster={tokensMaster} />
        )) 
      )    
  }

  return (
    <Container>
    <Grid container spacing={3}>
      <Grid item 
        lg={9}
        sm={12}
        xl={9}
        xs={12}>
          {displayPortfolios()}
      </Grid>

    </Grid>
    </Container>
  );
}
