import React, { useState, useContext, useRef } from 'react'
import Dot from '../../components/Sidebar/components/Dot'
import xWinLib from '../../xWinLib'
import xWinUtils from '../../xWinUtils'
import format from 'format-number'
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone'
import { Typography, Button, Avatar } from '../../components/Wrappers/Wrappers'
import CancelIcon from '@material-ui/icons/Cancel'
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import { AppContext } from '../../context/AppProvider'
import { WithdrawFarm } from '../../utils/masterChefInteractor'
import { WithdrawBuddyFarm } from '../../utils/buddyChefInteractor'

import {
    Box,
    CardHeader,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    ButtonGroup,
    TextField,
    InputAdornment,
    Grid
} from '@material-ui/core'
import useStyles from './styles'

const redeemfarmV2 = ({
    userData,
    openModal,
    port,
    selectedWallet,
    parentCallback,
    reset,
    buddyfarm,
}) => {
    const { theWeb3 } = useContext(AppContext)

    const classes = useStyles()
    const [loading, setLoading] = React.useState(false)
    const [subsAmt, setsubsAmt] = useState(0)
    const [isBuddyFarm, setIsBuddyFarm] = useState(buddyfarm)

    const refsnackbar = useRef(null)
    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }

    const handleClickRedeem = async event => {
        event.preventDefault()

        if (subsAmt <= 0) {
            showSnackBar('error', 'Please input withdraw amount')
            return
        }
        await withdrawChefFarm()
        setLoading(true)
    }

    const withdrawChefFarm = async () => {
        if (isBuddyFarm) {
            WithdrawBuddyFarm(theWeb3, port.pid, selectedWallet, subsAmt)
                .then(res => {
                    console.log(res)
                    setLoading(false)
                    showSnackBar('success', res)
                    reset
                    parentCallback(true)
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                    showSnackBar('error', err)
                    // parentCallback(false);
                })
        } else {
            WithdrawFarm(theWeb3, port.pid, selectedWallet, subsAmt)
                .then(res => {
                    console.log(res)
                    setLoading(false)
                    showSnackBar('success', res)
                    reset
                    parentCallback(true)
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                    showSnackBar('error', err)
                    // parentCallback(false);
                })
        }
    }

    const handleClickClearAmt = () => event => {
        event.preventDefault()
        setsubsAmt(0)
    }

    const handleAmtChange = () => async event => {
        const val = event.target.value.replace(/\+|-/gi, '')
        if (val >= 0 && val !== undefined && val !== '') {
            setsubsAmt(val)
        } else {
            setsubsAmt('')
        }
    }

    const handleClickAmt = ratio => async event => {
        event.preventDefault()

        if (ratio == 1) {
            setsubsAmt(userData.userstakebal)
        } else {
            const redeemAmt = xWinLib.roundDown(
                ratio * userData.userstakebal,
                8
            )
            if (redeemAmt > userData.userstakebal)
                redeemAmt = userData.userstakebal
            setsubsAmt(redeemAmt)
        }
    }

    const getSubHeader = () => {
        return (
            <Typography className={classes.subTitle}>
                {format({ prefix: 'Staked ', suffix: '' })(
                    xWinLib.roundTo(userData.userstakebal, 10)
                )}
            </Typography>
        )
    }

    return (
        <Dialog
            open={openModal}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            fullScreen={false}
            //maxWidth = {'sm'}
        >
            <SnackbarMessage ref={refsnackbar} />
            <CardHeader
                action={
                    <IconButton
                        className={classes.title}
                        onClick={reset}
                        aria-label="settings"
                    >
                        <CancelIcon />
                    </IconButton>
                }
                avatar={
                    <Avatar variant="round">
                        {
                            <img
                                alt={port.symbol}
                                src={xWinLib.getIcon(
                                    port.logo2 ? port.logo2 : port.logo
                                )}
                                className={classes.avatar}
                            />
                        }
                    </Avatar>
                }
                subheader={getSubHeader()}
            />
            <DialogContent>
                {xWinUtils.getProgress(loading)}

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'right'}
                >
                    <ButtonGroup size="small" color="secondary" aria-label="">
                        <Button
                            fullWidth
                            color="secondary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.25)}
                        >
                            {'25%'}
                        </Button>
                        <Button
                            fullWidth
                            color="secondary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.5)}
                        >
                            {'50%'}
                        </Button>
                        <Button
                            className={classes.columnHide}
                            fullWidth
                            color="secondary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.75)}
                        >
                            {'75%'}
                        </Button>
                        <Button
                            fullWidth
                            color="secondary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(1)}
                        >
                            {'100%'}
                        </Button>
                        <Button
                            fullWidth
                            color="secondary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickClearAmt()}
                        >
                            {'Clear'}
                        </Button>
                    </ButtonGroup>
                </Box>
            </DialogContent>
            <DialogContent>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'center'}
                >
                    <TextField
                        type="number"
                        className={classes.text}
                        margin="dense"
                        name="weight"
                        onChange={handleAmtChange()}
                        required
                        variant="outlined"
                        value={subsAmt}
                        fullWidth
                        InputProps={{
                            classes: {
                                input: classes.negativeNum,
                            },
                            style: { textAlign: 'right' },
                            startAdornment: (
                                <InputAdornment position="start">
                                    {port?.stakeSymbol}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogContent>
            <Grid container spacing={1}>
              <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                <Typography
                    color="text"
                    colorBrightness={'hint'}
                    variant={'caption'}
                    style={{ marginRight: 5 }}
                    // noWrap
                >
                    {'Pending Rewards'}
                </Typography>
              </Grid>
              <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={'right'}
                >
                  <img src={xWinLib.getIcon(port?.earnSymbol?.toLowerCase()+".png")} alt={port?.earnSymbol} className={classes.avatarSmall} />
                  <Typography
                      variant={'body2'}
                      className={classes.subTitle}
                      style={{ marginLeft: 5 }}
                  >
                    {format({ prefix: ' ', suffix: '' })(
                                xWinLib.roundTo(userData.pendingXwin, 5)
                            )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
            <DialogActions>
                <ButtonGroup
                    size="small"
                    color="primary"
                    aria-label="large outlined primary button group"
                >
                    <Button
                        // disabled={!isApproval}
                        onClick={handleClickRedeem}
                        color="secondary"
                        variant="contained"
                        startIcon={<AddCircleTwoToneIcon />}
                    >
                        {'Withdraw'}
                    </Button>
                </ButtonGroup>
            </DialogActions>
        </Dialog>
    )
}

export default redeemfarmV2
