const assetAllocationFAQ = {
	faqs: [
	{
		symbol: "assetallocation", 
		env: "v2bscmain", 
		question: 'Introduce xWIN Robo Advisor',
		defaultExpanded : true,
		desc: '<div style=\"margin-left: auto; margin-right: auto;\" data-ephox-embed-iri=\"https://xwinfinance.s3.amazonaws.com/XWIN+ROBO.mp4#t=2\"><video style=\"width: 100%;\" controls=\"controls\" preload=\"metadata\">   <source src=\"https://xwinfinance.s3.amazonaws.com/XWIN+ROBO.mp4#t=2\" type=\"video/mp4\">   Your browser does not support the video element. </video></div><div style=\"width: 100%; display: flex; flex-wrap: wrap;\"><div style=\"flex: 1 1 0%; text-align: justify;\">&nbsp;</div><div>&nbsp;</div></div>',
	},
	  {
		symbol: "assetallocation", 
		env: "v2bscmain", 
		question: 'What the Weight Average SR?',
		desc: 'Weight Average SR asset allocation are sharpe ratio weighted average approach on risky assets after stablecoin staking weight is determined. The higher the sharpe ratio asset will be having larger allocation in the portfolio. <br/> Compared to Mean Variance Optimization, weighted averate SR tends to allocate more in USDT staking even though the risk parameter is higher. Therefore, volatility tends to be lower',
	  },
	  {
		symbol: "assetallocation", 
		env: "v2bscmain", 
		question: 'What the Mean-Variance Optimization?',
		desc: 'Mean-Variance Optimization uses mean-variance analysis, where it is the process of weighing risk, expressed as variance, against expected return. <br/> Investors weigh how much risk they are willing to take on in exchange for different levels of reward. Mean-variance analysis allows investors to find the biggest reward at a given level of risk or the least risk at a given level of return.',
	  },
	  {
		symbol: "assetallocation", 
		env: "v2bscmain", 
		question: 'Are there any other asset allocation approach provided?',
		desc: 'We are currently provided only Weight Average SR and Mean-Variance Optimization. Our team is working toward introducing more approach such as Maximum Sharpe Ratio, Minimum Variance Portfolio or Inverse Variance Weighted Portfolio and etc etc in near future',
	  },
	  {
		symbol: "assetallocation", 
		env: "v2bscmain", 
		question: 'What is the checkbox of "Use xWIN Single Asset"',
		desc: 'You can turn this option on if you want to replace the individual native tokens in your portfolio with xWIN Single Asset. We provide multiple single asset with staking capabiltiy. It means you are getting the token while the token earn you some interest. Behind the scene the single asset will stake the tokens into lending protocol. <br/> Please note there is higher gas fee when you are using xWIN single asset in your portfolio',
	  },
	  {
		symbol: "assetallocation", 
		env: "v2bscmain", 
		question: 'Can I change the weight of the optimal portfolio when I building my own portfolio?',
		desc: 'After running the optimzer with the optimal weight was recommended, you can change, add or delete any tokens before you finally build your own portfolio by signing in your wallet. <br/> Please note this indirectly will change the sharpe ratio of the initial result recommended by the optimzer.',
	  },
	  {
		symbol: "assetallocation", 
		env: "v2bscmain", 
		question: 'Can I change my portfolio weight?',
		desc: 'Yes. You can change your portfolio weight by runnig the rebalance. Our statistic model will get updated every month. You can rerun the engine to get the optimal weight and rebalance it whenever necessary.',
	  },
	  {
		symbol: "assetallocation", 
		env: "v2bscmain", 
		question: 'Do I need to pay everytime when I rebalance my portfolio',
		desc: 'No. You are free to use the optimizer and rebalance at no cost. However, there is a initial small amount of cost at 50 XWIN when you create your own vault by deploying your smart contract in xWIN Finance platform.',
	  },
	  {
		symbol: "assetallocation", 
		env: "v2bscmain", 
		question: 'How does the risk preference impact the allocation?',
		desc: 'The risk preference is weight from 10 to 100. If you are selecting 10, it mean you are more risk averse and hence it would allocate more in stablecoin staking to earn the stable income. <br/> On the other hand, 100 would allow the engine to allocate more on the higher risk asset based on the risk-adjusted returns',
	  },
	  {
		symbol: "assetallocation", 
		env: "v2bscmain", 
		question: 'How does the age impact the allocation?',
		desc: 'In general, a 21 years old investor could afford more risk than a 60 years old. Hence the engine would adjust the risk tolerance accordingly',
	  },
	  {
		symbol: "assetallocation", 
		env: "v2bscmain", 
		question: 'Do you know that...?',
		desc: 'If two different tokens have the same expected return, but one has lower variance, the one with lower variance is preferred.<br/> Similarly, if two different tokens have approximately the same variance, the one with the higher return is preferred',
	  }
	],
  };
  
  export default assetAllocationFAQ;
  
  