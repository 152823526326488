import React, {  useEffect  } from "react";
import Slider from "react-slick";
import { Container, Grid,  } from '@material-ui/core';
import 'react-multi-carousel/lib/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './videos.css';
import AOS from 'aos';
import '../../home.css';
import {withRouter} from 'react-router-dom'

const Videos = props => {

	useEffect(() => {
		AOS.init({
			disable: 'mobile'
		  });
	});

	return (
		<section className="section-spacing" id="videos">
			<Container maxWidth="lg" className="main-container text-white">
				<Grid container spacing={3}>
					
				</Grid>
			</Container>
		</section>
	);
}
export default withRouter(Videos)
