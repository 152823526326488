import React, { useContext, useEffect, useRef, useState  } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage';
import appGlobalConfig from '../../appGlobalConfig';
import StrategyDetails from "./components/StrategyDetails";
import SingleAsset from "./components/SingleAsset";
import AutoTradingStrategy from "./components/AutoTradingStrategy";
import { AppContext } from '../../context/AppProvider';

import {
  Box,
  Container,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  Help as HelpIcon,
  Star as StarIcon,
} from '@material-ui/icons';

// styles
import useStyles from './styles';

// components
import Widget from '../../components/Widget/Widget';


const Strategies = (props) => {
  const {tokensMaster, relatedStrategyData } = props;
  const classes = useStyles();
  const [contractChoice, setContractChoice] = React.useState(appGlobalConfig.mainnetAddressZero);
  const [strategyTypes, setStrategyTypes] = React.useState([]);
  const [template, setTemplate] = React.useState("");
  
  const {
    isConnected, ETHBalance, account, theWeb3, web3static
  } = useContext(AppContext);

  const refsnackbar = useRef(null);
  
  useEffect(() => {
    init()
  }, [relatedStrategyData])


  const init = async () => {

    if (web3static === undefined) return;
    setStrategyTypes(relatedStrategyData);
    if(relatedStrategyData.length > 0)
    {
      setContractChoice(relatedStrategyData[0].address)
    } 
  };


  
  const handleChoice = (event) => {
    setContractChoice(event.target.value);
  };


  const displayStrategies = () => {

    if(relatedStrategyData == undefined) return;
    let strategy = relatedStrategyData.find(x=>x.address === contractChoice)
    if(strategy === undefined) return;

    if(strategy.template.toLowerCase() === "autotrading")
    {
      return (
        <AutoTradingStrategy 
          contractaddress = {contractChoice}
          strategy={strategy} 
          tokensMaster={tokensMaster} />
      )    
    }  
    if(strategy.template.toLowerCase() === "dca" || strategy.template?.toLowerCase() === "alpha")
    {
      return (
        <StrategyDetails strategy={strategy} tokensMaster={tokensMaster} />
      )
    }else if(strategy.template.toLowerCase() === "single")
    {
      return (
        <SingleAsset 
          singleaddress = {contractChoice}
          underlyingaddress = {strategy.underlying}
          tokensMaster={tokensMaster}
          />
      )

    }
  }

  const displaySelect = () => {

    strategyTypes.forEach(t => {
      <MenuItem value={t.address}>{t.name}</MenuItem>
    });
              
  }

  return (
    <Container>
      <SnackbarMessage ref={refsnackbar} />
      <Widget
        className={classes.widgetLayout}
        title=""
        disableWidgetMenu
        // enableWalletDisplay
        // selectedWallet={account}
      >
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xs={12}>
            <Box display="flex" alignItems="center" justifyContent="left">
              Select Strategy: &nbsp;&nbsp;
              <Select
                classes={{ root: classes.selectPadding }}
                id="contractChoice"
                value={contractChoice}
                onChange={handleChoice}
                label="contractChoice"
              >
               {strategyTypes?.map((type) => {
                return (
                  <MenuItem value={type.address}>{type.name}</MenuItem>
                  )
                })}
            </Select>
            </Box>
            <br/>
            <Box display="flex" alignItems="center" justifyContent="left">
              {displayStrategies()}
            </Box>
              
          </Grid>
        </Grid>
      </Widget>
    </Container>
  );
};


export default Strategies;
