const buddychefFAQ = {
	faqs: [
	  {
		symbol: "buddychef", 
		env: "v2bscmain", 
		question: 'What is xWIN Buddy Farm?',
		defaultExpanded : true,
		desc: 'This is one of the feature allow xWIN team to collaborate with any other projects. In Buddy farm, you stake XWIN tokens and earn our partners tokens',
	  },
	  {
		symbol: "buddychef", 
		env: "v2bscmain", 
		question: 'How long will be the staking period last for?',
		desc: 'Each of the buddy pool are setup the period accordingly. This is upon the agreement between xWIN team and our partners. It could be range from 14 days to 30 days. Or 3 months to 6 months. It is flexible!',
	  },
	  {
		symbol: "buddychef", 
		env: "v2bscmain", 
		question: 'Any Fee Charge in the xWIN Buddy Farm?',
		desc: 'There will be 5% deducting from your rewards proceeds as part of the incentive',
	  },
	  {
		symbol: "buddychef", 
		env: "v2bscmain", 
		question: 'How can I participate in this xWIN Buddy Farm?',
		desc: 'Please contact xWIN Team at admin@xwin.finance',
	  }
	],
  };
  
  export default buddychefFAQ;
  
  