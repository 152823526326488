import React, { useState, useEffect, useContext  } from "react";
import TradinglistV2 from "./components/TradinglistV2";
import {AppContext}  from '../../context/AppProvider';
import useStyles from "./styles";
import { MoreVert as MoreIcon, Search as SearchIcon, CastConnected as CastConnectedIcon } from "@material-ui/icons";
import { Typography } from "../../components/Wrappers/Wrappers";

import {
  Grid,
  Container,
  TextField as Input,
  InputAdornment,
  Box,
  IconButton,
} from "@material-ui/core";

// components
import Widget from "../../components/Widget/Widget";
import { getLatestPrivateFunds, getPrivateFunds, getPrivateFundsBySearchKey } from "../../utils/moralisInteractor";
import appGlobalConfig from "../../appGlobalConfig";
import VaultFaq from '../vaultfaq/VaultFaq'
import vaultfaqconfig from '../vaultfaq/faq/vaultFAQ'
import xWinUtils from "../../xWinUtils";

export default function TradingsV2(props) {
  const classes = useStyles();
  const { portfolios, tokensMaster, fundtype } = props;
  const [filterPortfolios, setFilterPortfolios] = useState([])
  const [filterPortfoliosByCategory, setFilterPortfoliosByCategory] = useState([])
  const { ETHBalance, isConnected, account, theWeb3, web3static, globalProvider, refreshAccount } = useContext(AppContext);
  const [loading, setLoading] = React.useState(false);
  const [faqs, setFaqs] = useState([])
  const [searchKey, setSearchKey] = React.useState("");
  
  useEffect(() => {
    loadWeb3()
  }, [portfolios, fundtype, account]);

  const loadWeb3 = async () => {

    setLoading(true)
    let filterport = []
    
    if(fundtype === "v2private" && account !== "---"){
      filterport = await getPrivateFunds(account)
    }else if(fundtype === "v2allprivate"){
      filterport = await getLatestPrivateFunds()
    }else{
      filterport = portfolios.filter(x=>x.env.toLowerCase() === appGlobalConfig.env && x.fundtype === fundtype)
    }
    
    setFilterPortfolios(filterport)
    setFilterPortfoliosByCategory(filterport)

    // let fqs = vaultfaqconfig.faqs.filter(x=>x.env === appGlobalConfig.env && x.symbol === fundtype);
    let fqs = vaultfaqconfig.faqs.filter(x=>x.symbol === fundtype);
    setFaqs(fqs);

    setLoading(false)
  }
  
  const displayPortfolios = () => {
    
    if(loading) return ""
    if(filterPortfoliosByCategory === undefined) return ""

    let funds = filterPortfoliosByCategory

    if(funds.length > 0){
      return (
        funds.map(port => (
          <Grid item lg={6} sm={12} xs={12} 
          className={classes.hoverHighlight}
          >
          
             <TradinglistV2 
              showBalWhenSmall={false}
              port={port}
              portfolios={funds}
              tokensMaster={tokensMaster}
              globalWeb3={theWeb3}
              web3static={web3static}
              selectedWallet={account}
              fundtype={fundtype}
              ETHBalance={ETHBalance}
              refreshAccount={refreshAccount}
              /> 
              
          </Grid>
          )) 
      )     
    }else{
      return (
        <Grid item lg={12} sm={12} xs={12} 
        // className={classes.hoverHighlight}
        >
          <Box display="flex" alignItems="center" justifyContent={"center"}>
            <img style={{maxWidth:"250px"}} alt={"no data"} src={"/images/background/nodata.png"} />
          </Box>
        </Grid>
      )
    }
    
    
  }

  const getTitle = () => {

    let name = ""
    if(fundtype === "v2index") name = "Token Index Vault"
    else if(fundtype === "v2trading") name = "Auto Trading Vault"
    else if(fundtype === "v2tradingmanual") name = "Manual Trading Vault"
    else if(fundtype === "v2single") name = "Single Asset Vault (Buy / Sell)"
    else if(fundtype === "v2singlebase") name = "Single Asset Vault (Stake and Earn)"
    else if(fundtype === "v2private") name = "My Private Vaults"
    else if(fundtype === "v2allprivate") name = "Recent Created Vaults"
    else if(fundtype === "v2stablealpha") name = "Stable Alpha"

    return name     
  }

  const handleOnchange = async event => {
    
    event.preventDefault();

    const value = event.target.value;
    let filterport = filterPortfolios.filter(x => x.symbol.toLowerCase().includes(value.toLowerCase()))
    
    if(filterport.length === 0){
      filterport = filterPortfolios.filter(x => x.name.toLowerCase().includes(value.toLowerCase()))
    }
    setFilterPortfoliosByCategory(filterport)      
  }

  const handleOnchangeSearchText = async event => {
    
    event.preventDefault();
    const value = event.target.value;
    setSearchKey(value)
    if(value === ""){
      setFilterPortfoliosByCategory(filterPortfolios)      
    }
    let filterport = filterPortfolios.filter(x => x.symbol.toLowerCase().includes(value.toLowerCase()))
    setFilterPortfoliosByCategory(filterport)      
  }

  const handleOnchangeSearch = async event => {
    event.preventDefault();

    setLoading(true)
    let filterport = await getPrivateFundsBySearchKey(searchKey)
    setFilterPortfoliosByCategory(filterport)  
    setLoading(false)

  }

  const handleOnchangeClear = async event => {
    event.preventDefault();
    setSearchKey("")
    setFilterPortfoliosByCategory(filterPortfolios) 
  }

  const displaySearchBar = () => {

    if(fundtype === "v2allprivate")
    {
      return (
        <Input
          placeholder="vault name"
          id="search-field"
          fullWidth
          value={searchKey}
          className={classes.textField}
          onChange={handleOnchangeSearchText}
          margin="dense"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle search'
                  onClick={handleOnchangeSearch}
                 > 
                 <Typography color={"primary"} variant={"caption"}>Search</Typography>
                </IconButton>
                <IconButton
                  aria-label='toggle clear'
                  onClick={handleOnchangeClear}
                 > 
                 <Typography color={"primary"} variant={"caption"}>Clear</Typography>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )

    }else{
      return (
        <Input
          placeholder="symbol or vault name"
          id="search-field"
          fullWidth
          onChange={handleOnchange}
          className={classes.textField}
          margin="dense"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            )
          }}
        />
      )
    }
    
  }

  return (
    <Container>
      {xWinUtils.getProgress(loading)}
      <Grid container spacing={2}>
      <Grid item lg={12} sm={12} xs={12} className={classes.hoverHighlight2}>
          <Widget
            spacing={0}
            className={classes.newsHeader}
            title={getTitle()}
            disableWidgetMenu
            inheritHeight
            enableWalletDisplay
            selectedWallet={account}
            globalWeb3={web3static}
          >
          {displaySearchBar()}
          </Widget>
        </Grid>
        <Grid item lg={12} sm={12} xs={12} className={classes.hoverHighlight2}>
              <Grid container spacing={2}>
                {displayPortfolios()}
              </Grid>
        </Grid>
      </Grid>
      <VaultFaq faqs={faqs}/>
    </Container>
  );
}
