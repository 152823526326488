import React from "react";
import { useTheme } from "@material-ui/styles";
import ReactApexChart from "react-apexcharts";
import xWinLib from '../../../xWinLib';
import format from 'format-number';
// components
import Widget from "../../../components/Widget";
import xWinUtils from '../../../xWinUtils';

import {
  Box,
} from "@material-ui/core";

export default function MyVaultValue(props) {
  const theme = useTheme();
  const { vaultCollections, vaultTotalValues, overallStat, loading, ...rest } = props;
  
  let myPayLabel = []
  let myBalDataValueOnly = []

  vaultCollections.forEach(v => {
    myPayLabel.push(v.symbol)
    myBalDataValueOnly.push(xWinLib.roundTo(v.fundvalue,2))
  });
  
  const values = {
    series: [
      {
        data: myBalDataValueOnly
      }
    ],
  };
  
  // local
  const themeOptions = theme => {
    return {
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories: myPayLabel
      },
      stroke: {
        curve: "smooth"
      },
      legend: {
        display: true,
        position: 'bottom'
      },
      chart: {
        foreColor: '#ccc',
        stacked: false,
        toolbar: {
          show: false
        }
      },
      tooltip: {
        theme: true,
      },
      colors: [theme.palette.secondary.main],
      options: {
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top"
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"]
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"]
        },
  
        xaxis: {
          categories: [2013, 2014, 2015, 2016, 2017, 2018, 2019]
        },
        colors: [theme.palette.primary.main, theme.palette.success.light]
      },
    };
  };

  const displayChart = () => {
  
    if(myBalDataValueOnly.length > 0){
      return (
        <ReactApexChart
          options={themeOptions(theme)}
          series={values?.series}
          type="bar"
          height="200"
        />
      )
    }else{
      return (
        <Box display="flex" alignItems="center" justifyContent={"center"}>
          <img style={{maxWidth:"250px"}} alt={"no data"} src={"/images/background/nodata.png"} />
        </Box>
      )
    }
  }
 
  
  return (
    <Widget 
      title={xWinUtils.displayBalance(loading, format({prefix: "$ ", suffix: ''})(xWinLib.roundTo(vaultTotalValues,0)))}
      disableWidgetMenu
      noBodyPadding
      subtitle= {"Vault Breakdown"}>
      {xWinUtils.getProgress(loading)}
      {displayChart()}
      
    </Widget>

  );
}
