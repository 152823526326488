import React, { useState, useEffect, useContext  } from "react";
import { 
  Grid,
  Box,
  CardHeader,
  Card,
  CardContent,
  Table,
  TableRow,
  TableCell,
 } from '@material-ui/core';
import useStyles from "../styles";
import xWinLib from '../../../xWinLib';
import xWinUtils from '../../../xWinUtils';
import format from 'format-number';
import Dot from "../../../components/Sidebar/components/Dot";
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { Typography, Link, Avatar } from "../../../components/Wrappers/Wrappers";


const UnrealizedPnL = (props) => {
  const classes = useStyles();
  const { port, tokensMaster } = props;
  const [pNL, setPnL] = useState(0)

  useEffect(() => {
    init()
  }, [port]);

  const init = async () => {
  
    let amount = (Number(port.unitPrice) - Number(port.avgPrice)) * Number(port.share)
    setPnL(amount)
  }

  return (
    <>
    <br/>
    <Card>
      <Link 
        underline="none"
        component={RouterLink}
        to={{
        pathname: "/app/funddetailV2/"+ port.contractaddress,
        state: {
          port: port,
          portparam: port,
          tokensMaster: tokensMaster,
          fundtypeparam: port.fundtype
        }
      }}>
      <CardHeader
        title={<Typography className={classes.primaryHeading} >{port.name}</Typography> }
        avatar={
          <Avatar
            variant={"rounded"}
            className={classes.avatar}
            src={xWinLib.getIcon(port.logo)}
          />
        }
        subheaderTypographyProps={{variant:'body2' }}
      />
      </Link>
      <CardContent>
        <Grid item>
          <Table size="small">
                  <TableRow className={classes.columnHide}>
                      <TableCell>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          Average Price:
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="success" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {xWinUtils.displayBalance(false, format({prefix: "", suffix: ""})(xWinLib.roundTo(port.avgPrice,4)))}                    
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          Unit Price:
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="success" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {xWinUtils.displayBalance(false, format({prefix: "", suffix: ""})(xWinLib.roundTo(port.unitPrice,4)))}                    
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          Shares:
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="success" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {xWinUtils.displayBalance(false, format({prefix: "", suffix: ""})(xWinLib.roundTo(port.share,4)))}                    
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          Est. PnL:
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color={pNL >= 0 ? "success" : "secondary"} />
                          <Typography
                            className={pNL >= 0? classes.positiveNum: classes.negativeNum}
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                          {xWinUtils.displayBalance(false, format({prefix: "", suffix: ""})(xWinLib.roundTo(pNL, 6)))}                    
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          PnL (%):
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color={pNL >= 0 ? "success" : "secondary"} />
                          <Typography
                            className={pNL >= 0? classes.positiveNum: classes.negativeNum}
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                          {xWinUtils.displayBalance(false, format({prefix: "", suffix: "%"})(xWinLib.roundTo(pNL/(port.avgPrice * port.share) * 100 , 2)))}                    
                          </Typography>
                        </Box>
                      </TableCell>
                  </TableRow>
                  <TableRow className={classes.columnShow}>
                      <TableCell>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          Average Price:
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="success" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {xWinUtils.displayBalance(false, format({prefix: "", suffix: ""})(xWinLib.roundTo(port.avgPrice,4)))}                    
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          Est. PnL:
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color={pNL >= 0 ? "success" : "secondary"} />
                          <Typography
                            className={pNL >= 0? classes.positiveNum: classes.negativeNum}
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                          {xWinUtils.displayBalance(false, format({prefix: "", suffix: ""})(xWinLib.roundTo(pNL, 6)))}                    
                          </Typography>
                        </Box>
                      </TableCell>
                  </TableRow>
                  <TableRow className={classes.columnShow}>
                      
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          Unit Price:
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="success" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {xWinUtils.displayBalance(false, format({prefix: "", suffix: ""})(xWinLib.roundTo(port.unitPrice,4)))}                    
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          Shares:
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="success" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {xWinUtils.displayBalance(false, format({prefix: "", suffix: ""})(xWinLib.roundTo(port.share,4)))}                    
                          </Typography>
                        </Box>
                      </TableCell>
                      
                  </TableRow>
                
                </Table>
        </Grid>
        
      </CardContent>
    </Card>
    </>
  );
};

export default withRouter(UnrealizedPnL);
