import React, {  useEffect  } from "react";
// import { Link } from "react-router-dom";
import { Container, Grid, Box } from '@material-ui/core';
import './Footer.css';
import AOS from 'aos';

function Footer() {
	useEffect(() => {
		AOS.init({
			disable: 'mobile'
		  });
	});
	return (
		<footer id="footer">
			<Container maxWidth="lg">
				<Grid container className="container" spacing={0}>
					<Box data-aos="fade-down" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" className="footer-top-text">
						<p>This project is in Beta. Use at your own risk.</p>
					</Box>
					<Grid item xs={12} sm={12}>
						<h4 data-aos="fade-down" data-aos-offset="80" data-aos-duration="600" data-aos-delay="200" >Join us</h4>
						<Box display="flex" justifyContent="center" component="ul" className="social-links list-unstyled">
							<Box component="li" className="plane"><a href="https://www.t.me/xwinfinance" rel="noreferrer" target="_blank" >&nbsp;</a></Box>
							<Box component="li" className="facebook"><a href="https://www.facebook.com/toppy.xwin" rel="noreferrer" target="_blank" >&nbsp;</a></Box>
							<Box component="li" className="twitter"><a href="https://twitter.com/xwinfinance" rel="noreferrer" target="_blank" >&nbsp;</a></Box>
							<Box component="li" className="mail"><a href="https://xwin.medium.com/" rel="noreferrer" target="_blank" >&nbsp;</a></Box>
							<Box component="li" className="fa fa-git"><a href="https://github.com/xwinfinance/xWinBSC" rel="noreferrer" target="_blank" >&nbsp;</a></Box>
							
						</Box>
					</Grid>
				</Grid>
				<Box className="footer-copyright-text">
					Powered by &copy; xWIN Technology Sdn. Bhd {new Date().getFullYear()}
				</Box>
				<br/>
			</Container>
		</footer>
	);
}
export default Footer;