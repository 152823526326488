import React, { useState, useEffect, useRef, useContext } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import format from 'format-number';
import "react-toastify/dist/ReactToastify.css";
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import { CreateFundAsync, getLatestFundID, IsWhiteListed } from "../../utils/fundV2FactoryInteractor";
import { Typography, Button } from "../../components/Wrappers/Wrappers";
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { RebalanceAsync } from "../../utils/fundV2Interactor";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import HomeIcon from '@material-ui/icons/Home';
import EditIcon from '@material-ui/icons/Edit';
import TextRotationNoneTwoToneIcon from '@material-ui/icons/TextRotationNoneTwoTone';

import {
  TableRow,
  Table,
  TableCell,
  TableBody,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  ButtonGroup,
  TextField,
  MenuItem,
  IconButton,
  InputAdornment,
  Select,
  TableHead,
  Box,
  Grid
} from "@material-ui/core";
// components
import useStyles from "./styles";
import {AppContext}  from '../../context/AppProvider';
import { useTheme } from "@material-ui/styles";
import { approveContract, IsTokenApproved } from "../../utils/erc20Interactor";
import appGlobalConfig from "../../appGlobalConfig";
import { getPrivateFunds } from "../../utils/moralisInteractor";
import VaultFaq from '../vaultfaq/VaultFaq'
import vaultfaqconfig from '../vaultfaq/faq/createFundFAQ'


const CreatePortV2 = ({ 
  openModal,
  allocatedports,
  tokensMaster,
  returns,
  proxyAssets,
  reset
}) => {
  var theme = useTheme();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [positions, setPositions] = useState([])
  const [newPort, setNewPort] = React.useState('new');
  const [newPortname, setNewPortname] = React.useState("");
  const [newPortSymbol, setNewPortSymbol] = React.useState("");
  const [selectedPortAddress, setselectedPortAddress] = React.useState("");
  const [selectedPortName, setselectedPortName] = React.useState("");
  const [isApproval, setIsApproval] = useState(false);
  const [privateVaults, setPrivateVaults] = React.useState([]);
  const [originalport, setOriginalport] = React.useState(allocatedports);
  const [errMsg, setErrmsg] = React.useState("");
  const [faqs, setFaqs] = useState([])
  const [whiteListed, setWhiteListed] = React.useState(false);
  const [statistic, setStatistic] = useState({
    portReturns : 0,
    portVol : 0,
    portSR : 0
  })
  

  const [ticker, setTicker] = useState({
    symbol: '',
    weight: 100
  })

  const { account, theWeb3, web3static, networkName, XWINBalance } = useContext(AppContext);
  
  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }
  
  useEffect(() => {
    init()
  }, [allocatedports, account])

  const init = async () => {
    if(allocatedports === undefined) return;
    
    setOriginalport(allocatedports);
    let p = setupPosition();
    setPositions(p);
    calcStatistic(p)
    let filterport = await getPrivateFunds(account)
    setPrivateVaults(filterport)
    setselectedPortAddress(filterport[0]?.contractaddress);
    setselectedPortName(filterport[0]?.name)

    if(account !== "---"){
      const isApp = await IsTokenApproved(theWeb3, appGlobalConfig.mainnetCreateFundCost, account, appGlobalConfig.mainnetXWIN, appGlobalConfig.mainnetFundFactory)
      setIsApproval(isApp)   
      let isWL = await IsWhiteListed(web3static, account)
      setWhiteListed(isWL)
    }
    // let fqs = vaultfaqconfig.faqs.filter(x=>x.env === appGlobalConfig.env);
    setFaqs(vaultfaqconfig.faqs);
    
    let lastWeight = getLastPositionWeight(p)
    setTicker({
      ...ticker,
      "weight": lastWeight
    })

  }

  const calcStatistic = (positions) => {

    let totalReturns = 0
    let totalVol = 0
    let totalSR = 0
    // console.log(returns)
    // console.log(positions)
      
    positions.forEach(p => {
      let r = returns.find(x=>x.asset.toLowerCase() === p.taddress.toLowerCase())
      if(r === undefined){
        let proxy = proxyAssets.find(x=>x.contractaddress.toLowerCase() === p.taddress.toLowerCase())
        console.log(proxy)
        if(proxy !== undefined){
          r = returns.find(x=>x.asset.toLowerCase() === proxy.underlying.toLowerCase())
        } 
      }
      // console.log(r)
      if(r){
        totalReturns += p.weight * r.return
        totalVol += p.weight * r.volatility
        totalSR += p.weight * r.sharpeRatio / 100
      }  
    });
    
    let portReturns = Math.pow(1 + totalReturns/100, 12) - 1
    let portVol = totalVol / 100 * Math.sqrt(12);

    // console.log(portReturns, portVol, totalReturns, totalVol, totalSR)
    setStatistic({
      portReturns : portReturns * 100,
      portVol : portVol * 100,
      portSR : portReturns / portVol  * 100
    })
    
  }

  const setupPosition = () => {

    let p = []
    let sum = 0;
    let count = 0;
    allocatedports.forEach(port => {
      let wgt = xWinLib.roundTo(port.ratio,2)
      
      if(count == allocatedports.length -1){
        let remain = xWinLib.roundTo(100 - sum,2);
        wgt = Number(remain);
      }
      p.push({
        symbol: port.name,
        weight: wgt, 
        taddress: port.contractaddress
      })
      sum = sum + Number(wgt);
      count++;
    });
    return p;
  }

  const getLastPositionWeight = (pos) => {
    
    let lastWeight = 0;
    let total = 0
    pos.forEach(p => {
      total = total + parseFloat(p.weight)
    })
    let currentTotal = xWinLib.roundTo(total, 3);
    if(currentTotal < 100){
      lastWeight = xWinLib.roundTo(100 - Number(currentTotal),2)
      // console.log(lastWeight)
    }
    return lastWeight
  }

  const alignPosition = () => {

    let currentTotal = getTotalWeight()
    if(currentTotal !== 100){
      let lastitem = positions.slice(-1).pop()
      let lastWeight = xWinLib.roundTo(100 - Number(currentTotal) + Number(lastitem.weight),2)
      const filteredItems = positions.filter(t => t.symbol !== lastitem.symbol)
      filteredItems.push({
        symbol: lastitem.symbol,
        weight: lastWeight,
        taddress: lastitem.taddress,
      })
      setPositions(filteredItems)
    }
  }

  const handleClickApprove = async event => {
    event.preventDefault();
    
    setLoading(true)
    approveContract(theWeb3, appGlobalConfig.mainnetXWIN, appGlobalConfig.mainnetFundFactory, account)
      .then(res => {
        setLoading(false)
        setIsApproval(true)
      }
    )
    .catch(err => {
      setLoading(false)
      console.log(err)
      setIsApproval(false)
    })
  };

  const handleClickOriginal = async event => {
    event.preventDefault();
    let p = setupPosition();
    setPositions(p);
  };

  const handleClickAlign = async event => {
    event.preventDefault();

    alignPosition();
  };

  const getTotalWeight = () => {
    let total = 0
    positions.forEach(p => {
      total = total + parseFloat(p.weight)
    })
    return xWinLib.roundTo(total, 3);
  }

  const handleNameChange = () => event => {
    event.preventDefault();
    setNewPortname(event.target.value)
  }
  
  const handleSymbolChange = () => event => {
    event.preventDefault();
    setNewPortSymbol(event.target.value)
  }
  
  const handleSelectedPortChange = (event) => {
    setselectedPortAddress(event.target.value)
    setselectedPortName(getSelectedFundName(event.target.value))
  };
  
  
  const getSetTarget = () => {

    return (
      <DialogContent> 
            <Grid item lg={12} sm={12} xs={12}>
            <Box display="flex" alignItems="center" justifyContent={"center"}>
              <ToggleButtonGroup
                color="primary"
                value={newPort}
                exclusive
                size="small" 
                onChange={handleNewPort}
                aria-label="NewPort"
              >
                <ToggleButton value="new" style={{outlineColor: theme.palette.primary.main, outlineWidth: '1px', outlineStyle: 'solid', margin: '2px'}}>New</ToggleButton>
                <ToggleButton value="update" style={{outlineColor: theme.palette.primary.main, outlineWidth: '1px', outlineStyle: 'solid', margin: '2px'}}>Rebalance</ToggleButton>
              </ToggleButtonGroup>
              </Box>
            </Grid>
            <br/>
            {
            newPort === "new" && (
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12} xs={12} className={classes.hoverHighlight2}>
                  <div className="mb-1">
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                      noWrap
                    >
                      {"Portfolio Name"}
                    </Typography>
                  </div>
                  <Box display="flex" alignItems="center" justifyContent={"left"}>
                    <TextField
                        placeholder="i.e. Long Only Vault"
                        size="small"
                        margin="dense"
                        name="portname"
                        onChange={handleNameChange()}
                        required
                        variant="outlined"
                        value={newPortname}
                        fullWidth
                      />
                  </Box>
                </Grid>
                <Grid item lg={6} sm={12} xs={12} className={classes.hoverHighlight2}>
                  <div className="mb-1">
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                      noWrap
                    >
                      {"Symbol"}
                    </Typography>
                  </div>
                  <Box display="flex" alignItems="center" justifyContent={"left"}>
                    <TextField
                        size="small"
                        margin="dense"
                        name="portsymbol"
                        placeholder="i.e. LOV"
                        onChange={handleSymbolChange()}
                        required
                        variant="outlined"
                        value={newPortSymbol}
                        fullWidth
                      />
                  </Box>
                </Grid>
              </Grid>
            )
          }
          {
            newPort === "update" && (
               
              <Grid item lg={6} sm={12} xs={12} className={classes.hoverHighlight2}>
                  <Typography
                    color="text"
                    colorBrightness={"hint"}
                    variant={"caption"}
                    style={{ marginRight: 5 }}
                    noWrap
                  >
                    {"Existing Portfolio"}
                  </Typography>
                  <Box display="flex" alignItems="center" justifyContent={"left"}>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="selectedPortAddress"
                      value={selectedPortAddress}
                      label="selectedPortAddress"
                      onChange={handleSelectedPortChange}
                    >
                      {privateVaults.map((p) => (
                        <MenuItem value={p.contractaddress}>{p.name}</MenuItem>
                      ))}
                    </Select>
                  </Box>
              </Grid> 
              
            )
          }
          {/* <br/>
          <Grid item lg={6} sm={12} xs={12}>
            <Table size="small">
              <TableHead>
              <TableRow>
                    <TableCell>
                      Expected Returns
                    </TableCell>
                    <TableCell>
                      Volatility
                    </TableCell>
                    <TableCell className={classes.tablecell}>
                      Sharpe Ratio
                    </TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cellHideSmall}>
                    {xWinUtils.displayToolTip(`The returns is based on the history returns for the past 1.5 years`)}
                    {format({prefix: '', suffix: '%'})(xWinLib.roundTo(statistic.portReturns,2))}
                  </TableCell>
                  <TableCell>
                    {format({prefix: '', suffix: '%'})(xWinLib.roundTo(statistic.portVol,2))}
                  </TableCell>
                  <TableCell>
                    {xWinUtils.displayToolTip(`A higher Sharpe ratio is better when comparing similar portfolios`)}
                    {format({prefix: '', suffix: ''})(xWinLib.roundTo(statistic.portSR / 100,2))}
                  </TableCell>
                </TableRow>
              </TableBody>
              </Table>
          </Grid> */}
          <br/>
          <Grid item lg={12} sm={12} xs={12} className={classes.hoverHighlight2}>
            <Table 
              size="small" 
            >
            <TableBody>
              {positions.map((p, i) => ( 
              <TableRow
                  hover
                >
                  <TableCell width="20%">
                    {xWinUtils.getIcons(p.symbol)}
                  </TableCell>
                  <TableCell width="20%">
                    {p.symbol}
                  </TableCell>
                  <TableCell width="40%" align="right">
                      <Typography color="primary">
                        {format({prefix: '', suffix: '%'})(p.weight)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <ButtonGroup size="small" color="secondary" aria-label="small outlined primary button group">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.button}
                        startIcon={<DeleteIcon />}
                        onClick={handleClickDelete(p.symbol)}
                      >
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}

              <TableRow className={classes.tableRow}>
                <TableCell width="20%" className={classes.columnHide}>
                    {xWinUtils.getIcons(ticker.symbol)}
                </TableCell>
                <TableCell width="20%">
                  <Autocomplete
                    id="symbol"
                    style={{ width: "100%" }}
                    name="symbol"
                    defaultValue={xWinLib.getTokenName(tokensMaster, appGlobalConfig.mainnetXWIN)}
                    options={xWinUtils.getSupportedSymbols(tokensMaster, networkName, privateVaults[0])}
                    getOptionLabel={option => option.symbol}
                    onChange={(event, newValue) => {
                      setTicker({
                        // ...ticker,
                        "weight": getLastPositionWeight(positions),
                        "symbol": newValue == null? "" : newValue.symbol
                      });
                    }}
                    renderInput={params => <TextField {...params} label="Token" margin="dense" variant="outlined" />}
                  />
                </TableCell>
                <TableCell width="40%" align="right">
                  <TextField
                    type="number" 
                    // className={classes.positiveNum}
                    label={'Weight %'}
                    margin="dense"
                    name="weight"
                    value={ticker?.weight}
                    onChange={handleChange("weight")}
                    required
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      style: { textAlign: "right" },
                      endAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                  />
                </TableCell>
                
                {/* <TableCell className={classes.columnHide}>
                  <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      {xWinLib.getTokenAddress(tokensMaster, ticker.symbol)} 
                  </Typography>    
                </TableCell>
                 */}
                <TableCell>
                  {/* <ButtonGroup size="small" color="primary" aria-label="small outlined primary button group"> */}
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      startIcon={<AddCircleIcon />}
                      onClick={handleClickAdd}
                    >
                    {""}
                      
                    </Button>
                  {/* </ButtonGroup> */}
                </TableCell>
              </TableRow>

              </TableBody>
            </Table>
          </Grid>  
          <VaultFaq faqs={faqs}/>  
      </DialogContent>
    )
  }

  const handleNewPort = (event, newP) => {
    if (newP !== null) {
      setNewPort(newP);
    }
  };

  const handleClickCreateFund = async event => {
    event.preventDefault()

    let totalweight = getTotalWeight()
    if(parseFloat(totalweight) !== 100){
      showSnackBar("error", "Total Weight Must be 100%")
      return
    }

    if(newPortSymbol.trim() === "" || newPortname.trim() === "" ){
      showSnackBar("error", "Please give your vault a symbol and name")
      return
    }

    if(!whiteListed && parseFloat(XWINBalance) < appGlobalConfig.mainnetCreateFundCost){
      showSnackBar("error", "Not enought XWIN token for fund creation")
      return
    }

    // if(isModifiedOriginalPort()){
    //   showSnackBar("info", "You have modified from the optimal allocation in the portfolio")
    // }

    setLoading(true)
    CreateFundAsync(
      theWeb3,
      newPortname,
      newPortSymbol,
      account,
      positions
    )
    .then(res => {
        setLoading(false)
      }
    )
    .catch(err => {
      setLoading(false)
      console.log(err)
    })
  }

  const handleClickRebalance = async event => {
    event.preventDefault();
    
    let totalweight = getTotalWeight()
    if(parseFloat(totalweight) !== 100){
      showSnackBar("error", "Total Weight Must be 100%")
      return
    }

    setLoading(true)
    RebalanceAsync(
        theWeb3,
        selectedPortAddress,
        account,
        positions
      )
      .then(res =>
        { 
          setLoading(false)
          showSnackBar("success", res)
          // props.parentCallback(true);
        }
      )
      .catch(err => {
        setLoading(false)
        showSnackBar("error", err)
        // props.parentCallback(false);
        }
      )
    
  };

  const getSelectedFundName = (address) => {

    let selected = privateVaults.find(x=>x.contractaddress === address)
    return selected?.name || ""
  }

  const handleClickDelete = (symbol) => event => {
    event.preventDefault();
    const filteredItems = positions.filter(t => t.symbol !== symbol)
    setPositions(filteredItems)
    let lastWeight = getLastPositionWeight(filteredItems)
    setTicker({
      ...ticker,
      "weight": lastWeight
    })
    calcStatistic(filteredItems)
  }
  
  const handleClickAdd = event => {
    event.preventDefault();
    
    if(ticker.symbol === "") return
    
    let result = positions.filter(t => t.symbol === ticker.symbol)
    if(result.length > 0){
      return
    }
    setErrmsg("")
    let existingWgt = getTotalWeight()
    let totalweight = parseFloat(existingWgt) + parseFloat(ticker.weight)
    if(totalweight > 100){
      setErrmsg("Not more than 100% weight")
      return
    }  

    let p = []
    positions.forEach(element => {
      p.push({
        symbol: element.symbol,
        weight: element.weight,
        taddress: element.taddress,
      })
    });

    let tokenaddress = xWinLib.getTokenAddress(tokensMaster, ticker.symbol) 
    
    p.push({
      symbol: ticker.symbol,
      weight: ticker.weight,
      taddress: tokenaddress,
    })
    setPositions(p)
    let lastWeight = getLastPositionWeight(p)
    setTicker({
      ...ticker,
      "weight": lastWeight
    })
    calcStatistic(p)
  };

  const handleChange = name => event => {
    
    setTicker({
      ...ticker,
      "weight": event.target.value
    }
    )
  };


  return (
    <Dialog 
          open={openModal} 
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          fullScreen={true}
          maxWidth = {'md'}
          >
          <SnackbarMessage ref={refsnackbar} />
          <CardHeader
              className={classes.expansion}
              
              action={
                <IconButton 
                  className={classes.title}
                  onClick={reset}
                  aria-label="settings">
                  <CancelIcon />
                </IconButton>
              }
              title={"Create / Rebalance"} 
              />
          {xWinUtils.getProgress(loading)}

          {getSetTarget()}  

          <DialogActions>
            <Typography variant="h5" className={classes.negativeNum}>
              {errMsg}
            </Typography> 
            
            <ButtonGroup size="small" color="primary" aria-label="small outlined primary button group">
                <Button 
                  onClick={handleClickOriginal} 
                  color="primary"
                  variant="outlined"
                  startIcon={<EditIcon />}>
                    {"Reset"}
                </Button>
                <Button 
                  onClick={handleClickAlign} 
                  color="primary"
                  variant="outlined"
                  startIcon={<EditIcon />}>
                    {"Align to 100%"}
                </Button>
              {
                !isApproval ? (
                <Button 
                  onClick={handleClickApprove} 
                  color="secondary"
                  variant="contained"
                  startIcon={<TextRotationNoneTwoToneIcon />}>
                    {"Approve"}
                </Button>
                ) : (
                <Button 
                  onClick={newPort === "new" ? handleClickCreateFund : handleClickRebalance} 
                  color="primary"
                  variant="contained"
                  startIcon={<AddCircleIcon />}>
                    {newPort === "new" ? "Build" : "Rebalance"}
                </Button>
                )
              }
              
              
            </ButtonGroup>
           
          </DialogActions>
            {
              newPort === "new" && (
                <DialogActions>
                  <Box display="flex" alignItems="left" justifyContent={"left"}>
                  <Typography
                      color="text"
                      variant={"small"}
                      // style={{ marginRight: 5 }}
                      className={classes.subTitle}
                      noWrap
                    >
                     {!whiteListed ? <>{appGlobalConfig.mainnetCreateFundCost + " XWIN for every new fund creation"}</> : <>Congratulation! You are white listed. Zero fee for fund creation!</>} 
                  </Typography>
                </Box>
                </DialogActions>
              )
            }
            {
              newPort === "update" && (
                <DialogActions>
                  <Box display="flex" alignItems="left" justifyContent={"left"}>
                  <Typography
                      color="text"
                      variant={"small"}
                      className={classes.subTitle}
                      noWrap
                    >
                    {"You will overite "+ selectedPortName + " weight and rebalance the fund"}
                  </Typography>
                </Box>
                </DialogActions>
              )
            }
         
    </Dialog>
  );
};


export default CreatePortV2;