import React, { useState, useEffect, useContext } from 'react'
import xWinLib from '../../xWinLib'
import xWinUtils from '../../xWinUtils'
import clsx from 'clsx';
import format from 'format-number'
import 'react-toastify/dist/ReactToastify.css'
import { Typography,  } from '../../components/Wrappers/Wrappers'
import {
    TableRow,
    Table,
    TableCell,
    TableBody,
    Card,
    DialogContent,
    Box,
} from '@material-ui/core'
import useStyles from './styles'
import { AppContext } from '../../context/AppProvider'
import _ from 'lodash'
import { getFundActivitiesByWalletOrContractAddr } from '../../utils/moralisInteractor'
import appGlobalConfig from '../../appGlobalConfig'

export default function HistoryEvents(props) {
    const {
        id, showAll
    } = props
    const classes = useStyles()
    const {
        account,tokenBalances
    } = useContext(AppContext)

    const [loading, setLoading] = React.useState(false);
    const [newResults, setNewResults] = useState([])

    useEffect(() => {
        getHistoryEvents()
    }, [id, account])


    const displayResult = () => {

        if(newResults.length === 0){
            return (
            <TableRow hover>
                <TableCell
                    colSpan={6}
                >
                <Box display="flex" alignItems="center" justifyContent={"center"}>
                    <img style={{maxWidth:"250px"}} alt={"no data"} src={"/images/background/nodata.png"} />
                </Box>
                </TableCell>
            </TableRow>
            )

        }else{
            return (
                newResults?.map((x) => (
                    <TableRow hover>
                            <TableCell
                                width="15%"
                                className={clsx(classes.columnHide)}
                            >
                                <a href={xWinLib.getEtherLink(x.contractaddress, "address")} target="_blank">
                                    {xWinUtils.getAddressShort(x.contractaddress)}
                                </a>  
                            </TableCell>
                            <TableCell
                                width="15%"
                            >
                            {xWinLib.roundTo(x.shares, 4)}
                            </TableCell>
                             <TableCell
                                width="15%"
                                className={clsx(classes.columnHide)}
                            >
                                <a href={xWinLib.getEtherLink(x.useraddress, "address")} target="_blank">
                                    {xWinUtils.getAddressShort(x.useraddress)}
                                </a>  
                            </TableCell>
                            <TableCell
                                width="15%"
                            >                                       
                               {x.type}
                            </TableCell>
                            <TableCell
                                width="15%"
                                className={clsx(classes.columnHide)}
                            >
                                
                               {xWinLib.roundTo(x.amount, 4)}
                            </TableCell>
                             <TableCell
                                width="15%"
                            >
                                <a href={xWinLib.getEtherLink(x.transaction_hash, "tx")} target="_blank">
                                {xWinUtils.getAddressShort(x.transaction_hash)}
                                </a>  
                            </TableCell>
                            
        
                    </TableRow>
                    
                ))
            )

        }
        

    }
   

    const historyEvents = () => {
        return (
            <DialogContent>
                <Box display="flex" alignItems="left" justifyContent={'left'}>
                    <Typography variant="h5" className={classes.newsHeader}>
                        History Events
                    </Typography>
                </Box>
                <br />
                <Box display="flex" alignItems="left" justifyContent={'left'}>
                    <Table size="small">
                        <TableBody>
                            <TableRow hover>
                                    <TableCell
                                        width="15%"
                                        className={clsx(classes.contentText, classes.columnHide)}
                                    >
                                        Contract
                                    </TableCell>
                                    <TableCell
                                        width="15%"
                                        className={clsx(classes.contentText)}
                                    >
                                        Shares
                                    </TableCell>
                                     <TableCell
                                        width="15%"
                                        className={clsx(classes.contentText, classes.columnHide)}
                                    >
                                       Wallet
                                    </TableCell>
                                     <TableCell
                                        width="15%"
                                        className={clsx(classes.contentText)}

                                    >
                                      Activity
                                    </TableCell>
                                    <TableCell
                                        width="15%"
                                        className={clsx(classes.contentText, classes.columnHide)}
                                    >
                                       Amount
                                    </TableCell>
                                    <TableCell
                                        width="15%"
                                        className={clsx(classes.contentText)}

                                    >
                                       Txn Hash
                                    </TableCell>

                                </TableRow>

                                {displayResult()}
                
                        </TableBody>
                    </Table>
                </Box>
            </DialogContent>
        )
    }

    const getHistoryEvents = async () => {
        setLoading(true)
        let contractaddress = id === undefined  ? appGlobalConfig.mainnetAddressZero : id
        let activities = await getFundActivitiesByWalletOrContractAddr(account, contractaddress, showAll);
        _.sortBy(activities, a => a.block_number)
        setNewResults(activities)
        // console.log(activities)
        setLoading(false)
    }

    return (
        <Card>
            {historyEvents()}
            {xWinUtils.getProgressLinear(loading)}
        </Card>
    )
}
