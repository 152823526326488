import React from 'react';
import {
  AccountBalance as AccountBalanceIcon,
  AllInclusive as AllInclusiveIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  History as HistoryIcon,
  Home as HomeIcon,
  Image as ImageIcon,
  InsertChart as InsertChartIcon,
  LocalAtm as LocalAtmIcon,
  People as PeopleIcon,
  SettingsInputAntennaOutlined as SettingsInputAntennaOutlinedIcon,
  Stars as StarsIcon,
  VideoLibrary as VideoLibraryIcon,
  ViewHeadline as ViewHeadlineIcon, 
  FavoriteBorderOutlined as FavoriteBorderOutlinedIcon,
  PhotoLibrary as PhotoLibraryIcon,
  DashboardOutlined as DashboardOutlinedIcon,
  DonutSmallOutlined as DonutSmallOutlinedIcon,
  HistoryOutlined as HistoryOutlinedIcon,
  AccountBalanceOutlined as AccountBalanceOutlinedIcon,

} from '@material-ui/icons';
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import appGlobalConfig from '../../appGlobalConfig';



const structure = [
  {
    id: 577,
    label: 'General',
    defaultExpand : true,
    icon:  <DashboardOutlinedIcon/>,
    children: [
      { 
        label: 'Markets', 
        link: '/app/dashboard',
        icon: <></>
      },
      { 
        label: 'Recent Private Vaults', 
        link: '/app/vaults/allprivatevault',
        icon: <></>
      },
    ],
  },
  {
    id: 578,
    defaultExpand : false,
    label: 'My Menu',
    icon:  <FavoriteBorderOutlinedIcon />,
    children: [
      { 
        label: 'My Vaults', 
        link: '/app/vaults/privatevault',
        icon: <></>
      },
      { 
        label: 'My Deposit', 
        link: '/app/mymenu',
        icon: <></>
      },
    ],
  },
  {
    id: 558,
    label: 'Robo Advisor',
    icon:  <DonutSmallOutlinedIcon/>,
    link: '/app/allocation',
  },
  {
    id: 878,
    defaultExpand : true,
    label: 'Public Vaults',
    icon:  <StarsIcon />,
    children: [
      { 
        label: 'Token Index', 
        link: '/app/vaults/index',
        icon: <></>
      },
      { 
        label: 'Auto Trading', 
        link: '/app/vaults/trading',
        icon: <></>
      },
      { 
        label: 'Stable Alpha', 
        link: '/app/vaults/stablealpha',
        icon: <></>
      },
      { 
        label: 'Single Asset', 
        link: '/app/vaults/singlebase',
        icon: <></>
      },
    ],
  },
  {
    id: 478,
    defaultExpand : true,
    label: 'xWIN Staking',
    icon:  <AccountBalanceOutlinedIcon />,
    children: [
      { 
        label: 'Auto Compound Lock', 
        link: '/app/autolockstake',
        icon: <></>
      },
      { 
        label: 'Earn xWIN', 
        link: '/app/farmingV2/all',
        icon: <></>
      },
      { 
        label: 'Earn Altcoin', 
        link: '/app/farmingbuddy',
        icon: <></>
      },
    ],
  },
  {
    id: 567,
    label: 'Network',
    icon:  <SettingsInputAntennaOutlinedIcon />,
    children: [
      { label: 'BNB Chain', link: '#',  
        icon: <>{xWinUtils.getIcons("bnb")}</>
      },
      { label: 'ETH Chain', link: '#', 
        icon: <>{xWinUtils.getIcons("eth")}</>
      },
    ],
  },
];


export default structure;
