import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => (
  {
    expansion: {
      backgroundColor: theme.palette.primary.light,
      color: 'white '
    },
    newsHeader: {
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: '#536DFE',
    },
    subTitle: {
      color: "#ffc247",
    },
    avatar: {
      height: 50,
      width: 50
    }
  }
))