import React from "react";

import {
  Popper,
  Box,
  Switch as SwitchMode
} from "@material-ui/core";

import useStyles from "../styles";

// components
import Widget from "../../Widget";
import { Typography } from "../../../components/Wrappers";
import Themes from "../../../themes";
import { useThemeDispatch } from "../../../context/ThemeContext";

function ColorChangeThemePopper({ open, id, anchorEl }) {
  const classes = useStyles();
  var themeDispatch = useThemeDispatch();
  
  const toggleDarkTheme = () => {
    if (localStorage.getItem("theme") === "dark") {
      localStorage.setItem("theme", "default");
      themeDispatch(Themes.default);
    } else {
      localStorage.setItem("theme", "dark");
      themeDispatch(Themes.dark);
    }
  };

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement={"left-start"}
      style={{ zIndex: 100 }}
      elevation={4}
    >
      <Widget disableWidgetMenu>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
            <Typography variant="body2" weight={"bold"} uppercase>
              dark mode
            </Typography>
            <SwitchMode
              checked={localStorage.getItem("theme") === "dark"}
              onChange={() => toggleDarkTheme()}
            />

        </Box>
      </Widget>
    </Popper>
  );
}

export default React.memo(ColorChangeThemePopper, (prevProps, nextProps) => {
  return prevProps.anchorEl === nextProps.anchorEl;
});
