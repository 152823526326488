import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import {
    AppBar,
    Toolbar,
    IconButton,
    Tooltip,
    Button,
    Typography,
    MenuItem,
    Menu,
    ListItemIcon,
    ListItemText,
    Divider,
} from '@material-ui/core'
import xWinLib from '../../xWinLib'
import format from 'format-number'
import SnackbarMessage from '../../pages/snackbarmessage/SnackbarMessage'
import ReactInterval from 'react-interval'

import { useTheme } from '@material-ui/styles'
import {
    Menu as MenuIcon,
    ArrowBack as ArrowBackIcon,
    FlashOff as FlashOffIcon,
} from '@material-ui/icons'
import classNames from 'classnames'
import xWinUtils from '../../xWinUtils'
import { AppContext } from '../../context/AppProvider'
// styles
import useStyles from './styles'

// context
import {
    useLayoutState,
    useLayoutDispatch,
    toggleSidebar,
} from '../../context/LayoutContext'
import appGlobalConfig from '../../appGlobalConfig'

import { getAddressShort, getNetworkById } from '../../utils/helper'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'
import { systemConfig } from '../../configs/systemConfig'


export default function Header(props) {
    var classes = useStyles()
    var theme = useTheme()
    const {
        ETHBalance,
        isConnected,
        account,
        tokenBalances,
        XWINBalance,
        globalProvider,
        connectNetwork,
        disconnectNetwork,
        setAppMsg,
        network
    } = useContext(AppContext)
    // global
    var layoutState = useLayoutState()
    var layoutDispatch = useLayoutDispatch()
    const [isSmall, setSmall] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const refsnackbar = useRef(null)
    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg, 'top')
    }

    const [isCopied, setIsCopied] = useState(false)

    
    
    useEffect(() => {
        if (!isConnected) {
            connect()
            if (refsnackbar.current !== null)
                refsnackbar.current.hideMessageTxn()
        } 
        window.addEventListener('resize', handleWindowWidthChange)
        handleWindowWidthChange()
        return function cleanup() {
            window.removeEventListener('resize', handleWindowWidthChange)
        }
      }, [isConnected]);

      useEffect(() => {
        // Subscribe to chainId change
        if (globalProvider) {
            globalProvider.on('chainChanged', chainId => {
                if (chainId !== systemConfig.chainId) {
                    let networkInfo = getNetworkById(systemConfig.chainId)
                    showSnackBar(
                        'error',
                        'Please connect your wallet to ' +
                            networkInfo.chainName +
                            ' network'
                    )
                }
            });    
        }
      }, [globalProvider]);

      
    const handleClickConnect = async event => {
        event.preventDefault()

        if (!isConnected) {
            connect()
        } else {
            disconnect()
            setAnchorEl(null)
        }
    }

    const connect = () => {
        connectNetwork()
        .then(res => res)
        .catch(err => {
            console.error(err)
            let networkInfo = getNetworkById(systemConfig.chainId)
            showSnackBar(
                'error',
                err + "! Please connect your wallet to "+ networkInfo.chainName +" network"
            )
        });
    }

    const disconnect = () => {
        disconnectNetwork()
            .then(() => {
                setAppMsg(['info', 'Disconnected'])
            })
            .catch(err => {
                setAppMsg([
                    'error',
                    `Error in Disconnect Network: ${err.message}`,
                ])
            })
    }

    function handleWindowWidthChange() {
        var windowWidth = window.innerWidth
        var breakpointWidth = theme.breakpoints.values.md
        var isSmallScreen = windowWidth < breakpointWidth
        setSmall(isSmallScreen)
    }

    const getWalletMessage = () => {
        if (!isConnected) {
            return (
                <Tooltip>
                    <Button onClick={handleClickConnect}>
                        <Typography
                            className={classes.positiveNum}
                            //color="text"
                            //colorBrightness={"hint"}
                            variant={'caption'}
                            style={{ marginRight: 5 }}
                            noWrap
                        >
                            Connect Wallet
                        </Typography>
                    </Button>
                </Tooltip>
            )
        } else {
            return (
                <IconButton
                    aria-haspopup="true"
                    color="inherit"
                    className={classes.headerMenuButton}
                    aria-controls="profile-menu"
                    onClick={handleClick}
                >
                    {xWinUtils.getIcons(appGlobalConfig.nativeToken)}
                    <Typography
                        color="text"
                        colorBrightness={'hint'}
                        variant={'caption'}
                        style={{ marginLeft: 8 }}
                        noWrap
                    >
                        {getAddressShort(account)}
                    </Typography>
                </IconButton>
            )
        }
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const closeBar = () => {
        refsnackbar.current.hideMessageTxn()
    }

    const copyAdressToClipboard = account => {
        navigator.clipboard.writeText(account)
        showSnackBar('info', 'Address copied!')
        setIsCopied(true)
        // refsnackbar.current.handleOpen("success", "Copied to clipboard !", "bottom")
    }

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <SnackbarMessage ref={refsnackbar} />
                <ReactInterval
                    timeout={5000}
                    enabled={true}
                    callback={() => closeBar()}
                />

                <IconButton
                    color="inherit"
                    onClick={() => toggleSidebar(layoutDispatch)}
                    className={classNames(
                        classes.headerMenuButton,
                        classes.headerMenuButtonCollapse
                    )}
                >
                    {(!layoutState.isSidebarOpened && isSmall) ||
                    (layoutState.isSidebarOpened && !isSmall) ? (
                        <ArrowBackIcon
                            classes={{
                                root: classNames(
                                    classes.headerIcon,
                                    classes.headerIconCollapse
                                ),
                            }}
                        />
                    ) : (
                        <MenuIcon
                            classes={{
                                root: classNames(
                                    classes.headerIcon,
                                    classes.headerIconCollapse
                                ),
                            }}
                        />
                    )}
                </IconButton>
                <Link to="/" classNames={classes.profileLabel}>
                    <img
                        src={'../../../images/background/xWin Logo.png'}
                        alt="logo"
                        height={40}
                    />
                </Link>
                <div className={classes.grow} />
                {getWalletMessage()}
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {tokenBalances.map(t => (
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <img
                                    alt={t.name}
                                    src={xWinLib.getIcon(t.logo)}
                                    className={classes.avatar}
                                />
                            </ListItemIcon>
                            <ListItemText
                                className={classes.positiveNum}
                                secondary={format({ prefix: '', suffix: '' })(
                                    xWinLib.roundTo(t.balance, 4)
                                )}
                            />
                        </MenuItem>
                    ))}
                    <Divider />
                    <MenuItem onClick={() => copyAdressToClipboard(account)}>
                        {!isCopied ? (
                            <LibraryAddIcon style={{ marginLeft: 6 }} />
                        ) : (
                            <LibraryAddCheckIcon style={{ marginLeft: 6 }} />
                        )}

                        <ListItemText
                            className={classes.positiveNum}
                            style={{ textAlign: 'right' , }}
                            secondary={getAddressShort(account)}
                        />
                    </MenuItem>
                    <MenuItem onClick={handleClickConnect}>
                        <ListItemIcon>
                            <FlashOffIcon
                                fontSize="small"
                                style={{ marginLeft: 6 }}
                            />
                        </ListItemIcon>
                        <ListItemText className={classes.positiveNum} secondary={'Disconnect'} />
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    )
}
