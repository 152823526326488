import React, { useState, useEffect, useContext  } from "react";
import appGlobalConfig from '../../appGlobalConfig';
import {AppContext}  from '../../context/AppProvider';
import { withRouter } from 'react-router-dom';
import { getUserFarmAllInfo } from "../../utils/masterChefInteractor";
// import format from 'format-number';
import ReactInterval from 'react-interval'

import {
  Grid,
  Container,
  Tabs,
  Tab,
  Box
} from "@material-ui/core";

import VaultPerformance from "../vaultPerformance";
import MyFarmValue from "./components/MyFarmValue";
import MyBuddyFarmValue from "./components/MyBuddyFarmValue";
import MyVaultValue from "./components/MyVaultValue";
import MyRewardValue from "./components/MyRewardValue";
import MyBuddyRewardValue from "./components/MyBuddyRewardValue";
import TradinglistV2 from "../tradingsV2/components/TradinglistV2";
import FarmlistV2 from "../farmingV2/components/FarmlistV2";
import FarmlistBuddyV2 from "../farmingBuddyV2/components/FarmlistBuddyV2";

import HistoryIcon from '@material-ui/icons/History';
import PieChartIcon from '@material-ui/icons/PieChart';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PropTypes from 'prop-types';

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import { Typography } from "../../components/Wrappers";
import { getTokenInfo } from "../../utils/erc20Interactor";
import { convertFromWei, getNetworkById } from "../../utils/helper";
import { systemConfig } from "../../configs/systemConfig";
import { getUserBuddyFarmAllInfo } from "../../utils/buddyChefInteractor";
import { getUserFundAllInfo } from "../../utils/fundV2FactoryInteractor";
import { getAllPrices } from "../../utils/xWinPriceMasterInteractor";
import HistoryEvents from "../historyDetails/HistoryDetailV2";
import { getPrivateFunds } from "../../utils/moralisInteractor";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const MyMenu = props => {
  const {portfolios, stakingPools, stakingBuddyPools, tokensMaster } = props;
  
  var classes = useStyles();
  const [filterFarmBuddy, setFilterFarmBuddy] = useState([])
  const [filterFarmPortfolios, setFilterFarmPortfolios] = useState([])
  const [filterAllport, setFilterAllport] = useState([])
  const [filterportPrivate, setFilterportPrivate] = useState([])
  const [farmBuddyCollections, setFarmBuddyCollections] = useState([])
  const [farmCollections, setFarmCollections] = useState([])
  const [farmTotalValues, setFarmTotalValues] = useState("0")
  const [farmTotalPending, setFarmTotalPending] = useState("0")
  const [farmBuddyTotalValues, setFarmBuddyTotalValues] = useState("0")
  const [farmBuddyTotalShares, setFarmBuddyTotalShares] = useState("0")
  const [vaultCollections, setVaultCollections] = useState([])
  const [vaultTotalValues, setVaultTotalValues] = useState("0")
  const [loading, setLoading] = React.useState(false);
  const [prices, setPrices] = useState([])
    
  const { isConnected, account, theWeb3, web3static, refreshAccount } = useContext(AppContext);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    loadWeb3()
  }, [portfolios, account]);

  
  const refreshData = async () => {
  
    if(value === 0){
      loadVault()
    }else if(value == 1){
      loadFarm()
    }
    refreshAccount()
  }

  const loadWeb3 = async () => {
    
    try {

      if(account === "---") {
        props.history.push('/app/dashboard')
      }
      if(!theWeb3) return;
          
      setLoading(true)
      let filterEnv = getNetworkById(systemConfig.chainId).env; 
      
      let filterport = []
      let filterportPrivate = []
      let filterportFarm = []
      let filterBuddyFarm = []
      
      filterportPrivate = await getPrivateFunds(account)

      filterport = portfolios; //portfolios.filter(x=>x.env.toLowerCase() === appGlobalConfig.env)
      filterportFarm = stakingPools; //.filter(x=>x.env.toLowerCase() === appGlobalConfig.env)
      filterBuddyFarm = stakingBuddyPools; //.filter(x=>x.env.toLowerCase() === appGlobalConfig.env)
      //get xwin price in USD
      const promiseData = await Promise.all([
        getAllPrices(web3static),
        getTokenInfo(web3static, appGlobalConfig.mainnetXWIN, appGlobalConfig.mainnetBurn),
      ]);
      let allprices = promiseData[0]
      let burnInfo = promiseData[1]
  
      setPrices(allprices)
      setFilterFarmPortfolios(filterportFarm)
      setFilterFarmBuddy(filterBuddyFarm)
      setFilterAllport(filterport)
      setFilterportPrivate(filterportPrivate)

      if(account !== "---"){
        
        const promiseData = await Promise.all([
          getUserFundAllInfo(web3static, account),
          getUserFarmAllInfo(web3static, account),
          getUserBuddyFarmAllInfo(web3static, account),
        ]);
        let userVaults = promiseData[0]
        let userMasterChefFarms = promiseData[1]
        let userBuddyFarms = promiseData[2]
        
        if(userMasterChefFarms !== undefined){
          setFarmCollections(getFarmsData(userMasterChefFarms, filterportFarm))
          setFarmTotalValues(convertFromWei(userMasterChefFarms.totalamount))
          setFarmTotalPending(convertFromWei(userMasterChefFarms.totalpending))
        }else{
          setFarmCollections([])
          setFarmTotalValues("0")
        }
        
        if(userBuddyFarms !== undefined){
          setFarmBuddyCollections(getBuddyFarmsData(userBuddyFarms, filterBuddyFarm))
          setFarmBuddyTotalValues(convertFromWei(userBuddyFarms.totalamount))
          setFarmBuddyTotalShares(convertFromWei(userBuddyFarms.totalshares))
        }else{
          setFarmBuddyCollections([])
        }

        if(userVaults !== undefined){
          let value = getVaultsData(userVaults, filterport, filterportPrivate);
          // console.log(value)
          setVaultCollections(value.arr)
          setVaultTotalValues(value.total)
        }else{
          setVaultCollections([])
          setVaultTotalValues("0") 
        }
      }else{
        setVaultCollections([])
        setFarmCollections([])
        setFarmTotalValues("0")
        setFarmBuddyCollections([])
        setVaultTotalValues("0")
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
    
  }

  const loadVault = async () => {
    
    try {

      if(!theWeb3) return;
          
      if(account !== "---"){
        
        const promiseData = await Promise.all([
          getUserFundAllInfo(web3static, account),
        ]);
        let userVaults = promiseData[0]
        if(userVaults === undefined) return;

        if(userVaults !== undefined){
          let value = getVaultsData(userVaults, filterAllport, filterportPrivate);
          // console.log(value)
          setVaultCollections(value.arr)
          setVaultTotalValues(value.total)
        }else{
          setVaultCollections([])
          setVaultTotalValues("0") 
        }
        // setVaultCollections(getVaultsData(userVaults, filterAllport))
        // setVaultTotalValues(convertFromWei(userVaults.totalamount))
        
      }else{
        setVaultCollections([])
        setVaultTotalValues("0")
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
    
  }

  const loadFarm = async () => {
    
    try {

      if(!theWeb3) return;
          
      if(account !== "---"){
        
        const promiseData = await Promise.all([
          getUserFarmAllInfo(web3static, account),
        ]);
        let userMasterChefFarms = promiseData[0]
        if(userMasterChefFarms === undefined) return;
        setFarmCollections(getFarmsData(userMasterChefFarms, filterFarmPortfolios))
        setFarmTotalValues(convertFromWei(userMasterChefFarms.totalamount))
        setFarmTotalPending(convertFromWei(userMasterChefFarms.totalpending))
        
      }else{
        setFarmCollections([])
        setFarmTotalPending("0")
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
    
  }

  const getVaultsData = (userVaults, filterport, filterportPrivate) => {

    if(filterport.length === 0) return [];

    let arr = [];
    let total = 0;
    for(let i=0; i < userVaults.funds.length; i++){
      let selected = filterport.find(x=>x.contractaddress.toLowerCase() === userVaults.funds[i].toLowerCase())
      if(selected === undefined){
        selected = filterportPrivate.find(x=>x.contractaddress.toLowerCase() === userVaults.funds[i].toLowerCase())
      }
      let fValue = convertFromWei(userVaults.fundvalues[i], selected.decimal)
      let data = {
        contractaddress: userVaults.funds[i],
        fundvalue: fValue,
        avgPrice: convertFromWei(userVaults.avgPrices[i], selected.decimal),
        unitPrice: convertFromWei(userVaults.unitPrices[i], selected.decimal),
        share: convertFromWei(userVaults.shares[i], selected.decimal),
        symbol: selected.symbol,
        name: selected.name,
        fundtype: selected.fundtype,
        logo : selected.logo
      }
      total += Number(fValue)
      arr.push(data)
    }
    return {
      arr,
      total
    }
  }

  const getFarmsData = (userMasterChefFarms, filterportFarm) => {

    if(filterportFarm.length === 0) return [];

    let arr = [];
    for(let i=0; i < userMasterChefFarms.funds.length; i++){
      let selected = filterportFarm.find(x=>x.contractaddress.toLowerCase() === userMasterChefFarms.funds[i].toLowerCase())
      let data = {
        contractaddress: userMasterChefFarms.funds[i],
        fundvalue: convertFromWei(userMasterChefFarms.fundvalues[i], selected.decimal),
        pending: convertFromWei(userMasterChefFarms.pendings[i], selected.decimal),
        symbol: selected.symbol,
        name: selected.name,
        pid : selected.pid
      }
      arr.push(data)
    }
    return arr;
  }

  const getBuddyFarmsData = (userBuddyFarms, filterBuddyFarm) => {

    if(filterBuddyFarm.length === 0) return [];
    let arr = [];
    for(let i=0; i < userBuddyFarms.funds.length; i++){
      let selected = filterBuddyFarm.find(x=>x.rewardtoken.toLowerCase() === userBuddyFarms.funds[i].toLowerCase())
      let data = {
        contractaddress: userBuddyFarms.funds[i],
        fundvalue: convertFromWei(userBuddyFarms.fundvalues[i], selected.decimal),
        pending: convertFromWei(userBuddyFarms.pendings[i], selected.decimal),
        symbol: selected.symbol,
        name: selected.name
      }
      arr.push(data)
    }
    return arr;
  }

  const handleCallback = (refresh) =>{
    if(refresh) loadWeb3()
  }

  const displayTradingPortfolios = () => {
    
    if(vaultCollections.length == 0) return
    let fundOwns = [] 
    vaultCollections.forEach(v => {
      let selected = filterAllport.find(x=>x.contractaddress.toLowerCase() === v.contractaddress.toLowerCase());
      if(selected === undefined){
        selected = filterportPrivate.find(x=>x.contractaddress.toLowerCase() === v.contractaddress.toLowerCase());
      }
      if(selected !== undefined){
        fundOwns.push(selected)
      }
    })

    if(fundOwns.length > 0){
      return (
        fundOwns.map(port => (
          <>
             <TradinglistV2 
              port={port}
              fundtype={port?.fundtype}
              tokensMaster={tokensMaster}
              globalWeb3={theWeb3}
              web3static={web3static}
              selectedWallet={account}
              refreshAccount={refreshAccount}
              parentCallback={handleCallback}
                
              /> 
              <br/>
              </>
          )) 
      )    
    }else{
      return (
        <Box display="flex" alignItems="center" justifyContent={"center"}>
          <img style={{maxWidth:"250px"}} alt={"no data"} src={"/images/background/nodata.png"} />
        </Box>
      )
    }
  }
  
  const displayFarmList = () => {
    
    let fundOwns = [] 
    farmCollections.forEach(v => {
      let selected = filterFarmPortfolios.find(x=>x.contractaddress === v.contractaddress);
      fundOwns.push(selected)
    })

    if(fundOwns.length > 0){
      return (
        fundOwns.map(port => (
          <>
            <FarmlistV2 
              port={port}
              globalWeb3={theWeb3}
              web3static={web3static}
              refreshAccount={refreshAccount}
              prices={prices}
              /> 
              <br/>
            </>
          )) 
      )   
    }else{
      return (
        <Box display="flex" alignItems="center" justifyContent={"center"}>
          <img style={{maxWidth:"250px"}} alt={"no data"} src={"/images/background/nodata.png"} />
        </Box>
      )
    }
  }

  const displayBuddyFarmList = () => {
    
    let fundOwns = [] 
    farmBuddyCollections.forEach(v => {
      let selected = filterFarmBuddy.find(x=>x.rewardtoken === v.contractaddress);
      fundOwns.push(selected)
    })

    if(fundOwns.length > 0){
      return (
        fundOwns.map(port => (
          <>
          <FarmlistBuddyV2 
              port={port}
              globalWeb3={theWeb3}
              web3static={web3static}
              selectedWallet={account}
              refreshAccount={refreshAccount}
              prices={prices}
              /> 
              <br/>
             </>
          )) 
      )   
    }else{
      return (
        <Box display="flex" alignItems="center" justifyContent={"center"}>
          <img style={{maxWidth:"250px"}} alt={"no data"} src={"/images/background/nodata.png"} />
        </Box>
      )
    }
  }

  
  return (
    <Container>
    <ReactInterval timeout={30000} enabled={true}
        callback={() => refreshData()} />
    <Grid container spacing={2}>
      <Grid item lg={12} sm={12} xs={12} className={classes.hoverHighlight}>
        <Widget
              title={"Deposited"}
              disableWidgetMenu
              inheritHeight
              enableWalletDisplay
              selectedWallet={account}
            >
          <Tabs 
            value={value} 
            onChange={handleChange} 
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="basic tabs example">
            <Tab 
              className={classes.contentText}
              icon={<PieChartIcon />}  
              iconPosition="start" 
              label="Vaults" {...a11yProps(0)} />
            <Tab 
              className={classes.contentText}
              icon={<FileCopyIcon />}  
              iconPosition="start"             
              label="Staking Farm" {...a11yProps(1)} />
            <Tab 
              className={classes.contentText}
              icon={<AttachMoneyIcon />}  
              iconPosition="start"             
              label="Profit / Loss" {...a11yProps(2)} />
            <Tab 
              className={classes.contentText}
              icon={<HistoryIcon />}  
              iconPosition="start"             
              label="History" {...a11yProps(3)} />
          </Tabs>
          
          <TabPanel value={value} index={0}>
            <br/>
            <Grid container spacing={2}>
              <Grid item lg={vaultCollections?.length == 0 ? 12 : vaultCollections?.length > 4 ? 12 : 6} sm={12} xs={12} className={classes.hoverHighlight}>
                <MyVaultValue 
                  vaultTotalValues={vaultTotalValues || 0}
                  vaultCollections={vaultCollections || []}
                  loading={loading}
                  />
              </Grid>
              {vaultCollections?.length > 0 && (    
                <Grid item lg={vaultCollections?.length > 4 ? 12 : 6} sm={12} xs={12} className={classes.hoverHighlight}>
                  {displayTradingPortfolios()}
                </Grid>
              )}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <br/>
            <Grid container spacing={2}>
              
                    <Grid item lg={farmBuddyCollections?.length === 0 ? 9 : 6} sm={12} xs={12} className={classes.hoverHighlight}>
                      <Grid item lg={12} sm={12} xs={12}>
                        <MyFarmValue 
                          farmTotalValues={farmTotalValues}
                          farmTotalPending={farmTotalPending}
                          farmCollections={farmCollections}
                          loading={loading}
                          />
                          <br/>
                      </Grid>
                      <Box display="flex" alignItems="center" justifyContent={"left"}>
                        <Typography
                          className={classes.positiveNum}
                        >
                          xWIN MasterChef: Earn XWIN 
                        </Typography>
                        <br/>
                      </Box>
                      <Box display="flex" alignItems="center" justifyContent={"left"}>
                      <MyRewardValue 
                          farmCollections={farmCollections}
                          farmTotalPending={farmTotalPending}
                          loading={loading}
                          parentCallback={refreshData}
                        /> 
                      </Box>
                      {farmCollections.length > 0 && (                      
                        displayFarmList()
                      )}   
                </Grid>
                {farmBuddyCollections?.length > 0 && (    
                  <Grid item lg={6} sm={12} xs={12} className={classes.hoverHighlight}>
                    <Grid item lg={12} sm={12} xs={12}>
                      <MyBuddyFarmValue 
                        farmBuddyTotalValues={farmBuddyTotalValues}
                        farmBuddyCollections={farmBuddyCollections}
                        loading={loading}
                        />
                        <br/>
                    </Grid>
                    <Box display="flex" alignItems="center" justifyContent={"left"}>
                      <Typography
                        className={classes.positiveNum}
                        >
                        xWIN Buddy Chef: Earn Alt  
                      </Typography>
                      <br/>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent={"left"}>
                      <MyBuddyRewardValue 
                          farmBuddyCollections={farmBuddyCollections}
                          loading={loading}
                          parentCallback={loadFarm}
                        /> 
                    </Box>
                    {farmBuddyTotalValues > 0 && (
                      displayBuddyFarmList()
                    )}                      
                </Grid>
                )}
                    
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <VaultPerformance portfolios={filterAllport} vaultCollections={vaultCollections || []} tokensMaster={tokensMaster}/>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <HistoryEvents showAll = {false}/>
          </TabPanel>
        </Widget>
      </Grid>
    </Grid>
    </Container>
  );
}


export default withRouter(MyMenu);
