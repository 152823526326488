import React, { useState, useEffect, useContext } from 'react'
import xWinUtils from '../../../xWinUtils'
import moment from 'moment'

// styles
import useStyles from '../styles'
import Widget from '../../../components/Widget/Widget'
import { VictoryPie } from 'victory';
import { VictoryAnimation } from 'victory'
import { VictoryLabel } from 'victory'
import { GetMarketTrends } from '../../../utils/firestoreDataInteractor'


const MarketTrends = (props) => {

    var classes = useStyles()
    const [mainData, setMainData] = useState("")
    const [upTrendPrediction, setUpTrendPrediction]= useState("")
    const [downTrendPrediction, setDownTrendPrediction]= useState("")
    const [neutralPrediction, setNeutralPrediction]= useState("")
    const isDark = (localStorage.getItem("theme") === "dark") ? true : false

    const {AD_OBV,Alligator,Awesome,Cross_MA,Power_BearBull, TWO_RSI}= mainData;
    const newArr=[AD_OBV,Alligator,Awesome,Cross_MA,Power_BearBull, TWO_RSI]
       
    const estimatePrediction = () => {
        var upTrend=0
        var downTrend=0
        var neutral=0
        var upTrendP=0
        var downTrendP=0
        var neutralP=0


        if(mainData!==""){
            newArr.map(item=>{
                if(item.trend === "UpT"){
                    upTrend+=item.probability
                }
                else if(item.trend === "DownT"){
                    downTrend+=item.probability

                }else if(item.trend === "Neutral")
                    neutral+=item.probability

            })
            upTrendP = upTrend
            downTrendP =downTrend
            neutralP =neutral

     


        }else{
            return
        }

        setUpTrendPrediction((upTrendP))
        setDownTrendPrediction((downTrendP))
        setNeutralPrediction((neutralP))




    }

    let yyyymm = moment(new Date())
    .endOf('month')
    .format('YYYYMM')

    let lastMonth = moment(yyyymm)
    .subtract(1, 'month')
    .format('YYYYMM')

    let thisMonth =moment(new Date())
    .endOf('month')
    .format('MMM YYYY')

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        let data = await GetMarketTrends(yyyymm)
        setMainData(data)
    }

    useEffect(()=>{
        estimatePrediction();

    },[mainData])


  return (
    <div >
        <Widget
        bodyClass={classes.mainChartBody}
        title={<div><div >Market Trend({thisMonth})</div><div>{xWinUtils.displayToolTip(`The widget shows the current market trends, as well as the predicted trends for the upcoming month.`)}</div></div>}
        disableWidgetMenu 
    >           
        <div style={{display :"flex", flexDirection:"row"}}>
        
            <div style={{display :"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}} >
                <svg viewBox="0 0 400 400" width="100%" height="100%">

                <VictoryPie
                standalone={false}
                animate={{ duration: 1000 }}
                width={400} height={400}
                data={[{ x: "upTrend", y: downTrendPrediction }, { x: 2, y: 100-downTrendPrediction }]}
                innerRadius={120}
                cornerRadius={25}
                labels={() => null}
                style={{
                data: { 
                    opacity:({datum})=>{
                        const opacityLevel = 0.1;
                        return datum.x === 2 ? opacityLevel : 1;
                    },
                    fill: 
                    // {color:"red"}
                    ({ datum }) => {
                    const color =  "gray" ;
                    return datum.x === 2 ? color : "red";
                }
                }
                }}
            />

                <VictoryAnimation duration={1000} data={{percent:downTrendPrediction}}>
                    {(newProps) => {
                    return (
                        <VictoryLabel
                        textAnchor="middle" verticalAnchor="middle"
                        x={200} y={200}
                        text={`${Math.round(newProps.percent)}%`}
                        style={{ fontSize: 45, fill:isDark && "white"}}
                        />
                    );
                    }}
                </VictoryAnimation>
                        
            </svg>
            <p>Bearish</p>

          </div>

          <div style={{display :"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}} >
                <svg viewBox="0 0 400 400" width="100%" height="100%">

                <VictoryPie
                standalone={false}
                animate={{ duration: 1000 }}
                width={400} height={400}
                data={[{ x: "upTrend", y: neutralPrediction }, { x: 2, y: 100-neutralPrediction }]}
                innerRadius={120}
                cornerRadius={25}
                labels={() => null}
                style={{
                data: {
                    opacity:({datum})=>{
                        const opacityLevel = 0.1;
                        return datum.x === 2 ? opacityLevel : 1;
                    },
                     fill: 
                    // {color:"red"}
                    ({ datum }) => {
                    const color =  "gray" ;
                    return datum.x === 2 ? color : "yellow";
                }
                }
                }}
            />

                <VictoryAnimation duration={1000} data={{percent:neutralPrediction}}>
                    {(newProps) => {
                    return (
                        <VictoryLabel
                        textAnchor="middle" verticalAnchor="middle"
                        x={200} y={200}
                        text={`${Math.round(newProps.percent)}%`}
                        style={{ fontSize: 45, fill:isDark && "white"}}
                        />
                    );
                    }}
                </VictoryAnimation>
                        
            </svg>
            <p>Neutral</p>

          </div>
          <div style={{display :"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}} >
                <svg viewBox="0 0 400 400" width="100%" height="100%">

                <VictoryPie
                standalone={false}
                animate={{ duration: 1000 }}
                width={400} height={400}
                data={[{ x: "upTrend", y: upTrendPrediction }, { x: 2, y: 100-upTrendPrediction }]}
                innerRadius={120}
                cornerRadius={25}
                labels={() => null}
                style={{
                data: { 
                    opacity:({datum})=>{
                        const opacityLevel = 0.1;
                        return datum.x === 2 ? opacityLevel : 1;
                    },fill: 
                    // {color:"red"}
                    ({ datum }) => {
                    const color =  "gray" ;
                    return datum.x === 2 ? color : "green";
                }
                }
                }}
            />

                <VictoryAnimation duration={1000} data={{percent:upTrendPrediction}}>
                    {(newProps) => {
                    return (
                        <VictoryLabel
                        textAnchor="middle" verticalAnchor="middle"
                        x={200} y={200}
                        text={`${Math.round(newProps.percent)}%`}
                        style={{ fontSize: 45, fill:isDark && "white"}}
                        />
                    );
                    }}
                </VictoryAnimation>
                        
            </svg>
            <p>Bullish</p>

          </div>

          </div>
        </Widget>
    </div>
  )
}

export default MarketTrends