import xWinFund from "../abi/v2/fundV2.json";
import { getGasPrice, convertToWei, convertFromWei } from "../utils/helper";
import xWinLib from '../xWinLib';

export async function getFundExtraInfo(web3, contractaddress, targetaddress) {
	const contract = new web3.eth.Contract(xWinFund, contractaddress);
	
  const promiseData = await Promise.all([
    contract.methods.TargetWeight(targetaddress).call(),
    contract.methods.getTokenValues(targetaddress).call(),
    contract.methods.getBalance(targetaddress).call(),
    contract.methods.decimals().call()
  ]);
  let targetweight = promiseData[0]
  let fundTokenValue = promiseData[1]
  let AllBal = promiseData[2]
  let decimals = promiseData[3]
  
  return {
    "targetweight": targetweight,
    "fundTokenValue": convertFromWei(fundTokenValue || 0, decimals),
    "AllBal": convertFromWei(AllBal || 0, decimals),
  };
}

export async function getPerformanceData(web3, contractaddress, account) {
	
  const contract = new web3.eth.Contract(xWinFund, contractaddress);
	let performanceMap = await contract.methods.performanceMap(account).call();
	return {
    "shares": convertFromWei(performanceMap.shares || 0),
    "avgPrice": convertFromWei(performanceMap.avgPrice || 0)
  };
}

export async function getMyPorts(web3, portfolios, account) {
  
  let myPorts = []
  
  const promises = portfolios.map(async port => {
    
    const data = await getPerformanceData(web3, port.contractaddress, account);
    if(parseFloat(data.shares) > 0){
      const funddata = await GetFundDataAll(web3, port.contractaddress);
      return {
        ...port,
        ...funddata,
        "shares" : data.shares,
        "avgPrice" : data.avgPrice
      }
    }
  });
  let ports = await Promise.all(promises);
  ports.forEach(p => {
    if(p !== undefined){
      myPorts.push(p);
    }
  });
  return myPorts;

}

export async function getEstimateShares(web3, contractaddress, subAmount) {
  
  const subsAmtInWei = convertToWei(subAmount);
  const contract = new web3.eth.Contract(xWinFund, contractaddress);
  let estimateShares = await contract.methods.getEstimateShares(subsAmtInWei).call();
  return convertFromWei(estimateShares) * 0.9975;
}

export async function GetFundDataAll(web3, contractaddress) {
  const contract = new web3.eth.Contract(xWinFund, contractaddress);

  try {
    const promiseData = await Promise.all([
      contract.methods.GetFundDataAll().call(),
      contract.methods.GetFundExtra().call(),
      contract.methods.decimals().call(),
      contract.methods.openForPublic().call(),
    ]);
    let myFundData = promiseData[0]
    let feesData = promiseData[1]
    let decimals = promiseData[2]
    let openForPublic = promiseData[3]
    let targetaddress = myFundData.targetAddress; //_targetNamesAddress
    let fundmanager = feesData.mRebAddr; //_managerAddr
    let totalSupply = convertFromWei(myFundData.totalUnitB4, decimals); //totalUnitB4
    let fundETHbalance = convertFromWei(myFundData.baseBalance, decimals); //baseBalance
    let uniprice = convertFromWei(myFundData.unitprice, decimals); //unitprice
    let fundvalue = convertFromWei(myFundData.fundvalue, decimals); //fundvalue
    let fundvalueUSD = convertFromWei(myFundData.fundvalueUSD, decimals); //fundvalueUSD
    let fundName = myFundData.fundName; //fundName
    let symbol = myFundData.symbolName; //symbolName
    let unipriceInUSD = convertFromWei(myFundData.unitpriceUSD, decimals);
    let baseToken = myFundData.baseToken; //baseToken 
    let managerFee = feesData.managementFee; //managementFee
    let performanceFee = feesData.performanceFee; // perfomanceFee
    let platformFee = feesData.platFee; //platformFee
    
    return {
      targetaddress,
      fundmanager,
      totalSupply,
      fundETHbalance,
      uniprice,
      fundvalue,
      fundvalueUSD,
      fundName,
      symbol,
      managerFee,
      performanceFee,
      platformFee,
      unipriceInUSD,
      baseToken,
      openForPublic
    };
  } catch (error) {
    console.log(error)
  }
  
}

export async function validInvestor(web3, xfundaddress, account){

  const contract = new web3.eth.Contract(xWinFund, xfundaddress);
  const promiseData = await Promise.all([
    contract.methods.validInvestors(account).call(),
    contract.methods.openForPublic().call(),
  ]);
  let isValid = promiseData[0]
  let openForPublic = promiseData[1]
  if(openForPublic) return true;
  if(!openForPublic && isValid) return true;
  return false;
}

export async function SubscribeDirectAsync(web3, xfundaddress, account, subsAmt, decimal) {

  return new Promise((resolve, reject) => {    
    const contract = new web3.eth.Contract(xWinFund, xfundaddress);
    const gasParam = getGasPrice();
    const subsAmtInWei = convertToWei(subsAmt, decimal);
    contract.methods.deposit(subsAmtInWei).send({
      from: account,
      value: 0,
      ...gasParam
    })
    .on('confirmation', (confirmationNumber, receipt) => {
      resolve(receipt.transactionHash);
    })
    .on('error', (error, receipt) => {
      if (receipt !== undefined) reject(receipt.transactionHash);
    })
    .catch((err) => {
      console.log('error: %j', err);
      reject(err.message);
    });
  });
}

export async function RedeemAsync(web3, xfundaddress, account, amtRedeem) {
  
  return new Promise((resolve, reject) => {
    
    const contract = new web3.eth.Contract(xWinFund, xfundaddress);
    const gasParam = getGasPrice();
    const amtRedeemInWei = convertToWei(amtRedeem.toString());
    contract.methods.withdraw(amtRedeemInWei).send({
      from: account,
      value: 0,
      ...gasParam
    })
      .on('confirmation', (confirmationNumber, receipt) => {
        resolve(receipt.transactionHash);
      })
      .on('error', (error, receipt) => {
        if (receipt !== undefined) reject(receipt.transactionHash);
      })
      .catch((err) => {
        console.log('error: %j', err);
        reject(err.message);
      });
  });
}

export async function RebalanceAsync(web3, xfundaddress, account, positions) {
  
  return new Promise((resolve, reject) => {
    
    const destAddress = [];
    const targetWgts = [];

    positions.forEach((p) => {
      destAddress.push(p.taddress);
      targetWgts.push(xWinLib.roundTo(p.weight * 100, 0));
    });

    const contract = new web3.eth.Contract(xWinFund, xfundaddress);
    const gasParam = getGasPrice();
    contract.methods.Rebalance(destAddress, targetWgts).send({
      from: account,
      value: 0,
      ...gasParam
    })
      .on('confirmation', (confirmationNumber, receipt) => {
        resolve(receipt.transactionHash);
      })
      .on('error', (error, receipt) => {
        if (receipt !== undefined) reject(receipt.transactionHash);
      })
      .catch((err) => {
        console.log('error: %j', err);
        reject(err.message);
      });
  });
}

export async function CollectFeeAsync(web3, xfundaddress, account) {
  
  return new Promise((resolve, reject) => {
    
    const contract = new web3.eth.Contract(xWinFund, xfundaddress);
    const gasParam = getGasPrice();
    contract.methods.collectFundFee().send({
      from: account,
      value: 0,
      ...gasParam
    })
      .on('confirmation', (confirmationNumber, receipt) => {
        resolve(receipt.transactionHash);
      })
      .on('error', (error, receipt) => {
        if (receipt !== undefined) reject(receipt.transactionHash);
      })
      .catch((err) => {
        console.log('error: %j', err);
        reject(err.message);
      });
  });
}
