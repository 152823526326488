import React, { useEffect, useState } from 'react'
import Widget from "../../../components/Widget/Widget";
import useStyles from "../styles";
import clsx from 'clsx';
import {
    Tooltip,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableContainer,
} from '@material-ui/core'
import { Typography } from "../../../components/Wrappers";
import { ethers } from "ethers";
import strategyList from '../../../v2Config/xwinstrategies';
import fundV2 from "../../../abi/v2/fundV2.json"
import xWinLib from '../../../xWinLib';
import xWinUtils from '../../../xWinUtils';
import format from 'format-number'
import { getNetworkById } from '../../../utils/helper';
import { systemConfig } from '../../../configs/systemConfig';
import { Link } from 'react-router-dom';


const StrategyTVL = () => {
    
    const classes = useStyles();
    const provider = new ethers.providers.JsonRpcProvider(
        systemConfig.rpc
    )
    const { strategies } = strategyList
    const [strategiesArr, setStrategiesArr] = useState(strategies)
    const [newStrategiesArr, setNewStrategiesArr]= useState([])

    const loadStrategies = async () => {
        
        try {
            if (strategiesArr) {
                strategiesArr.map(async strategy => {
                    const v2Fund = new ethers.Contract(
                        strategy.contractaddress,
                        fundV2,
                        provider
                    )
                    const data = await v2Fund.getUnitPriceInUSD()
                    const data2 = await v2Fund.getVaultValuesInUSD()
                    let price = ethers.utils.formatEther(data.toString())
                    let tvl = ethers.utils.formatEther(data2.toString())
    
                    price = parseFloat(price).toFixed(4)
                    tvl = parseFloat(tvl).toFixed(2)
                    const newData = {
                        name: strategy.name,
                        price: price,
                        tvl: tvl,
                        strategyname: strategy.strategyname,
                        proxyfund: strategy.proxyfund,
                    }
                    setNewStrategiesArr(oldArray => [...oldArray, newData])
                })
            }
                
        } catch (error) {
            console.log(error)
        }
    }

    useEffect( ()=>{
        loadStrategies()
    },[])

    function CustomTableRow({ to, ...props }) {
      return (
        <TableRow component={Link} to={to} sx={{ textDecoration: 'none' }} {...props} hover />
      );
    }
    const DisplayTVL=()=>{
        
        return(
            newStrategiesArr.map(strategy=>(

              <CustomTableRow to={"/app/funddetailV2/"+strategy.proxyfund }>

                <TableCell className={classes.link}>
                  <Tooltip
                        title={strategy?.strategyname}
                        arrow={true}
                    >
                        {xWinUtils.getIcons(strategy?.name.toLowerCase())}
                    </Tooltip>
                      <br/>
                      <Typography
                      className={classes.columnShow}
                      color="text"
                      colorBrightness={"hint"}
                    >
                      {strategy.name}
                    </Typography>
                      
                </TableCell>
                <TableCell className={clsx(classes.contentText, classes.columnHide)}>
                      {strategy.strategyname}
                </TableCell>
                <TableCell>
                  <Typography
                      className={classes.positiveNum}
                    >
                    {format({ prefix: '$', suffix: '' })(xWinLib.roundTo(strategy.price, 3))}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography
                      className={classes.positiveNum}
                    >
                      {format({ prefix: '$', suffix: '' })(xWinLib.roundTo(strategy.tvl, 2))}
                    </Typography>
                </TableCell>

              </CustomTableRow>

            ))

        )
    
    }

  return (
    <Widget
    // bodyClass={classes.mainChartBody}
    title="Strategies TVL"
    disableWidgetMenu    >

    <TableContainer>
          <Table size="small"
            >
          <TableHead>
              <TableRow>
                  <TableCell width={"15%"}></TableCell>
                  <TableCell width={"40%"} className={clsx(classes.contentText, classes.columnHide)}>Strategy</TableCell>
                  <TableCell width={"30%"} className={classes.positiveNum}>Unit Price</TableCell>
                  <TableCell width={"30%"} className={classes.positiveNum}>
                      TVL
                  </TableCell>
              </TableRow>
          </TableHead>
            {DisplayTVL()}
          </Table>
      </TableContainer>

    </Widget>
  )

  }
export default StrategyTVL