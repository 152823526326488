import ERC20 from "../abi/BEP20.json";
import { getGasPrice, convertToWei, convertFromWei } from "../utils/helper";
import appGlobalConfig from "../appGlobalConfig";

export async function getTokenInfo(web3, token, targetaddress) {
  const contract = new web3.eth.Contract(ERC20, token);

  const promiseData = await Promise.all([
    contract.methods.decimals().call(),
    contract.methods.balanceOf(targetaddress).call()
  ]);
  let decimals = promiseData[0]
  let targetBal = promiseData[1]

  return {
    "decimals": decimals,
    "targetBal": convertFromWei(targetBal, decimals)
  };
}

export async function approveContract(web3, sourceToken, contractAddr, account) {

  return new Promise((resolve, reject) => {
    const latestGasPrice = getGasPrice();
    const contract = new web3.eth.Contract(ERC20, sourceToken);
  
    contract.methods.approve(contractAddr, appGlobalConfig.MAXALLOWANCE).send({
      from: account,
      value: 0,
      ...latestGasPrice
    })
    .on('confirmation', (confirmationNumber, receipt) => {
      resolve(receipt.transactionHash);
    })
    .on('error', (error, receipt) => {
      if (receipt !== undefined) reject(receipt.transactionHash);
    })
    .catch((err) => {
      console.log('error: %j', err);
      reject(err.message);
    });
  });
}

export async function IsTokenApproved(web3, amount, account, sourceToken, contractaddress) {
  if(amount === undefined) return false;
  if(parseFloat(amount) === NaN) return false;
  if(parseFloat(amount) === 0) return false;
  
  const amountInWei = convertToWei(amount) // globalWeb3.utils.toWei(subsAmount.toString())
  const contract = new web3.eth.Contract(ERC20, sourceToken);
  let contractAllowance = await contract.methods
  .allowance(account, contractaddress)
  .call();
  
  return parseFloat(contractAllowance) > parseFloat(amountInWei)  

}

