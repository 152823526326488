import React, { useState, useEffect, useRef, useContext } from 'react'
import xWinLib from '../../xWinLib'
import xWinUtils from '../../xWinUtils'
import format from 'format-number'
// components
import { Typography, Button, Avatar } from '../../components/Wrappers/Wrappers'
import CancelIcon from '@material-ui/icons/Cancel'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Link as RouterLink } from 'react-router-dom'
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import { SubscribeDirectAsync, getEstimateShares, validInvestor } from '../../utils/fundV2Interactor'
import { getTokenInfo } from '../../utils/erc20Interactor'
import confetti from 'canvas-confetti'
import Dot from '../../components/Sidebar/components/Dot'

import {
    Box,
    Grid,
    Slide,
    CardHeader,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Chip,
    ButtonGroup,
    TextField,
    InputAdornment,
} from '@material-ui/core'
import useStyles from './styles'
import { AppContext } from '../../context/AppProvider'
import { approveContract, IsTokenApproved } from '../../utils/erc20Interactor'

const DepositV2 = ({
    fundData,
    openModal,
    port,
    selectedWallet,
    userData,
    parentCallback,
    reset,
}) => {
    const classes = useStyles()
    const [loadingTxn, setLoadingTxn] = React.useState(false)
    const [openAdd, setOpenAdd] = React.useState(openModal)

    const [subsAmt, setsubsAmt] = useState('')
    const [estimateShares, setEstimateShares] = useState(0)
    const {
        isConnected,
        account,
        theWeb3,
        web3static,
        ETHBalance,
        USDTBalance,
        refreshAccount,
    } = useContext(AppContext)
    const [isApproval, setIsApproval] = useState(false)

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />
    })

    useEffect(() => {
        init()
    }, [account])

    const [open, setOpen] = React.useState(false)
    const [myFundBaseBalance, setMyFundBaseBalance] = React.useState('0')
    const [baseDecimals, setBaseDecimals] = React.useState('0')

    const refsnackbar = useRef(null)
    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }

    const init = async () => {
        if (account !== '---' && fundData?.baseToken !== undefined) {
            const tokenInfo = await getTokenInfo(
                web3static,
                fundData.baseToken,
                account
            )
            setMyFundBaseBalance(tokenInfo.targetBal)
        }
    }

    const calcEstimateShares = async (subAmount) => {
        if (account !== '---' && fundData?.baseToken !== undefined) {
            const shares = await getEstimateShares(web3static, fundData.contractaddress, subAmount)
            setEstimateShares(shares)
        }
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        reset
        setOpenAdd(false)
        setOpen(false)
        parentCallback(true)
    }

    const handleClickSubs = async event => {
        event.preventDefault()

        let isValid = await validInvestor(theWeb3, port.contractaddress, account)

        if(!isValid){
            showSnackBar("error", "This is private vault. You are not able to deposit unless you are whitelisted by the fund owner")
            return
        }
        
        if (subsAmt <= 0) {
            showSnackBar('error', 'Please input subscribe amount')
            return
        }
        if(subsAmt > userData?.userbasebal){
            showSnackBar("error", "Deposit amount is larger than available balance")
            return
        } 
        

        setLoadingTxn(true)

        SubscribeDirectAsync(theWeb3, port?.contractaddress, account, subsAmt, port.basedecimal)
            .then(res => {
                console.log(res)
                setLoadingTxn(false)
                showSnackBar('success', res)
                confetti()
                parentCallback(true)
                
            })
            .catch(err => {
                setLoadingTxn(false)
                console.log(err)
                showSnackBar('error', err)
                parentCallback(false)
            })
    }

    const handleClickApprove = async event => {
        event.preventDefault()

        setLoadingTxn(true)
        // web3, sourceToken, contractAddr, account
        approveContract(
            theWeb3,
            fundData.baseToken,
            port?.contractaddress,
            account
        )
            .then(res => {
                setLoadingTxn(false)
                setIsApproval(true)
            })
            .catch(err => {
                setLoadingTxn(false)
                console.log(err)
                setIsApproval(false)
            })
    }

    const handleClickAmt = ratio => async event => {
        event.preventDefault()
        const subAmount = xWinLib.roundDown(ratio * userData?.userbasebal, 6)
        setsubsAmt(subAmount)
        const isApp = await IsTokenApproved(
            theWeb3,
            subAmount,
            account,
            fundData.baseToken,
            port?.contractaddress
        )
        setIsApproval(isApp)
        calcEstimateShares(subAmount);
    }

    const handleClickClearAmt = () => event => {
        event.preventDefault()
        setsubsAmt('')
    }

    const handleAmtChange = async event => {
        event.preventDefault()
        const subsAmount = parseFloat(event.target.value)
        if (subsAmount >= 0 && subsAmount !== undefined) {
            setsubsAmt(subsAmount)
            const isApp = await IsTokenApproved(
                theWeb3,
                subsAmount,
                account,
                fundData.baseToken,
                port?.contractaddress
            )
            setIsApproval(isApp);
            calcEstimateShares(subsAmount);
        }else{
            setsubsAmt("")
        }
    }

    const getSubHeader = () => {

        return (
          <Typography color="primary">
            {format({prefix: "", suffix: ''})(xWinLib.roundTo(userData?.userbasebal,10))}
          </Typography>
        )
      }
    
    return (
        <Dialog
            open={openModal}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            fullScreen={false}
            className={classes.expanded}
            //maxWidth = {'sm'}
        >
            <SnackbarMessage ref={refsnackbar} />
            <CardHeader
              action={
                <IconButton 
                  className={classes.positiveNum}
                  onClick={reset}
                  aria-label="settings">
                  <CancelIcon />
                </IconButton>
              }
              avatar={
                <Avatar variant="round"> 
                    {<img alt={fundData.baseCcy} src={xWinLib.getIcon(fundData.baseCcy?.toLowerCase()+".png")} className={classes.avatar}/>}
                 </Avatar>
              }
              subheader={getSubHeader()}
            />

            <DialogContent>
                {xWinUtils.getProgress(loadingTxn)}
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'right'}
                >
                    <ButtonGroup size="small" color="primary" aria-label="">
                        <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.25)}
                        >
                            {'25%'}
                        </Button>
                        <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.5)}
                        >
                            {'50%'}
                        </Button>
                        <Button
                            className={classes.columnHide}
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.75)}
                        >
                            {'75%'}
                        </Button>
                        <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(1)}
                        >
                            {'100%'}
                        </Button>
                        <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickClearAmt()}
                        >
                            {'Clear'}
                        </Button>
                    </ButtonGroup>
                </Box>
            </DialogContent>
            <DialogContent>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'center'}
                >
                    <TextField
                        type="number"
                        // label={'Amount'}
                        margin="dense"
                        name="subsAmt"
                        onChange={handleAmtChange}
                        required
                        variant="outlined"
                        value={subsAmt}
                        fullWidth
                        InputProps={{
                            classes: {
                                input: classes.positiveNum,
                            },
                            style: { textAlign: 'right' },
                            startAdornment: (
                                <InputAdornment position="start">
                                    {fundData.baseCcy}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogContent>

                <Grid container spacing={0}>
                <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                    <Typography
                        color="text"
                        colorBrightness={'hint'}
                        variant={'caption'}
                        style={{ marginRight: 5 }}
                        // noWrap
                    >
                        {'Est Shares Receive'}
                    </Typography>
                </Grid>
                <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                    <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'right'}
                    >
                        {<img alt={port?.symbol} src={xWinLib.getIcon(port?.logo)} className={classes.avatarSmall}/>}
                        <Typography
                        variant={'body2'}
                        color={"primary"}
                        // className={classes.subTitle}
                        style={{ marginLeft: 5 }}
                        >
                        {format({ prefix: ' ', suffix: " "})(xWinLib.roundDown(estimateShares, 4))}
                    </Typography>
                    </Box>
                </Grid>
                </Grid>
            </DialogContent>    
            <DialogActions>
                <ButtonGroup
                    size="small"
                    color="primary"
                    aria-label="outlined primary button group"
                >
                    <Button
                        disabled={isApproval}
                        onClick={handleClickApprove}
                        color="secondary"
                        variant="contained"
                        startIcon={<AddCircleIcon />}
                    >
                        {'Approve'}
                    </Button>
                    <Button
                        disabled={!isApproval}
                        onClick={handleClickSubs}
                        color="primary"
                        variant="contained"
                        startIcon={<AddCircleIcon />}
                    >
                        {'Deposit'}
                    </Button>
                </ButtonGroup>
            </DialogActions>
        </Dialog>
    )
}

export default DepositV2
